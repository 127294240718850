import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';

import { Observable, switchMap, tap } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IJob } from '@modules/jobs/interfaces';
import { IResume } from '@modules/resumes/interfaces';
import { IntegrationService } from '@modules/settings/services';

import { AuthService, IProfile } from '@core/auth';
import { ModalService, NotificationService } from '@core/services';

import { IntegrationEnum } from '@common/shared/enums';
import { IUserIntegration } from '@common/shared/interfaces';

import {
  ModalAtsjobIdComponent,
  ModalAtsUsersComponent
} from '@client/shared/components/ats-button/components';

import { environment } from '@env';

@Component({
  selector: 'its-ats-button',
  templateUrl: './ats-button.component.html',
  styleUrls: ['./ats-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtsButtonComponent {
  env = environment;
  IntegrationEnum = IntegrationEnum;
  @Input() disabled = false;
  @Input() isRequest = true;
  @Input() resume: IResume;
  @Input() job: IJob;
  profile: IProfile;
  profile$: Observable<IProfile> = this.authService.getProfile().pipe(
    tap((profile) => {
      this.profile = profile;
    })
  );
  atsJobId = null;

  constructor(
    readonly integrationService: IntegrationService,
    readonly modalService: ModalService,
    readonly authService: AuthService,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly notificationService: NotificationService
  ) {}

  pushCandidate(ats: IntegrationEnum) {
    if (!this.haveUserIntegration(ats)) {
      this.getIntegrationUsers(ats);
      return;
    }

    this.getAtsJobId();

    if (!this.atsJobId) {
      this.getManualAtsJobId(ats);
      return;
    }

    if (ats === IntegrationEnum.Greenhouse) {
      this.integrationService
        .createGreenhouseResume(
          this.atsJobId,
          this.job.id,
          this.resume.id,
          this.isRequest
        )
        .pipe(finalize(() => (this.atsJobId = null)))
        .subscribe((res) => {
          this.disabled = true;
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  getIntegrationUsers(ats: IntegrationEnum) {
    this.integrationService.getGreenhouseCompanyUsers().subscribe((users) => {
      const user = users.find(
        (u) => u.primary_email_address === this.profile.email
      );
      if (user) {
        this.saveUserIntegrations(
          {
            type: IntegrationEnum.Greenhouse,
            integrationId: user.id
          },
          ats
        );
      } else {
        this.modalService.openComponent(
          ModalAtsUsersComponent,
          (user) => {
            if (user) {
              this.saveUserIntegrations(user, ats);
            }
          },
          {
            data: users
          }
        );
      }
    });
  }

  saveUserIntegrations(user: IUserIntegration, ats: IntegrationEnum) {
    return this.integrationService
      .saveUserIntegrations(user)
      .pipe(switchMap(() => this.authService.loadProfile()))
      .subscribe(() => {
        this.pushCandidate(ats);
      });
  }

  haveUserIntegration(ats: IntegrationEnum) {
    return this.profile?.integrations?.some((i) => i.type === ats);
  }

  getAtsJobId() {
    this.atsJobId = this.atsJobId || +this.job.title.match(/\(#(\d+)\)/)?.[1];
  }

  getManualAtsJobId(ats: IntegrationEnum) {
    this.modalService.openComponent(ModalAtsjobIdComponent, (id) => {
      if (id) {
        this.atsJobId = id;
        this.pushCandidate(ats);
      }
    });
  }
}
