import { SortEnum } from '../enums';

export interface IQuery {
  [key: string]: any;
  search?: any;
  sort?: string;
  dir?: SortEnum;
  page?: number; // from frontend side
  size?: number;
  offset?: number;
}
