import * as moment from 'moment';

import { ICompany, ICompanyResponse } from '@modules/companies/interfaces';

import {
  ActiveStatusEnum,
  AWSMediaPathEnum,
  PlanStatusEnum,
  PlanTypeEnum
} from '@common/shared/enums';

import { environment } from '@env';

export function companyFromResponse(company: ICompanyResponse): ICompany {
  const plan = company?.plan;
  return company
    ? {
        ...company,
        logo: company.logo
          ? `${environment.storageUrl}/${AWSMediaPathEnum.CompanyLogos}/${company.logo}`
          : '',
        banner: company.banner
          ? `${environment.storageUrl}/${AWSMediaPathEnum.CompanyBanners}/${company.banner}`
          : '',
        bannerMobile: company.banner
          ? `${environment.storageUrl}/${AWSMediaPathEnum.CompanyBanners}/sm_${company.banner}`
          : '',
        ogLogo: company.ogLogo
          ? `${environment.storageUrl}/${AWSMediaPathEnum.CompanyLogos}/${company.ogLogo}`
          : '',
        ogJobPlaceholder: company.ogJobPlaceholder
          ? `${environment.storageUrl}/${AWSMediaPathEnum.JobPlaceholders}/${company.ogJobPlaceholder}`
          : '',
        isActive: company.status === ActiveStatusEnum.Active,
        activeDaysLeft: company.dateToDelete
          ? moment().diff(moment(company.dateToDelete), 'days')
          : 0,
        cities: company?.offices?.length
          ? [...new Set(company.offices.map((o) => o.city))].join(', ')
          : '',
        siteValue: company?.site
          ? company?.site.replace('https://', '').split('/')[0]
          : '',
        plan: plan
          ? {
              ...plan,
              bannedAllowed:
                moment(plan?.featureExpired).diff(moment(), 'days') >= 0 &&
                plan?.feature?.customBanner,
              newCompanyPage:
                moment(plan?.featureExpired).diff(moment(), 'days') >= 0 &&
                plan?.planType !== PlanTypeEnum.Basic // For Standard and Premium
            }
          : null,

        premiumPlan:
          (plan?.status === PlanStatusEnum.Active ||
            plan?.status === PlanStatusEnum.Trial) &&
          plan?.planType === PlanTypeEnum.Premium
      }
    : null;
}
