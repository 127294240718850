import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({
  selector: '[itsDragDrop]'
})
export class DragDropDirective {
  @Input() multiple = false;
  @Input() disableUpload = false;
  @Output() fileDropped = new EventEmitter<any>();

  @HostBinding('style.outline-color') private borderColor = '#dae2ec';
  @HostBinding('style.outline-width') private borderWidth = '2px';
  @HostBinding('style.outline-style') private borderStyle = 'dashed';
  @HostBinding('style.border-radius') private borderRadius = '12px';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = this.disableUpload ? '#da4c4c' : '#77d1ef';
    this.borderWidth = '2px';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#dae2ec';
    this.borderWidth = '2px';
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#dae2ec';
    this.borderWidth = '2px';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      if (this.multiple) {
        this.fileDropped.emit(files);
      } else {
        this.fileDropped.emit(files[0]);
      }
    }
  }
}
