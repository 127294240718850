import { LanguageEnum, WebPushTypesEnum } from '../enums';

type Operation =
  | 'openWindow'
  | 'navigateLastFocused'
  | 'focusLastFocused'
  | 'focusLastFocusedOrOpen';

export interface IWebPushMessage {
  title?: string;
  body?: string;
  icon?: string;
  tag?: string;
  vibrate?: any;
  data?: {
    type?: WebPushTypesEnum;
    lang?: LanguageEnum;
    onActionClick?: {
      default: {
        operation: Operation;
        url?: string;
      };
    };
  };
}
