<mat-form-field appearance="outline" [@.disabled]="true">
  <mat-label>{{ placeholder}}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-select
    [@.disabled]="true"
    [formControl]="control"
    [compareWith]="compareFn"
    [disableOptionCentering]="true"
    (openedChange)="onOpenPanel($event)"
    [multiple]="true"
  >
    <mat-option appearance="outline" class="search-holder" [disabled]="true">
      <mat-label>Search</mat-label>
      <mat-form-field appearance="outline" [@.disabled]="true">
        <input
          autocomplete="off"
          [formControl]="searchControl"
          type="text"
          matInput
        />
       <!-- <erp-icon
          color="light-gray"
          variant="small"
          *ngIf="searchControl.value?.length"
          icon="action:cross-circle"
          matSuffix
          (click)="onClearValue()"
        ></erp-icon>-->
      </mat-form-field>
    </mat-option>
    <ng-container *ngFor="let option of options; trackBy: trackByFn">
      <mat-option [value]="valueFn(option)">
        {{ labelFn(option) }}
      </mat-option>
    </ng-container>
    <ng-container *ngIf="options && !options.length && searchControl.value?.length > 0">
      <mat-option class="mat-option-empty" disabled>
        {{'common.form.autocomplete.not-found' | translate}}
      </mat-option>
    </ng-container>
  </mat-select>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
     {{'common.form.errors.required' | translate}}
  </mat-error>
</mat-form-field>
