<mat-form-field appearance="outline" [@.disabled]="true">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    #searchElement
    [formControl]="control"
    autocomplete="off"
    [matAutocomplete]="autocomplete"
    [maxlength]="100"
    [readonly]="readonly"
    (input)="onInput()"
    (keydown.enter)="onEnterKeyDown($event)"
    (keyup.enter)="onSelectValue()"
  />
  <its-button
    *ngIf="control.enabled && clearable && length"
    matSuffix
    (click)="$event.stopPropagation(); onClearValue()"
    variant="icon"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>

  <its-button
    *ngIf="!length"
    matSuffix
    (click)="onSelectValue()"
    variant="icon"
  >
    <its-icon svgIcon="search"></its-icon>
  </its-button>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-autocomplete
    #autocomplete
    panelWidth="auto"
    [autoActiveFirstOption]="true"
    [displayWith]="displayFn"
    [@.disabled]="true"
    (opened)="onAutocompleteOpen()"
    (optionSelected)="onSelectOption($event)"
  >
    <ng-container *ngIf="options$ | async as options">
      <ng-container *ngIf="options && !options.length && length >= minlength">
        <mat-option class="mat-option-empty" disabled>
          {{ 'common.form.autocomplete.not-found' | translate }}
        </mat-option>
      </ng-container>

      <ng-container *ngFor="let option of options; trackBy: trackByFn">
        <mat-option [value]="option">
          {{ labelFn(option) }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="loading$ | async">
        <mat-option class="t-a_c" disabled>
          <mat-spinner color="primary" [diameter]="20"></mat-spinner>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('nomatch')">
    {{ 'common.form.autocomplete.errors.no-match' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <span
      *ngIf="control.getError('maxlength') as error"
      translate
      [translateParams]="{ maxlength: error.requiredLength }"
      >common.form.errors.max-length</span
    >
  </mat-error>
  <mat-error *ngIf="control.hasError('integer')">
    {{ 'common.form.errors.integer' | translate }}
  </mat-error>
</mat-form-field>
