import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { switchMap, timer } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { GlobalObjectService } from '@core/services/global-object.service';

import {
  PwaAddPromptComponent,
  PwaUpdatePromptComponent
} from '@client/shared/components/pwa';
import { NEW_VERSION_RELOAD } from '@client/shared/constants';
import { PwaMobileTypeEnum } from '@client/shared/enums';

import { BottomSheetService } from './';

// https://pupli.net/2019/07/push-notifications-with-angular-express/
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  constructor(
    readonly swUpdate: SwUpdate,
    readonly bottomSheetService: BottomSheetService,
    readonly platform: Platform,
    readonly globalObjectService: GlobalObjectService
  ) {
    if (this.globalObjectService.isPlatformBrowser()) {
      this.checkPwaUpdates();
      this.initPwaPrompt();
    }
  }

  initPwaPrompt() {
    const window = this.globalObjectService.getWindow();
    if (this.platform.IOS) {
      const isInStandaloneMode =
        'standalone' in window?.navigator && window?.navigator?.['standalone'];
      if (!isInStandaloneMode) {
        this.openPromptComponent(PwaMobileTypeEnum.Ios);
      }
      return;
    }
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent(PwaMobileTypeEnum.Android);
      });
      return;
    }
  }

  openPromptComponent(mobileType: PwaMobileTypeEnum) {
    timer(3000)
      .pipe(take(1))
      .subscribe(() =>
        this.bottomSheetService.openSheet(
          PwaAddPromptComponent,
          (res) => {
            if (res) {
              this.promptEvent.prompt();
            }
          },
          {
            data: mobileType
          }
        )
      );
  }

  checkPwaUpdates() {
    if (this.swUpdate.isEnabled) {
      timer(0, 30 * 60 * 1000) // 30min
        .pipe(
          switchMap(() =>
            this.swUpdate.versionUpdates.pipe(
              filter(
                (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
              ),
              map((evt) => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion
              }))
            )
          )
        )
        .subscribe((res) => {
          this.globalObjectService.setStorageData(
            NEW_VERSION_RELOAD,
            '1',
            'sessionStorage'
          );
          /*this.bottomSheetService.openSheet(
            PwaUpdatePromptComponent,
            (res) => {
              if (res) {
                this.globalObjectService.removeStorageData(
                  NEW_VERSION_RELOAD,
                  'sessionStorage'
                );
                this.globalObjectService.getLocation()?.reload();
              }
            },
            {
              disableClose: true
            }
          );*/
        });
    }
  }
}
