import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@core/auth';
import { GlobalObjectService, HelperService } from '@core/services';

import { Destroyable } from '@client/shared/abstracts';
import { AuthQueryEnum } from '@client/shared/enums';

@Component({
  selector: 'its-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent extends Destroyable implements OnInit {
  AuthQueryEnum = AuthQueryEnum;
  authComponent: AuthQueryEnum;

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly authService: AuthService,
    readonly router: Router,
    readonly helperService: HelperService,
    readonly globalObjectService: GlobalObjectService,
    readonly changeDetector: ChangeDetectorRef
  ) {
    super();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((param) => {
        if (Object.values(AuthQueryEnum).includes(param.auth)) {
          this.authComponent = param.auth;
        } else {
          this.authComponent = null;
        }
        if (param.google) {
          this.authService.setToken(param.google);
          this.authService.updateProfileLang().subscribe(() => {
            this.authService.loadProfile().subscribe();
          });
          this.onClosedStart();
        }
        this.changeDetector.markForCheck();
      });
  }

  ngOnInit(): void {}

  onClosedStart() {
    this.authComponent = null;
    setTimeout(() => {
      const queryParams = {
        ...this.activatedRoute.snapshot.queryParams,
        auth: null,
        google: null,
        agent: null
      };
      this.helperService.changeQueryParam(queryParams);
    }, 0);
  }
}
