<article class="public-text">
  <mat-card>
    <mat-card-content>
      <h2 class="t-a_c">
        <b>Terms of Use</b>
      </h2>
      <div class="m-b_12">
        Please carefully read the Terms of Use of the Kupno job search service
        (hereinafter referred to as the Terms) before using the website
        {{ origin }} (hereinafter referred to as the Site). You must review and
        accept these Terms if you wish to use the Site. If you do not agree with
        these Terms or with individual clauses outlined in them, you may refuse
        to use the Site and not use it.
      </div>

      <ol>
        <li>
          <h3>1. Introduction</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">1.1.</div>
              <div>
                These Terms are a legally binding agreement between the user of
                the Site and the individual - entrepreneur Lisnyi Roman
                Vasylioviych (taxpayer registration number - 3284219853),
                registered at the address: Lviv region, Zolochivskyi district,
                Zakomaria village (hereinafter referred to as the Site Owner).
              </div>
            </li>
            <li>
              <div class="point-number">1.2.</div>
              <div>
                These Terms govern the mutual rights and obligations of Users
                and the Site Owner regarding the use of the Site and the
                services provided through it.
              </div>
            </li>
            <li>
              <div class="point-number">1.3.</div>
              <div>
                Users of the Site, by the registered profile, can be a User (an
                individual looking for a job) and a User with a company (an
                employer looking for employees). In no case can the user of the
                Site be an individual or legal entity registered on the
                territory of the Russian Federation and/or the Republic of
                Belarus or cooperating with them.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>2. Basic provisions</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">2.1.</div>
              <div>
                To use the functionality and services of the Site, the User must
                register. Using the services of the Site and registering on the
                Site means full and unconditional acceptance of these Terms.
              </div>
            </li>
            <li>
              <div class="point-number">2.2.</div>
              <div>
                These Terms and all changes to them come into force from the
                moment they are published on the Site. Changes to the Terms may
                be made by the Site Owner at any time. If the User does not
                agree with the changes, he must stop using the Site. The fact of
                prolonging the use of the Site is confirmation of the User’s
                consent and acceptance of the relevant version of the Terms.
              </div>
            </li>
            <li>
              <div class="point-number">2.3.</div>
              <div>
                Registration on the site is free. For further use of the Site
                and all its functions, the User must fill out the appropriate
                registration form - User profile. To do this, one must fill in
                the appropriate fields in the User’s profile, including login
                and password, as well as the email address to which the User
                will receive a link to activate the User’s account, search
                results or notifications from the Site Owner. Publication by the
                User of his resume is free of charge. The publication by the
                User of job advertisements with the company is paid within the
                limits of the purchased package or individual publications.
                Additional services on the Site are paid. Payment for services
                is made by Section 4 of these Terms.
              </div>
            </li>
            <li>
              <div class="point-number">2.4.</div>
              <div>
                The purpose of using the Site is to ensure high-quality and
                effective placement of information by The user with the company
                (ads, orders, offers, etc.) as well as search for candidates for
                vacancies or search for vacancies by the User in the field of IT
                technologies.
              </div>
            </li>
            <li>
              <div class="point-number">2.5.</div>
              <div>
                <div class="m-b_8">With the assistance of the Site</div>
                <div>
                  <b>A user gets a possibility:</b>
                </div>
                <ol class="bullet-items">
                  <li>to publish your resume;</li>
                  <li>to access all job postings on the Site;</li>
                  <li>
                    to select job advertisements by searching advertisements on
                    the Site, establish contact with the User with the company
                    and conduct correspondence using a profile on the Site;
                  </li>
                  <li>
                    to improve your profile on the Site by purchasing more
                    extended package of services;
                  </li>
                  <li>
                    to access to posted information using his own profile on the
                    Site;
                  </li>
                </ol>
                <div>
                  <b>A user with a company gets a possibility:</b>
                </div>
                <ol class="bullet-items">
                  <li>
                    to post vacancies, which will be available for viewing by
                    other site users;
                  </li>
                  <li>to receive feedback on vacancies;</li>
                  <li>
                    to find resumes in the resume database according to various
                    criteria (by field of activity, by profession, etc.);
                  </li>
                  <li>
                    to select employees by searching for resumes on the site,
                    establish contact with the User and conduct correspondence
                    using a profile on the site;
                  </li>
                  <li>
                    to send an offer to a User who has an active resume on the
                    Site and/or added to the User’s contact list with the
                    company, after successfully establishing contact with the
                    User on the Site;
                  </li>
                  <li>
                    to improve one’s profile on the site by purchasing a more
                    expanded package of services, as well as to place a banner
                    in your profile and a logo on the site;
                  </li>
                  <li>
                    to gain access to posted information using your profile on
                    the site.
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>3. Rights and obligations of the parties</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">3.1.</div>
              <div>
                Rights and Responsibilities of Users
                <ol class="point-items">
                  <li>
                    <div class="point-number">3.1.1.</div>
                    <div>
                      The user is responsible for the truthfulness and accuracy
                      of filling out his profile, as well as all information
                      posted by him on the Site.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.2.</div>
                    <div>
                      The following are prohibited on the Site:
                      <ol class="bullet-items">
                        <li>
                          calls for a violent change or overthrow of the
                          constitutional order or the seizure of state power;
                          calls for changes in administrative boundaries or the
                          state border of Ukraine, violation of the order
                          established by the Constitution of Ukraine; calls for
                          pogroms, arson, destruction of property, seizure of
                          buildings or structures, and forced eviction of
                          citizens; calls for aggression or resolution of a
                          military conflict;
                        </li>
                        <li>
                          obscene direct and indirect images in anyone's
                          direction, in particular, based on ethnic, racial or
                          religious affiliation, as well as statements that are
                          chauvinistic;
                        </li>
                        <li>
                          to use someone else's address, email or address that
                          the User does not have the right to use;
                        </li>
                        <li>
                          offensive behaviour, publication of deliberately false
                          information, as well as information that may harm the
                          site owner and other users or violate their rights;
                        </li>
                        <li>
                          sending spam, as well as developing software for
                          carrying out such mailings;
                        </li>
                        <li>
                          to post information that contradicts concluded and
                          duly ratified international treaties of Ukraine, the
                          current legislation of Ukraine, as well as
                          advertisements related to the gambling business
                          (except for cases where activities related to the
                          organisation, conduct and provision of access to
                          gambling are carried out in the country where gambling
                          is not prohibited), financial pyramids, religious
                          sects, occult and erotic services, as well as Trojan
                          programs, viruses, Internet worms, programs for
                          hacking or unauthorised access to information and
                          other “hacker” programs that can harm software and/or
                          components of information systems ;
                        </li>
                        <li>
                          to use the Site to search for employees to work in the
                          Russian Federation, the Republic of Belarus and the
                          temporarily occupied territories of Ukraine, as well
                          as post resumes for Users registered in these
                          countries or cooperating with them;
                        </li>
                        <li>
                          to change your profile photo using frames or other
                          graphic elements, as well as using photos of third
                          parties;
                        </li>
                        <li>
                          any actions directly prohibited by the current
                          legislation of Ukraine.
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.3.</div>
                    <div>
                      It is prohibited to publish on the site, in particular,
                      but not exclusively, the following information:
                      <ol class="bullet-items">
                        <li>
                          any messages and announcements related to obtaining
                          illegal income;
                        </li>
                        <li>
                          any messages and announcements containing information
                          about the illegal distribution of narcotic drugs,
                          psychotropic substances, their analogues and
                          precursors;
                        </li>
                        <li>
                          explicit and hidden advertising of third-party sites,
                          including referral links and links to sites of a
                          similar nature;
                        </li>
                        <li>advertising for quick money;</li>
                        <li>
                          anything that provokes conflicts based on political,
                          racial, religious, national or other hostility;
                        </li>
                        <li>anything that contains obscene language;</li>
                        <li>
                          advertisements for the sale of any goods and services;
                        </li>
                        <li>integrated advertising in personal messages;</li>
                        <li>
                          information provided in resumes/vacancies in
                          inappropriate categories;
                        </li>
                        <li>
                          vacancies whose description does not contain detailed
                          information about functional responsibilities and/or
                          tasks;
                        </li>
                        <li>
                          vacancies containing information regarding a
                          significantly smaller budget compared to similar
                          proposals;
                        </li>
                        <li>
                          vacancies that do not correspond to the specialisation
                          of the Site;
                        </li>
                        <li>
                          vacancies that involve writing or posting reviews of
                          products, services or services;
                        </li>
                        <li>
                          vacancies that involve searching for sponsors for
                          promotions, sweepstakes or similar events;
                        </li>
                        <li>
                          vacancies created to attract people to pyramid
                          schemes;
                        </li>
                        <li>
                          vacancies related to passing exams, performing tests,
                          and passing tests for third parties;
                        </li>
                        <li>
                          vacancies related to the provision of intimate
                          services, as well as the production, broadcast or
                          retransmission of erotic, pornographic photographs,
                          video and audio materials;
                        </li>
                        <li>
                          vacancies related to the activities of religious
                          sects, as well as the provision of occult services;
                        </li>
                        <li>
                          resumes/vacancies related to the offer of intimate and
                          escort services, any investment of funds, work that
                          violates the current legislation of Ukraine,
                          participation in financial pyramids and other dubious
                          structures.
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.4.</div>
                    <div>
                      The user and the company have the right to publish an
                      advertisement, both regular and hot, under the terms of
                      the purchased service package on the Site.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.5.</div>
                    <div>
                      A user can register no more than one account. Registration
                      of multiple accounts of the same type is prohibited, as is
                      the transfer of login parameters to the Site to other
                      Users. Transfer of accounts to third parties is
                      prohibited. One account belongs to one person. Buying and
                      selling accounts is prohibited.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.6.</div>
                    <div>
                      User has no right to gain unauthorised access to any part
                      or feature of the Site or other systems or networks
                      connected to the Site, or any server of the Site, through
                      any unlawful means.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.7.</div>
                    <div>
                      The User has the right to provide the Site Owner with his
                      wishes or suggestions for improving the operation of the
                      Site.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.8.</div>
                    <div>
                      The user gives consent to the site owner to use
                      photos/videos and other images created as part of
                      cooperation on the site in any way and on any sites,
                      social networks, YouTube, etc. to advertise the site.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.9.</div>
                    <div>
                      The user acknowledges and agrees that the site owner has
                      access to all content on the site, including personal
                      messages on the site.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">3.2.</div>
              <div>
                Rights and obligations of the Site Owner.
                <ol class="point-items">
                  <li>
                    <div class="point-number">3.2.1.</div>
                    <div>
                      The site owner determines the rules of behaviour on the
                      site and reserves the right to demand their implementation
                      from the user.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.2.</div>
                    <div>
                      The Site Owner reserves the right to make changes to the
                      Terms and all their integral parts without any special
                      notification to the User about such changes.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.3.</div>
                    <div>
                      The Site owner reserves the right to make changes to the
                      information published on the Site at any time.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.4.</div>
                    <div>
                      The site owner has the right to block a job advertisement
                      or resume if it receives a significant number of
                      complaints from other users.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.5.</div>
                    <div>
                      The Site Owner is not responsible for the accuracy of the
                      information published within the Site and/or the
                      correctness of the statements of its Users.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.6.</div>
                    <div>
                      If the User does not agree with the decision of the site
                      owner, he has the right to write a letter justifying his
                      disagreement to the Site owner
                      <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                      >.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.7.</div>
                    <div>
                      The Site Owner may deprive a User of the right to use the
                      Site if he violates these Terms.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.8.</div>
                    <div>
                      The Site Owner has the right to block or delete the User’s
                      account, as well as prohibit access using any information
                      to the Site and delete any User content without giving
                      reasons, including in any of the following cases:
                      violation by the User of the Terms or provisions of other
                      documents, provided for in these Terms; the User commits
                      actions that entail or may harm the business reputation of
                      the Site and/or other Users; receiving a significant
                      number of complaints from other users; providing false
                      information in the User's public profile; publishing
                      private correspondence with other Users on publicly
                      accessible resources; violation of the principles of fair
                      cooperation and mutual respect and understanding.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.9.</div>
                    <div>
                      The Site Owner has the right to review, control, and
                      delete public information specified by the User on the
                      Site if it does not violate these Terms. The Site Owner is
                      not responsible and does not endorse or guarantee
                      information about opinions, views, advice or
                      recommendations posted or submitted by Users.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.10.</div>
                    <div>
                      The site owner is not responsible for any errors,
                      inaccuracies or omissions in the registration or uploading
                      of content on the site and cannot be held liable for any
                      damages (including lost profits) arising from such errors,
                      inaccuracies or omissions.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.11.</div>
                    <div>
                      The Site Owner reserves the right to advertise the
                      services of the Site, at its discretion, by processing the
                      content and displaying it on the relevant pages of the
                      Site and beyond, with the right to structure, edit,
                      integrate, divide, regroup, translate the text part of the
                      content into other languages, etc. .d. The Site Owner is
                      not responsible for any errors, distortions, omissions,
                      inaccuracies, deletions, or content defects in connection
                      with the translation of the text part of the content into
                      other languages.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.12.</div>
                    <div>
                      The procedure for processing the personal data of Site
                      users is provided by the Privacy Policy
                      <a
                        [routerLink]="
                          '/' + ROUTES_DATA.PUBLIC.children.POLICY.url
                        "
                        >{{ origin }}/{{
                          ROUTES_DATA.PUBLIC.children.POLICY.url
                        }}</a
                      >, which is an integral part of these Terms. Please, refer
                      to the document.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.13.</div>
                    <div>
                      The Site Owner has the right to send information messages
                      to the User solely within the framework of using the Site.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.14.</div>
                    <div>
                      The Site Owner reserves the right to require verification
                      of the User’s profile if suspicious activity is detected
                      in the said profile.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.15.</div>
                    <div>
                      The Site Owner has the right to request and receive from
                      the User any data necessary for his verification.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.16.</div>
                    <div>
                      The Site Owner has the right to verify information about
                      the User using any publicly available and official
                      databases, including databases of payment systems and
                      their analogues. At the same time, the User reimburses the
                      Site Owner for the costs of such verification. The cost of
                      verification is pre-agreed with the User.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>4. Payment procedure</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">4.1.</div>
              <div>
                The site owner publishes a list of paid services on the site,
                including a description of such services and information about
                their cost. The User has the right to order and receive paid
                services from the Site by selecting the desired service on the
                Site.
              </div>
            </li>
            <li>
              <div class="point-number">4.2.</div>
              <div>
                The Site Owner has the right, at his discretion, to introduce
                and change fees for certain paid services of the Site, of which
                Users will be notified in advance.
              </div>
            </li>
            <li>
              <div class="point-number">4.3.</div>
              <div>
                In case of violation of the rules of the Site, funds used to
                purchase services on the Site are not returned. However, if the
                User was unable to use the purchased service due to the full
                fault of the Site Owner, the funds must be returned to the
                payment card from which the payment was made in an amount
                corresponding to the full cost of this service.
              </div>
            </li>
            <li>
              <div class="point-number">4.4.</div>
              <div>
                The User is obliged to pay for the services of the Site. Payment
                methods are indicated on the website, including using the LiqPay
                payment system.
              </div>
            </li>
            <li>
              <div class="point-number">4.5.</div>
              <div>
                Payment is made by the User to the account of the Site Owner and
                is considered to be made at the moment the funds are received by
                the Site Owner.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>5. Publication of a vacancy announcement and resume</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">5.1.</div>
              <div>
                The user with the company has the right to publish a vacancy
                announcement. A vacancy announcement must contain information
                about only one vacancy. A user and a company cannot post several
                identical advertisements (ads with the same semantic content) at
                the same time.
              </div>
            </li>
            <li>
              <div class="point-number">5.2.</div>
              <div>
                The user and the company pay for the service of publishing an
                advertisement.
              </div>
            </li>
            <li>
              <div class="point-number">5.3.</div>
              <div>
                It is prohibited to indicate the age of candidates in a vacancy
                announcement or resume, to offer jobs only to women or only to
                men, except for specific work that can only be performed by
                persons of the appropriate gender, to present requirements that
                prefer female or male gender (except for cases determined by the
                current legislation of Ukraine), representatives of the relevant
                race, skin colour, political, religious and other beliefs,
                members of trade unions or other associations of citizens,
                ethnic and social origin, property status, place of residence,
                linguistic or other characteristics, as well as the information
                specified in paragraph 3.1.3 of given Terms of Use.
              </div>
            </li>
            <li>
              <div class="point-number">5.4.</div>
              <div>
                The User has the right to publish his resume on the Site.
              </div>
            </li>
            <li>
              <div class="point-number">5.5.</div>
              <div>
                The User has the right to free access to all job postings on the
                Site.
              </div>
            </li>
            <li>
              <div class="point-number">5.6.</div>
              <div>
                The Site Owner reserves the right to delete job advertisements
                and resumes at any time if they do not meet the requirements of
                these Terms, and at its discretion without specifying any
                reason.
              </div>
            </li>
            <li>
              <div class="point-number">5.7.</div>
              <div>
                The Site Owner is not responsible for the content of job
                advertisements and resumes, as well as for damage or other
                losses that the User may experience.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>6. Intellectual property rights.</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">6.1.</div>
              <div>
                All components of the Site and the Site as a whole belong to the
                Site owner and are protected by legislation in the field of
                intellectual property rights. All rights reserved.
              </div>
            </li>
            <li>
              <div class="point-number">6.2.</div>
              <div>
                The User acknowledges and agrees that all content and materials
                available on the Site are protected, including, but not limited
                to, copyright, trademarks, and trade secrets. Reproduction,
                copying or distribution for commercial purposes of any materials
                or elements of the site without the prior permission of the site
                owner is prohibited.
              </div>
            </li>
            <li>
              <div class="point-number">6.3.</div>
              <div>
                The User with the company grants the Site Owner a perpetual
                non-exclusive right to publicly display signs for goods and
                services and/or logos appropriate to the User with the company
                by posting them on the Site (until the User withdraws such
                consent).
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>7. Responsibility of the parties</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">7.1.</div>
              <div>
                For failure to fulfil or improper fulfilment of their
                obligations under these Terms, the parties to these Terms are
                liable under the current legislation of Ukraine.
              </div>
            </li>
            <li>
              <div class="point-number">7.2.</div>
              <div>
                The Site Owner does not participate in disputes (in particular,
                litigation) between Users, as well as with the participation of
                third parties.
              </div>
            </li>
            <li>
              <div class="point-number">7.3.</div>
              <div>
                The site owner is not responsible for:
                <ol class="bullet-items">
                  <li>for the quality of services provided by Users;</li>
                  <li>
                    for any possible losses and/or lost profits of Users caused
                    as a result of the use or inability to use the Site;
                  </li>
                  <li>
                    or the actions of users who violate the current legislation
                    of Ukraine;
                  </li>
                  <li>
                    for information and materials posted by Users on the Site.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">7.4.</div>
              <div>
                The Site Owner does not guarantee that the operation of the Site
                will be uninterrupted, error-free or free from malware or other
                defects, but undertakes to make every effort to ensure the
                error-free and uninterrupted operation of the Site. If the User
                is not satisfied with the conditions and quality of the Site, he
                may stop using the Site or contact the Site support service.
              </div>
            </li>
            <li>
              <div class="point-number">7.5.</div>
              <div>
                The User agrees not to violate or attempt to violate the
                provisions of these Terms. If the Site Owner, in its sole
                discretion, determines that the User has violated or attempted
                to violate these Terms, the User's access to the Site may be
                terminated.
              </div>
            </li>
            <li>
              <div class="point-number">7.6.</div>
              <div>
                The User is responsible for all actions performed on behalf of
                the User on the Site.
              </div>
            </li>
            <li>
              <div class="point-number">7.7.</div>
              <div>
                The user cannot transfer his login and password to enter the
                Site to third parties. The user is responsible for the actions
                of third parties from his account in the same way as if he did
                them personally.
              </div>
            </li>
            <li>
              <div class="point-number">7.8.</div>
              <div>
                The User is responsible for the quality and timeliness of
                fulfilment of obligations accepted on the Site.
              </div>
            </li>
            <li>
              <div class="point-number">7.9.</div>
              <div>
                The User agrees to release and hold harmless the Site Owner from
                any claims or demands from any third parties in connection
                with/or as a result of the User's violation of these Terms, the
                current legislation of Ukraine or the rights of third parties.
              </div>
            </li>
          </ol>
        </li>
      </ol>
      <div>
        If you have any questions and/or suggestions regarding these Terms,
        please contact the Site support service by email:
        <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
        >.
      </div>
    </mat-card-content>
  </mat-card>
</article>
