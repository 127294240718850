import intlTelInput from 'intl-tel-input';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NgControl,
  Validators
} from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GlobalObjectService } from '@core/services';

import { IPhone } from '@common/shared/interfaces';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';

@Component({
  selector: 'its-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class PhoneComponent<T extends IPhone>
  extends BaseControlComponent<T>
  implements OnInit, OnDestroy
{
  @ViewChild('phoneInput', { static: true }) phoneInput: ElementRef;
  private intlTelInstance: any;
  readonly destroyed$: Observable<unknown>;
  type = 'tel';
  @Input() placeholder = '';
  @Input() autocomplete = 'off';
  @Input() clear = false;
  @Input() icon: string;

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly iconAction = new EventEmitter<boolean>();
  @Output() readonly numberChanged = new EventEmitter<string>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null,
    readonly globalObjectService: GlobalObjectService
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });

    this.intlTelInstance = intlTelInput(this.phoneInput.nativeElement, {
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      excludeCountries: ['ru', 'by', 'ir'],
      preferredCountries: ['ua', 'pl'],
      customPlaceholder: (selectedCountryPlaceholder, selectedCountryData) => {
        return `e.g. +${selectedCountryData.dialCode} ...`;
      }
    });

    this.setFullNumber(true);

    this.phoneInput.nativeElement.addEventListener('countrychange', () => {
      this.checkValidation();
    });
  }

  ngOnDestroy() {
    if (this.intlTelInstance) {
      this.intlTelInstance.destroy();
    }
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = (this.control.value as string) || '';
    const trimmedValue = value.trim().replace(/\s{2,}/g, ' ');

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
    if (value) {
      this.setFullNumber();
    }
  }

  checkValidation() {
    if (!this.intlTelInstance.isValidNumber()) {
      if (
        this.control.hasValidator(Validators.required) ||
        (this.control.value || '').trim()?.length
      ) {
        this.control.setErrors({ phone: true });
      }
    } else {
      this.control.setErrors(null);
    }
  }

  clearValue() {
    this.control.setValue('');
  }

  setFullNumber(init = false) {
    const value = this.intlTelInstance.getNumber(
      (this.globalObjectService?.getWindow() as any)?.intlTelInputUtils
        ?.numberFormat?.E164
    );
    const initialValue = init ? this.control.value : '';
    const data = initialValue || value;

    if (initialValue) {
      this.intlTelInstance.setNumber(data);
    }

    if (this.control.value) {
      let timerId = setInterval(() => {
        if (this.intlTelInstance.getNumber()) {
          clearInterval(timerId);
          this.control.setValue(data, {
            onlySelf: true,
            emitEvent: !init
          });
          this.checkValidation();
          this.changeDetector.markForCheck();
        }
      }, 100);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
  onIconAction() {
    this.iconAction.emit(true);
  }
}
