import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { of, Subject } from 'rxjs';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';

import { IChat, IMessage } from '@modules/chat/interfaces';
import { ChatService, MessageService } from '@modules/chat/services';
import { IJob } from '@modules/jobs/interfaces';

import {
  GlobalObjectService,
  ModalService,
  SocketService,
  UploaderService
} from '@core/services';

import {
  ActiveStatusEnum,
  InviteStatusEnum,
  MessageTypeEnum
} from '@common/shared/enums';
import { IQuery } from '@common/shared/interfaces';

import { ROUTES_DATA } from '@client/shared/constants';
import { TemplateTypeEnum } from '@client/shared/enums';
import { IFile } from '@client/shared/interfaces';

const MAX_TEXTAREA_HEIGHT = 140;
const MIN_SCROLL_CONTAINER_HEIGHT = 334;

@Component({
  selector: 'its-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessengerComponent implements OnInit, OnDestroy {
  TemplateTypeEnum = TemplateTypeEnum;
  destroyed$ = new Subject();
  ActiveStatusEnum = ActiveStatusEnum;
  InviteStatusEnum = InviteStatusEnum;
  ROUTES_DATA = ROUTES_DATA;
  MAX_TEXTAREA_HEIGHT = MAX_TEXTAREA_HEIGHT;
  MIN_SCROLL_CONTAINER_HEIGHT = MIN_SCROLL_CONTAINER_HEIGHT;
  heightScrollbar = null;
  textareaHeight = 55;
  prevTextareaHeight = this.textareaHeight;
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild('messagesList', { static: false }) messagesList?: ElementRef;
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('frameChatForm') frameChatForm: ElementRef;
  form: FormGroup;
  currentPage = 0;
  messagesCount = 0;
  messages: IMessage[] = [];
  requestInProgress = false;
  query: IQuery = {
    page: 1,
    size: 50
  };
  files: IFile[] = [];

  _chat: IChat;
  @Input() job: IJob;

  @Input() set chat(chat: IChat) {
    if (chat) {
      this._chat = chat;
      this.destroySubject();
      this.buildForm();

      this.destroyed$ = new Subject();

      this.getChatMessages();
      this.listenNewMessages();
    }
  }

  get chat() {
    return this._chat;
  }

  @Input() inMobileDrawer = false;
  _chatDrawer: IChat;
  @Input() set chatDrawer(chat: IChat) {
    this._chatDrawer = chat;
    this.chat = chat;
  }

  get chatDrawer() {
    return this._chatDrawer;
  }

  @Output() detectChanges = new EventEmitter<boolean>();
  messagesToRead = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.getScrollbarHeight();
  }
  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly messageService: MessageService,
    readonly modalService: ModalService,
    readonly uploaderService: UploaderService,
    readonly socketService: SocketService,
    readonly chatService: ChatService,
    readonly globalObjectService: GlobalObjectService,
    readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getScrollbarHeight();
  }

  ngOnDestroy() {
    this.destroySubject();
  }

  destroySubject() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  listenNewMessages() {
    this.messageService
      .getNewMessage()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((message) => {
        if (message?.chat?.id === this.chat?.id) {
          this.messages.push(message);
          if (!message.owner) {
            if (document.visibilityState === 'visible') {
              this.subtractNewMessages();
              this.socketService.readMessages([message.id]);
            } else {
              this.messagesToRead.push(message.id);
            }
          }
          this.changeDetector.markForCheck();
          this.scrollToBottom();
        }
      });
  }

  onScrollTop(event: any) {
    if (this.messages.length < this.messagesCount && !this.requestInProgress) {
      ++this.query.page;
      this.getChatMessages(true);
    }
  }

  onSendMessage() {
    const message = this.form.value.message?.trim();
    if (message || this.files.length) {
      (this.files.length
        ? this.uploaderService.uploadMessageFiles(this.files, this.chat.id)
        : of(null)
      )
        .pipe(
          mergeMap((res) => {
            const files = res || [];
            return this.messageService.sendMessage(this.chat.id, {
              message,
              files,
              type: MessageTypeEnum.NewMessage
            });
          })
        )
        .subscribe(() => {
          this.files = [];
          this.form.reset();
          this.textAreaAdjust();
        });
    }
  }

  textareaChange(ev: any) {
    if (
      ev.keyCode === 13 &&
      !ev.shiftKey &&
      this.globalObjectService.isDesktop()
    ) {
      this.onSendMessage();
    }
  }

  buildForm(value = null) {
    this.form = null;
    this.changeDetector.detectChanges();
    this.form = new FormGroup({
      message: new FormControl(
        {
          value,
          disabled: !this.chat.enabled
        } || ''
      )
    });
    this.changeDetector.markForCheck();
  }

  textAreaAdjust() {
    const el = this.textarea.nativeElement;
    const lineHeight = 24;
    el.style.height = '1px';
    const textareaHeight =
      el.scrollHeight < lineHeight
        ? lineHeight + el.scrollHeight
        : el.scrollHeight;
    el.style.height = `${textareaHeight}px`;

    if (this.prevTextareaHeight !== textareaHeight) {
      this.prevTextareaHeight = textareaHeight;
      this.scrollToBottom();
    }
    this.textareaHeight =
      textareaHeight > MAX_TEXTAREA_HEIGHT
        ? MAX_TEXTAREA_HEIGHT
        : textareaHeight;
    this.getScrollbarHeight();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      if (this.componentRef && this.componentRef.directiveRef) {
        this.componentRef.directiveRef.scrollToBottom();
      }
    }, 0);
  }

  scrollToElement(el: HTMLElement): void {
    el.scrollIntoView();
  }

  getScrollbarHeight() {
    const ww = window.innerWidth;
    const siteContentSizes = '228px'; // header(80) + content paddings(100) + container paddings (24 * 2)
    const desktopChatInvReqRow = '71px';
    const frameChatFormH =
      this.frameChatForm?.nativeElement?.offsetHeight || 55;

    const drawerTitleHeight = ww > 1023 ? '64px' : '52px';
    const drawerContentPadding = ww > 1023 ? '80px' : '35px';
    const drawerRecipientHeight = ww > 767 ? '49px' : '29px'; // because margin-top: -30px;
    const drawerChatInvReqRow = this.inMobileDrawer ? '71px' : '0px';
    this.heightScrollbar =
      this.chatDrawer || this.inMobileDrawer
        ? this.sanitizer.bypassSecurityTrustStyle(
            `calc(100vh - ${drawerTitleHeight} - ${drawerRecipientHeight} - ${drawerContentPadding} - ${drawerChatInvReqRow} - ${frameChatFormH}px)`
          )
        : this.sanitizer.bypassSecurityTrustStyle(
            `calc(100vh - ${siteContentSizes} - ${desktopChatInvReqRow} - ${frameChatFormH}px)`
          );
    this.changeDetector.markForCheck();
  }

  trackByFn(i: number, el: any) {
    return el.id;
  }

  getChatMessages(prev = false) {
    this.requestInProgress = true;
    this.messageService
      .getChatMessages(this.chat.id, this.query, prev)
      .pipe(
        finalize(() => {
          this.requestInProgress = false;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(({ data, count }) => {
        let newMessages = false;
        this.messagesCount = count;
        if (prev) {
          for (const m of data) {
            this.messages.unshift(m); // for smooth scrolling
          }
          setTimeout(() => {
            this.scrollToElement(
              this.messagesList.nativeElement.children[data.length]
            );
          }, 0);
        } else {
          this.messages = [...data];
          newMessages = !!this.messages.find((m) => m.new);
          this.scrollToBottom();
        }

        if (newMessages) {
          this.chatService.subtractNotReadChat(this.chat.id);
          setTimeout(() => {
            this.messages.forEach((m) => {
              m.new = false;
              this.changeDetector.detectChanges();
            });
          }, 3000); // target-fade animation duration

          if (!this.chatDrawer) {
            this.chat.newMessagesCount = 0;
            this.detectChanges.emit(true);
          }
        }

        this.changeDetector.detectChanges();
      });
  }

  onFileSelected(files: File[]) {
    this.files = [...files, ...this.files] as IFile[];
    this.changeDetector.detectChanges();
    this.getScrollbarHeight();
    this.scrollToBottom();
  }

  onRemoveFile(index: number) {
    this.files.splice(index, 1);
    this.changeDetector.detectChanges();
    this.getScrollbarHeight();
  }

  readUnreadMessages() {
    if (this.messagesToRead.length) {
      this.chatService.subtractNotReadChat(this.chat.id);
      if (!this.chatDrawer) {
        this.subtractNewMessages(this.messagesToRead.length);
        this.detectChanges.emit(true);
      }
    }
  }

  subtractNewMessages(count = 1) {
    if (this.job) {
      // full chat
      this.job.newMessagesCount = this.job.newMessagesCount - count;
    }
    this.messagesToRead = [];
    this.chat.newMessagesCount = 0;
    this.changeDetector.detectChanges();
  }

  onTemplateSelected(message: string) {
    this.form.setValue({ message });
    this.textarea.nativeElement.focus();
    this.textAreaAdjust();
  }

  onSelectEmoji(emoji: any) {
    const textarea = this.textarea.nativeElement;
    textarea.focus();
    if (document.execCommand) {
      document.execCommand('insertText', false, emoji);
    } else {
      // insert emoji on carrot position
      try {
        const [start, end] = [textarea.selectionStart, textarea.selectionEnd];
        textarea.setRangeText(emoji, start, end, 'end');
      } catch (e) {
        console.log(e);
      }
    }
  }
}
