<mat-form-field appearance="outline">
  <mat-label
    >{{ placeholder | translate }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-date-range-input
    [min]="minDate"
    [max]="maxDate"
    [disabled]="disabled"
    [formGroup]="dateForm"
    [rangePicker]="campaignOnePicker"
  >
    <input matStartDate formControlName="startDate" />
    <input matEndDate formControlName="endDate" (dateChange)="dateChanged()" />
  </mat-date-range-input>
  <its-button
    *ngIf="
      dateForm.enabled &&
      clear &&
      dateForm.get('startDate')?.value &&
      dateForm.get('endDate')?.value
    "
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="$event.stopPropagation(); clearValue()"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>
  <mat-datepicker-toggle
    matSuffix
    [for]="campaignOnePicker"
  ></mat-datepicker-toggle>
  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>

  <mat-error
    *ngIf="
      dateForm.get('startDate').hasError('matDatepickerParse') ||
      dateForm.get('endDate').hasError('matDatepickerParse')
    "
  >
    {{ 'common.form.errors.invalidDateFormat' | translate }}
  </mat-error>
  <mat-error *ngIf="dateForm.get('startDate').hasError('matDatepickerMin')">
    <span
      *ngIf="dateForm.get('startDate').getError('matDatepickerMin') as error"
      translate
      [translateParams]="{
        date: error.min | date
      }"
      >common.form.errors.minInvalid</span
    >
  </mat-error>
  <mat-error *ngIf="dateForm.get('endDate').hasError('matDatepickerMax')">
    <span
      *ngIf="dateForm.get('endDate').getError('matDatepickerMax') as error"
      translate
      [translateParams]="{
        date: error.max | date
      }"
      >common.form.errors.maxInvalid</span
    >
  </mat-error>
</mat-form-field>
