import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ROUTES_DATA } from '@client/shared/constants';
import { GlobalObjectService } from '@core/services';
import { HELLO_EMAIL } from '@common/shared/constants';

@Component({
  selector: 'its-legal-en',
  templateUrl: './legal-en.component.html',
  styleUrls: ['./legal-en.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalEnComponent implements OnInit {
  ROUTES_DATA = ROUTES_DATA;
  HELLO_EMAIL = HELLO_EMAIL;
  origin = this.globalObjectService.getLocation()?.origin;
  host = this.globalObjectService.getLocation()?.host;
  constructor(readonly globalObjectService: GlobalObjectService) {}

  ngOnInit(): void {}
}
