import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MessageService } from '@modules/chat/services';

import { LocaleService, ModalService } from '@core/services';

import { InviteStatusEnum, MessageTypeEnum } from '@common/shared/enums';
import {
  ICountResponse,
  IQuery,
  IReqInvState
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';

import {
  IJobInviteAnswer,
  IJobInviteRequest,
  IJobInviteResponse
} from '../interfaces';
import { JobsInviteFactory } from './jobs-invite.factory';

@Injectable({
  providedIn: 'root'
})
export class JobsInviteService extends BaseHttpService {
  inviteStatusChanged$ = new BehaviorSubject(null);

  constructor(
    readonly factory: JobsInviteFactory,
    readonly localeService: LocaleService,
    readonly modalService: ModalService,
    readonly messageService: MessageService
  ) {
    super('jobs-invites');
  }

  getInviteStatusChanged() {
    return this.inviteStatusChanged$.asObservable();
  }

  setInviteStatusChanged() {
    this.inviteStatusChanged$.next(true);
  }

  sendInvite(resumeId: string, data: IJobInviteRequest) {
    const request = this.factory.toRequest(data);

    return this.post<IJobInviteRequest, IJobInviteResponse[]>(
      request,
      {},
      `${resumeId}/invite`
    ).pipe(
      map(({ content }) =>
        content.map((el) => {
          this.setInviteStatusChanged();
          return this.factory.fromResponse(el);
        })
      )
    );
  }

  answerInviteByUser(id: number, data: IJobInviteAnswer) {
    return this.put<IJobInviteAnswer, IJobInviteResponse>(
      data,
      {},
      `${id}/user/invite`
    ).pipe(
      map(({ content }) => this.factory.fromResponse(content)),
      tap((invite) => {
        const message =
          data.status === InviteStatusEnum.Approve
            ? this.localeService.getInstant(_('invitesToMe.message.accepted'))
            : data?.message || '';
        this.messageService
          .sendMessage(invite.chat.id, {
            ...data,
            invReqStatus: data.status,
            message,
            type: MessageTypeEnum.AnswerInvite
          })
          .subscribe();
      })
    );
  }

  answerInviteByCompany(
    id: number,
    data: IJobInviteAnswer,
    result: (res) => void = null
  ) {
    const request = () => {
      return this.put<IJobInviteAnswer, IJobInviteResponse>(
        data,
        {},
        `${id}/company/invite`
      )
        .pipe(map(({ content }) => this.factory.fromResponse(content) || true))
        .subscribe(() => {
          result(true);
        });
    };
    if (
      data.status === InviteStatusEnum.Canceled ||
      data.status === InviteStatusEnum.Archived
    ) {
      const options = {
        header: _('common.modal.title.warning'),
        body:
          data.status === InviteStatusEnum.Canceled
            ? _('companyInvite.modal.cancelInviteWarning')
            : _('companyInvite.modal.archiveInviteWarning'),
        buttons: [
          { name: _('common.modal.button.cancel') },
          { name: _('common.modal.button.confirm') }
        ]
      };

      this.modalService.openModal(options, (answer) => {
        if (answer) {
          if (data.status === InviteStatusEnum.Canceled) {
            this.setInviteStatusChanged();
          }
          return request();
        }
      });
    }
  }

  companyBulkArchiveInvites(data: number[]) {
    return this.put<number[]>(data, {}, 'company/bulk-archive');
  }

  companyBulkCancelInvites(data: number[]) {
    return this.put<number[]>(data, {}, 'company/bulk-cancel');
  }

  resumeBulkArchiveInvites(data: number[]) {
    return this.put<number[]>(data, {}, 'resume/bulk-archive');
  }

  changeInvitePriority(id: number, priority: number) {
    return this.put<any>({ priority }, {}, `${id}/company/priority`);
  }

  getInviteStates(id: number) {
    return this.get<IReqInvState[]>({}, `${id}/state`).pipe(
      map(({ content }) => content)
    );
  }

  saveInviteState(id: number, state: IReqInvState) {
    return this.put<any>(state, {}, `${id}/state`).pipe(
      map(({ content }) => content)
    );
  }

  deleteInviteState(id: number) {
    return this.delete<any>({}, `state/${id}`);
  }

  getUserInvites(params: IQuery) {
    return this.get<ICountResponse<IJobInviteResponse[]>>(
      params,
      'user/invites'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getUserPendingInvites() {
    return this.get<number>({ [NO_LOADER_PARAM]: true }, 'user/pending').pipe(
      map(({ content }) => content)
    );
  }

  getCompanyInvites(params: IQuery) {
    return this.get<ICountResponse<IJobInviteResponse[]>>(
      params,
      'company/invites'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getCompanyPendingInvites() {
    return this.get<number>(
      { [NO_LOADER_PARAM]: true },
      'company/pending'
    ).pipe(map(({ content }) => content));
  }

  getResumeInvites(resumeId: string, params: IQuery) {
    return this.get<IJobInviteResponse[]>(
      params,
      `resume/${resumeId}/invites`
    ).pipe(
      map(({ content }) => content.map((el) => this.factory.fromResponse(el)))
    );
  }

  getJobInvites(jobId: number, params: IQuery) {
    return this.get<ICountResponse<IJobInviteResponse[]>>(
      params,
      `jobs/${jobId}/invites`
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getJobDevInvites(jobId: number, params: IQuery) {
    return this.get<IJobInviteResponse[]>(
      params,
      `jobs-dev/${jobId}/invites`
    ).pipe(
      map(({ content }) => content.map((el) => this.factory.fromResponse(el)))
    );
  }

  testEmail() {
    return this.get({}, 'test-email');
  }

  archiveSelectedAnswer(cb: (answer) => void) {
    const options = {
      header: _('common.modal.title.warning'),
      body: _('invites.modal.archiveSelected'),
      buttons: [
        { name: _('common.modal.button.cancel') },
        { name: _('common.modal.button.confirm') }
      ]
    };

    this.modalService.openModal(options, (answer) => {
      cb(answer);
    });
  }
}
