<div>
  <ul class="line-chart-legend">
    <li *ngFor="let data of lineChartData?.datasets">
      <span class="marker" [style.background]="data?.borderColor"></span>
      <span> {{ data.label }}</span>
    </li>
  </ul>
  <div class="canvas-frame">
    <canvas
      *ngIf="lineChartOptions"
      baseChart
      style="width: 100%"
      type="line"
      [height]="height"
      [data]="lineChartData"
      [options]="lineChartOptions"
    ></canvas>
  </div>
</div>
