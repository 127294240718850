import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { IResumeGreenhouse } from '@modules/resumes/interfaces';

import { AuthService } from '@core/auth';

import {
  ICompanyIntegration,
  IGreenhouseUser,
  IUserIntegration
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService extends BaseHttpService {
  constructor(readonly authService: AuthService) {
    super('integrations');
  }

  getGreenhouseCompanyUsers() {
    return this.get<IGreenhouseUser[]>({}, `greenhouse/users`).pipe(
      map(({ content }) => content)
    );
  }

  createGreenhouseResume(
    greenhouseJobId: number,
    jobId: number,
    resumeId: string,
    isRequest: boolean
  ) {
    return this.post<IResumeGreenhouse>(
      {
        greenhouseJobId,
        jobId,
        resumeId,
        isRequest
      },
      {},
      `greenhouse`
    ).pipe(map(({ content }) => content));
  }

  saveCompanyIntegrations(data: ICompanyIntegration) {
    return this.post<any>(data, {}, 'company');
  }

  saveUserIntegrations(user: IUserIntegration) {
    return this.post<any>(user, {}, 'user');
  }
}
