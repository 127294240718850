import { Checkbox } from './checkbox';
import { RequiredFileType } from './file-type';
import { Location } from './location';
import { Password } from './password';
import { Phone } from './phone';
import { CheckURL } from './url';
import { PlusInEmail } from './plus-in-email';
import { CheckYoutubeURL } from './youtube';

const CUSTOM_VALIDATORS = {
  password: Password,
  URL: CheckURL,
  YouTube: CheckYoutubeURL,
  requiredFileType: RequiredFileType,
  phone: Phone,
  locationFormat: Location,
  checkbox: Checkbox,
  plusInEmail: PlusInEmail
};

export { CUSTOM_VALIDATORS as CustomValidators };
