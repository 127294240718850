import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LocaleService } from '@core/services';

import { StickerAnimationEnum, StickerNameEnum } from '@client/shared/enums';

@Component({
  selector: 'its-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickerComponent {

  @Input() round = false;
  @Input() animation: StickerAnimationEnum = '' as any;
  @Input() name: StickerNameEnum;

  $language$ = this.localeService.getLocale();
  constructor(readonly localeService: LocaleService) {}
}
