<ng-container *ngIf="$language$ | async as lang">
  <ng-container *ngIf="name">
    <img
      class="{{animation}}"
      [class.round]="round"
      src="/assets/v1/stickers/lang_{{ lang }}/{{ name }}"
      [alt]="name"
    />
  </ng-container>
</ng-container>
