import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { GlobalObjectService } from '@core/services/global-object.service';

import { MAIN_LANGUAGE } from '@common/shared/constants';
import { LanguageEnum } from '@common/shared/enums';

import { LANG_KEY } from '@client/shared/constants';
import { ISeoData } from '@client/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  siteImage = this.globalObjectService.buildUrl('assets/v1/og/kupno.png');
  constructor(
    readonly title: Title,
    readonly globalObjectService: GlobalObjectService,
    readonly meta: Meta
  ) {}

  updateWindowTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgDescription(desc: string) {
    this.meta.updateTag({ property: 'og:description', content: desc });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: this.replaceHttp(url) });
  }

  updateCanonicalUrl(url: string) {
    const document = this.globalObjectService.getDocument();
    try {
      const existingLink = document.head.querySelector("link[rel='canonical']");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
      // Create new canonical link
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', this.replaceHttp(url));
      document.head.appendChild(link);
    } catch (e) {}
  }

  updateHreflang(currentUrl: string) {
    const document = this.globalObjectService.getDocument();

    const createLink = (lang) => {
      let url = currentUrl.replace(
        `/${this.globalObjectService.getServerCookies(LANG_KEY)}`,
        ''
      );
      const hreflang = lang === MAIN_LANGUAGE ? 'x-default' : lang;

      if (lang !== MAIN_LANGUAGE) {
        const origin = this.globalObjectService.getOrigin();
        const path = currentUrl.replace(origin, '').replace(`/${lang}`, '');
        url = `${origin}/${lang}${path}`;
      }

      const existingLink = document.head.querySelector(
        `link[hreflang="${hreflang}"]`
      );
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
      // Create new canonical link
      const link: HTMLLinkElement = document.createElement('link');
      link.setAttribute('hreflang', hreflang);
      link.setAttribute('href', this.replaceHttp(url));
      link.setAttribute('rel', 'alternate');
      document.head.appendChild(link);
    };

    Object.values(LanguageEnum).forEach((l) => {
      try {
        createLink(l);
      } catch (e) {}
    });
  }

  private replaceHttp(url) {
    return url.replace('http://', 'https://');
  }

  updateOgImage(image: string) {
    this.meta.updateTag({ property: 'og:image', content: image });
  }

  updateOgType(title: string) {
    this.meta.updateTag({ property: 'og:type', content: title });
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ property: 'keywords', content: keywords });
  }

  updateMetaData(data: ISeoData) {
    const {
      windowTitle,
      ogTitle,
      description,
      ogDescription,
      ogUrl,
      ogType,
      ogImage,
      keywords
    } = data;
    if (windowTitle) {
      this.updateWindowTitle(windowTitle);
    }

    if (ogTitle) {
      this.updateOgTitle(ogTitle);
    }

    if (description) {
      const desc = this.getStringLength(description);
      this.updateDescription(desc);
      if (!ogDescription) {
        this.updateOgDescription(desc);
      }
    }

    if (ogDescription) {
      const desc = this.getStringLength(description);
      this.updateOgDescription(desc);
    }

    if (ogUrl) {
      this.updateOgUrl(ogUrl);
      this.updateCanonicalUrl(ogUrl);
      this.updateHreflang(ogUrl);
    }

    if (ogType) {
      this.updateOgType(ogType);
    }

    if (keywords) {
      this.updateKeywords(keywords);
    }

    this.updateOgImage(ogImage || this.siteImage);
  }

  private getStringLength(text: string, len = 160) {
    if (text.length <= 160) {
      return text;
    }
    const arrWords = text.substring(0, len).split(' ');
    arrWords.length = arrWords.length - 1;
    return arrWords.join(' ') + '...';
  }
}
