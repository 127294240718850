<mat-form-field appearance="outline" floatLabel="never" [@.disabled]="true">
  <mat-label *ngIf="!staticPlaceholder">
    {{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <input
    autocomplete="off"
    [placeholder]="staticPlaceholder ? placeholder : ''"
    [formControl]="control"
    [type]="type"
    matInput
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    #search
  />

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <its-icon
    *ngIf="prefixIcon"
    class="c-text-primary"
    size="20"
    matPrefix
    [svgIcon]="prefixIcon"
  ></its-icon>
  <its-button
    *ngIf="control.enabled && ((clear && control?.value) || clearStatic)"
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="$event.stopPropagation(); clearValue()"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>

  <its-button *ngIf="icon" matSuffix (click)="onIconAction()" variant="icon">
    <its-icon [svgIcon]="icon"></its-icon>
  </its-button>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('notSelected')">
    <div class="show-with-transition">
      {{ 'common.form.errors.locationFormat' | translate }}
    </div>
  </mat-error>
</mat-form-field>
