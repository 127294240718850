import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { ValuesService } from '@core/services';

import {
  EXCLUDE_LOCATION,
  INCLUDE_LOCATION,
  LOCATION_JOIN
} from '@common/shared/constants';
import { LogicConditionEnum } from '@common/shared/enums';
import { getExcludeLocation, getIncludeLocation } from '@common/shared/helpers';

import { Destroyable, FormStateDispatcher } from '@client/shared/abstracts';
import { IValues } from '@client/shared/interfaces';
import { getLocationValue } from '@client/shared/utils';

@Component({
  selector: 'its-modal-boolean-search-location',
  templateUrl: './modal-boolean-search-location.component.html',
  styleUrls: ['./modal-boolean-search-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ModalBooleanSearchLocationComponent
  extends Destroyable
  implements OnInit
{
  form: FormGroup;
  values$: Observable<IValues> = this.valuesService.getValues();
  maxConditions: number;
  constructor(
    readonly dialogRef: MatDialogRef<ModalBooleanSearchLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    readonly valuesService: ValuesService,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  buildForm() {
    this.maxConditions = this.data.maxConditions || 6;
    const includeLocation = getIncludeLocation(this.data?.value);
    const excludeLocation = getExcludeLocation(this.data?.value);
    const incl = includeLocation ? includeLocation.split('__') : [];
    const excl = excludeLocation ? excludeLocation.split('__') : [];

    this.form = new FormGroup({
      include: new FormArray(
        incl?.length
          ? incl
              .map((v, i) => {
                const group: any = [
                  new FormControl({
                    value: {
                      value: getLocationValue(v),
                      initialValue: v
                    },
                    disabled: false
                  })
                ];

                if (incl?.length - 1 > i) {
                  group.push(new FormControl(LogicConditionEnum.And));
                }
                return group;
              })
              .flat()
          : [new FormControl('')]
      ),
      exclude: new FormArray(
        excl?.length
          ? excl
              .map((v, i) => {
                const group: any = [
                  new FormControl({
                    value: {
                      value: getLocationValue(v),
                      initialValue: v
                    },
                    disabled: false
                  })
                ];

                if (excl?.length - 1 > i) {
                  group.push(new FormControl(LogicConditionEnum.And));
                }
                return group;
              })
              .flat()
          : [new FormControl('')]
      )
    });
  }

  get includeArray() {
    return this.form.get('include') as FormArray;
  }

  get excludeArray() {
    return this.form.get('exclude') as FormArray;
  }

  addCondition(include = true) {
    if (include) {
      this.includeArray.push(
        new FormControl({
          value: LogicConditionEnum.And,
          disabled: true
        })
      );
      this.includeArray.push(new FormControl(''));
    } else {
      this.excludeArray.push(
        new FormControl({
          value: LogicConditionEnum.And,
          disabled: true
        })
      );
      this.excludeArray.push(new FormControl(''));
    }
  }

  clearSelect(ev: any, index: number, include = true) {
    if (include) {
      if (this.includeArray?.length > 1) {
        this.includeArray.removeAt(index);
        this.includeArray.removeAt(index === 0 ? index : index - 1);
      }
    } else {
      if (this.excludeArray?.length > 1) {
        this.excludeArray.removeAt(index);
        this.excludeArray.removeAt(index === 0 ? index : index - 1);
      }
    }
  }

  search() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      const { include, exclude } = this.form.value;
      const inclValue = include
        .map((el) => el?.initialValue || el?.locationQuery)
        .filter(Boolean)
        .join('__');

      const exclValue = exclude
        .map((el) => el?.initialValue || el?.locationQuery)
        .filter(Boolean)
        .join('__');
      let result = '';
      if (inclValue && exclValue) {
        result = `${INCLUDE_LOCATION}=${inclValue}${LOCATION_JOIN}${EXCLUDE_LOCATION}=${exclValue}`;
      } else {
        if (inclValue) {
          result = `${INCLUDE_LOCATION}=${inclValue}`;
        }
        if (exclValue) {
          result = `${EXCLUDE_LOCATION}=${exclValue}`;
        }
      }

      this.onNoClick(result);
    }
  }

  isEven(num: number): boolean {
    return num % 2 === 0;
  }
}
