import { ICategory } from './category.interface';

export interface ICategoryStats extends ICategory {
  jobsCount?: number;
  jobsWithSalaryPercent: number;
  jobsWithSalaryCount: number;
  jobsSalaryMedian: number;
  resumesCount: number;
  resumesWithSalaryCount: number;
}
