<ul *ngIf="options?.length" class="desk-options" [class.no-mobile]="noMobile">
  <li>
    <span class="sort-element">
      <span>{{ 'sort.title.sort' | translate }}:</span>
    </span>
  </li>
  <ng-container *ngFor="let option of options">
    <li>
      <button
        class="sort-btn"
        (click)="sortElement(option.value)"
        [class.sort]="
          sort?.sort === option.value || sort?.sort === '-' + option.value
        "
        [class.active-asc]="!sort?.sort?.startsWith('-')"
      >
        <span>{{ option.title | translate }}</span>
        <its-icon size="15" svgIcon="south"></its-icon>
      </button>
    </li>
  </ng-container>
</ul>

<div class="mob-options" [class.no-mobile]="noMobile">
  <its-button
    class="filter-btn"
    classes="square square-sm"
    variant="mini-fab"
    color="accent"
    [matMenuTriggerFor]="sortMenu"
  >
    <its-icon svgIcon="sort"></its-icon>
  </its-button>

  <mat-menu #sortMenu="matMenu" xPosition="before">
    <ng-container *ngFor="let option of options">
      <button
        mat-menu-item
        class="sort-btn sort-btn-menu"
        (click)="sortElement(option.value)"
        [class.sort]="
          sort?.sort === option.value || sort?.sort === '-' + option.value
        "
        [class.active-asc]="!sort?.sort?.startsWith('-')"
      >
        <span class="m-r_8">{{ option.title | translate }}</span>
        <its-icon classes="m_0i" size="15" svgIcon="south"></its-icon>
      </button>
    </ng-container>
  </mat-menu>
</div>
