import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

import { pluck } from 'rxjs/operators';

import { IJobInvite, IJobInviteAnswer } from '@modules/jobs/interfaces';
import { JobsInviteService } from '@modules/jobs/services';

import { ModalService, ValuesService } from '@core/services';

import { InviteStatusEnum } from '@common/shared/enums';

import { CommentModalComponent } from '@client/shared/components/comment-modal';
import { TemplateTypeEnum } from '@client/shared/enums';

@Directive({
  selector: '[itsUserCancelInvite]'
})
export class UserCancelInviteDirective {
  @Input() invite: IJobInvite;

  @Output() statusChanged = new EventEmitter<InviteStatusEnum>();

  constructor(
    readonly modalService: ModalService,
    readonly jobsInviteService: JobsInviteService,
    readonly valuesService: ValuesService
  ) {}

  @HostListener('click')
  cancelInvite() {
    const data: IJobInviteAnswer = {
      status: InviteStatusEnum.Canceled
    };
    this.modalService.openComponent(
      CommentModalComponent,
      (answer: IJobInviteAnswer) => {
        if (answer) {
          this.jobsInviteService
            .answerInviteByUser(this.invite.id, { ...data, ...answer })
            .subscribe(() => {
              this.invite.status = InviteStatusEnum.Canceled;
              this.statusChanged.emit(InviteStatusEnum.Canceled);
            });
        }
      },
      {
        width: '500px',
        data: {
          header: _('userInvite.modal.title.cancelInvite'),
          subTitle: _('userInvite.modal.subTitle.cancelInvite'),
          reasons: this.valuesService
            .getValues()
            .pipe(pluck('cancelInviteReasons')),
          userName: this.invite?.job?.user?.firstName,
          type: TemplateTypeEnum.CancelInvite
        }
      }
    );
  }
}
