import { AgentRoleEnum, InviteStatusEnum } from '../enums';

export interface IAgentStats {
  agent: {
    [key: string]: any;
    startedWork: Date;
    status: InviteStatusEnum;
    role: AgentRoleEnum;
    email: string;
    user: {
      firstName: string;
      lastName: string;
    }
  }
  publishedVacancies: number;
  closedVacancies: number;
  usedServices: number;
  sentInvites: number;
  acceptedInvites: number;
  receivedRequests: number;
  acceptedRequests: number;
}
