import { Injectable } from '@angular/core';

import { AWSMediaPathEnum } from '@common/shared/enums';

import { COUNTRIES } from '@client/shared/constants';

import { environment } from '@env';

import { IOffice, IOfficeRequest, IOfficeResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OfficesFactory {
  toRequest(office: IOffice): IOfficeRequest {
    return {
      phones: office.phones,
      comment: office.comment,
      location: office.location,
      removedPhotos: office.removedPhotos
    };
  }

  fromResponse(office: IOfficeResponse): IOffice {
    return {
      id: office.id,
      phones: office.phones,
      country: COUNTRIES[office.countryCode],
      comment: office.comment,
      address: office.address,
      location: office?.location,
      city: office.city,
      photos: (office?.photos || []).map((f) => {
        f.path = `${environment.storageUrl}/${AWSMediaPathEnum.OfficePhotos}/${f.preview}`;
        f.originPath = `${environment.storageUrl}/${AWSMediaPathEnum.OfficePhotos}/${f.filename}`;
        return f;
      })
    };
  }
}
