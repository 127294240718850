import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional
} from '@angular/core';
import { Router } from '@angular/router';

import { concatAll, interval, Observable, of, Subject, switchMap } from 'rxjs';
import {
  catchError,
  delay,
  map,
  share,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import { AdminService } from '@modules/admin/services';
import { ChatService, MessageService } from '@modules/chat/services';
import { JobsInviteService, JobsRequestService } from '@modules/jobs/services';
import { ResumeStatsService } from '@modules/resumes/services';
import { PlanService } from '@modules/settings/services';

import { AuthService, IProfile } from '@core/auth';
import {
  GlobalObjectService,
  LocaleService,
  ThemeService
} from '@core/services';

import {
  ActiveStatusEnum,
  LanguageEnum,
  MessageTypeEnum,
  PlanStatusEnum,
  PlanTypeEnum
} from '@common/shared/enums';
import { clickAllowed } from '@common/shared/helpers';

import { ADMIN_TOKEN, DARK_THEME, ROUTES_DATA } from '@client/shared/constants';

import { environment } from '@env';

@Component({
  selector: 'its-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  environment = environment;
  darkMode$ = this.themeService.getDarkTheme();
  lastViewCount = null;
  invReqCounters = {
    requests: 0,
    invites: 0
  };
  isMobile = false;
  logoBlack = '/assets/v1/images/logo.svg';
  logoWhite = '/assets/v1/images/logo-white.svg';
  localeIcons = {
    [LanguageEnum.Ukraine]: 'ukraine',
    [LanguageEnum.English]: 'britain'
  };
  localeShort = {
    [LanguageEnum.Ukraine]: 'UK',
    [LanguageEnum.English]: 'EN'
  };
  PlanTypeEnum = PlanTypeEnum;
  PlanStatusEnum = PlanStatusEnum;
  LanguageEnum = LanguageEnum;
  isProfile$: any;
  ActiveStatusEnum = ActiveStatusEnum;
  ROUTES_DATA = ROUTES_DATA;
  admin = false;
  counterIsRun = false;
  counterInterval = 0;
  userProfile: IProfile;
  getResumeViews$: Observable<{
    count: number;
    tooltip: string;
    tooltipData: string;
  }> = interval(100).pipe(
    map(() => interval(this.counterInterval).pipe(take(1))),
    concatAll(),
    tap(() => (this.counterInterval = 10 * 60 * 1000)),
    switchMap(() =>
      this.resumeStatsService.getResumeViews().pipe(
        tap((res) => {
          this.lastViewCount = res;
        }),
        catchError(() => of(this.lastViewCount))
      )
    )
  );
  profile$: Observable<IProfile> = this.authService.getProfile(false).pipe(
    map((profile) => {
      if (this.globalObjectService.isPlatformBrowser()) {
        if (profile.exist && !this.userProfile) {
          this.isProfile$ = new Subject();
        }

        if (profile?.company?.isActive && !this.userProfile) {
          this.planService.loadJobServices().subscribe();
          this.getTabCounters();
          this.tabCounterInterval();
        }

        if (!profile.exist) {
          this.counterIsRun = false;
          this.stopInterval();
        } else {
          this.admin = !!this.globalObjectService.getStorageData(ADMIN_TOKEN);
          if (!this.userProfile) {
            this.getNotReadChats();
            this.listenNewMessages();
          }
          this.counterIsRun = !!profile.resumes.find(
            (resume) =>
              resume?.status === ActiveStatusEnum.Active || resume?.anonymUuid
          );
        }
      }
      this.userProfile = profile?.exist ? profile : null;
      this.changeDetectorRef.detectChanges();
      return profile;
    }),
    share()
  );
  jobServices$ = this.planService.getJobServices();
  hotServices$ = this.planService.getHotServices();
  notReadChatsCount$ = this.chatService.notReadChatsCount();
  locale$ = this.localeService.getLocale();
  notificationSound = this.globalObjectService.isPlatformBrowser()
    ? new Audio('/assets/mp3/chat-sound.mp3')
    : null;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
  }

  constructor(
    readonly authService: AuthService,
    readonly adminService: AdminService,
    readonly router: Router,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly planService: PlanService,
    readonly messageService: MessageService,
    readonly chatService: ChatService,
    readonly localeService: LocaleService,
    readonly jobsInviteService: JobsInviteService,
    readonly globalObjectService: GlobalObjectService,
    readonly resumeStatsService: ResumeStatsService,
    readonly jobsRequestService: JobsRequestService,
    readonly themeService: ThemeService,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  ngOnInit(): void {
    /* if (this.request && !this.globalObjectService.isPlatformBrowser()) {
      this.logo = `https://${this.request.get('host')}${this.logo}`;
    }*/
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = this.globalObjectService.getWindow()?.innerWidth <= 767;
    this.counterInterval = 100;
    this.changeDetectorRef.detectChanges();
  }

  toAuth() {
    this.authService.toAuth();
  }

  logOut() {
    this.authService.logOut();
  }

  switchTheme() {
    this.themeService.toggleTheme();
  }

  createCompany(ev) {
    if (clickAllowed(ev)) {
      this.router.navigateByUrl(
        `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children.FORM.url}`
      );
    }
  }

  listenNewMessages() {
    this.messageService
      .getNewMessage()
      .pipe(takeUntil(this.isProfile$))
      .subscribe((m) => {
        if (!m.owner) {
          this.notificationSound.play().catch(() => {});
          this.chatService.addNotReadChat(m.chat.id);
        }
        if (
          m.type === MessageTypeEnum.NewRequest ||
          m.type === MessageTypeEnum.AnswerRequest
        ) {
          this.jobsRequestService.setRequestStatusChanged();
        }
        if (
          m.type === MessageTypeEnum.NewInvite ||
          m.type === MessageTypeEnum.AnswerInvite
        ) {
          this.jobsInviteService.setInviteStatusChanged();
        }
      });
  }

  getNotReadChats() {
    this.chatService
      .getNotReadChats()
      .pipe(takeUntil(this.isProfile$))
      .subscribe();
  }

  changeLanguage(lang: LanguageEnum) {
    this.localeService.useLanguage(lang);
    this.authService
      .getProfile()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.authService.updateProfileLang(lang).subscribe();
        }
      });
  }

  backToAdmin() {
    this.adminService.backToAdmin();
  }

  testEmail() {
    this.jobsInviteService.testEmail().subscribe();
  }

  goToPricing(access: boolean) {
    if (access) {
      this.router.navigateByUrl(
        `/${ROUTES_DATA.SETTINGS.children.PRICING.url}`
      );
    }
  }

  getTabCounters() {
    this.jobsRequestService
      .getRequestStatusChanged()
      .pipe(
        takeUntil(this.isProfile$),
        delay(500),
        switchMap(() =>
          this.jobsRequestService
            .getPendingRequestsByCompany()
            .pipe(catchError(() => of(this.invReqCounters?.requests || 0)))
        )
      )
      .subscribe((count) => {
        this.invReqCounters = {
          ...this.invReqCounters,
          requests: count || 0
        };
        this.changeDetectorRef.detectChanges();
      });

    this.jobsInviteService
      .getInviteStatusChanged()
      .pipe(
        takeUntil(this.isProfile$),
        delay(500),
        switchMap(() =>
          this.jobsInviteService
            .getCompanyPendingInvites()
            .pipe(catchError(() => of(this.invReqCounters?.invites || 0)))
        )
      )
      .subscribe((count) => {
        this.invReqCounters = {
          ...this.invReqCounters,
          invites: count || 0
        };
        this.changeDetectorRef.detectChanges();
      });
  }

  tabCounterInterval() {
    interval(10 * 60 * 1000)
      .pipe(takeUntil(this.isProfile$))
      .subscribe(() => {
        this.jobsRequestService.setRequestStatusChanged();
      });
  }

  stopInterval() {
    this.isProfile$?.next(true);
    this.isProfile$?.complete();
  }
}
