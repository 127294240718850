export interface ILocation {
  countryCode: string;
  postalCode?: string;
  regionCode?: string;
  region?: string;
  address?: string; // street and number
  city?: string;
  value: string;
  locationQuery?: string;
  mapUrl?: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}
