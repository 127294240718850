import { Directive, HostListener } from '@angular/core';

import { AuthService } from '@core/auth';

import { AuthQueryEnum } from '@client/shared/enums';

@Directive({
  selector: '[itsNeedAuth]'
})
export class NeedAuthDirective {
  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const el = event.target as HTMLElement;
    if (!this.authService.isLoggedIn()) {
      el.setAttribute('notAllowed', 'true');
      this.authService.toAuth(AuthQueryEnum.SignIn);
    } else {
      el.removeAttribute('notAllowed');
    }
  }
  constructor(readonly authService: AuthService) {}
}
