import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { ICustomPrice } from '@modules/admin/interfaces/custom-price.interface';
import { ICompanyResponse } from '@modules/companies/interfaces';
import { CompaniesFactory } from '@modules/companies/services';
import { IResume } from '@modules/resumes/interfaces';
import { IOrder } from '@modules/settings/interfaces';

import { AuthService, IAdminUserEntity, IUserEntity } from '@core/auth';
import { GlobalObjectService, NotificationService } from '@core/services';

import { ADMIN_WITH_USER } from '@common/shared/constants';
import { ActiveStatusEnum } from '@common/shared/enums';
import { deleteCookie, setCookie } from '@common/shared/helpers';
import {
  IApiResponse,
  ICountResponse,
  IQuery,
  ISpendingStats
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { ADMIN_TOKEN, BACK_URL, ROUTES_DATA } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseHttpService {
  constructor(
    readonly companiesFactory: CompaniesFactory,
    readonly authService: AuthService,
    readonly notificationService: NotificationService,
    readonly globalObjectService: GlobalObjectService,
    readonly router: Router
  ) {
    super('admin');
  }

  adminGetCompanies(query: IQuery) {
    return this.get<ICountResponse<ICompanyResponse[]>>(
      query,
      'companies'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.companiesFactory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  adminUpdateCompany(
    id: number,
    data: {
      status: ActiveStatusEnum;
      comment: string;
    }
  ) {
    return this.put(data, {}, `companies/${id}`).pipe(
      tap(() => {
        this.notificationService.showMessage('Company updated');
      })
    );
  }

  adminUpdateResume(
    id: number,
    data: {
      status: ActiveStatusEnum;
      comment: string;
    }
  ) {
    return this.put(data, {}, `resume/${id}`).pipe(
      tap(() => {
        this.notificationService.showMessage('Resume updated');
      })
    );
  }

  adminGetUsers(query: IQuery) {
    return this.get<ICountResponse<IAdminUserEntity[]>>(query, 'users').pipe(
      map(({ content }) => content)
    );
  }

  adminGetResumes(query: IQuery) {
    return this.get<ICountResponse<IResume[]>>(query, 'resumes').pipe(
      map(({ content }) => content)
    );
  }

  adminGetEmails(query: IQuery) {
    return this.get<IUserEntity[]>(query, 'users/emails').pipe(
      map(({ content }) => content)
    );
  }

  adminUpdateUser(
    id: string,
    data: {
      status: ActiveStatusEnum;
    }
  ) {
    return this.put(data, {}, `users/${id}`).pipe(
      tap(() => {
        this.notificationService.showMessage('User updated');
      })
    );
  }

  resetGptValidation(id: string) {
    return this.patch(null, {}, `resumes/${id}`).pipe(
      tap(() => {
        this.notificationService.showMessage('Count cleared');
      })
    );
  }

  adminLoginToUser(id: string) {
    this.globalObjectService.setStorageData(
      ADMIN_TOKEN,
      this.authService.getToken()
    );
    setCookie(ADMIN_WITH_USER, '1', 1);
    this.globalObjectService.setStorageData(BACK_URL, this.router.url);
    return this.post(null, {}, `users/${id}/login`).pipe(
      tap((res) => {
        this.authService.setToken(res.content.accessToken);
        this.globalObjectService.getLocation().href = `${this.globalObjectService.getOrigin()}/${
          ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url
        }`;
      })
    );
  }

  backToAdmin() {
    this.router.navigateByUrl('/').then(() => {
      deleteCookie(ADMIN_WITH_USER);
      const backUrl = this.globalObjectService.getStorageData(BACK_URL);
      const token = this.globalObjectService.getStorageData(ADMIN_TOKEN);
      this.globalObjectService.removeStorageData(ADMIN_TOKEN);
      this.globalObjectService.removeStorageData(BACK_URL);

      this.authService.setToken(token);
      this.globalObjectService.getLocation().href = `${this.globalObjectService.getOrigin()}/${
        backUrl || '/'
      }`;
    });
  }

  adminAddCompanyServices(
    id: string,
    data: {
      count: number;
    }
  ) {
    return this.put(data, {}, `companies/${id}/services`);
  }

  adminGetOrders(params: IQuery) {
    return this.get<ICountResponse<IOrder[]>>(params, 'orders').pipe(
      map(({ content }) => content)
    );
  }

  adminConfirmPayment(id: number) {
    return this.post<IOrder>(null, {}, `orders/${id}/confirm`).pipe(
      map(({ content }) => content)
    );
  }

  getAdminSpending() {
    return this.get<ISpendingStats>({}, 'orders/spending').pipe(
      map(({ content }) => content)
    );
  }

  blockResume(id: string) {
    return this.put<any>(null, {}, `users/resume/${id}/block`).pipe(
      map(({ content }) => content)
    );
  }

  setCustomPrice(body: ICustomPrice, id: number) {
    return this.post<any>(body, {}, `companies/${id}/set-plan`);
  }
}
