import { FormGroup } from '@angular/forms';

import { ICompany } from '@modules/companies/interfaces';
import { IJob } from '@modules/jobs/interfaces';

import { IPhone } from '@common/shared/interfaces';
import { ILocation } from '@common/shared/interfaces';

import { IFile } from '@client/shared/interfaces';

import { OfficeActionEnum } from '../enums';

interface ICommonOffice {
  location?: ILocation;
  phones: string[]; // IPhone[];
  city?: string;
  country?: string;
  countryCode?: string;
  comment?: string;
  address?: string;
  lat?: number;
  lng?: number;
  jobs?: Partial<IJob>[];
}

export interface IOffice extends ICommonOffice {
  id?: number;
  company?: ICompany;
  form?: FormGroup;
  photos?: IFile[];
  removedPhotos?: number[];
  jobsCount?: number;
}

export interface IOfficeRequest extends ICommonOffice {
  removedPhotos?: number[];
}

export interface IOfficeResponse extends IOffice {}

export interface IOfficeAction {
  action: OfficeActionEnum;
  office: IOffice;
  index?: number;
}

/*sd = {
  "city": "L'viv",
  "value": "Zelena St,  44, L'viv, L'vivs'ka oblast, Ukraine",
  "mapUrl": "https://maps.google.com/?q=Zelena+St,+44,+L%27viv,+L%27vivs%27ka+oblast,+Ukraine,+79000&ftid=0x473add5cbf69235d:0x7d4bd017581c6e38",
  "address": "Zelena St, 44",
  "country": "Ukraine",
  "placeId": "ChIJXSNpv1zdOkcROG4cWBfQS30",
  "coordinates": {
    "lat": 49.8307832,
    "lng": 24.0418864
  },
  "countryCode": "UA",
  "administrativeArea": "L'vivs'ka oblast"
}*/
