import { ICategory } from '@client/shared/interfaces/category.interface';

export interface ICandidatesStats {
  publicResumes: number[];
  publicResumesInfo?: {
    percent: number;
    dir: string;
  };
  anonymousResumes?: number[];
  anonymousResumesInfo: {
    percent: number;
    dir: string;
  };
  middleEnglishLevel: number;
  middleEnglishLevelPercent: number;
  middleEnglishLevelInfo?: {
    title: string;
    percent: number;
  };
  middleExperience: number;
  middleExperiencePercent: number;
  middleExperienceInfo?: {
    title: string;
    percent: number;
  };
  mostPopularCategories: ICategory[];
  mostPopularCategoriesStr: string;
}
