import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from '@env';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor(readonly recaptchaV3Service: ReCaptchaV3Service) {
  }

  getRecaptchaToken(action: string) {
    if (environment.local) {
      return of(null)
    }
    return this.recaptchaV3Service.execute(action);
  }
}
