<article class="public-text">
  <mat-card>
    <mat-card-content>
      <h2 class="t-a_c">
        <b>Політика конфіденційності</b>
      </h2>
      <div class="m-b_32 m-l_16">
        <div>Версія: 1.01.</div>
        <div>Останнє оновлення: 01 листопада 2023.</div>
      </div>
      <ol>
        <li>
          <h3>1. Загальні положення</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">1.1.</div>
              <div>
                Ця Політика конфіденційності (далі – «Політика») застосовується
                до послуг, що надаються сервісом із пошуку роботи Kupno, що
                доступний за веб-адресою {{ origin }} (далі – «Сайт» або
                «Kupno»). Політика описує порядок обробки персональних даних,
                зібраних і пов’язаних із Сайтом послуг та інструментів, що
                дозволяють користувачам реєструватися на Сайті, публікувати або
                переглядати в режимі реального часу вже опубліковану інформацію,
                використовувати інші, пов’язані з вищенаведеними, послуги Kupno.
              </div>
            </li>
            <li>
              <div class="point-number">1.2.</div>
              <div>
                У всіх зазначених випадках персональні дані користувачів
                обробляються відповідно до вимог Закону України «Про захист
                персональних даних». Ця Політика розроблена відповідно до
                положень зазначеного документа.
              </div>
            </li>
            <li>
              <div class="point-number">1.3.</div>
              <div>
                Персональні дані користувачів Kupno обробляються володільцем
                персональних даних, яким є ФОП Лісний Роман Васильович, ІПН
                3284219853, зареєстрований за адресою: Львівська обл.,
                Золочівський р-н, с. Закомар’я. Володілець персональних даних є
                власником бази персональних даних користувачів Kupno.
              </div>
            </li>
            <li>
              <div class="point-number">1.4.</div>
              <div>
                Суб’єкт персональних даних, здійснюючи реєстрацію та/або
                авторизацію на Сайті, публікуючи вакансії, розміщуючи резюме
                та/або інші дані на Сайті, здійснюючи комунікацію на Сайті,
                використовуючи іншим чином Сайт чи повʼязані послуги та
                інструменти Kupno або здійснюючи будь-які інші дії на Сайті,
                засвідчує свою повну і безумовну згоду з цією Політикою та
                вказаними в ній цілями та умовами обробки його персональних
                даних.
              </div>
            </li>
            <li>
              <div class="point-number">1.5.</div>
              <div>
                Підставою для обробки персональних даних володільцем є укладення
                та виконання
                <a [routerLink]="'/' + ROUTES_DATA.PUBLIC.children.LEGAL.url"
                  >Умов використання</a
                >
                між володільцем та суб’єктом персональних даних.
              </div>
            </li>
          </ol>
        </li>

        <li>
          <h3>2. Визначення термінів</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">2.1.</div>
              <div>
                У цій Політиці нижченаведені терміни вживаються в такому
                значенні:
                <ol class="bullet-items">
                  <li>
                    база персональних даних - іменована сукупність упорядкованих
                    персональних даних в електронній формі та/або у формі
                    картотек персональних даних;
                  </li>
                  <li>
                    володілець персональних даних (або володілець) - фізична або
                    юридична особа, яка визначає мету обробки персональних
                    даних, встановлює склад цих даних та процедури їх обробки,
                    якщо інше не визначено законом;
                  </li>
                  <li>
                    згода суб’єкта персональних даних - добровільне
                    волевиявлення фізичної особи (за умови її поінформованості)
                    щодо надання дозволу на обробку її персональних даних
                    відповідно до сформульованої мети їх обробки, висловлене у
                    письмовій формі або у формі, що дає змогу зробити висновок
                    про надання згоди;
                  </li>
                  <li>
                    знеособлення персональних даних - вилучення відомостей, які
                    дають змогу прямо чи опосередковано ідентифікувати особу;
                  </li>
                  <li>
                    картотека - будь-які структуровані персональні дані,
                    доступні за визначеними критеріями, незалежно від того, чи
                    такі дані централізовані, децентралізовані або розділені за
                    функціональними чи географічними принципами;
                  </li>
                  <li>
                    обробка персональних даних - будь-яка дія або сукупність
                    дій, таких як збирання, реєстрація, накопичення, зберігання,
                    адаптування, зміна, поновлення, використання і поширення
                    (розповсюдження, реалізація, передача), знеособлення,
                    знищення персональних даних, у тому числі з використанням
                    інформаційних (автоматизованих) систем;
                  </li>
                  <li>
                    персональні дані - відомості чи сукупність відомостей про
                    фізичну особу, яка ідентифікована або може бути конкретно
                    ідентифікована;
                  </li>
                  <li>
                    розпорядник персональних даних - фізична чи юридична особа,
                    якій володільцем персональних даних або законом надано право
                    обробляти ці дані від імені володільця;
                  </li>
                  <li>
                    суб’єкт персональних даних (або користувач) - фізична особа,
                    персональні дані якої обробляються;
                  </li>
                  <li>
                    третя особа - будь-яка особа, за винятком суб’єкта
                    персональних даних, володільця чи розпорядника персональних
                    даних та Уповноваженого Верховної Ради України з прав людини
                    (далі – «Уповноважений»), якій володільцем чи розпорядником
                    персональних даних здійснюється передача персональних даних.
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>3. Персональні дані, які обробляються</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">3.1.</div>
              <div>
                Залежно від того, як субʼєкт персональних даних використовує
                Сайт, володілець може збирати та обробляти такі персональні
                дані:
                <ol class="bullet-items">
                  <li>
                    <b>Реєстрація на Сайті:</b> при створенні користувачем
                    облікового запису на Сайті він повинен вказати свої імʼя,
                    прізвище, дійсну адресу електронної пошти та створити
                    пароль.
                  </li>
                  <li>
                    <b>Створення резюме:</b> фото, регіон, посада, на яку
                    претендує, категорія, до якої належить посада, рівень
                    володіння англійською мовою та з якого року досвід, домени,
                    в яких є досвід, мінімальний розмір заробітної плати, на яку
                    претендує, вид зайнятості, досвід роботи, контактні дані,
                    технічні навички, додаткова інформація про себе, мови, з
                    якими може працювати, посилання на соціальні мережі тощо.
                    Користувач може також прикріпити PDF документ, персональні
                    дані з якого оброблятимуться відповідно до цієї Політики.
                  </li>
                  <li>
                    <b>Створення профілю компанії:</b> назва, логотип, регіон,
                    кількість працівників, рік заснування, опис компанії, тип
                    компанії, вебсайт компанії, посилання на соціальні мережі,
                    стек технологій, переваги, телефон, інформація для
                    виставлення рахунків, офіси компанії (адреса, телефон, фото,
                    опис) тощо.
                  </li>
                  <li>
                    <b>Оголошення та угоди:</b> в межах діяльності Сайту
                    володілець може розміщувати інформацію, в тому числі
                    особистого і контактного характеру, необхідну для здійснення
                    угод між компанією і кандидатом, для взаємодії користувачів
                    між собою (включно із комунікацією на Сайті), та здійснення
                    платежів.
                  </li>
                  <li>
                    <b>Технічна підтримка:</b> при зверненні користувачів до
                    відділу обслуговування клієнтів володілець при необхідності
                    може в процесі роботи Сайту збирати персональні дані,
                    необхідні для виконання запиту користувача й отримання
                    зворотного зв’язку. Володілець може також зв’язатися з
                    користувачем, використовуючи наявну контактну інформацію
                    облікового запису, надану для цієї мети. Володілець може
                    також збирати іншу інформацію при спілкування з
                    користувачами, наприклад, будь-які запити до служби
                    підтримки, включаючи зміст повідомлення та метадані, що
                    подаються користувачами, або будь-який зворотній зв’язок, що
                    надається ними.
                  </li>
                  <li>
                    <b>Вебсайт та мобільні дані:</b> володілець може автоматично
                    отримувати й реєструвати на своїх серверах інформацію з
                    браузера користувача або будь-якого пристрою, включаючи
                    інформацію про комп’ютер (IP-адресу, географічне
                    розташування, тип і версію браузера, операційну систему
                    тощо), інформацію про відвідування та використання Сайту
                    (реферальні джерела, протяжність візитів, переглянуті
                    сторінки та шляхи навігації по Сайту тощо), інформацію, яка
                    генерується при використанні Сайту (час, частоту та умови
                    його використання). Додаткова інформація про те, як
                    користувачі можуть контролювати та/або блокувати такий збір
                    інформації, знаходиться нижче в розділах цієї Політики.
                  </li>
                  <li>
                    <b>Інформація, отримана в результаті опитувань:</b>
                    володілець може збирати та зберігати інформацію, що отримана
                    в результаті опитувань, що можуть проводитись володільцем
                    або залученими володільцем підрядниками (третіми особами), а
                    саме інформацію щодо діяльності Kupno, нових проєктів,
                    розвитку Kupno, цілей та ін.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">3.2.</div>
              <div>
                Користувач несе відповідальність за всю інформацію, що
                розміщується ним на Сайті. Користувач повинен уважно усвідомити
                всі ризики, пов’язані з тим, що він оприлюднює персональні дані
                або інформацію публічного характеру.
              </div>
            </li>
            <li>
              <div class="point-number">3.3.</div>
              <div>
                Перед тим, як розкрити для Kupno персональні дані третьої особи,
                користувач повинен отримати згоду цієї особи як на розкриття,
                так і на обробку цієї інформації у відповідності до цієї
                Політики.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>4. Використання персональних даних</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">4.1.</div>
              <div>
                Для надання своїх послуг володілець може використовувати
                інформацію, яку він збирає і розміщує для наступних цілей:
                <ol class="bullet-items">
                  <li>
                    забезпечення обслуговування користувачів, у тому числі для
                    створення і управління обліковими записами користувачів,
                    вирішення технічних труднощів і доступу до різних функцій;
                  </li>
                  <li>
                    адаптація пропозицій і досвіду, в тому числі реклами на
                    своїх сервісах або сервісах третіх осіб;
                  </li>
                  <li>
                    контроль загальної та індивідуальної активності
                    користувачів, такий як пошук за ключовими словами,
                    активності за публікаціями оголошень та укладання угод, а
                    також для управління трафіком на Сайті;
                  </li>
                  <li>
                    зв’язок із користувачами, в тому числі з питань сервісу,
                    обслуговування користувачів або дозволених маркетингових
                    комунікацій через будь-які доступні канали зв’язку;
                  </li>
                  <li>
                    проведення науково-дослідницької та аналітичної активності з
                    метою поліпшення Kupno;
                  </li>
                  <li>
                    забезпечення дотримання угоди з користувачами Kupno,
                    враховуючи боротьбу з шахрайством і образами;
                  </li>
                  <li>
                    оцінка деяких факторів особистої інформації, зокрема, для
                    аналізу та прогнозування особистих уподобань, інтересів,
                    поведінки та місцезнаходження.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">4.2.</div>
              <div>
                Володілець зберігатиме персональні дані протягом найдовшого з
                таких періодів:
                <ol class="bullet-items">
                  <li>
                    час, необхідний для досягнення цілей, заради яких вони були
                    зібрані, зважаючи на бізнес-потреби володільця та вимоги
                    законодавства, в тому числі для виконання будь-яких
                    юридичних, бухгалтерських вимог або вимог щодо звітності;
                  </li>
                  <li>
                    кінець періоду, впродовж якого можуть виникнути судові
                    розгляди чи розслідування стосовно послуг;
                  </li>
                  <li>поки існує обліковий запис користувача.</li>
                </ol>
                Це включає дані, які були надані користувачем або іншими
                особами, а також дані, отримані в результаті використання
                користувачем послуг.
              </div>
            </li>
            <li>
              <div class="point-number">4.3.</div>
              <div>
                Для визначення відповідного періоду зберігання володілець
                враховує обсяг, характер і чутливість персональних даних,
                потенційний ризик заподіяння шкоди від несанкціонованого
                використання або розголошення персональних даних, мету, заради
                яких володілець обробляє персональні дані, а також чи може
                володілець досягти цих цілей за допомогою інших засобів і
                відповідні юридичні вимоги.
              </div>
            </li>
            <li>
              <div class="point-number">4.4.</div>
              <div>
                У разі виникнення запитань щодо періоду зберігання даних, будь
                ласка, зв’яжіться з нами за адресою:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>5. Доступ до персональних даних</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">5.1.</div>
              <div>
                Доступ до персональних даних третій особі здійснюється у
                відповідності до ст. 16, 17 Закону України «Про захист
                персональних даних» і не надається, якщо зазначена особа
                відмовляється взяти на себе зобов’язання щодо забезпечення
                виконання вимог цього закону або неспроможна їх забезпечити.
              </div>
            </li>
            <li>
              <div class="point-number">5.2.</div>
              <div>
                Про передачу персональних даних третій особі володілець протягом
                десяти робочих днів повідомляє суб’єкта персональних даних, крім
                випадків, що передбачені ст. 14 Закону України «Про захист
                персональних даних».
              </div>
            </li>
            <li>
              <div class="point-number">5.3.</div>
              <div>
                Володілець розкриває персональні та інші дані суб’єкта
                персональних даних виключно для цілей, пов’язаних із публікацією
                таким суб’єктом вакансій на Kupno і доступом до змісту цих
                вакансій інших користувачів Kupno. Погодившись із цією
                Політикою, суб’єкт персональних даних дає згоду на розкриття
                внесених ним даних у вакансіях на Kupno всім користувачам Сайту
                з урахуванням умов та обмежень, встановлених цією Політикою.
              </div>
            </li>
            <li>
              <div class="point-number">5.4.</div>
              <div>
                Володілець залишає за собою право у відповідності із вимогами
                діючого законодавства України здійснювати обмін персональними
                даними з органами державної влади з метою: попередження
                шахрайства на Сайті; врегулювання спірних ситуацій та з’ясування
                обставин, які є або можуть стати причиною порушення діючого
                законодавства, а також у випадку виявлення володільцем
                неправомірних дій суб’єкта персональних даних та/або отримання
                претензій, скарг, звернень третіх осіб.
              </div>
            </li>
            <li>
              <div class="point-number">5.5.</div>
              <div>
                У разі якщо бізнес володільця або частина цього бізнесу будуть
                продані або реорганізовані та володілець передає всі або
                практично всі свої активи новому власнику, то персональні дані
                користувачів можуть бути передані покупцеві, щоб забезпечити
                безперервність обслуговування Сайту.
              </div>
            </li>
            <li>
              <div class="point-number">5.6.</div>
              <div>
                Володілець може передавати певну знеособлену інформацію
                стороннім постачальникам послуг, довіреним партнерам або
                авторизованим дослідникам з метою кращого розуміння, яка реклама
                або послуги можуть зацікавити користувачів, поліпшення загальної
                якості та ефективності послуг на Сайті або для забезпечення
                свого внеску в наукові дослідження, які, на думку володільця,
                можуть приносити велику соціальну користь.
              </div>
            </li>
            <li>
              <div class="point-number">5.7.</div>
              <div>
                Володілець може передавати інформацію, яку він збирає, третім
                особам, які надають послуги володільцеві (зокрема, послуги з
                аналітики, хостингу, управління взаємовідносинами з клієнтами),
                з метою проведення досліджень або надання послуг користувачам,
                при цьому дані, які передаються, є предметом цієї Політики, а
                залучені треті особи не мають права використовувати отриману
                інформацію інакше як для надання послуг володільцеві.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>6. Використання кукіс</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">6.1.</div>
              <div>
                У процесі роботи Сайту володілець може збирати певну інформацію
                за допомогою таких технологій, як кукіс (репʼяшків), - невеликих
                текстових файлів, які зберігають інформацію безпосередньо на
                комп’ютері користувача, його мобільному телефоні або іншому
                пристрої. За допомогою кукіс веб-сервер може зберегти,
                наприклад, вподобання та налаштування на комп’ютері користувача,
                його мобільному телефоні або іншому пристрої, яке потім
                автоматично відновлюється при наступному відвідуванні. Інакше
                кажучи, файли кукіс призначені для того, щоб зробити
                використання Сайту більш зручним, наприклад, щоб користувач не
                повторював процес входу в систему під час наступного візиту.
              </div>
            </li>
            <li>
              <div class="point-number">6.2.</div>
              <div>
                Володілець використовує як постійні, так і сесійні кукіс.
                Постійні кукіс залишаються на комп’ютері користувача протягом
                більш тривалого періоду часу, а сесійні кукіс автоматично
                видаляються при закритті вікна браузера. Зокрема, володілець
                використовує авторизаційні, аналітичні, маркетингові кукіс, а
                також зберігання CID юзера та допоміжні кукіс для збереження
                інформації про мову користувача тощо.
              </div>
            </li>
            <li>
              <div class="point-number">6.3.</div>
              <div>
                Володілець може дозволяти третім сторонам, таким як
                постачальники рекламних та/або аналітичних послуг, збирати
                інформацію, використовуючи ці типи технологій безпосередньо на
                Сайті. Дані, які вони збирають, підлягають захисту відповідно до
                чинної політики конфіденційності цих третіх сторін.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>
            7. Права субʼєктів персональних даних та користувацький контроль
          </h3>
          <ol class="point-items">
            <li>
              <div class="point-number">7.1.</div>
              <div>
                Суб’єкт персональних даних має право:
                <ol class="bullet-items">
                  <li>
                    знати про джерела збирання, місцезнаходження своїх
                    персональних даних, мету їх обробки, місцезнаходження або
                    місце проживання (перебування) володільця чи розпорядника
                    персональних даних або дати відповідне доручення щодо
                    отримання цієї інформації уповноваженим ним особам, крім
                    випадків, встановлених законом;
                  </li>
                  <li>
                    отримувати інформацію про умови надання доступу до
                    персональних даних, зокрема інформацію про третіх осіб, яким
                    передаються його персональні дані;
                  </li>
                  <li>на доступ до своїх персональних даних;</li>
                  <li>
                    отримувати не пізніш як за тридцять календарних днів з дня
                    надходження запиту, крім випадків, передбачених законом,
                    відповідь про те, чи обробляються його персональні дані, а
                    також отримувати зміст таких персональних даних;
                  </li>
                  <li>
                    пред’являти вмотивовану вимогу володільцю персональних даних
                    із запереченням проти обробки своїх персональних даних;
                  </li>
                  <li>
                    пред’являти вмотивовану вимогу щодо зміни або знищення своїх
                    персональних даних будь-яким володільцем та розпорядником
                    персональних даних, якщо ці дані обробляються незаконно чи є
                    недостовірними;
                  </li>
                  <li>
                    на захист своїх персональних даних від незаконної обробки та
                    випадкової втрати, знищення, пошкодження у зв’язку з умисним
                    приховуванням, ненаданням чи несвоєчасним їх наданням, а
                    також на захист від надання відомостей, що є недостовірними
                    чи ганьблять честь, гідність та ділову репутацію фізичної
                    особи;
                  </li>
                  <li>
                    звертатися із скаргами на обробку своїх персональних даних
                    до Уповноваженого або до суду;
                  </li>
                  <li>
                    застосовувати засоби правового захисту в разі порушення
                    законодавства про захист персональних даних;
                  </li>
                  <li>
                    вносити застереження стосовно обмеження права на обробку
                    своїх персональних даних під час надання згоди;
                  </li>
                  <li>відкликати згоду на обробку персональних даних;</li>
                  <li>
                    знати механізм автоматичної обробки персональних даних;
                  </li>
                  <li>
                    на захист від автоматизованого рішення, яке має для нього
                    правові наслідки.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">7.2.</div>
              <div>
                <b>Доступ, виправлення і видалення:</b> користувачі, які
                створили обліковий запис або розмістили профіль компанії на
                Сайті, можуть отримувати доступ, виправляти або видаляти
                інформацію, яку вони надають. Користувач несе відповідальність
                за точність і достовірність наданих даних. Розміщена інформація
                може бути змінена або видалена в особистому кабінеті користувача
                на Сайті. Володілець може припинити обробку персональних даних
                користувача в разі отримання письмового повідомлення користувача
                про відкликання його згоди на обробку персональних даних.
              </div>
            </li>
            <li>
              <div class="point-number">7.3.</div>
              <div>
                Щоб реалізувати будь-яке із зазначених вище прав (п. 7.1, 7.2)
                або у разі виникнення будь-яких запитань щодо використання
                персональних даних, користувачі можуть надіслати володільцеві
                запит за допомогою електронної пошти:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
            <li>
              <div class="point-number">7.4.</div>
              <div>
                <b>Кукіс:</b> більшість основних комп’ютерних веб-браузерів
                (наприклад, Safari, Firefox, Internet Explorer, Chrome, Opera)
                забезпечують елементи управління, які дозволяють користувачеві
                обмежити або заблокувати встановлення кукіс на системах
                користувачів. Проте відключення кукіс щодо доменів першої
                категорії (відвідуваних сайтів) та інших доменів (сайтів,
                пов’язаних із відвідуваними) може привести в деяких випадках до
                обмеженої функціональності цих вебсайтів.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>8. Безпека даних</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">8.1.</div>
              <div>
                Вся інформація, яку збирається на Сайті, в розумних межах
                захищена технічними засобами й процедурами забезпечення безпеки
                з метою запобігання несанкціонованому доступу або використанню
                даних. Надійні партнери й незалежні постачальники послуг
                зобов’язуються використовувати отриману від володільця
                інформацію відповідно до його вимог безпеки та цієї Політики
                конфіденційності.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>9. Внесення змін до Політики</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">9.1.</div>
              <div>
                Володілець може час від часу оновлювати цю Політику
                конфіденційності. Нова редакція Політики вступає в силу з
                моменту її розміщення в мережі Інтернет на Сайті, якщо інше не
                передбачено новою редакцією Політики.
              </div>
            </li>
            <li>
              <div class="point-number">9.2.</div>
              <div>
                У разі наявності будь-яких зауважень, побажань або претензій
                щодо цієї Політики та персональних даних, які обробляються
                відповідно до її положень, користувачі можуть звернутися за
                вказаною електронною адресою:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
            <li>
              <div class="point-number">9.3.</div>
              <div>
                Якщо володільцем були внесені будь-які зміни в Політику, з якими
                користувач не згоден, він зобов’язаний припинити використання
                Сайту. Факт продовження використання Сайту є підтвердженням
                згоди і прийняттям користувачем відповідної редакції Політики
                конфіденційності.
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </mat-card-content>
  </mat-card>
</article>
