import { CurrencyEnum } from '../enums';
import { UAH_CURRENCY_RATE } from './values';

const getUahPrice = (usd: number) => {
  return usd * UAH_CURRENCY_RATE;
};

export const JOBS_COUNT = [
  {
    id: 1,
    prices: {
      [CurrencyEnum.USD]: 25,
      [CurrencyEnum.UAH]: getUahPrice(25)
    }
  },
  {
    id: 5,
    prices: {
      [CurrencyEnum.USD]: 23,
      [CurrencyEnum.UAH]: getUahPrice(23)
    }
  },
  {
    id: 10,
    prices: {
      [CurrencyEnum.USD]: 21,
      [CurrencyEnum.UAH]: getUahPrice(21)
    }
  },
  {
    id: 50,
    prices: {
      [CurrencyEnum.USD]: 19,
      [CurrencyEnum.UAH]: getUahPrice(19)
    }
  }
];
