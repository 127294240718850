import { IChat, IChatResponse } from '@modules/chat/interfaces';
import { ICompany, IOffice } from '@modules/companies/interfaces';
import { IJobInvite } from '@modules/jobs/interfaces/jobs-invite.interfaces';
import { IJobApplyRequest } from '@modules/jobs/interfaces/jobs-request.interfaces';

import { IProfile } from '@core/auth';

import { JobStatusEnum } from '@common/shared/enums';
import { IJobQuestion } from '@common/shared/interfaces';

import {
  ICategory,
  IDomain,
  ILanguage,
  IValues,
  IWorkType
} from '@client/shared/interfaces';

interface ICommonJob {
  id?: number;
  title: string;
  hardSkills: string[];
  projectStack: string[];
  requirements: string[];
  niceToHave: string[];
  responsibilities: string[];
  teamSize: number;
  customerCountry: string;
  customerTrip: boolean;
  projectDescription: string;
  description: string;
  youtubeVideo: string;
  youtubeVideoUrl?: string;
  english: number;
  englishRequired: boolean;
  userLocation: string;
  userLocationRequired: boolean;
  experience: number;
  experienceRequired: boolean;
  status: JobStatusEnum;
  veteran: boolean;
  hot?: boolean;
  comment?: string; // admin verification comment
  user?: IProfile;
  company?: ICompany;
  salaryFrom?: number;
  salaryTo?: number;
  showSalary?: boolean;
  salary?: string;
  bonus: number;
  views?: number;
  advantages: string[];
  questionnaire: IJobQuestion[];
  hiringSteps: any;
  recruiterRate?: number;
  applicationUrl: string;
  categoryConditions?: string;
}

export interface IJob extends ICommonJob {
  categories: ICategory[];
  workTypes: IWorkType[];
  domains: IDomain[];
  domainsValue: string;
  languages: ILanguage[];
  languagesValue: string;
  userLocationValue: string;
  offices: IOffice[];
  isFollow: boolean;
  companyOwner: boolean;
  companyManager: boolean;
  companyOwnerOrManager: boolean;
  isAuthor: boolean;
  published: Date;
  activeDays?: number;
  created: Date;
  updated: Date;
  newRequestsCount?: number;
  newInvitesCount?: number;
  wasPublished?: number;
  newMessagesCount?: number;
  chats?: IChat[];
  [key: string]: any;
  viewsCount?: number;
  uniqueViewsCount?: number;
  allRequests?: number;
  acceptedRequests?: number;
  allInvites?: number;
  acceptedInvites?: number;
  invites?: Partial<IJobInvite>[];
  invitesCount?: number;
  requests?: Partial<IJobApplyRequest>[];
  requestsCount?: number;
}

export interface IJobRequest extends ICommonJob {
  domains: number | number[];
  languages: number[];
  workTypes: number[];
  offices: string[];
  userLocation: IValues | (string & any);
}

export interface IJobResponse extends ICommonJob {
  categories: ICategory[];
  workTypes: IWorkType[];
  domains: IDomain[];
  languages: ILanguage[];
  offices: IOffice[]; // ids;
  isFollow: boolean;
  companyOwner: boolean;
  companyManager: boolean;
  isAuthor: boolean;
  published: Date;
  created: Date;
  updated: Date;
  newRequestsCount?: number;
  newInvitesCount?: number;
  wasPublished?: number;
  newMessagesCount?: number;
  chats?: IChatResponse[];
  viewsCount?: number;
  uniqueViewsCount?: number;
  allRequests?: number;
  acceptedRequests?: number;
  allInvites?: number;
  acceptedInvites?: number;
}
