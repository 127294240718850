import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IResumeContact } from '@modules/contacts/interfaces';
import { ResumeContactService } from '@modules/contacts/services';
import { IJobApplyRequest, IJobInvite } from '@modules/jobs/interfaces';
import { JobsInviteService, JobsRequestService } from '@modules/jobs/services';
import { IResume } from '@modules/resumes/interfaces';

import { GlobalObjectService, ValuesService } from '@core/services';

import {
  ActiveStatusEnum,
  InviteStatusEnum,
  WorkTypeGroupEnum
} from '@common/shared/enums';
import { ReadyToWorkEnum } from '@common/shared/enums/ready-to-work.enum';

import { Destroyable } from '@client/shared/abstracts';
import { ROUTES_DATA } from '@client/shared/constants';
import { Memoise } from '@client/shared/decorators';
import { ITag, IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResumeComponent extends Destroyable implements OnInit {
  WorkTypeGroupEnum = WorkTypeGroupEnum;
  priorityOptions: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => ({
    id: el,
    value: el
  }));
  ActiveStatusEnum = ActiveStatusEnum;
  InviteStatusEnum = InviteStatusEnum;
  ReadyToWorkEnum = ReadyToWorkEnum;
  ROUTES_DATA = ROUTES_DATA;
  priorityForm: FormGroup;
  @Input() contactPage = false;
  @Input() userPreview = false;
  @Input() titleLink = true;
  @Input() resume: IResume;
  @Input() request: IJobApplyRequest;
  @Input() invite: IJobInvite;

  @Output() priorityChanged = new EventEmitter<number>();
  @Output() contactRemoved = new EventEmitter<boolean>();

  getCheckCount$ = new BehaviorSubject<number>(1);
  checkContact$: Observable<IResumeContact>;
  values$: Observable<IValues> = this.valuesService.getValues();
  constructor(
    readonly jobsInviteService: JobsInviteService,
    readonly jobsRequestService: JobsRequestService,
    readonly resumeContactService: ResumeContactService,
    readonly globalObjectService: GlobalObjectService,
    readonly valuesService: ValuesService,
    readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  @Memoise()
  getSiteName(url: string) {
    try {
      return new URL(url).host
        .replace('www.', '')
        .split('.')
        .reverse()[1]
        .split('.')
        .join(' ');
    } catch (e) {
      return url;
    }
  }

  ngOnInit() {
    this.buildPriorityForm();
    this.checkContact$ = this.getCheckCount$.pipe(
      switchMap(() => this.resumeContactService.checkContact(this.resume.id))
    );
  }

  buildPriorityForm() {
    const priority = this.request?.priority || this.invite?.priority;

    if (priority) {
      this.priorityForm = new FormGroup({
        priority: new FormControl({
          value: priority,
          disabled: !this.request?.jobOwner && !this.invite?.jobOwner
        })
      });

      this.priorityForm.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({ priority }) => {
          if (this.invite) {
            this.jobsInviteService
              .changeInvitePriority(this.invite.id, priority)
              .subscribe(() => {
                this.invite.priority = priority;
                this.priorityChanged.emit(priority);
              });
          }
          if (this.request) {
            this.jobsRequestService
              .changeRequestPriority(this.request.id, priority)
              .subscribe(() => {
                this.request.priority = priority;
                this.priorityChanged.emit(priority);
              });
          }
        });
    }
  }

  getAllTags(tags: ITag[]) {
    if (this.invite || this.request) {
      try {
        (this.invite || this.request).resume.tags = tags;
        this.changeDetector.detectChanges();
      } catch (e) {}
    }
  }

  triggerContactInfo() {
    this.getCheckCount$.next(1);
  }

  addToContacts() {
    this.resumeContactService
      .addToContacts(this.resume.id)
      .subscribe(() => this.triggerContactInfo());
  }

  removeResumeContact(id: number) {
    if (this.contactPage) {
      this.contactRemoved.emit(true);
    } else {
      this.resumeContactService
        .removeResumeContact(id)
        .subscribe(() => this.triggerContactInfo());
    }
  }
}
