import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  ICreateOrder,
  IJobService,
  IHotService
} from '@modules/settings/interfaces';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { IPlan } from '@client/shared/interfaces';

import { PlanFactory } from './plan.factory';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends BaseHttpService {
  jobService$ = new BehaviorSubject<IJobService[]>(null);
  hotServices$ = new BehaviorSubject<IHotService[]>(null);

  constructor(readonly factory: PlanFactory) {
    super('plans');
  }

  getMyPlans(params: IQuery) {
    return this.get<ICountResponse<IPlan[]>>(params).pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  selectPlan(data: ICreateOrder) {
    return this.post<ICreateOrder, { trial: boolean }>(data).pipe(
      map(({ content }) => content)
    );
  }

  activatePlan(id: number) {
    return this.patch(null, {}, `${id}/activate`);
  }

  loadJobServices(query: IQuery = {}) {
    return forkJoin([
      this.get<IJobService[]>(query, 'services'),
      this.get<IHotService[]>(query, 'hots')
    ]).pipe(
      tap(([serv, hots]) => {
        this.setServices(serv.content);
        this.setHots(hots.content);
      })
    );
  }

  setServices(services: IJobService[]) {
    this.jobService$.next(services);
  }

  setHots(services: IHotService[]) {
    this.hotServices$.next(services);
  }

  getJobServices() {
    return this.jobService$.asObservable();
  }

  getHotServices() {
    return this.hotServices$.asObservable();
  }

  getHotServicesValue() {
    return this.hotServices$.getValue();
  }
}
