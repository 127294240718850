import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@core/auth';
import { SeoService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  token = '';
  constructor(
    readonly fb: FormBuilder,
    readonly authService: AuthService,
    readonly activatedRoute: ActivatedRoute,
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly seoService: SeoService
  ) {
    this.token = activatedRoute.snapshot.queryParamMap.get('token');
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      password: new FormControl(
        '',
        Validators.compose([Validators.required, CustomValidators.password])
      ),
      confirmPassword: new FormControl(
        '',
        Validators.compose([Validators.required, CustomValidators.password])
      )
    });
  }

  send() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      return this.authService
        .resetPassword(this.token, this.form.value)
        .subscribe(() => {
          this.authService.toAuth();
        });
    }
  }
}
