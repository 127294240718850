import {
  Component,
  OnInit,
  Inject,
  Optional,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SkipSelf,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { SOCIAL_ICONS } from '@client/shared/constants';

@Component({
  selector: 'its-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class InputComponent<T extends string>
  extends BaseControlComponent<T>
  implements OnInit
{
  socialIcons = SOCIAL_ICONS;
  readonly destroyed$: Observable<unknown>;
  @Input() prefixIcon = '';
  @Input() staticPlaceholder = false;
  @Input() social = false;
  @Input() toggleVisibility = false;
  @Input() autocomplete = 'off';
  @Input() type = 'text';
  @Input() clear = false;
  @Input() icon: string;
  @Input() placeholder: string;

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input() maxlength: number;

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly iconAction = new EventEmitter<boolean>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue =
      value && typeof value !== 'number'
        ? value.trim().replace(/\s{2,}/g, ' ')
        : value;

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }

  clearValue() {
    this.control.setValue('');
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }

  onIconAction() {
    this.iconAction.emit(true);
  }

  getIcon(url: string) {
    const icon = '';
    try {
      const hostname = new URL(url).hostname;
      const key = Object.keys(this.socialIcons).find((i) => hostname.includes(i));
      if (key) {
        return this.socialIcons[key];
      }
    } catch (e) {}
    return icon;
  }
}
