import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { OfficesFactory } from '@modules/companies/services/offices.factory';

import { LocationCodesEnum } from '@common/shared/enums';
import { IDataLocations, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import {
  ISelectGroupOption,
  ISelectOption
} from '@client/shared/components/select';
import { COUNTRIES } from '@client/shared/constants';

import { IOfficeRequest, IOfficeResponse, IOffice } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OfficesService extends BaseHttpService {
  constructor(readonly factory: OfficesFactory) {
    super('offices');
  }

  getOffices() {
    return this.get<IOfficeResponse[]>().pipe(
      map(({ content }) => content.map((o) => this.factory.fromResponse(o)))
    );
  }

  getCompanyOffice(slug: string) {
    return this.get<IOfficeResponse[]>({}, `company/${slug}`).pipe(
      map(({ content }) => content.map((o) => this.factory.fromResponse(o)))
    );
  }

  getOfficesWithActiveJobs(slug: string) {
    return this.get<IOfficeResponse[]>({}, `company/${slug}/have-jobs`).pipe(
      map(({ content }) => content.map((o) => this.factory.fromResponse(o)))
    );
  }

  getSearchOffices(param: IQuery): Observable<ISelectOption<string>[]> {
    return this.get<IDataLocations>({ ...param }, `data/locations`).pipe(
      map(({ content }) => {
        const locations: ISelectOption<string>[] = [];

        Object.keys(content)
          .sort((a, b) => {
            if (b === 'UA' || a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
            return 0;
          })
          .forEach((cc) => {
            locations.push({
              value: COUNTRIES[cc],
              id: `${LocationCodesEnum.CountryCode}_${cc}`,
              level: 1
            });
            const regions = content[cc];
            for (const regionCode in regions) {
              const cities = regions[regionCode]?.cities;
              const oneCity = cities?.length === 1;

              locations.push({
                value: oneCity ? cities[0] : regions[regionCode].region,
                id: `${LocationCodesEnum.CountryCode}_${cc}+${LocationCodesEnum.RegionCode}_${regionCode}`,
                level: 2
              });
              if (regions.hasOwnProperty(regionCode) && !oneCity) {
                locations.push(
                  ...(regions[regionCode]?.cities || []).map((c) => ({
                    value: c,
                    id: `${LocationCodesEnum.CountryCode}_${cc}+${LocationCodesEnum.RegionCode}_${regionCode}+${LocationCodesEnum.City}_${c}`,
                    level: 3
                  }))
                );
              }
            }
          });

        return locations;
      })
    );
  }

  createOffice(office: IOfficeRequest) {
    const request = this.factory.toRequest(office);

    return this.post<IOfficeRequest, IOfficeResponse>(request).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  getOffice(id: number) {
    return this.get<IOfficeResponse>({}, id).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  updateOffice(office: IOfficeRequest, id: number) {
    const request = this.factory.toRequest(office);

    return this.put<IOfficeRequest, IOfficeResponse>(request, {}, id).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  deleteOffice(id: number) {
    return this.delete({}, id);
  }
}
