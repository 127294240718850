import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  InviteStatusEnum,
  ActiveStatusEnum,
  JobStatusEnum,
  InvoiceStatusEnum, PlanStatusEnum
} from '@common/shared/enums';
import { ROUTES_DATA } from '@client/shared/constants';

@Component({
  selector: 'its-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent {
  ROUTES_DATA = ROUTES_DATA;
  InviteStatusEnum = InviteStatusEnum;
  ActiveStatusEnum = ActiveStatusEnum;
  JobStatusEnum = JobStatusEnum;
  InvoiceStatusEnum = InvoiceStatusEnum;
  PlanStatusEnum = PlanStatusEnum;
  @Input() bg = false;
  @Input() inviteStatus: InviteStatusEnum;
  @Input() activeStatus: ActiveStatusEnum;
  @Input() jobStatus: JobStatusEnum;
  @Input() invoiceStatus: InvoiceStatusEnum;
  @Input() planStatus: PlanStatusEnum;
  constructor() {}
}
