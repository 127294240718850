<article class="public-text">
  <mat-card>
    <mat-card-content>
      <h2 class="t-a_c">
        <b>Privacy Policy</b>
      </h2>
      <div class="m-b_32 m-l_16">
        <div>Version: 1.01.</div>
        <div>Last updated: 01 November 2023.</div>
      </div>
      <ol>
        <li>
          <h3>1. General provisions</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">1.1.</div>
              <div>
                This Privacy Policy (hereinafter referred to as the "Policy")
                applies to the services provided by the search service Kupno,
                which is available at the web address {{ origin }}
                (hereinafter referred to as the "Site" or "Kupno"). The policy
                describes the procedure for processing personal data collected
                and related to the Site services and tools that allow users to
                register on the Site, publish or view in real-time already
                published information, and use others related to the above
                services of Kupno.
              </div>
            </li>
            <li>
              <div class="point-number">1.2.</div>
              <div>
                In all specified cases, users' data is processed under the
                requirements of the Law of Ukraine "On the Protection of
                Personal Data". This Policy is developed following the
                provisions of the specified document.
              </div>
            </li>
            <li>
              <div class="point-number">1.3.</div>
              <div>
                Personal data of Kupno users is processed by the owner of
                personal data, which is private entrepreneur Lisnyi Roman
                Vasyliovych, TIN 3284219853, registered at the address: Lviv
                region, Zolochivskyi district, Zakomaria village. The owner of
                personal data is the owner of the database of Kupno users’
                personal data.
              </div>
            </li>
            <li>
              <div class="point-number">1.4.</div>
              <div>
                The subject of personal data, by registering and/or authorizing
                on the Site, posting vacancies, posting resumes and/or other
                data on the Site, communicating on the Site, using the Site or
                related services and tools of Kupno in any other ways or
                performing any other actions on the Site, certifies his full and
                unconditional agreement with this Policy and the purposes and
                conditions of processing his personal data specified in it.
              </div>
            </li>
            <li>
              <div class="point-number">1.5.</div>
              <div>
                The basis for the processing of personal data by the owner is
                the conclusion and implementation of the
                <a [routerLink]="'/' + ROUTES_DATA.PUBLIC.children.LEGAL.url"
                  >Terms of Use</a
                >
                between the owner and the subject of personal data.
              </div>
            </li>
          </ol>
        </li>

        <li>
          <h3>2. Definition of terms</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">2.1.</div>
              <div>
                In this Policy, the following terms are used with the following
                meanings:
                <ol class="bullet-items">
                  <li>
                    personal database - a named set of organised personal data
                    in electronic form and/or in the form of personal data
                    files;
                  </li>
                  <li>
                    the owner of personal data (or owner) - a natural or legal
                    person who determines the purpose of personal data
                    processing, establishes the composition of this data and the
                    procedures for their processing unless otherwise determined
                    by law;
                  </li>
                  <li>
                    consent of the subject of personal data - a voluntary
                    expression of the will of a natural person (provided that he
                    is informed) regarding the granting of permission for the
                    processing of his data by the formulated purpose of their
                    processing, expressed in writing or in a form that makes it
                    possible to conclude that consent has been given;
                  </li>
                  <li>
                    depersonalisation of personal data - the removal of
                    information that makes it possible to directly or indirectly
                    identify a person;
                  </li>
                  <li>
                    card file - any structured personal data accessible
                    according to defined criteria, regardless of whether such
                    data is centralised, decentralised or divided according to
                    functional or geographical principles;
                  </li>
                  <li>
                    processing of personal data - any action or set of actions,
                    such as collection, registration, accumulation, storage,
                    adaptation, change, renewal, use and distribution
                    (distribution, implementation, transfer), depersonalization,
                    destruction of personal data, including using information
                    (automated) systems;
                  </li>
                  <li>
                    personal data - information or a set of information about a
                    natural person who is identified or can be specifically
                    identified;
                  </li>
                  <li>
                    The administrator of personal data - a natural or legal
                    person who is authorized by the owner of personal data or by
                    law to process this data on behalf of the owner;
                  </li>
                  <li>
                    subject of personal data (or user) - a natural person whose
                    personal data is processed;
                  </li>
                  <li>
                    third party - any person, except the Subject of personal
                    data, the Owner or manager of personal data and the
                    Commissioner of the Verkhovna Rada of Ukraine for Human
                    Rights (hereinafter - the "Commissioner"), to whom the owner
                    or manager of personal data transfers personal data.
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>3. Personal data processed</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">3.1.</div>
              <div>
                Depending on how the Subject of personal data uses the Site, the
                owner may collect and process the following personal data:
                <ol class="bullet-items">
                  <li>
                    <b>Registration on the Site:</b> when the user creates an
                    account on the Site, he must specify his first name, last
                    name, valid e-mail address and create a password.
                  </li>
                  <li>
                    <b>Creating a resume:</b> photo, region, position applied
                    for, category to which the position belongs, level of
                    English language proficiency and year of experience, domains
                    in which experience is available, minimum salary applied
                    for, type of employment, experience jobs, contact details,
                    technical skills, additional information about yourself,
                    languages you can work with, social media links, etc. The
                    user can also attach a PDF document from which personal data
                    will be processed by this Policy.
                  </li>
                  <li>
                    <b>Creating a company profile:</b> name, logo, region,
                    number of employees, year founded, company description,
                    company type, company website, social media links,
                    technology stack, benefits, phone, billing information,
                    company offices (address, phone, photo, description), etc.
                  </li>
                  <li>
                    <b>Announcements and agreements:</b> within the scope of the
                    Site, the owner may post information, including personal and
                    contact information, necessary for the execution of
                    agreements between the company and the candidate, for the
                    interaction of users with each other (including
                    communication on the Site), and for making payments.
                  </li>
                  <li>
                    <b>Technical support:</b> when users contact the customer
                    service department, if necessary, the owner may, during the
                    operation of the Site, collect personal data necessary to
                    fulfil the user's request and receive feedback. The owner
                    may also contact the user using the existing account contact
                    information provided for this purpose. The Owner may also
                    collect other information when communicating with users,
                    such as any support requests, including message content and
                    metadata provided by users or any feedback provided by them.
                  </li>
                  <li>
                    <b>Website and mobile data:</b> the owner may automatically
                    receive and record on its servers information from the
                    user's browser or any device, including computer information
                    (IP address, geographic location, browser type and version,
                    operating system, etc.), information about visits and use of
                    the Site (referral sources, duration of visits, viewed pages
                    and ways of navigating the Site, etc.), information
                    generated when using the Site (time, frequency and
                    conditions of its use). Additional information on how users
                    can control and/or block such information collection is
                    provided below in the sections of this Policy.
                  </li>
                  <li>
                    <b>Information obtained as a result of surveys:</b>
                    the owner may collect and store information obtained as a
                    result of surveys that may be conducted by the owner or
                    contractors (third parties) engaged by the owner, namely
                    information about Kupno activities, new projects, Kupno
                    development, goals, etc.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">3.2.</div>
              <div>
                The user is responsible for all information posted by him on the
                Site. The user must carefully understand all the risks
                associated with the fact that he publishes personal data or
                information of a public nature.
              </div>
            </li>
            <li>
              <div class="point-number">3.3.</div>
              <div>
                Before disclosing the personal data of a third party to Kupno,
                the user must obtain the consent of that person both for the
                disclosure and for the processing of this information by this
                Policy.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>4. Use of personal data</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">4.1.</div>
              <div>
                To provide its services, the owner may use the information they
                collect and places for the following purposes:
                <ol class="bullet-items">
                  <li>
                    to provide user service, including creating and managing
                    user accounts, solving technical difficulties and accessing
                    various functions;
                  </li>
                  <li>
                    to adapt offers and experiences, including advertising on
                    its services or services of third parties;
                  </li>
                  <li>
                    to control general and individual user activity, such as
                    keyword searches, ad posting and transaction activity, as
                    well as manage traffic on the Site;
                  </li>
                  <li>
                    to communicate with users, including for service, user
                    support or permitted marketing communications through any
                    available communication channels;
                  </li>
                  <li>
                    to carry out research and analytical activities to improve
                    Kupno;
                  </li>
                  <li>
                    to ensure compliance with the agreement with Kupno users,
                    taking into account the fight against fraud and abuse;
                  </li>
                  <li>
                    to evaluate certain factors of personal information, in
                    particular, to analyze and predict personal preferences,
                    interests, behaviour and location.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">4.2.</div>
              <div>
                The Owner will retain personal data for the longest of the
                following periods:
                <ol class="bullet-items">
                  <li>
                    the time necessary to achieve the purposes for which they
                    were collected, taking into account the owner's business
                    needs and legal requirements, including to comply with any
                    legal, accounting or reporting requirements;
                  </li>
                  <li>
                    the end of the period during which legal proceedings or
                    investigations may arise about the Services;
                  </li>
                  <li>as long as the user account exists.</li>
                </ol>
                This includes data that has been provided by the user or others,
                as well as data obtained as a result of the user's use of the
                services.
              </div>
            </li>
            <li>
              <div class="point-number">4.3.</div>
              <div>
                To determine the appropriate storage period, the owner takes
                into account the volume, nature and sensitivity of the personal
                data, the potential risk of harm from unauthorized use or
                disclosure of the personal data, the purpose for which the owner
                processes the personal data, and whether the owner can achieve
                these purposes by other means and appropriate legal
                requirements.
              </div>
            </li>
            <li>
              <div class="point-number">4.4.</div>
              <div>
                If you have any questions about the data retention period,
                please contact us at:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>5. Access to personal data</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">5.1.</div>
              <div>
                Access to personal data by a third party is carried out by Art.
                16, 17 of the Law of Ukraine "On the Protection of Personal
                Data" and is not provided if the specified person refuses to
                undertake obligations to ensure the fulfilment of the
                requirements of this law or is unable to ensure them.
              </div>
            </li>
            <li>
              <div class="point-number">5.2.</div>
              <div>
                The owner shall notify the subject of personal data within ten
                working days about the transfer of personal data to a third
                party, except for the cases provided for in Art. 14 of the Law
                of Ukraine "On Personal Data Protection".
              </div>
            </li>
            <li>
              <div class="point-number">5.3.</div>
              <div>
                The Owner discloses the personal and other data of the subject
                of personal data exclusively for the purposes related to the
                publication of vacancies by such subject on Kupno and access to
                the content of these vacancies by other Kupno users. By agreeing
                to this Policy, the subject of personal data consents to the
                disclosure of the data entered by him in vacancies on Kupno to
                all users of the Site, taking into account the conditions and
                restrictions established by this Policy.
              </div>
            </li>
            <li>
              <div class="point-number">5.4.</div>
              <div>
                The Owner reserves the right, under the requirements of the
                current legislation of Ukraine, to exchange personal data with
                state authorities to prevent fraud on the Site; settle disputed
                situations and clarify circumstances that are or may be the
                cause of violation of the current legislation, as well as in the
                case of detection by the Owner of illegal actions of the subject
                of personal data and/or receipt of claims, complaints, appeals
                of third parties
              </div>
            </li>
            <li>
              <div class="point-number">5.5.</div>
              <div>
                If the owner's business or a part of this business is sold or
                reorganized and the owner transfers all or almost all of its
                assets to a new owner, then the personal data of users may be
                transferred to the buyer to ensure the continuity of the Site.
              </div>
            </li>
            <li>
              <div class="point-number">5.6.</div>
              <div>
                The Owner may transfer certain de-identified information to
                third-party service providers, trusted partners or authorized
                researchers to better understand which advertisements or
                services may be of interest to users, to improve the overall
                quality and efficiency of the services on the Site, or to ensure
                its contribution to scientific research that, in the opinion of
                the Owner, can bring great social benefit.
              </div>
            </li>
            <li>
              <div class="point-number">5.7.</div>
              <div>
                The Owner may transfer the information it collects to third
                parties that provide services to the Owner (in particular,
                analytics, hosting, and customer relationship management
                services) to conduct research or provide services to users,
                while the data transferred is the subject of this Politicians
                and involved third parties have no right to use the received
                information other than to provide services to the owner.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>6. Use of cookies</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">6.1.</div>
              <div>
                During the operation of the Site, the owner may collect certain
                information using technologies such as cookies, which are small
                text files that store information directly on the user's
                computer, mobile phone or other device. With the help of
                cookies, the web server can save, for example, preferences and
                settings on the user's computer, mobile phone or other device,
                which are then automatically restored on the next visit. In
                other words, cookies are designed to make the use of the Site
                more convenient, for example, so that the user does not have to
                repeat the login process during the next visit.
              </div>
            </li>
            <li>
              <div class="point-number">6.2.</div>
              <div>
                The owner uses both persistent and session cookies. Persistent
                cookies remain on the user's computer for a longer period, and
                session cookies are automatically deleted when the browser
                window is closed. In particular, the owner uses authorization,
                analytical, and marketing cookies, as well as user CID storage
                and auxiliary cookies to save information about the user's
                language, etc.
              </div>
            </li>
            <li>
              <div class="point-number">6.3.</div>
              <div>
                The Owner may allow third parties, such as advertising and/or
                analytics service providers, to collect information using these
                types of technologies directly on the Site. The data they
                collect is subject to protection under the applicable privacy
                policies of those third parties.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>7. Rights of Subjects of personal data and user control</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">7.1.</div>
              <div>
                The Subject of personal data has the right to:
                <ol class="bullet-items">
                  <li>
                    to know about the sources of collection, the location of
                    their data, the purpose of their processing, the location or
                    place of residence (residence) of the owner or manager of
                    personal data or to give an appropriate order to obtain this
                    information to persons authorized by him, except for cases
                    established by law;
                  </li>
                  <li>
                    to receive information about the conditions of providing
                    access to personal data, in particular, information about
                    third parties to whom his data is transferred;
                  </li>
                  <li>to access your data;</li>
                  <li>
                    to receive an answer about whether his data is being
                    processed, as well as to receive the content of such
                    personal data no later than thirty calendar days from the
                    date of receipt of the request, except in cases provided by
                    law;
                  </li>
                  <li>
                    to make a reasoned demand to the owner of personal data with
                    an objection to the processing of his data;
                  </li>
                  <li>
                    to make a reasoned demand for the change or destruction of
                    your data by any owner and manager of personal data, if
                    these data are processed illegally or are unreliable;
                  </li>
                  <li>
                    to protect your data from illegal processing and accidental
                    loss, destruction, or damage due to intentional concealment,
                    failure to provide or untimely provision of them, as well as
                    protection from providing information that is unreliable or
                    disgraces the honour, dignity and business reputation of a
                    natural person ;
                  </li>
                  <li>
                    to file complaints about the processing of your data with
                    the Commissioner or the court;
                  </li>
                  <li>
                    to apply legal remedies in case of violation of the
                    legislation on the protection of personal data;
                  </li>
                  <li>
                    to offer the limitation of the right to process your data
                    when giving consent;
                  </li>
                  <li>
                    to withdraw consent to the processing of personal data;
                  </li>
                  <li>
                    to know the mechanism of automatic personal data processing;
                  </li>
                  <li>
                    to protect against an automated decision that has legal
                    consequences for it.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">7.2.</div>
              <div>
                <b>Right of access, correction and deletion:</b> Users who have
                created an account or posted a company profile on the Site may
                access, correct or delete the information they provide. The user
                is responsible for the accuracy and reliability of the data
                provided. Posted information can be changed or deleted from the
                user's account on the Site. The owner may stop the processing of
                the user's data upon receipt of written notification of the user
                withdrawing his consent to the processing of personal data.
              </div>
            </li>
            <li>
              <div class="point-number">7.3.</div>
              <div>
                To exercise any of the above-mentioned rights (clauses 7.1, 7.2)
                or in case of any questions regarding the use of personal data,
                users can send a request to the owner by e-mail:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
            <li>
              <div class="point-number">7.4.</div>
              <div>
                <b>Cookies:</b> most major computer web browsers (eg Safari,
                Firefox, Internet Explorer, Chrome, Opera) provide controls that
                allow the user to limit or block the installation of cookies on
                users' systems. However, disabling cookies for domains of the
                first category (sites visited) and other domains (sites related
                to the sites visited) may result in limited functionality of
                these websites in some cases.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>8. Data security</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">8.1.</div>
              <div>
                All information collected on the Site is reasonably protected by
                technical means and security procedures to prevent unauthorized
                access or use of data. Trusted partners and independent service
                providers undertake to use the information received from the
                owner by its security requirements and this Privacy Policy.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>9. Amendments to the Policy</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">9.1.</div>
              <div>
                The Owner may update this Privacy Policy from time to time. The
                new version of the Policy enters into force from the moment of
                its posting on the Internet on the Site, unless otherwise
                provided by the new version of the Policy.
              </div>
            </li>
            <li>
              <div class="point-number">9.2.</div>
              <div>
                In case of any comments, wishes or claims regarding this Policy
                and personal data processed by its provisions, users can contact
                the indicated email address:
                <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                >.
              </div>
            </li>
            <li>
              <div class="point-number">9.3.</div>
              <div>
                If the owner has made any changes to the Policy, with which the
                user does not agree, he must stop using the Site. The fact of
                continued use of the Site is a confirmation of consent and
                acceptance by the user of the appropriate version of the Privacy
                Policy.
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </mat-card-content>
  </mat-card>
</article>
