import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { AuthService } from '@core/auth';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import {
  IResumeContact,
} from '../interfaces';
import { ResumeFactory } from '@modules/resumes/services';
import { IResumeResponse } from '@modules/resumes/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ResumeContactService extends BaseHttpService {
  constructor(
    readonly factory: ResumeFactory,
    readonly authService: AuthService
  ) {
    super('contacts');
  }

  getAllContacts(params: IQuery) {
    return this.get<ICountResponse<IResumeResponse[]>>(params).pipe(
      map(({ content }) => ({
        data: content.data.map((i) => this.factory.fromResponse(i)),
        count: content.count
      }))
    );
  }

  checkContact(id: string) {
    return this.get<IResumeContact>({}, `resume/${id}/check`).pipe(
      map(({ content }) => content)
    );
  }

  getOneContact(id: string) {
    return this.get<IResumeResponse>({}, `resume/${id}`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  addToContacts(id: string) {
    return this.post(null, {}, `resume/${id}/save`);
  }

  removeResumeContact(id: number) {
    return this.delete({}, id);
  }

  companyBulkRemoveContacts(data: number[]) {
    return this.put<number[]>(data, {}, 'resume/bulk-remove');
  }
}
