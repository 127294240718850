import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HelperService } from '@core/services';

import { IDateRange } from '@common/shared/interfaces';

import { Destroyable } from '@client/shared/abstracts';

@Component({
  selector: 'its-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent extends Destroyable implements OnInit {
  queryParams = this.activatedRoute.snapshot.queryParamMap;

  @Input() placeholder: string = _('common.dateRange.placeholder');
  @Input() isoDate = true;
  @Input() initialPeriod = 0;
  @Input() changeQuery = true;
  @Input() isRequired = false;
  @Input() clear = false;
  @Input() disabled = false;
  @Input() minDate: Date;
  @Input() maxDate = moment().toDate();

  @Output() selectPeriod = new EventEmitter<IDateRange>();

  dateForm: FormGroup;
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly helperService: HelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.dateChanged(true);
  }

  initFormGroup() {
    let startDate = null;
    let endDate = null;
    const startParam = this.queryParams.get('startDate');
    const endParam = this.queryParams.get('endDate');

    if (startParam) {
      startDate = moment(startParam, 'DD-MM-YYYY').toDate();
    } else {
      if (!startParam && this.initialPeriod) {
        startDate = moment().subtract(this.initialPeriod, 'day').toDate();
      }
    }

    if (endParam) {
      endDate = moment(endParam, 'DD-MM-YYYY').toDate();
    } else {
      if (!endParam && this.initialPeriod) {
        endDate = moment().toDate();
      }
    }

    this.dateForm = new FormGroup({
      startDate: new FormControl(startDate),
      endDate: new FormControl(endDate)
    });
  }

  dateChanged(init = false) {
    const { startDate, endDate } = this.dateForm.value;
    if ((startDate && endDate) || init) {
      const date = {
        startDate: startDate
          ? moment(startDate).startOf('day').toISOString()
          : startDate,
        endDate: endDate ? moment(endDate).endOf('day').toISOString() : endDate
      };
      this.emitDate(date);

      if (this.changeQuery) {
        this.helperService.changeQueryParam(this.getStringDate(date));
      }
    }
  }

  clearValue() {
    const date = {
      startDate: null,
      endDate: null
    };
    this.dateForm.patchValue(date);
    this.emitDate(date);
    if (this.changeQuery) {
      this.helperService.changeQueryParam(date);
    }
  }

  emitDate(date: IDateRange) {
    this.selectPeriod.emit(this.isoDate ? date : this.getStringDate(date));
  }

  getStringDate(date: IDateRange) {
    const { startDate, endDate } = date;
    return {
      startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : startDate,
      endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate
    };
  }
}
