import { Injectable } from '@angular/core';
import {
  DialogPosition,
  MatDialog, MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';

import { ModalComponent } from '@client/shared/components/modal';
import { DomSanitizer } from '@angular/platform-browser';

export interface ConfirmOptions {
  header?: string;
  body?: string;
  plusBody?: string;
  width?: string;
  html?: any;
  disableClose?: boolean;
  buttons?: { name: string; warn?: boolean }[];
  classes?: string;
  position?: DialogPosition;
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(readonly dialog: MatDialog,
              readonly sanitizer: DomSanitizer) {}

  /**
   * Open modal
   */

  public openModal(
    options: ConfirmOptions,
    answer: (res) => void = null
  ): MatDialogRef<any> {
    const disable = options.disableClose || false;

    if (options.html) {
      options.html = this.sanitizer.bypassSecurityTrustHtml(options.html);
    }

    const dialogRef = this.dialog.open(ModalComponent, {
      width: options?.width || '450px',
      data: options,
      disableClose: disable,
      position: options.position
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (answer) {
        answer(result);
      }
    });

    return dialogRef;
  }

  openComponent(
    component: any,
    result: (res) => void = null,
    config: MatDialogConfig = {}
  ) {
    const dialogRef = this.dialog.open(component, {
      width: '450px',
      ...config
    } as ConfirmOptions);
    dialogRef.afterClosed().subscribe((res) => {
      if (result) {
        result(res);
      }
    });
    return dialogRef;
  }

  closeActivePopups() {
    this.dialog.closeAll();
  }

  /**
   * How use in component
   */

  /*
  method() {
    const options = {
      header: 'Title',
      body: 'Description text',
      buttons: [{name: 'Yes'}, {name: 'No'}]
    };
    this.modalService.openModal(options, answer => {
      if (answer) {
        // if answer CONFIRM
      } else {
        // IF answer REJECT
      }
    });
  }
  */
}
