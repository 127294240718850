export interface ICompanyStats {
  publishedVacancies: number;
  closedVacancies: number;
  closedWithKupno: number;
  viewsVacancies: number;
  allRequests: number;
  acceptedRequests: number;
  allInvites: number;
  acceptedInvites: number;
  viewsCompanyProfile: number;
  date?: Date;
}
