import { IChatResponse } from '@modules/chat/interfaces';
import { IJob } from '@modules/jobs/interfaces/jobs.interfaces';
import { IResume } from '@modules/resumes/interfaces';

import { IUserEntity } from '@core/auth';

import { InviteStatusEnum, ReqInvStateEnum } from '@common/shared/enums';
import { IReqInvState } from '@common/shared/interfaces';

export interface IJobApplyRequest {
  created: Date;
  id: number;
  job: IJob;
  new: boolean;
  status: InviteStatusEnum;
  updated: Date;
  user: IUserEntity;
  resume: IResume;
  reason?: number;
  isAuthor?: boolean;
  chat?: IChatResponse;
  jobOwner?: boolean;
  userArchived?: boolean;
  companyArchived?: boolean;
  priority?: number;
  selected?: boolean;
  state?: ReqInvStateEnum;
  stateDescription?: string;
  stateHistory?: IReqInvState[];
}

export interface IJobApplyAnswer {
  status: InviteStatusEnum;
  message?: string;
  reason?: number;
}

export interface IJobApply {
  resume?: number;
  message?: string;
}
