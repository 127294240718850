import {
  ANONYMOUS_ID_PREFIX,
  ANONYMOUS_VALUE,
  COOKIE_DOMAIN,
  EXCLUDE_LOCATION,
  EXPERIENCES_MAP,
  INCLUDE_LOCATION,
  LOCATION_JOIN,
  SALARY_MAX
} from '../constants';
import { LocationCodesEnum, LogicConditionEnum } from '../enums';

export type Enum<E> = Record<keyof E, number | string> & {
  [k: number]: string;
};

export function timing(hours = 0, minutes = 0) {
  return (+hours * 1000 * 60 || 0) + (+minutes * 1000 || 0);
}

export function isUndefined(value: any) {
  return typeof value === 'undefined';
}

export function isNullOrUndefined(value: any) {
  return value === null || isUndefined(value);
}

export function isFunction(value: any) {
  return typeof value === 'function';
}

export function isNumber(value: any) {
  return typeof +value === 'number';
}

export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function generateGuid() {
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
export function generateHash() {
  return s4() + s4() + s4();
}

export function generateUniqToken(length = 100) {
  return [...new Array(Math.round(length / 4))].map(() => s4()).join('');
}

export function generateAnonymToken() {
  return ANONYMOUS_ID_PREFIX + s4() + s4() + '-' + s4() + Date.now();
}

export function generateConfirmationToken() {
  return s4() + s4() + Date.now() + s4() + s4() + s4() + s4();
}

export function generateCid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + Date.now();
}

export function isString(value: any) {
  return typeof value === 'string';
}

export function isBoolean(value: any) {
  return typeof value === 'boolean';
}

export function isObject(value: any) {
  return value !== null && typeof value === 'object';
}

export function isArray<T>(item: T[] | any): item is Array<T> {
  return !!(item && item.constructor === Array);
}

export function isNumberFinite(value: any) {
  return isNumber(value) && isFinite(value);
}

export function isNumeric(value: any) {
  return (
    (isNumber(value) || isString(value)) && !isNaN(value - parseFloat(value))
  );
}

export function applyPrecision(num: number, precision: number) {
  if (precision <= 0) {
    return Math.round(num);
  }

  const tho = 10 ** precision;

  return Math.round(num * tho) / tho;
}

export function extractDeepPropertyByMapKey(obj: any, mapString: string): any {
  const keys = mapString.split('.');
  const head = keys.shift();

  return keys.reduce((prop: any, key: string) => {
    return !isUndefined(prop) && !isUndefined(prop[key])
      ? prop[key]
      : undefined;
  }, obj[head || '']);
}

export function getKeysTwoObjects(obj: any, other: any): any {
  return [...Object.keys(obj), ...Object.keys(other)].filter(
    (key, index, array) => array.indexOf(key) === index
  );
}

export function isDeepEqual(obj: any, other: any, exceptKeys?: string[]): any {
  if (!isObject(obj) || !isObject(other)) {
    return obj === other;
  }

  return getKeysTwoObjects(obj, other).every((key: any): boolean => {
    if (exceptKeys && exceptKeys.indexOf(key) >= 0) {
      return true;
    }
    if (!isObject(obj[key]) && !isObject(other[key])) {
      return obj[key] === other[key];
    }
    if (!isObject(obj[key]) || !isObject(other[key])) {
      return false;
    }

    return isDeepEqual(obj[key], other[key]);
  });
}

export function isEmptyString(value) {
  return isUndefined(value) || (isString(value) && !value.length);
}

export function isEmptyObject(object, deep = false) {
  if (object == null) {
    return true;
  } else {
    if (Object.keys(object).length) {
      return deep
        ? Object.values(object).every((val) => isUndefined(val) || val === null)
        : false;
    }
    return true;
  }
}

/**
 * o[name] is of type T[K]
 * @param o
 * @param name: o[name] is of type T[K]
 */
export function getProperty<T, K extends keyof T>(o: T, name: K): T[K] {
  return o[name]; // Inferred type is T[K]
}

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
  obj[key] = value;
}

/**
 * @description get nested property
 * @param p
 * @param o
 * @tutorial https://glebbahmutov.com/blog/call-me-maybe/
 */
export function get(keys: string | string[], o: object) {
  keys = isArray(keys) ? keys : keys.split('.');
  return keys.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
}

/**
 * @tutorial https://stackoverflow.com/a/25835337/4115894
 * @description Creates an object composed of the picked object properties.
 * @param o Object
 * @param fields []
 */
export function pick(o, ...fields) {
  return fields.reduce((a, x) => {
    if (o.hasOwnProperty(x)) {
      a[x] = o[x];
    }
    return a;
  }, {});
}

/**
 * @tutorial https://gist.github.com/bisubus/2da8af7e801ffd813fab7ac221aa7afc#file-omit-es2017-js
 * @description this method creates an object composed of the own and inherited enumerable property paths of object that are not omitted.
 * @param obj Object
 * @param fields []
 */
export function omit(o, ...fields) {
  return Object.entries(o)
    .filter(([key]) => !fields.includes(key))
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
}

/**
 * @tutorial https://stackoverflow.com/a/17781518/4115894
 * @param sourceObject
 * @param keys
 */
export function cloneAndPluck(sourceObject, keys) {
  const newObject = {};
  keys.forEach((key) => {
    newObject[key] = sourceObject[key];
  });
  return newObject;
}

/**
 * @tutorial https://stackoverflow.com/a/47225591
 * @param array Array
 * @param checkValid Function
 * @example
 *  const [pass, fail] = partition(myArray, (e) => e > 5);
 */
export function partition<T>(
  array: T[],
  checkValid: (item: T) => boolean
): [T[], T[]] {
  return array.reduce(
    ([pass, fail], elem) => {
      return checkValid(elem)
        ? [[...pass, elem], fail]
        : [pass, [...fail, elem]];
    },
    [[], []]
  ) as [T[], T[]];
}

/**
 * The base implementation of `_.toString` which doesn't convert nullish
 * values to empty strings.
 *
 * @private
 * @param {*} value The value to process.
 * @returns {string} Returns the string.
 */
export function toString(value: any) {
  // Exit early for strings to avoid a performance hit in some environments.
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value)) {
    // Recursively convert values (susceptible to call stack limits).
    return value.map((item) => toString(item)) + '';
  }
  const result = value + '';
  return result;
}

export function fillOptionsWith(n) {
  return Array.from(Array(n).keys()).map((i) => ({
    label: i.toString(),
    value: i
  }));
}

export function move(array: any[], fromIndex: number, toIndex: number) {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  return array;
}

export function findById(objArray: any[], id: number | string) {
  return objArray.find((obj) => obj.id === id);
}

export function fromObjToArray(obj: object) {
  const arr = [];
  if (!isEmptyObject(obj)) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        arr.push(obj[key]);
      }
    }
  }
  return arr;
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function deepMerge(source, obj) {
  const newObj = {};
  for (const key of Object.keys(source)) {
    if (obj instanceof Object && key in obj) {
      if (source[key] instanceof Object) {
        newObj[key] = deepMerge(source[key], obj[key]);
      } else {
        newObj[key] = isNullOrUndefined(obj[key]) ? source[key] : obj[key];
      }
    } else {
      newObj[key] = source[key];
    }
  }
  return newObj;
}

// Humanize a string
// From: https://stackoverflow.com/a/28339742/477550
export const humanize = (str: string, cap = false) => {
  const newStr = str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, (m) => m.toUpperCase());
  return cap
    ? newStr
        .split(' ')
        .map((s) => capitalize(s))
        .join(' ')
    : str;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
export const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const stringMatcher = (str: string) =>
  new RegExp(`^${escapeRegExp(str)}$`, 'i');

// Capitalize first letter in a string
// From: https://stackoverflow.com/a/43237732
export const capitalize = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.toLowerCase().substring(1)}`;

/** Utility function to create a K:V from a list of strings */
export function strEnum<T extends string>(
  s: T[],
  cap = false
): { [K in T]: K } {
  return s.reduce((res, key) => {
    res[key] = cap ? capitalize(key) : humanize(key, true);
    return res;
  }, Object.create(null));
}

export function removeEmptyObjProperty(myObj: object) {
  const obj = { ...myObj };
  Object.keys(obj).forEach(
    (key) =>
      (isNullOrUndefined(obj[key]) || isEmptyString(obj[key])) &&
      delete obj[key]
  );
  return obj;
}

export function deepRemoveEmptyObjProperty(object) {
  const obj = { ...object };
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (isObject(val) && !isEmptyObject(val)) {
      obj[key] = deepRemoveEmptyObjProperty(val);
    }
    if (isNullOrUndefined(obj[key]) || isEmptyString(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
}

export function fromEnumToFormOptions<T>(
  enm: Enum<T>,
  isHumanize = true
): { value: number; label: string }[] {
  const options = [];
  for (const item in enm) {
    if (typeof enm[item] !== 'number') {
      const index = +item;
      options.push({
        value: index,
        label: isHumanize ? humanize(enm[index], true) : enm[index]
      });
    }
  }
  return options;
}

export function fromEnumToArray<T>(enm: Enum<T>): Array<T> {
  const arr = [];
  for (const item in enm) {
    if (enm.hasOwnProperty(item)) {
      arr.push(enm[item]);
    }
  }
  return arr;
}

export function isEqualTwoArrays(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function isEqualTwoDataSets(data1: any, data2: any) {
  return JSON.stringify(data1) === JSON.stringify(data2);
}

export function isIEOrEdge() {
  const ua = navigator?.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  const is_ie =
    ua.indexOf('MSIE ') > -1 ||
    ua.indexOf('Trident/') > -1 ||
    ua.indexOf('Edge/') > -1;

  return is_ie;
}

export function timeConvert(value: number) {
  if (value && isNumber(value)) {
    const hours = Math.floor(value / 60);
    const minutes = ('0' + (value % 60)).slice(-2);
    return `${hours} hr ${minutes} min`;
  }
  return '00 min';
}

export function getFileExtensionFromName(filename: string) {
  const r = /.+\.(.+)$/.exec(filename);
  return r ? r[1] : null;
}

export function getReadableFileSize(
  bytes: number = 0,
  precision: number = 1
): string {
  if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
    return '';
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let unit = 0;

  while (bytes >= 1024) {
    bytes /= 1024;
    unit++;
  }

  return `${bytes.toFixed(+precision)} ${units[unit]}`;
}

export const allowOnlyNumber = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
};

export const allowNumberAndDot = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  return !(
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 46
  );
};

export const allowOnlyNumberAndDash = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  return !(
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 45
  );
};

export const allowOnlyNumberAndSemicolon = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 58));
};

export function clickAllowed(ev) {
  return !ev.target.hasAttribute('notAllowed');
}

export function getRandomNumber(lengths = 4) {
  const count = +`1${[...new Array(lengths || 1)].map(() => 0).join('')}`;
  return Math.floor(Math.random() * count);
}

export function isUuidFormat(id: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    id
  );
}

export function GColor(r, g, b) {
  r = typeof r === 'undefined' ? 0 : r;
  g = typeof g === 'undefined' ? 0 : g;
  b = typeof b === 'undefined' ? 0 : b;
  return { r, g, b };
}

export function createColorRange(count: number, c1, c2) {
  const step = 255 / count;
  return Array.from(Array(count).keys()).map((el, i) => {
    const r = c1.r + Math.floor((step * i * (c2.r - c1.r)) / 255);
    const g = c1.g + Math.floor((step * i * (c2.g - c1.g)) / 255);
    const b = c1.b + Math.floor((step * i * (c2.b - c1.b)) / 255);
    return GColor(r, g, b);
  });
}

export function getSearchArray(value: any) {
  if (typeof value === 'string' && !!value) {
    return value.split(',');
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [];
}

export function createTextLinks(text) {
  return (text || '').replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      let hyperLink = url;
      if (!hyperLink.match('^https?://')) {
        hyperLink = 'http://' + hyperLink;
      }
      return (
        space + '<a href="' + hyperLink + '" target="_blank">' + url + '</a>'
      );
    }
  );
}

export function setCookie(cname, cvalue, exdays = 365) {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${COOKIE_DOMAIN}`;
  } catch (e) {}
}

export function getCookie(name: string, cookies = null) {
  try {
    const nameEQ = name + '=';
    const ca = (cookies || document.cookie).split(';');
    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function deleteCookie(name) {
  try {
    document.cookie = `${name}=;path=/;domain=${COOKIE_DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  } catch (e) {}
}

export function getPercentageDiff(previous: number, current: number) {
  const prev = +previous || 0;
  const curr = +current || 0;
  const diff = curr - prev;
  if (diff > 0) {
    const increase = curr - prev;
    return increase / curr;
  } else {
    // decrease
    const decrease = prev - curr;
    return decrease ? decrease / prev : 0;
  }
}

export function getStatsDirection(prev, curr) {
  return +prev >= +curr ? 'down' : 'up';
}

export function parseLocation(location: string): {
  countryCode?: string;
  regionCode?: string;
  city?: string;
  address?: string;
} {
  const obj = (location || '').split('+').reduce((acc, el) => {
    const option = el.trim().split('_');
    acc[option[0]] = option[1];
    return acc;
  }, {});
  const countryCode = obj[LocationCodesEnum.CountryCode] || null;
  const regionCode = obj[LocationCodesEnum.RegionCode] || null;
  const city = obj[LocationCodesEnum.City] || null;
  const address = obj[LocationCodesEnum.Address] || null;

  return {
    countryCode,
    regionCode,
    city,
    address
  };
}

export function getIncludeLocation(value: string) {
  const arr = (value || '').split(LOCATION_JOIN);
  return (
    arr.find((el) => el.startsWith(INCLUDE_LOCATION))?.split('=')?.[1] || ''
  );
}

export function getExcludeLocation(value: string) {
  const arr = (value || '').split(LOCATION_JOIN);
  return (
    arr.find((el) => el.startsWith(EXCLUDE_LOCATION))?.split('=')?.[1] || ''
  );
}

export function parseBooleanLocation(location: string): {
  include: string[];
  exclude: string[];
} {
  const includeLocation = getIncludeLocation(location);
  const excludeLocation = getExcludeLocation(location);
  return {
    include: includeLocation ? includeLocation.split('__') : [],
    exclude: excludeLocation ? excludeLocation.split('__') : []
  };
}

export function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue / 100;
}

export function getTemplateMessage(
  message: string,
  variableValue: string,
  variableName = 'name'
) {
  let regex = new RegExp(`\\s\\{${variableName}\\}`, 'g');
  return (message || '').replace(
    regex,
    variableValue !== ANONYMOUS_VALUE && variableValue
      ? ` ${variableValue}`
      : ''
  );
}

export function getBulkTemplateMessage(message: string, userName: string) {
  return (message || '').replace(
    /\s\{name\}/g,
    userName !== ANONYMOUS_VALUE && userName ? ` ${userName}` : ''
  );
}

export function getPercentDiff(sum: number, value: number) {
  const step = sum / 100;
  return Math.round(100 - value * step) / 100;
}

export function isBase64(value: string): boolean {
  return value.split(';base64,').length > 1;
}

export function getNamePreview(firstName: string, lastName: string): string {
  return (
    (firstName || '').charAt(0).toUpperCase() +
    (lastName || '').charAt(0).toUpperCase()
  );
}

export function getSalary(salaryFrom: number, salaryTo: number) {
  return salaryFrom === SALARY_MAX
    ? `$${salaryFrom} +`
    : salaryTo
    ? `$${salaryFrom}-${salaryTo}`
    : '';
}

export function getBaseURL(url) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.host}`;
  } catch (e) {
    return '';
  }
}

export function getResultCategoriesBooleanConditions(
  value: string | string[],
  categoriesMap: any
) {
  const data =
    (typeof value === 'string' ? value.split('_') : (value as string[])) || [];
  return data
    .filter(Boolean)
    .map((v, i, arr) => {
      if (v === LogicConditionEnum.And || v === LogicConditionEnum.Or) {
        return v;
      } else {
        if (!data.find((v) => v === LogicConditionEnum.And)) {
          // if no AND condition
          return categoriesMap[v];
        }

        const prev = arr?.[i - 1];
        const next = arr?.[i + 1];
        if (
          next === LogicConditionEnum.Or &&
          (prev === LogicConditionEnum.And || !prev)
        ) {
          return `( ${categoriesMap[v]}`;
        }
        if (
          prev === LogicConditionEnum.Or &&
          (next === LogicConditionEnum.And || !next)
        ) {
          return `${categoriesMap[v]} )`;
        }

        return categoriesMap[v];
      }
    })
    .join(' ');
}

export function getCategoriesBooleanConditions(categories: string): number[][] {
  return categories.split(LogicConditionEnum.And).map((part) =>
    part
      .trim()
      .split(LogicConditionEnum.Or)
      .filter(Boolean)
      .map((subPart) => +subPart?.trim()?.match(/\d+/)?.[0] || 0)
  );
}

export function checkIfResumeIsMatchedToVacancy(resume: any, job: any) {
  let result = [];
  if (job.userLocationRequired) {
    const { countryCode, regionCode, city } = parseLocation(job.userLocation);
    const trimValue = (value: string) => {
      return value.split(' ')[0].slice(0, 4).toLowerCase();
    };
    if (
      (city && city !== resume.city) ||
      (regionCode && trimValue(regionCode) !== trimValue(resume.regionCode)) ||
      (countryCode && countryCode !== resume.countryCode)
    ) {
      result.push('error.validation.applyJob.locationRequired');
    }
  }
  if (job.englishRequired) {
    if (job.english && resume.english < job.english) {
      result.push('error.validation.applyJob.englishRequired');
    }
  }
  if (job.experienceRequired) {
    if (job.experience > 1) {
      const jobExtYears = EXPERIENCES_MAP[job.experience];
      const resumeExtYears = resume.experienceFrom
        ? new Date().getFullYear() - resume.experienceFrom
        : 0;

      if (!resume.experienceFrom || resumeExtYears < jobExtYears) {
        result.push('error.validation.applyJob.experienceRequired');
      }
    }
  }
  return result;
}
