<a
  class="link-empty"
  routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{ company.slug }}"
>
  <mat-card class="company-item card-hover">
    <mat-card-content class="company-item__content c-default-white">
      <div class="company-item__data" [class.no-logo]="!company.logo">
        <a
          *ngIf="company.logo"
          class="company-item__logo"
          routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{ company.slug }}"
        >
          <img [src]="company.logo" [alt]="company.name" />
        </a>
        <div>
          <div class="a-i_b">
            <h3 class="company-item__title highlight-hover f-w_e-b">
              <span>{{ company.name }}</span>
              <span *ngIf="company.verified" class="verified-icon">
                <its-icon svgIcon="verified"></its-icon>
              </span>
            </h3>

            <div class="company-item__location" *ngIf="company.cities">
              <its-icon size="15" svgIcon="place"></its-icon>
              <span class="title">{{ company.cities }}</span>
            </div>
          </div>
          <div *ngIf="company.employees" class="m-b_8">
            <span>{{ 'itemCompany.text.employees' | translate }}</span> -
            <b>{{ values.employeesMap[company.employees] }}</b>
          </div>
        </div>
      </div>
      <p class="company-item__description m-b_0">{{ company.description }}</p>
      <div class="a-i_c j-c_f-e">
        <small
          ><span>{{ 'itemCompany.text.jobsCount' | translate }}</span
          >&nbsp;-&nbsp;<b>{{ company.jobsCount }}</b></small
        >
        <its-button
          class="company-item__follow"
          itsNeedAuth
          variant="icon"
          color="{{ company.isFollow ? 'accent' : '' }}"
          (click)="toggleFollow($event)"
          [matTooltip]="
            company.isFollow
              ? ('common.tooltip.unfollow' | translate)
              : ('common.tooltip.follow' | translate)
          "
          matTooltipPosition="after"
        >
          <its-icon
            svgIcon="{{ company.isFollow ? 'star' : 'star_border' }}"
          ></its-icon>
        </its-button>
      </div>
    </mat-card-content>
  </mat-card>
</a>
