import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';

import { Observable } from 'rxjs';

import { IJob } from '@modules/jobs/interfaces';
import { JobsService } from '@modules/jobs/services';

import { ValuesService } from '@core/services';

import {
  InviteStatusEnum,
  JobViewTypeEnum,
  WorkTypeGroupEnum,
  WorkTypesEnum
} from '@common/shared/enums';
import { clickAllowed } from '@common/shared/helpers';

import { ROUTES_DATA } from '@client/shared/constants';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-item-job',
  templateUrl: './item-job.component.html',
  styleUrls: ['./item-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemJobComponent {
  JobViewTypeEnum = JobViewTypeEnum;
  WorkTypeGroupEnum = WorkTypeGroupEnum;
  InviteStatusEnum = InviteStatusEnum;
  WorkTypesEnum = WorkTypesEnum;
  ROUTES_DATA = ROUTES_DATA;
  _job: IJob;
  @Input() set job(job: IJob) {
    this._job = {
      ...job,
      cities: [...new Set(job.offices.map((o) => o.city))].join(', '),
      hardSkills: job.hardSkills.slice(0, 7)
    };
  }

  get job() {
    return this._job;
  }
  values$: Observable<IValues> = this.valuesService.getValues();

  constructor(
    readonly jobsService: JobsService,
    readonly valuesService: ValuesService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  toggleFollow(ev, job: IJob) {
    ev.preventDefault();
    ev.stopPropagation();
    if (clickAllowed(ev)) {
      const follow = !job.isFollow;
      this.jobsService.followJobStatus(follow, job.id).subscribe(() => {
        job.isFollow = follow;
        this.changeDetector.markForCheck();
      });
    }
  }
}
