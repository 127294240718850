import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { IChat, IChatResponse } from '@modules/chat/interfaces';
import { IJobResponse } from '@modules/jobs/interfaces';
import { JobsFactory } from '@modules/jobs/services/jobs.factory';

import { AuthService, IUserEntity } from '@core/auth';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';
import { getAvatarPath } from '@client/shared/utils';

import { ChatFactory } from './chat.factory';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseHttpService {
  notReadChats = new Set();
  notReadChats$ = new BehaviorSubject<number>(0);
  drawerChat$ = new BehaviorSubject<IChat>(null);

  constructor(
    readonly chatFactory: ChatFactory,
    readonly jobsFactory: JobsFactory,
    readonly authService: AuthService
  ) {
    super('chat');
  }

  getJobsWithChat(params: IQuery) {
    return this.get<ICountResponse<IJobResponse[]>>(params, 'jobs').pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([{ content }, profile]) => ({
        data: content.data.map((j) =>
          this.jobsFactory.fromResponse(j, profile)
        ),
        count: content.count
      }))
    );
  }

  getJobWithChats(jobId: number) {
    return this.get<IJobResponse>({}, `jobs/${jobId}`).pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([{ content }, profile]) =>
        this.jobsFactory.fromResponse(content, profile)
      )
    );
  }

  getChatRecipient(chatId: number) {
    return this.get<IUserEntity>({}, `${chatId}/recipient`).pipe(
      map(({ content }) => ({
        ...content,
        avatar: getAvatarPath(content.avatar)
      }))
    );
  }

  getJobChats(jobId: number, disabled: number) {
    return this.get<IChatResponse[]>({ disabled }, `jobs/${jobId}/chats`).pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([{ content }, profile]) =>
        content.map((c) => this.chatFactory.fromResponse(c, profile))
      )
    );
  }

  getNotReadChats() {
    return this.get<IChat[]>({ [NO_LOADER_PARAM]: true }, 'not-read').pipe(
      map(({ content }) => content),
      tap((chats) => {
        this.notReadChats.clear();
        for (const c of chats) {
          this.notReadChats.add(c.id);
        }
        this.notReadChats$.next(this.notReadChats.size);
      })
    );
  }

  notReadChatsCount() {
    return this.notReadChats$.asObservable();
  }

  addNotReadChat(id: number) {
    this.notReadChats.add(id);
    this.notReadChats$.next(this.notReadChats.size);
  }

  subtractNotReadChat(id: number) {
    this.notReadChats.delete(id);
    this.notReadChats$.next(this.notReadChats.size);
    this.post(
      null,
      { [NO_LOADER_PARAM]: true },
      `${id}/messages/read`
    ).subscribe();
  }

  openChat(data: IChat) {
    this.drawerChat$.next(
      data
        ? this.chatFactory.fromResponse(
            data as any,
            this.authService.getProfileValue()
          )
        : null
    );
  }

  getChat() {
    return this.drawerChat$.asObservable();
  }
}
