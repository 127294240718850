import { CompanyReviewCategoryEnum, CompanyReviewImpressionEnum } from '@common/shared/enums';

export interface ICompanyReview {
  id?: number;
  created: Date;
  category: CompanyReviewCategoryEnum;
  impression: CompanyReviewImpressionEnum;
  message: string;
  children?: ICompanyReview[];
  parent?: ICompanyReview;
  user?: {
    firstName: string,
    lastName: string,
    avatar: string,
    id: string,
  },
  company?: {
    name: string;
  },
  reply?: boolean;
  treadShowed?: boolean;
}
