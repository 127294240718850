<mat-form-field
  [class.multi-select]="multiple"
  [class.show-all-selected]="showAllSelected"
  appearance="outline"
  [@.disabled]="true"
>
  <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-select
    [@.disabled]="true"
    [formControl]="control"
    [compareWith]="compareFn"
    [disableOptionCentering]="true"
    (keydown.enter)="onEnterKeyDown($event)"
    (openedChange)="onOpenedChange($event)"
    [multiple]="multiple"
    panelClass="{{ fullSize ? 'select-panel-full-size' : '' }} {{
      fullHeight ? 'select-panel-full-height' : ''
    }}"
  >
    <mat-select-trigger *ngIf="displayFn">
      {{ displayFn(options, control.value) }}
    </mat-select-trigger>
    <ng-container *ngIf="optional">
      <mat-option [value]="null"> &mdash;</mat-option>
    </ng-container>

    <ng-container *ngIf="search">
      <mat-option class="search-option" [value]="null">
        <ng-container *ngTemplateOutlet="searchBlock"></ng-container>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="group; else default">
      <mat-optgroup
        *ngFor="
          let group of optionsGroup
            | filterSelectGroup: 'value':selectFilterCtrl?.value
        "
        [label]="group.title | translate"
        [disabled]="group.disabled"
        [class.d_ni]="group.dn"
      >
        <mat-option
          *ngFor="let option of group.options; trackBy: trackByFn"
          [class.d_ni]="option.dn"
          [value]="valueFn(option)"
        >
          {{ labelFn(option) }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-template #default>
      <ng-container
        *ngFor="
          let option of options | filterSelect: 'value':selectFilterCtrl?.value;
          trackBy: trackByFn
        "
      >
        <ng-container *ngIf="!option.hidden">
          <mat-option
            [class.d_ni]="option.dn"
            class="{{ option.level ? 'level-' + option.level : '' }}"
            [value]="valueFn(option)"
            [disabled]="option.disabled"
          >
            {{ labelFn(option) }}
          </mat-option>
        </ng-container>
      </ng-container>
    </ng-template>
  </mat-select>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <its-button
    *ngIf="control.enabled && clear && (checkEmptyValue() || staticClear)"
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="clearValue($event)"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('maxValues')">
    <span
      *ngIf="control.getError('maxValues') as error"
      translate
      [translateParams]="{ maxValues: error.maxValues }"
      >common.form.errors.maxValues</span
    >
  </mat-error>
</mat-form-field>

<ng-template #searchBlock>
  <input
    #searchInput
    (click)="$event.stopPropagation()"
    (keyup)="$event.stopPropagation()"
    (keydown)="$event.stopPropagation()"
    (focusout)="searchInput.value = searchInput.value.trim()"
    [formControl]="selectFilterCtrl"
    class="search-option__input"
    type="text"
    placeholder="{{ 'common.select.placeholder.search' | translate }}"
  />
</ng-template>
