<div
  class="its-editor editor-html"
  [class.invalid]="control.enabled && control.invalid"
  [class.disabled]="control.disabled"
>
  <div *ngIf="control?.value" class="its-editor-label">
    {{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </div>
  <div #toolbarContainer></div>
  <quill-editor
    #editor
    [placeholder]="placeholder"
    [styles]="{ minHeight }"
    [formControl]="control"
    [modules]="modules"
    [trimOnValidation]="true"
    (onBlur)="onBlur()"
    (onFocus)="onFocus()"
  ></quill-editor>
</div>

<div class="editor-error">
  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <span
      *ngIf="control.getError('minlength') as error"
      translate
      [translateParams]="{ minlength: error.requiredLength }"
      >common.form.errors.min-length</span
    >
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      <span
        *ngIf="control.getError('maxlength') as error"
        translate
        [translateParams]="{ maxlength: error.requiredLength }"
        >common.form.errors.max-length</span
      >
    </ng-container>
  </mat-error>
</div>

<!--<mat-error *ngIf="control.hasError('required')">
  {{ 'common.form.errors.required' | translate }}
</mat-error>-->

<!--
<mat-form-field class="its-textarea" appearance="outline" [@.disabled]="true">
  <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <textarea
    #textarea
    class="textarea"
    [class.textarea-height]="!autoHeight"
    [rows]="rows"
    [formControl]="control"
    matInput
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="1"
    (focus)="onFocus()"
    (blur)="onBlur()"
  ></textarea>

  <div *ngIf="control.enabled" class="a-i_c j-c_f-e">
    <its-emoji (selectEmoji)="onSelectEmoji($event)"></its-emoji>
    <ng-container *ngIf="template">
      <its-template-btn
        (click)="$event.stopPropagation()"
        [excludeType]="excludeType"
        [templateType]="templateType"
        [templateUserName]="templateUserName"
        [templateAutoSelect]="templateAutoSelect"
        (templateSelected)="onTemplateSelected($event)"
      ></its-template-btn>
    </ng-container>
  </div>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <span
      *ngIf="control.getError('minlength') as error"
      translate
      [translateParams]="{ minlength: error.requiredLength }"
      >common.form.errors.min-length</span
    >
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error">
      <span
        *ngIf="control.getError('maxlength') as error"
        translate
        [translateParams]="{ maxlength: error.requiredLength }"
        >common.form.errors.max-length</span
      >
    </ng-container>
  </mat-error>
  &lt;!&ndash; <mat-error *ngIf="control.hasError('alphanum')">
    Use letters and numbers only
  </mat-error>&ndash;&gt;
</mat-form-field>
-->
