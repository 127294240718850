import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { ICategory } from '@client/shared/interfaces';

@Component({
  selector: 'its-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesComponent {
  @Input() white = false;
  @Input() categories: ICategory[] = [];

  @Output() select = new EventEmitter<ICategory>();

  selectCategory(ev: any, c: ICategory) {
    ev.stopPropagation();
    ev.target.dispatchEvent(
      new CustomEvent('categorySelected', { bubbles: true, detail: c })
    );
  }
}
