import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { ActiveStatusEnum } from '@common/shared/enums';

import { IFile } from '@client/shared/interfaces';

const FILE_TYPE_ICON = {
  apk: 'f-apk',
  html: 'f-code',
  php: 'f-code',
  java: 'f-code',
  swift: 'f-code',
  ts: 'f-code',
  doc: 'f-doc',
  docm: 'f-doc',
  docx: 'f-doc',
  dll: 'f-doc',
  xlsx: 'f-excel',
  csv: 'f-excel',
  ttf: 'f-ttf',
  wof: 'f-font',
  otf: 'f-font',
  eps: 'f-illustration',
  ai: 'f-illustration',
  ait: 'f-illustration',
  jpg: 'f-image',
  jpeg: 'f-image',
  png: 'f-image',
  tiff: 'f-image',
  webp: 'f-image',
  iso: 'f-iso',
  js: 'f-js',
  mp3: 'f-music',
  m4a: 'f-music',
  m4p: 'f-music',
  m4b: 'f-music',
  mpc: 'f-music',
  wav: 'f-music',
  wma: 'f-music',
  webm: 'f-music',
  pdf: 'f-pdf',
  pptx: 'f-powerpoint',
  pptm: 'f-powerpoint',
  ppt: 'f-ppt',
  psd: 'f-psd',
  sql: 'f-sql',
  svg: 'f-svg',
  txt: 'f-txt',
  zip: 'f-zip',
  gif: 'gif',
  xls: 'f-xls',
  MP4: 'f-video',
  mp4: 'f-video',
  AVI: 'f-video',
  AVCHD: 'f-video',
  FLV: 'f-video',
  json: 'f-json'
};

@Component({
  selector: 'its-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent {
  ActiveStatusEnum = ActiveStatusEnum;
  icon = '';
  _file: IFile;
  @Input() chatForm = false;
  @Input() chatOwner = false;
  @Input() active = false;

  @Input() set file(file: IFile) {
    if (file) {
      this._file = file;
      const ext = (file?.originalName || file?.name).split('.').pop();
      this.icon = FILE_TYPE_ICON[ext] || 'f-file';
    }
  }

  get file() {
    return this._file;
  }

  @Output() removeFile = new EventEmitter<IFile | File>();

  onRemoveFile() {
    this.removeFile.emit(this.file);
  }

  openFile(ev: any) {
    if ((this.file as IFile).status !== ActiveStatusEnum.Active) {
      ev.preventDefault();
    }
  }
}
