export interface IVacancyStats {
  published: number[];
  publishedInfo?: {
    percent: number;
    dir: string;
  };
  acceptedInvites: number[];
  acceptedInvitesInfo?: {
    percent: number;
    dir: string;
  };
  acceptedRequests: number[];
  acceptedRequestsInfo?: {
    percent: number;
    dir: string;
  };
  withRemote: number[];
  withRemoteInfo?: {
    percent: number;
    dir: string;
  };
  minExperience: number;
  minExperienceValue?: string;
  minEnglish: number;
  minEnglishValue?: string;
}
