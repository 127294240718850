export enum StickerNameEnum {
  OneWhoBelieve = 'one-who-believe.png', // image name
  CandidateMagic = 'candidate-magic.png',
  DonateDream = 'donate-dream.png',
  DontPanic = 'dont-panic.png',
  GoodJob = 'good-job.png',
  KeepGoing = 'keep-going.png',
  WelcomeCompany = 'welcome-company.png',
  HundredReview = '100-reviews.png',
  HundredReviewAgain = '100-reviews-again.png',
  LongWaiting = 'long-waiting.png',
  TenProposals = 'ten-proposals.png',
  ReviewSent = 'review-sent.png',
}
