import { Provider, Type, forwardRef, ɵDirectiveDef } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';

import { identity } from 'rxjs';

function ValidatorFeature(validatorTypeFn?: () => Type<unknown>) {
  return function Validator<T extends Type<unknown>>(
    directiveDef: ɵDirectiveDef<T>
  ) {
    const { providersResolver, type } = directiveDef;
    const processProvidersFn =
      (fn: (providers: Provider[]) => Provider[]) =>
      (providers: Provider[]) => {
        return fn([
          ...providers,
          {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => validatorTypeFn?.() ?? type),
            multi: true
          }
        ]);
      };

    directiveDef.providersResolver = (def, fn = identity) => {
      providersResolver?.(def, processProvidersFn(fn));
    };
  };
}

export { ValidatorFeature };
