<ng-container *ngIf="values$ | async as values">
  <mat-card
    class="m-b_0 no-shadow"
    [class.drawer-card]="drawer"
    [class.mat-card-color]="!mapFilter && !drawer"
    [class.company-map-card]="mapFilter"
  >
    <mat-card-content>
      <form
        *ngIf="searchForm"
        class="search-form"
        [class.contacts]="contacts || favorites"
        [ngClass]="{
          jobs: type === SearchTypeEnum.Jobs,
          companies: type === SearchTypeEnum.Companies,
          resume: type === SearchTypeEnum.Resume
        }"
        [formGroup]="searchForm"
        (ngSubmit)="search()"
      >
        <ng-container *ngIf="type === SearchTypeEnum.Jobs">
          <div
            class="m-b_16"
            itsNeedAuth
            (click)="authActions($event)"
            [class.two-col]="mapFilter"
          >
            <div class="form-control s-checkbox">
              <its-checkbox
                bg="{{ mapFilter || drawer ? 'color' : 'white' }}"
                formControlName="hide_inv_req"
                >{{ 'search.form.hideInvReq.placeholder' | translate }}
              </its-checkbox>
            </div>
            <div class="form-control s-checkbox">
              <its-checkbox
                bg="{{ mapFilter || drawer ? 'color' : 'white' }}"
                formControlName="hide_viewed"
                >{{ 'search.form.hideViewed.placeholder' | translate }}
              </its-checkbox>
            </div>
            <div class="form-control s-checkbox">
              <its-checkbox
                bg="{{ mapFilter || drawer ? 'color' : 'white' }}"
                formControlName="hide_favorite"
                >{{ 'search.form.hideFavorite.placeholder' | translate }}
              </its-checkbox>
            </div>
          </div>

          <its-boolean-search-textarea
            formControlName="categories"
            placeholder="{{ 'search.form.categories.placeholder' | translate }}"
            [clear]="true"
          ></its-boolean-search-textarea>
          <ng-container *ngIf="!mapFilter">
            <div class="form-control">
              <its-location
                formControlName="region"
                placeholder="{{
                  'search.form.location.placeholder' | translate
                }}"
                autocompleteType="(regions)"
                [clear]="true"
              ></its-location>
            </div>
          </ng-container>

          <div [class.two-col]="mapFilter">
            <div class="form-control">
              <its-select
                formControlName="experience"
                placeholder="{{
                  'search.form.maxExperience.placeholder' | translate
                }}"
                [clear]="true"
                [optional]="false"
                [options]="values.experiences"
              ></its-select>
            </div>
            <div class="form-control">
              <its-select
                formControlName="workTypes"
                placeholder="{{
                  'search.form.workTypes.placeholder' | translate
                }}"
                [fullSize]="true"
                [clear]="true"
                [multiple]="true"
                [optional]="false"
                [group]="true"
                [optionsGroup]="values.workTypesGroup"
              ></its-select>
            </div>
          </div>
          <div [class.two-col]="mapFilter">
            <div class="form-control">
              <its-select
                formControlName="english"
                placeholder="{{
                  'search.form.requiredEnglish.placeholder' | translate
                }}"
                [clear]="true"
                [optional]="false"
                [options]="jobEnglish"
              ></its-select>
            </div>
            <div class="form-control">
              <its-select
                formControlName="domains"
                placeholder="{{
                  'search.form.jobDomains.placeholder' | translate
                }}"
                [clear]="true"
                [search]="true"
                [multiple]="true"
                [optional]="false"
                [options]="values.domains"
              ></its-select>
            </div>
          </div>
          <div [class.two-col]="mapFilter">
            <div class="form-control">
              <its-input
                formControlName="title"
                placeholder="{{
                  'common.searchInTitle.placeholder' | translate
                }}"
                [clear]="true"
              ></its-input>
            </div>
            <div class="form-control">
              <its-select
                formControlName="languages"
                placeholder="{{
                  'search.form.jobLanguages.placeholder' | translate
                }}"
                [search]="true"
                [clear]="true"
                [multiple]="true"
                [optional]="false"
                [options]="values.languages"
              ></its-select>
            </div>
          </div>
          <div class="form-control">
            <its-autocomplete
              [formControl]="excludedCompaniesControl"
              placeholder="{{
                'search.form.excludedCompanies.placeholder' | translate
              }}"
              [autoSelect]="true"
              (changed)="onCompanyChanged($event)"
              [optionsFn]="companiesOptionsFn"
              [labelFn]="companyLabelFn"
              [displayFn]="companyLabelFn"
            >
              {{ 'jobstMap.blockedCompanies.explanation' | translate }}
            </its-autocomplete>
            <ul class="companies-items">
              <li *ngFor="let company of excludedCompanies">
                <div class="a-i_c">
                  <div>
                    {{ company.name }}
                  </div>
                  <its-button
                    classes="btn-sm btn-icon f-s_0i"
                    type="button"
                    variant="icon"
                    (click)="removeExcludedCompany(company)"
                  >
                    <its-icon size="15" svgIcon="clear"></its-icon>
                  </its-button>
                </div>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="type === SearchTypeEnum.Companies">
          <div class="form-control s-checkbox">
            <its-checkbox
              bg="{{ mapFilter || drawer ? 'color' : 'white' }}"
              formControlName="jobs"
              >{{ 'search.form.withActiveJobs.placeholder' | translate }}
            </its-checkbox>
          </div>
          <div class="form-control" *ngIf="!mapFilter">
            <its-select
              formControlName="region"
              placeholder="{{ 'search.form.location.placeholder' | translate }}"
              [fullHeight]="true"
              [clear]="true"
              [optional]="false"
              [options]="companyLocations$ | async"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="employees"
              placeholder="{{
                'search.form.employees.placeholder' | translate
              }}"
              [fullHeight]="true"
              [clear]="true"
              [multiple]="true"
              [optional]="false"
              [options]="values.employees"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="types"
              placeholder="{{
                'search.form.companyType.placeholder' | translate
              }}"
              [clear]="true"
              [multiple]="true"
              [optional]="false"
              [options]="values.companyTypes"
            ></its-select>
          </div>
          <div class="form-control">
            <its-input
              formControlName="name"
              placeholder="{{ 'search.form.name.placeholder' | translate }}"
              [clear]="true"
            ></its-input>
          </div>
        </ng-container>
        <ng-container *ngIf="type === SearchTypeEnum.Resume">
          <div
            class="form-control m-b_8"
            matTooltip="{{
              contacts || favorites
                ? ('search.form.jobForResume.info' | translate)
                : ''
            }}"
            matTooltipPosition="above"
          >
            <its-select
              formControlName="job"
              placeholder="{{
                'search.form.jobForResume.placeholder' | translate
              }}"
              [clear]="true"
              [optional]="false"
              [options]="jobs$ | async"
            >
              <div *ngIf="!contacts && !favorites">
                {{ 'search.form.jobForResume.info' | translate }}
              </div>
            </its-select>
          </div>

          <div class="form-control">
            <its-boolean-search-textarea
              formControlName="categories"
              placeholder="{{
                'search.form.categories.placeholder' | translate
              }}"
              [clear]="true"
            ></its-boolean-search-textarea>
          </div>
          <div class="form-control">
            <its-boolean-search-textarea
              [type]="BooleanSearchTypeEnum.Location"
              formControlName="location"
              placeholder="{{ 'search.form.location.placeholder' | translate }}"
              [clear]="true"
            ></its-boolean-search-textarea>
          </div>
          <div class="form-control">
            <its-select
              formControlName="experience"
              placeholder="{{
                'search.form.minExperience.placeholder' | translate
              }}"
              [clear]="true"
              [optional]="false"
              [options]="values.experiences"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="english"
              placeholder="{{
                'search.form.requiredEnglish.placeholder' | translate
              }}"
              [clear]="true"
              [optional]="false"
              [options]="values.english"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="domains"
              placeholder="{{
                'search.form.resumeDomains.placeholder' | translate
              }}"
              [clear]="true"
              [search]="true"
              [multiple]="true"
              [optional]="false"
              [options]="values.domains"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="workTypes"
              placeholder="{{
                'search.form.workTypes.placeholder' | translate
              }}"
              [fullSize]="true"
              [clear]="true"
              [multiple]="true"
              [optional]="false"
              [group]="true"
              [optionsGroup]="values.workTypesGroup"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="readyToWork"
              placeholder="{{
                'search.form.readyToWork.placeholder' | translate
              }}"
              [fullSize]="true"
              [clear]="true"
              [optional]="false"
              [options]="values.readyToWork"
            ></its-select>
          </div>
          <div class="form-control">
            <its-select
              formControlName="languages"
              placeholder="{{
                'search.form.resumeLanguages.placeholder' | translate
              }}"
              [search]="true"
              [clear]="true"
              [multiple]="true"
              [optional]="false"
              [options]="values.languages"
            ></its-select>
          </div>
          <div class="form-control">
            <its-input
              formControlName="position"
              placeholder="{{ 'search.form.position.placeholder' | translate }}"
              [clear]="true"
            ></its-input>
          </div>
          <div class="form-control m-b_16 s-checkbox">
            <its-checkbox
              bg="{{ mapFilter || drawer ? 'color' : 'white' }}"
              formControlName="veteran"
              >{{ 'search.form.veteran.placeholder' | translate }}
            </its-checkbox>
          </div>
          <div *ngIf="contacts || favorites" class="form-control">
            <its-select
              formControlName="tags"
              placeholder="{{ 'search.form.tags.placeholder' | translate }}"
              [fullSize]="true"
              [clear]="true"
              [multiple]="true"
              [optional]="false"
              [options]="allTags$ | async"
            ></its-select>
          </div>
          <div class="form-control m-b_12">
            <div class="hide-invited">
              <span class="title">
                {{ 'search.form.hideRequested.text' | translate }}
              </span>
              <its-input
                type="number"
                formControlName="hide_requested"
                placeholder="{{ 'search.form.hideInvited.days' | translate }}"
              ></its-input>
            </div>
          </div>
          <div class="form-control">
            <div class="hide-invited">
              <span class="title">
                {{ 'search.form.hideInvited.text' | translate }}
              </span>
              <its-input
                type="number"
                formControlName="hide_invited"
                placeholder="{{ 'search.form.hideInvited.days' | translate }}"
              ></its-input>
            </div>
          </div>
        </ng-container>
        <div
          class="search-form__actions"
          [class.p-t_16]="type !== SearchTypeEnum.Companies"
          [class.in-drawer]="drawer"
        >
          <its-button
            (click)="clearForm()"
            classes="w_100"
            color="accent"
            type="button"
            variant="stroked"
          >
            {{ 'search.form.button.clear' | translate }}
          </its-button>

          <ng-container *ngIf="type !== SearchTypeEnum.Companies && !mapFilter">
            <its-button
              itsNeedAuth
              (click)="followFilter($event)"
              type="button"
              [variant]="drawer ? 'stroked' : 'flat'"
              classes="w_100"
              color="primary"
            >
              <span>{{ 'search.form.button.follow' | translate }}</span>
            </its-button>
          </ng-container>
          <ng-container *ngIf="drawer">
            <its-button
              (click)="search()"
              type="button"
              classes="w_100"
              color="primary"
            >
              <span>{{ 'search.form.button.search' | translate }}</span>
            </its-button>
          </ng-container>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
