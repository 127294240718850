import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { IErrorClient, IErrorServer } from '@modules/admin/interfaces';
import { ICustomPrice } from '@modules/admin/interfaces/custom-price.interface';
import { ICompanyResponse } from '@modules/companies/interfaces';
import { CompaniesFactory } from '@modules/companies/services';
import { IResume } from '@modules/resumes/interfaces';
import { IOrder } from '@modules/settings/interfaces';

import { AuthService, IAdminUserEntity } from '@core/auth';
import { GlobalObjectService, NotificationService } from '@core/services';

import { ADMIN_WITH_USER } from '@common/shared/constants';
import { ActiveStatusEnum } from '@common/shared/enums';
import { deleteCookie, setCookie } from '@common/shared/helpers';
import {
  ICountResponse,
  IQuery,
  ISpendingStats
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { ADMIN_TOKEN, BACK_URL, ROUTES_DATA } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService extends BaseHttpService {
  constructor() {
    super('logger');
  }

  getClientErrors(query: IQuery) {
    return this.get<ICountResponse<IErrorClient[]>>(query, 'client').pipe(
      map(({ content }) => content)
    );
  }

  getServerErrors(query: IQuery) {
    return this.get<ICountResponse<IErrorServer[]>>(query, 'server').pipe(
      map(({ content }) => content)
    );
  }
}
