import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { ICompany } from '@modules/companies/interfaces';

import {
  GlobalObjectService,
  StatisticService,
  ValuesService
} from '@core/services';

import { getPercentageDiff, getStatsDirection } from '@common/shared/helpers';

import { ROUTES_DATA } from '@client/shared/constants';
import { ICandidatesStats, IVacancyStats } from '@client/shared/interfaces';

@Component({
  selector: 'its-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetComponent implements OnInit {
  experienceWidget = {
    0: _('experience.widget.noExp'),
    1: _('experience.widget.oneYear'),
    2: _('experience.widget.twoYears'),
    3: _('experience.widget.threeYears'),
    4: _('experience.widget.fourthYears'),
    5: _('experience.widget.fiveYears'),
    10: _('experience.widget.fromFiveToTen'),
    11: _('experience.widget.moreTen')
  };
  ROUTES_DATA = ROUTES_DATA;
  @Input() type: 'companies' | 'jobs' | 'resumes' = null;

  topCompanies$: Observable<ICompany[]> = this.statisticService.getTopCompanies(
    {
      page: 1,
      size: 24
    }
  );

  vacanciesStats$: Observable<IVacancyStats> = this.statisticService
    .widgetVacanciesStats()
    .pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([res, values]) => {
        return {
          ...res,
          publishedInfo: {
            percent: getPercentageDiff(res.published[1], res.published[0]),
            dir: getStatsDirection(res.published[1], res.published[0])
          },
          acceptedInvitesInfo: {
            percent: getPercentageDiff(
              res.acceptedInvites[1],
              res.acceptedInvites[0]
            ),
            dir: getStatsDirection(
              res.acceptedInvites[1],
              res.acceptedInvites[0]
            )
          },
          acceptedRequestsInfo: {
            percent: getPercentageDiff(
              res.acceptedRequests[1],
              res.acceptedRequests[0]
            ),
            dir: getStatsDirection(
              res.acceptedRequests[1],
              res.acceptedRequests[0]
            )
          },
          withRemoteInfo: {
            percent: getPercentageDiff(res.withRemote[1], res.withRemote[0]),
            dir: getStatsDirection(res.withRemote[1], res.withRemote[0])
          },
          minExperienceValue: values.experiencesMap[res.minExperience],
          minEnglishValue: values.englishMap[res.minEnglish]
        };
      })
    );

  resumesStats$: Observable<ICandidatesStats> = this.statisticService
    .widgetResumeStats()
    .pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([res, values]) => {
        return {
          ...res,
          publicResumesInfo: {
            percent: getPercentageDiff(
              res.publicResumes[1],
              res.publicResumes[0]
            ),
            dir: getStatsDirection(res.publicResumes[1], res.publicResumes[0])
          },
          anonymousResumesInfo: {
            percent: getPercentageDiff(
              res.anonymousResumes[1],
              res.anonymousResumes[0]
            ),
            dir: getStatsDirection(
              res.anonymousResumes[1],
              res.anonymousResumes[0]
            )
          },
          middleEnglishLevelInfo: {
            title: values.englishMap[res.middleEnglishLevel],
            percent: res.middleEnglishLevelPercent
          },
          middleExperienceInfo: {
            title: this.experienceWidget[res.middleExperience],
            percent: res.middleExperiencePercent
          },
          mostPopularCategoriesStr: res.mostPopularCategories
            .map((c) => c.value)
            .join(', ')
        };
      })
    );

  isPlatformBrowser = this.globalObjectService.isPlatformBrowser();

  constructor(
    readonly statisticService: StatisticService,
    readonly globalObjectService: GlobalObjectService,
    readonly valuesService: ValuesService
  ) {}

  ngOnInit(): void {}
}
