import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CompanyEmployeesEnum } from '@common/shared/enums';

export const EMPLOYEES = Object.freeze([
  {
    id: CompanyEmployeesEnum.LessThan50,
    value: _('employees.lessThan50')
  },
  {
    id: CompanyEmployeesEnum.From50To200,
    value: _('employees.from50To200')
  },
  {
    id: CompanyEmployeesEnum.From200To500,
    value: _('employees.from200To500')
  },
  {
    id: CompanyEmployeesEnum.From500To1000,
    value: _('employees.from500To1000')
  },
  {
    id: CompanyEmployeesEnum.From1000To5000,
    value: _('employees.from1000To5000')
  },
  {
    id: CompanyEmployeesEnum.From5000to10000,
    value: _('employees.from5000to10000')
  },
  {
    id: CompanyEmployeesEnum.MoreThan10000,
    value: _('employees.moreThan10000')
  }
]);
