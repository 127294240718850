import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { FilterInformIntervalEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const FILTER_INFORM_INTERVAL: ISelectOption[] = [
  {
    id: FilterInformIntervalEnum.MondayToFriday10AM,
    value: _('filterInformInterval.mondayToFriday10AM')
  },
  {
    id: FilterInformIntervalEnum.EveryMonday10AM,
    value: _('filterInformInterval.everyMonday10AM')
  }
];
