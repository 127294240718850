import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CancelRequestReasonEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const CANCEL_REQUEST_REASONS: ISelectOption[] = [
  {
    id: CancelRequestReasonEnum.Experience,
    value: _('cancelRequestReason.experience')
  },
  {
    id: CancelRequestReasonEnum.Skills,
    value: _('cancelRequestReason.skills')
  },
  {
    id: CancelRequestReasonEnum.English,
    value: _('cancelRequestReason.english')
  },
  {
    id: CancelRequestReasonEnum.VacancyClosed,
    value: _('cancelRequestReason.vacancyClosed')
  },
  {
    id: CancelRequestReasonEnum.Expired,
    value: _('cancelRequestReason.expired'),
    hidden: true
  },
  {
    id: CancelRequestReasonEnum.Other,
    value: _('cancelRequestReason.other')
  }
];
