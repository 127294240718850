import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'its-info',
  template: ` <button
    [disabled]="disabled"
    [itsInfo]="text"
    [position]="position"
    [itsInfoTitle]="title"
    [itsInfoButton]="button"
    mat-icon-button
    type="button"
    aria-label="Info"
  >
    <its-icon svgIcon="info"></its-icon>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoComponent implements OnInit {
  @Input() disabled = false;
  @Input() position = {};
  @Input() title = '';
  @Input() text = '';
  @Input() button = _('common.modal.button.gotIt');

  constructor() {}

  ngOnInit(): void {}
}
