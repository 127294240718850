import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CategoryGroupEnum } from '@common/shared/enums';

import { ICategoriesGroup, ICategory } from '@client/shared/interfaces';

export function mapCategoryGroup(categories: ICategory[]): ICategoriesGroup[] {
  const data = {
    [CategoryGroupEnum.SoftwareDevelopment]: {
      group: CategoryGroupEnum.SoftwareDevelopment,
      title: _('categoryGroup.softwareDevelopment'),
      options: []
    },
    [CategoryGroupEnum.Management]: {
      group: CategoryGroupEnum.Management,
      title: _('categoryGroup.management'),
      options: []
    },
    [CategoryGroupEnum.CorporateFunctions]: {
      group: CategoryGroupEnum.CorporateFunctions,
      title: _('categoryGroup.corporateFunctions'),
      options: []
    },
    [CategoryGroupEnum.Security]: {
      group: CategoryGroupEnum.Security,
      title: _('categoryGroup.security'),
      options: []
    },
    [CategoryGroupEnum.Support]: {
      group: CategoryGroupEnum.Support,
      title: _('categoryGroup.support'),
      options: []
    },
    [CategoryGroupEnum.QualityAssurance]: {
      group: CategoryGroupEnum.QualityAssurance,
      title: _('categoryGroup.qualityAssurance'),
      options: []
    },
    [CategoryGroupEnum.UserExperience]: {
      group: CategoryGroupEnum.UserExperience,
      title: _('categoryGroup.userExperience'),
      options: []
    }
  };
  categories.forEach((c) => {
    try {
      data[c.group]?.options.push(c);
    } catch (e) {}
  });
  return Object.values(data);
}
