import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { LocaleService } from '@core/services';

@Component({
  selector: 'its-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyPageComponent implements OnInit {
  @Input() chat: boolean;
  @Input() title: string;
  @Input() description: string;

  constructor(readonly localeService: LocaleService) {}

  ngOnInit(): void {}
}
