import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelectGroup'
})
export class FilterSelectGroupPipe implements PipeTransform {
  transform(list: any[], prop: string, value = '') {
    const trueValue = (value || '').trim();
    (list || []).forEach((el) => {
      if (trueValue) {
        let dn = true;
        el.options.forEach((o) => {
          const match = o[prop].toLowerCase().includes(trueValue.toLowerCase());
          if (match && dn) {
            dn = false;
          }
          o.dn = !match;
        });
        el.dn = dn;
      } else {
        el.dn = false;
        el.options.forEach((o) => {
          o.dn = false;
        });
      }
    });
    return list;
  }
}
