<form
  class="modal"
  *ngIf="values$ | async as values"
  [formGroup]="form"
  (ngSubmit)="search()"
>
  <h1 mat-dialog-title>{{ 'booleanSearch.modal.title' | translate }}</h1>
  <div mat-dialog-content>
    <div class="m-b_32">
      <p>{{ 'booleanSearch.modal.description' | translate }}</p>
    </div>

    <div formArrayName="search" class="search-group">
      <ng-container *ngFor="let control of searchArray.controls; let i = index">
        <ng-container *ngIf="isEven(i + 1); else category">
          <div class="condition">
            <its-select
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.condition.placeholder' | translate
              }}"
              [optional]="false"
              [options]="values.logicConditions"
            ></its-select>
          </div>
        </ng-container>
        <ng-template #category>
          <div class="search-value">
            <its-select
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.categories.placeholder' | translate
              }}"
              [fullSize]="true"
              [clear]="true"
              [optional]="false"
              [group]="true"
              [search]="true"
              [staticClear]="true"
              [optionsGroup]="values.categoriesGroup"
              (onClear)="clearSelect($event, i)"
            ></its-select>
          </div>
        </ng-template>
      </ng-container>
      <its-button
        *ngIf="searchArray.controls?.length < maxConditions * 2 - 1"
        variant="mini-fab"
        color="accent"
        classes="square"
        (click)="addCondition()"
      >
        <its-icon svgIcon="add"></its-icon>
      </its-button>
    </div>

    <div class="search-result">
      <span>{{ 'booleanSearch.modal.result' | translate }}:</span>&nbsp;
      <span class="c-default-white">{{ formValue }}</span>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.form.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary">
      {{ 'common.form.button.toApply' | translate }}
    </its-button>
  </div>
</form>
