import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'its-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.scss']
})
export class ItemEventComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
