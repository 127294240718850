import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'its-router-outlet',
  templateUrl: './router-outlet.component.html',
  styleUrls: ['./router-outlet.component.scss']
})
export class RouterOutletComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
