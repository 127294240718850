import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';

import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { AuthService, IProfile } from '@core/auth';
import { GlobalObjectService } from '@core/services/global-object.service';
import { ModalService } from '@core/services/modal.service';

import { getCookie, setCookie } from '@common/shared/helpers';

import { BaseHttpService } from '@client/shared/abstracts';
import { WebPushConfirmComponent } from '@client/shared/components/web-push-confirm';
import {
  ADMIN_TOKEN,
  WEB_PUSH_CONFIRMED_COOKIE,
  WEB_PUSH_PAUSE_COOKIE
} from '@client/shared/constants';

import { environment } from '@env';

const REASK_WEB_PUSH_D = 1;
// https://pupli.net/2019/07/push-notifications-with-angular-express/
@Injectable({
  providedIn: 'root'
})
export class WebPushService extends BaseHttpService {
  constructor(
    readonly swPush: SwPush,
    readonly modalService: ModalService,
    readonly authService: AuthService,
    readonly router: Router,
    readonly globalObjectService: GlobalObjectService
  ) {
    super('');
    if (
      this.globalObjectService.isPlatformBrowser() &&
      typeof Notification !== 'undefined'
    ) {
      this.authService
        .getProfile()
        .pipe(
          filter(
            (profile: IProfile) =>
              !!profile?.resumes?.filter((r) => r.position)?.length ||
              profile?.company?.isActive
          ),
          take(1),
          delay(10000)
        )
        .subscribe(() => {
          if (!this.globalObjectService.getStorageData(ADMIN_TOKEN)) {
            this.refreshSubscriptionIfExpired();
            this.requestPushNotifications();
          }
        });
    }
  }

  requestPushNotifications() {
    if (
      Notification?.permission === 'default' &&
      !getCookie(WEB_PUSH_PAUSE_COOKIE) &&
      !getCookie(WEB_PUSH_CONFIRMED_COOKIE)
    ) {
      this.modalService.openComponent(
        WebPushConfirmComponent,
        (pushSettings) => {
          if (pushSettings) {
            Notification.requestPermission()
              .then((res: NotificationPermission) => {
                if (res === 'granted') {
                  this.subscribeToNotifications();
                }
              })
              .catch((err) => {
                setCookie(WEB_PUSH_PAUSE_COOKIE, '1', REASK_WEB_PUSH_D);
              });
          } else {
            setCookie(WEB_PUSH_PAUSE_COOKIE, '1', REASK_WEB_PUSH_D);
          }
        },
        {
          width: '350px',
          position: this.globalObjectService.isDesktop()
            ? {
                top: '50px',
                left: '50px'
              }
            : null,
          disableClose: true,
          panelClass: 'push-notification-modal',
          maxWidth: '90%'
        }
      );
    }
  }

  subscribeToNotifications(expiredSub = null) {
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.VAPID_PUBLIC_KEY
      })
      .then((sub: PushSubscription) => {
        const device = JSON.parse(JSON.stringify(sub));
        this.post(
          {
            device,
            endpoint: expiredSub?.endpoint
          },
          {},
          expiredSub ? 'chat/web-push-refresh' : 'chat/web-push'
        ).subscribe(() => {
          setCookie(WEB_PUSH_CONFIRMED_COOKIE, '1');
        });
      })
      .catch((err) =>
        console.error('Could not subscribe to notifications', err)
      );
  }

  refreshSubscriptionIfExpired() {
    if (Notification?.permission === 'granted') {
      navigator.serviceWorker.ready.then(
        (registration: ServiceWorkerRegistration) =>
          registration.pushManager
            .getSubscription()
            .then((subs: PushSubscription) => {
              let currentSub = null;
              try {
                currentSub = subs.toJSON();
              } catch (e) {}

              if (
                currentSub?.expirationTime &&
                currentSub?.expirationTime <= Date.now()
              ) {
                return currentSub;
              }
              return null;
            })
            .then((expiredSub) => {
              if (expiredSub) {
                this.subscribeToNotifications(expiredSub);
              }
            })
      );
    }
  }
}
