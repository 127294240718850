import { Directive, Input, Self, HostListener } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[itsTooltip]',
  providers: [MatTooltip]
})
export class TooltipDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('ispTooltip')
  readonly message: string;
  // tslint:disable-next-line:no-input-rename
  @Input('ispTooltipPosition')
  readonly position: TooltipPosition = 'above';
  // tslint:disable-next-line:no-input-rename
  @Input('ispTooltipDisabled')
  readonly disabled = false;
  @Input() ispTooltipShowDelay = 0;
  @Input() ispTooltipHideDelay = 0;

  constructor(@Self() readonly tooltip: MatTooltip) {
    this.tooltip = tooltip;
    this.tooltip.tooltipClass = 'isp-tooltip';
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.tooltip.message = this.message;
    this.tooltip.position = this.position;
    this.tooltip.disabled = this.disabled;
    this.tooltip.show(this.ispTooltipShowDelay);
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.tooltip.hide(this.ispTooltipHideDelay);
  }
}
