import { TranslateService } from '@ngx-translate/core';

import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgModule,
  ErrorHandler,
  APP_INITIALIZER,
  Injector
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ErrorHandlerService } from '@core/errors';
import { CookiesInterceptor, HttpRequestInterceptor } from '@core/interceptors';
import { LocaleService, ThemeService } from '@core/services';

import { SharedModule } from '@client/shared/shared.module';

import { StartUpService } from './services/start-up.service';

export function startUpServiceFactory(startUpService: StartUpService) {
  return () => startUpService.preloadUser();
}

export function startUpTheme(themeService: ThemeService) {
  return () => themeService.initTheme();
}

export function appInitializerFactory(
  translate: TranslateService,
  localeService: LocaleService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const locale = localeService.setDefaultLanguage();
    try {
      await translate.use(locale).toPromise();
    } catch (err) {}
  };
}

@NgModule({
  declarations: [],
  providers: [
    {
      provide: ErrorHandler,
      useExisting: ErrorHandlerService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startUpTheme,
      multi: true,
      deps: [ThemeService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startUpServiceFactory,
      multi: true,
      deps: [StartUpService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocaleService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookiesInterceptor,
      multi: true
    },
    MatSnackBar
  ],
  imports: [CommonModule, SharedModule]
})
export class CoreModule {}
