import { GLOBAL_PREFIX_V1 } from '@common/shared/constants';

export const environment = {
  production: false,
  local: false,
  authKey: 'kup_auth_dev',
  tokenDurationDays: 1,
  serverUrl: `https://dev.api.kupno.io${GLOBAL_PREFIX_V1}`,
  storageUrl: 'https://kupno-stage-media.fra1.cdn.digitaloceanspaces.com',
  googleRecaptchaKey: '6LfnFoUkAAAAAFll8nz8ZuYCDiK5JR0ShGo8410P',
  googleApiKey: 'AIzaSyBLtbT81L2kNzF-tsjmJX_tVwR1QmSlFrs',
  VAPID_PUBLIC_KEY:
    'BIG2mGsz2T0hBaAX3QemA2Y5YWfl8XeHg39qHiXZPfFs1LfVueSErs5DLDF_O2oN9QwEjMJCb7FfM6gj_mH2QqI',
  TAWK_API_KEY: 'e7397d97cdbdf8b51a1bfd0b94116f22424cce74'
};
