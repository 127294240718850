import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { IJob } from '@modules/jobs/interfaces';

import { GlobalObjectService } from '@core/services';

import { VACANCY_DURATION_D } from '@common/shared/constants';

import { ROUTES_DATA } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class JobsPostingService {
  private renderer: Renderer2;

  constructor(
    readonly rendererFactory: RendererFactory2,
    readonly globalObjectService: GlobalObjectService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private generateJobPostingSchema(job: IJob): string {
    const schema = {
      '@context': 'https://schema.org/',
      '@type': 'JobPosting',
      title: job.title,
      description: job.description,
      identifier: {
        '@type': 'PropertyValue',
        name: job.company?.name,
        value: job.id
      },
      datePosted: new Date(job.published).toISOString(),
      validThrough: new Date(
        new Date(job.published).setDate(
          new Date(job.published).getDate() + VACANCY_DURATION_D
        )
      ).toISOString(),
      employmentType: job.workTypes.map((type: any) => type.value).join(', '),
      hiringOrganization: {
        '@type': 'Organization',
        name: job.company?.name,
        sameAs: `${this.globalObjectService.getLocation()?.origin}/${
          ROUTES_DATA.COMPANIES.url
        }/${job.company?.slug}`,
        logo: job.company?.logo
      },
      jobLocation: job.offices.map((office: any) => ({
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: office.address,
          addressLocality: office.city,
          addressRegion: office.region,
          addressCountry: office.countryCode
        }
      })),
      baseSalary: job.showSalary
        ? {
            '@type': 'MonetaryAmount',
            currency: 'UAH',
            value: {
              '@type': 'QuantitativeValue',
              value: job.salaryTo || 0,
              unitText: 'MONTH'
            }
          }
        : undefined,
      qualifications: job.requirements,
      responsibilities: job.responsibilities,
      skills: job.hardSkills,
      industry: job.domains.map((domain: any) => domain.value).join(', '),
      workHours: '40 hours per week',
      applicationContact: {
        '@type': 'ContactPoint',
        email: job.user?.username,
        name: `${job.user?.firstName} ${job.user?.lastName}`
      }
    };

    return JSON.stringify(schema, null, 2);
  }

  addJobPostingSchemaToHead(job: IJob): void {
    if (!this.globalObjectService.isPlatformBrowser()) {
      const schema = this.generateJobPostingSchema(job);
      const script = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'type', 'application/ld+json');
      this.renderer.appendChild(script, this.renderer.createText(schema));
      this.renderer.appendChild(document.head, script);
    }
  }
}
