import { HmacSHA256, enc } from 'crypto-js';
import { StatusCodes } from 'http-status-codes/build/es';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  switchMap,
  tap
} from 'rxjs/operators';

import { IResume } from '@modules/resumes/interfaces';

import {
  GlobalObjectService,
  LocaleService,
  NotificationService,
  SocketService
} from '@core/services';
import { TrackingService } from '@core/services/tracking.service';

import { ADMIN_WITH_USER } from '@common/shared/constants';
import {
  LanguageEnum,
  PlanStatusEnum,
  PlanTypeEnum
} from '@common/shared/enums';
import { deleteCookie, getCookie, setCookie } from '@common/shared/helpers';

import { BaseHttpService } from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import {
  ADMIN_TOKEN,
  AUTH_REDIRECT,
  FIRST_VISIT,
  LANG_KEY,
  NO_LOADER_PARAM,
  ROUTES_DATA
} from '@client/shared/constants';
import { AuthQueryEnum } from '@client/shared/enums';

import { environment } from '@env';

import {
  IForgotPassword,
  IProfile,
  IProfileResponse,
  IResetPassword,
  ISignIn,
  ISignUp
} from '../auth.interface';
import { AuthFactory } from './auth.factory';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseHttpService {
  private profile$ = new BehaviorSubject<IProfile>(null);
  private login$ = new Subject<boolean>();

  constructor(
    readonly factory: AuthFactory,
    readonly activatedRoute: ActivatedRoute,
    readonly socketService: SocketService,
    readonly localeService: LocaleService,
    readonly globalObjectService: GlobalObjectService,
    readonly notificationService: NotificationService,
    readonly trackingService: TrackingService,
    readonly router: Router
  ) {
    super('auth');
  }

  signUp(model: ISignUp) {
    return this.post<ISignUp, IProfile>(model, {}, 'register');
  }

  signIn(model: ISignIn) {
    return this.post<ISignIn, { accessToken: string; lastActivity: Date }>(
      model,
      {},
      'login'
    ).pipe(
      tap((res) => this.setToken(res.content.accessToken)),
      switchMap(({ content }) =>
        this.loadProfile().pipe(
          tap(() => {
            this.login$.next(true);
          }),
          map(() => content.lastActivity)
        )
      )
    );
  }

  googleSignIn() {
    if (this.globalObjectService.getLocation()) {
      this.globalObjectService.getLocation().href = `${environment.serverUrl}/auth/google`;
    }
  }

  toAuth(route: AuthQueryEnum = AuthQueryEnum.SignIn) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { auth: route },
      replaceUrl: true
    });
  }

  logOut(err = false) {
    if (err) {
      if (this.globalObjectService.isPlatformBrowser()) {
        this.toAuth();
        setCookie(
          AUTH_REDIRECT,
          window.location.href.replace(window.location.origin, ''),
          0.0138
        ); // for 20 min
      }
    }
    this.router.navigateByUrl('/').then(() => {
      deleteCookie(ADMIN_WITH_USER);
      this.removeToken();
      this.globalObjectService.removeStorageData(ADMIN_TOKEN);
      this.setProfile(null);
      this.login$.next(false);
      this.destroyLiveChart();
      this.socketService.disconnectSocket();
    });
  }

  resendConfirm(model: Partial<ISignIn>) {
    return this.post<Partial<ISignIn>>(model, {}, 'resend-confirm');
  }

  confirmation(token: string) {
    return this.post(null, {}, `confirm/${token}`).pipe(
      tap((res) => this.setToken(res.content.accessToken)),
      switchMap(() => this.updateProfileLang()),
      switchMap(() => this.loadProfile(true)),
      catchError((err) => {
        this.router.navigateByUrl(`/`);
        return throwError(err);
      })
    );
  }

  forgotPassword(model: IForgotPassword) {
    return this.post(model, {}, 'restore');
  }

  resetPassword(token: string, model: IResetPassword) {
    return this.post(model, {}, `reset-password/${token}`);
  }

  isLoggedIn() {
    return Boolean(this.getToken());
  }

  setToken(token: string) {
    if (this.globalObjectService.isPlatformBrowser()) {
      setCookie(environment.authKey, token, environment.tokenDurationDays);
    }
  }

  getToken() {
    return this.globalObjectService.getServerCookies(environment.authKey);
  }

  removeToken() {
    if (this.globalObjectService.isPlatformBrowser()) {
      deleteCookie(environment.authKey);
    }
  }

  getProfile(logged = true): Observable<IProfile> {
    return this.profile$
      .asObservable()
      .pipe(filter((prof) => prof && (!logged || prof.exist)));
  }

  getProfileValue(): IProfile {
    const profile = this.profile$.getValue();
    return profile?.exist ? profile : null;
  }

  getResumesOptions(): Observable<ISelectOption<string>[]> {
    return this.getProfile().pipe(
      pluck('resumes'),
      map((resumes) =>
        resumes
          .filter((r) => !!r?.position)
          .map((r) => ({
            id: r.id,
            value: r.position
          }))
      )
    );
  }

  getMyResumes(): Observable<IResume[]> {
    return this.getProfile().pipe(
      pluck('resumes'),
      map((resumes) => resumes.filter((r) => !!r?.position))
    );
  }

  setProfile(profile: IProfileResponse) {
    const prof = profile
      ? this.factory.fromResponse(profile)
      : ({ exist: false } as any);
    return this.profile$.next(prof);
  }

  loadProfile(firstVisit = false) {
    return this.get<IProfileResponse>({}, 'profile').pipe(
      tap((res) => {
        const { content } = res;
        if (content) {
          this.trackingService.checkAuthUsers(); // stop tracking
          this.checkProfileLang(content);
          this.setProfile(content);
          if (this.globalObjectService.isPlatformBrowser()) {
            this.socketService.connectSocket();
          }
        }

        if (content?.company && environment.production) {
          this.initLiveChart();

          const userId = content.id;
          if (
            userId !== '7ecf01d4-d334-43cf-b4b2-0edbe08c3062' &&
            userId !== '80e15ed4-4cd9-46d2-8e9e-8b6361c6756e' &&
            userId !== '0cd6eee3-491a-4aeb-8eba-2eed9698c7ab'
          ) {
            this.loadHotjar();
          }
        }

        const redirect = getCookie(AUTH_REDIRECT);
        if (redirect) {
          deleteCookie(AUTH_REDIRECT);
          setTimeout(() => {
            this.router.navigate([redirect], {
              ...(firstVisit ? this.getFistVisitParams() : {})
            });
          }, 10);
        } else {
          if (firstVisit) {
            this.router.navigate(
              [`/${ROUTES_DATA.RESUME.children.PROFILE.url}`],
              this.getFistVisitParams()
            );
          }
        }
      }),
      map((res) => res),
      catchError((err) => {
        if (
          err.error?.statusCode === StatusCodes.UNAUTHORIZED ||
          err.error?.statusCode === StatusCodes.NOT_FOUND
        ) {
          // token expired
          this.removeToken();
        }
        if (err.error?.statusCode === StatusCodes.CONFLICT) {
          // account locked
          if (this.globalObjectService.isPlatformBrowser()) {
            this.notificationService.showError(err);
          }
          this.removeToken();
        }
        return of(null);
      })
    );
  }

  getFistVisitParams() {
    return {
      queryParams: {
        [FIRST_VISIT]: '1'
      },
      queryParamsHandling: 'merge'
    } as NavigationExtras;
  }

  acceptInvite(token: string, model: ISignUp = null) {
    return this.post<ISignUp, { accessToken: string }>(
      model,
      {},
      `accept-invite/${token}`
    ).pipe(
      tap((res) => this.setToken(res.content.accessToken)),
      switchMap(() => this.loadProfile())
    );
  }

  userAuthToggle() {
    return this.login$.asObservable().pipe(distinctUntilChanged());
  }

  checkProfileLang(profile: IProfileResponse) {
    if (!profile.lang) {
      this.updateProfileLang();
    } else {
      if (
        profile.lang !== getCookie(LANG_KEY) &&
        Object.values(LanguageEnum).includes(profile.lang)
      ) {
        this.localeService.useLanguage(profile.lang);
      }
    }
  }

  updateProfileLang(lang = getCookie(LANG_KEY) as LanguageEnum) {
    return this.patch(null, { [NO_LOADER_PARAM]: true }, `lang/${lang}`);
  }

  initLiveChart() {
    if (this.globalObjectService.isPlatformBrowser()) {
      try {
        const window = this.globalObjectService.getWindow() as any;
        if (window?.Tawk_API?.isChatHidden()) {
          window?.Tawk_API?.showWidget();
        } else {
          window.Tawk_API = window.Tawk_API || {};
          window.Tawk_LoadStart = new Date();
          window.Tawk_API.onLoad = () => {
            const profile = this.getProfileValue();
            if (profile) {
              window.Tawk_API.setAttributes({
                name: profile.fullName,
                email: profile.email,
                hash: enc.Hex.stringify(
                  HmacSHA256(profile.email, environment?.TAWK_API_KEY)
                )
              });

              if (profile?.company) {
                const plans = {
                  [PlanTypeEnum.Basic]: 'Basic',
                  [PlanTypeEnum.Standard]: 'Standard',
                  [PlanTypeEnum.Premium]: 'Premium'
                };

                window.Tawk_API.setAttributes(
                  {
                    companyId: profile.company?.id,
                    company: profile.company?.name,
                    plan: profile.company?.plan
                      ? plans[profile.company?.plan?.planType] +
                        (profile.company?.plan?.status === PlanStatusEnum.Trial
                          ? ' - Trial'
                          : '')
                      : '',
                    expiredDate: profile.company?.plan
                      ? moment(
                          profile.company?.plan?.expiredDate,
                          'DD MMM, YYYY'
                        )
                      : ''
                  },
                  (err) => {
                    if (err) {
                      console.log(err);
                    }
                  }
                );
              }
            }
          };

          // The rest of your script loading logic
          const script = this.globalObjectService
            .getDocument()
            .createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src =
            'https://embed.tawk.to/6558b787d600b968d314875d/1hfh8pqvn';
          script.charset = 'UTF-8';
          script.setAttribute('crossorigin', '*');
          script.setAttribute('id', 'kupno-chat');
          this.globalObjectService.getDocument()?.body?.appendChild(script);
        }
      } catch (e) {}
    }
  }

  destroyLiveChart() {
    if (this.globalObjectService.isPlatformBrowser()) {
      try {
        const window = this.globalObjectService.getWindow() as any;
        window?.Tawk_API?.hideWidget();
      } catch (e) {}
    }
  }

  loadHotjar(): void {
    const scriptId = 'hotjar-script-id';
    if (this.globalObjectService.isPlatformBrowser()) {
      if (!this.globalObjectService.getDocument().getElementById(scriptId)) {
        try {
          const script = this.globalObjectService
            .getDocument()
            .createElement('script');
          script.id = scriptId;
          script.text = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3810892,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
          this.globalObjectService.getDocument()?.body?.appendChild(script);
        } catch (e) {}
      }
    }
  }
}
