import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { IJobApplyRequest, IJobInvite } from '@modules/jobs/interfaces';
import { JobsInviteService, JobsRequestService } from '@modules/jobs/services';

import {
  LocaleService,
  NotificationService,
  ValuesService
} from '@core/services';

import { IReqInvState } from '@common/shared/interfaces';

import { Destroyable, FormStateDispatcher } from '@client/shared/abstracts';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-req-inv-state-history-modal',
  templateUrl: './req-inv-state-history-modal.component.html',
  styleUrls: ['./req-inv-state-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ReqInvStateHistoryModalComponent
  extends Destroyable
  implements OnInit
{
  form: FormGroup;
  values$: Observable<IValues> = this.valuesService.getValues();
  stateHistory: IReqInvState[] = [];
  lastState = null;
  constructor(
    readonly dialogRef: MatDialogRef<ReqInvStateHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invite?: IJobInvite;
      request?: IJobApplyRequest;
      edit: boolean;
    },
    readonly valuesService: ValuesService,
    readonly jobsRequestService: JobsRequestService,
    readonly jobsInviteService: JobsInviteService,
    readonly changeDetector: ChangeDetectorRef,
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService,
    readonly formStateDispatcher: FormStateDispatcher
  ) {
    super();
    this.dialogRef.beforeClosed().subscribe(() => {
      this.dialogRef.close(this.lastState);
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.getStateHistory();
  }

  getStateHistory() {
    const inviteId = this.data.invite?.id;
    const requestId = this.data.request?.id;

    (inviteId
      ? this.jobsInviteService.getInviteStates(inviteId)
      : this.jobsRequestService.getRequestState(requestId)
    ).subscribe((res) => {
      this.stateHistory = res;
      this.changeDetector.detectChanges();
    });
  }

  buildForm() {
    this.form = new FormGroup({
      state: new FormControl(null, Validators.required),
      stateDescription: new FormControl(''),
      date: new FormControl(null),
      id: new FormControl(null)
    });
    this.changeDetector.markForCheck();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  saveState() {
    this.formStateDispatcher.onSubmit.notify();
    const inviteId = this.data.invite?.id;
    const requestId = this.data.request?.id;
    if (this.form.valid) {
      const data = this.form.getRawValue();
      (inviteId
        ? this.jobsInviteService.saveInviteState(inviteId, data)
        : this.jobsRequestService.saveRequestState(requestId, data)
      ).subscribe((res) => {
        this.resetForm();

        if (data.id) {
          // update
          this.stateHistory = this.stateHistory.map((s) => {
            if (s.id === res.id) {
              return res;
            }
            return s;
          });
          this.lastState = this.getLastElement();
        } else {
          // create
          this.lastState = {
            state: res.state,
            stateDescription: res.stateDescription
          };
          this.stateHistory.push(res);
        }

        this.changeDetector.detectChanges();
      });
    }
  }

  deleteState(id: number, i: number) {
    const invite = this.data.invite;
    (invite
      ? this.jobsInviteService.deleteInviteState(id)
      : this.jobsRequestService.deleteRequestState(id)
    ).subscribe(() => {
      this.stateHistory.splice(i, 1);
      this.lastState = this.getLastElement();
      this.changeDetector.detectChanges();
      this.notificationService.showSuccess(
        this.localeService.getInstant(
          _('reqInvState.notification.stateDeletes')
        ),
        1500
      );
    });
  }

  getLastElement() {
    const last = this.stateHistory.length
      ? this.stateHistory[this.stateHistory.length - 1]
      : null;
    return {
      state: last?.state || null,
      stateDescription: last?.stateDescription || ''
    };
  }

  editState(state: IReqInvState) {
    this.form.patchValue({
      id: state.id,
      state: state.state || null,
      stateDescription: state.stateDescription || '',
      date: state.date || null
    });
  }

  resetForm() {
    this.form = null;
    this.changeDetector.detectChanges();
    this.buildForm();
  }
}
