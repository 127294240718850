import { Injectable, OnDestroy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';

const submitSubject = Symbol('submitSubject');
const deleteSubject = Symbol('deleteSubject');
const cancelSubject = Symbol('cancelSubject');
const validateSubject = Symbol('validateSubject');

class SubmitFormStateDispatcher {
  readonly [submitSubject] = new Subject<unknown>();
  readonly listen = this[submitSubject].asObservable();

  notify<T>(state?: T) {
    this[submitSubject].next(state);
  }
}

class CancelFormStateDispatcher {
  readonly [cancelSubject] = new Subject<unknown>();
  readonly listen = this[cancelSubject].asObservable();

  notify<T>(state?: T) {
    this[cancelSubject].next(state);
  }
}

class DeleteFormStateDispatcher {
  readonly [deleteSubject] = new Subject<unknown>();
  readonly listen = this[deleteSubject].asObservable();

  notify<T>(state?: T) {
    this[deleteSubject].next(state);
  }
}

class ValidateFormStateDispatcher {
  readonly [validateSubject] = new Subject<{
    control: string;
    errors: ValidationErrors;
  }>();
  readonly listen = this[validateSubject].asObservable();

  notify(state: { control: string; errors: ValidationErrors }) {
    this[validateSubject].next(state);
  }
}

@Injectable()
export class FormStateDispatcher implements OnDestroy {
  readonly onSubmit = new SubmitFormStateDispatcher();
  readonly onCancel = new CancelFormStateDispatcher();
  readonly onDelete = new DeleteFormStateDispatcher();
  readonly onValidate = new ValidateFormStateDispatcher();

  ngOnDestroy() {
    this.onCancel[cancelSubject].complete();
    this.onDelete[deleteSubject].complete();
    this.onSubmit[submitSubject].complete();
    this.onValidate[validateSubject].complete();
  }
}
