import { Injectable } from '@angular/core';

import { getAvatarPath, resumeFromResponse } from '@client/shared/utils';

import {
  IJobInvite,
  IJobInviteRequest,
  IJobInviteResponse,
  IJobResponse
} from '../interfaces';
import { JobsFactory } from './jobs.factory';
import { ANONYMOUS_VALUE } from '@common/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class JobsInviteFactory {
  constructor(readonly jobFactory: JobsFactory) {
  }

  toRequest(invite: IJobInviteRequest): IJobInviteRequest {
    return {
      ...invite
    };
  }

  fromResponse(response: IJobInviteResponse): IJobInvite {
    return response
      ? {
        ...response,
        job: this.jobFactory.fromResponse(response.job as IJobResponse),
        resume: resumeFromResponse(response.resume),
        user: response.user
          ? {
            ...response.user,
            fullName: response.user?.firstName ? `${response.user?.firstName} ${response.user?.lastName}` : ANONYMOUS_VALUE,
          }
          : null,
      }
      : null;
  }
}
