import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { IntegrationEnum } from '@common/shared/enums';

@Component({
  selector: 'its-modal-atsjob-id',
  templateUrl: './modal-atsjob-id.component.html',
  styleUrls: ['./modal-atsjob-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAtsjobIdComponent implements OnInit {
  form: FormGroup;
  constructor(readonly dialogRef: MatDialogRef<ModalAtsjobIdComponent>) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  buildForm() {
    this.form = new FormGroup({
      id: new FormControl('', Validators.required)
    });
  }

  save() {
    if (this.form.valid) {
      this.onNoClick(this.form.value.id);
    }
  }
}
