<div class="j-c_f-e">
  <its-button (click)="close()" variant="icon">
    <its-icon size="18" svgIcon="clear"></its-icon>
  </its-button>
</div>
<div class="m-b_16 t-a_c">
  {{ 'pwa.newVersion.description' | translate }}
</div>
<div class="j-c_c m-b_16">
  <its-button (click)="updateApp()" color="primary">
    {{ 'pwa.button.updateApp' | translate }}
  </its-button>
</div>
