import 'moment-timezone';

import { APP_BASE_HREF, DOCUMENT, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  Renderer2
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';

import { Observable, of, switchMap, tap } from 'rxjs';

import { IChat } from '@modules/chat/interfaces';
import { ChatService } from '@modules/chat/services';
import { OrdersService } from '@modules/settings/services';

import { AuthService, IProfile, IUserEntity } from '@core/auth';
import {
  GlobalObjectService,
  LocaleService,
  PwaService,
  SocketService,
  WebPushService
} from '@core/services';
import { TrackingService } from '@core/services/tracking.service';

import { COOKIE_CID_KEY } from '@common/shared/constants';
import { LanguageEnum } from '@common/shared/enums';
import { generateCid, getCookie, setCookie } from '@common/shared/helpers';

import {
  LANG_KEY,
  MAP_JOB_CENTER_KEY,
  MAP_JOB_ZOOM_KEY,
  MAP_OFFICE_CENTER_KEY,
  MAP_OFFICE_ZOOM_KEY,
  NEW_VERSION_RELOAD,
  ROUTES_DATA
} from '@client/shared/constants';

@Component({
  selector: 'its-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  chat: IChat;
  recipient: IUserEntity;
  drawerChat$: Observable<any> = this.chatService.getChat().pipe(
    switchMap((chat) =>
      (chat ? this.chatService.getChatRecipient(chat.id) : of(null)).pipe(
        tap((recipient) => {
          this.recipient = recipient;
          this.chat = chat;
          this.changeDetector.detectChanges();
        })
      )
    )
  );
  map = false;
  home = false;
  haveFooter = true;
  // isDarkMode =
  //   window.matchMedia &&
  //   window.matchMedia('(prefers-color-scheme: dark)').matches;
  form = new FormGroup({
    date: new FormControl(null)
  });
  profile$: Observable<IProfile> = this.authService.getProfile();

  constructor(
    readonly router: Router,
    readonly authService: AuthService,
    readonly activatedRoute: ActivatedRoute,
    readonly localeService: LocaleService,
    readonly location: Location,
    readonly sanitizer: DomSanitizer,
    readonly socketService: SocketService,
    readonly changeDetector: ChangeDetectorRef,
    readonly globalObjectService: GlobalObjectService,
    readonly chatService: ChatService,
    readonly ordersService: OrdersService,
    readonly webPushService: WebPushService,
    readonly pwaService: PwaService,
    readonly trackingService: TrackingService,
    readonly renderer: Renderer2,
    @Inject(DOCUMENT) readonly document: Document,
    @Optional() @Inject(APP_BASE_HREF) private baseUrl: string
  ) {
    this.changeHtmlLanguage();
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.globalObjectService.isPlatformBrowser()) {
          if (!this.map) {
            this.globalObjectService.removeStorageData(MAP_JOB_ZOOM_KEY);
            this.globalObjectService.removeStorageData(MAP_JOB_CENTER_KEY);
            this.globalObjectService.removeStorageData(MAP_OFFICE_ZOOM_KEY);
            this.globalObjectService.removeStorageData(MAP_OFFICE_CENTER_KEY);
          }
        }
      }
      if (event instanceof NavigationEnd) {
        this.detectCurrentPage(event.url);
        if (this.globalObjectService.isPlatformBrowser()) {
          // change url for multi language /companies => /uk/companies
          this.localeService.updateLocaleUrl(event.url);
          this.checkNewVersion();
        }
      }
    });

    if (
      !getCookie(COOKIE_CID_KEY) &&
      this.globalObjectService.isPlatformBrowser()
    ) {
      setCookie(COOKIE_CID_KEY, generateCid());
    }
    // this.trackingService.setupTracking();
  }

  detectCurrentPage(path: string) {
    const url = path
      .split('?')[0]
      .replace(this.globalObjectService.getServerCookies(LANG_KEY), '/')
      .replace(/\//g, '');

    this.map =
      url === ROUTES_DATA.HOME.children.JOBS_MAP.url ||
      url === ROUTES_DATA.HOME.children.COMPANIES_MAP.url;

    this.home = !url;
    this.haveFooter = url !== ROUTES_DATA.CHAT.url;
    this.changeDetector.detectChanges();
  }

  closedChat(ev: any) {
    this.chatService.openChat(null);
  }

  changeHtmlLanguage(): void {
    this.localeService.getLocale().subscribe((language: LanguageEnum) => {
      this.renderer.setAttribute(
        this.document.documentElement,
        'lang',
        language
      );
    });
  }

  checkNewVersion() {
    if (
      this.globalObjectService.getStorageData(
        NEW_VERSION_RELOAD,
        'sessionStorage'
      )
    ) {
      this.globalObjectService.removeStorageData(
        NEW_VERSION_RELOAD,
        'sessionStorage'
      );
      this.globalObjectService.getLocation()?.reload();
    }
  }
}
