<div class="cropper-frame">
  <div class="j-c_s-b">
    <div class="cropper-frame__actions">
      <its-button
        (click)="rotateLeft()"
        matTooltip="{{ 'common.form.button.rotateLeft' | translate }}"
        variant="icon"
      >
        <its-icon class="flip" size="20" svgIcon="refresh"></its-icon>
      </its-button>
      <its-button
        (click)="rotateRight()"
        matTooltip="{{ 'common.form.button.rotateRight' | translate }}"
        variant="icon"
      >
        <its-icon size="20" svgIcon="refresh"></its-icon>
      </its-button>
      <its-button
        (click)="flipHorizontal()"
        matTooltip="{{ 'common.form.button.flipHorizontal' | translate }}"
        variant="icon"
      >
        <its-icon svgIcon="sync"></its-icon>
      </its-button>
      <its-button
        (click)="flipVertical()"
        matTooltip="{{ 'common.form.button.flipVertical' | translate }}"
        variant="icon"
      >
        <its-icon class="rotate" svgIcon="sync"></its-icon>
      </its-button>
    </div>
    <div class="cropper-frame__clear">
      <its-button
        (click)="resetImage()"
        matTooltip="{{ 'common.form.button.resetImage' | translate }}"
        variant="icon"
      >
        <its-icon svgIcon="undo"></its-icon>
      </its-button>
    </div>
  </div>
  <!--<input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()"/>
  <button (click)="zoomOut()">Zoom -</button>
  <button (click)="zoomIn()">Zoom +</button>-->
  <div
    class="cropper-frame__image-cropper"
    [ngClass]="{
      'company-logo': imageType === ImageTypeEnum.CompanyLogo
    }"
  >
    <div>
      <div [class.round]="settings.roundCropper" class="image-border">
        <img [src]="croppedImage" />
      </div>
      <div class="t-a_c">{{ 'cropper.text.preview' | translate }}</div>
    </div>

    <image-cropper
      [backgroundColor]="'#ffffff'"
      [imageChangedEvent]="imageChangedEvent"
      [imageBase64]="imageBase64"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="settings.aspectRatio"
      [resizeToWidth]="settings.resizeToWidth"
      [cropperMinWidth]="settings.cropperMinWidth"
      [onlyScaleDown]="true"
      [roundCropper]="settings.roundCropper"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'center'"
      [style.display]="showCropper ? null : 'block'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>

  <div class="j-c_f-e">
    <its-button class="m-r_16" variant="basic" (click)="onSave(false)">
      {{ 'common.form.button.cancelImage' | translate }}
    </its-button>
    <its-button (click)="onSave()" color="primary">
      {{ 'common.form.button.chooseImage' | translate }}
    </its-button>
  </div>
</div>
