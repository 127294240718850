<form class="modal" [formGroup]="form" (ngSubmit)="publish()">
  <h1 mat-dialog-title>{{ 'publishVacancy.modal.title' | translate }}</h1>
  <div *ngIf="form" mat-dialog-content>
    <p>
      <span>{{
        'publishVacancy.modal.description'
          | translate: { days: VACANCY_DURATION_D }
      }}</span>
    </p>

    <div class="m-l_12 m-b_24">
      <ng-container *ngIf="hotServices$ | async as hots">
        <div class="form-control d_f">
          <its-checkbox
            formControlName="hot"
            matTooltip="{{
              hots?.length ? '' : ('jobForm.tooltip.noHots' | translate)
            }}"
          >
            <div class="hot-placeholder">
              {{
                'jobForm.hot.placeholder'
                  | translate: { days: HOT_VACANCY_DURATION_D }
              }}
              <its-icon svgIcon="hot"></its-icon>
            </div>
          </its-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.modal.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary">
      {{ 'common.button.publish' | translate }}
    </its-button>
  </div>
</form>
