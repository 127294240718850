import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'its-pwa-update-prompt',
  templateUrl: './pwa-update-prompt.component.html',
  styleUrls: ['./pwa-update-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaUpdatePromptComponent {

  constructor(
    readonly bottomSheetRef: MatBottomSheetRef<PwaUpdatePromptComponent>
  ) { }

  updateApp() {
    this.close(true);
  }
x
  close(result = null) {
    this.bottomSheetRef.dismiss(result);
  }
}
