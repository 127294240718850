<form class="modal" [formGroup]="form" (ngSubmit)="selectReason()">
  <h1 mat-dialog-title>{{ 'closeVacancy.modal.title' | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ 'closeVacancy.modal.description' | translate }}</p>

    <div class="m-l_24 m-b_24">
      <its-radio
        formControlName="reason"
        [vertical]="true"
        [options]="reasons$ | async"
      ></its-radio>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.modal.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary">
      {{ 'common.modal.button.confirm' | translate }}
    </its-button>
  </div>
</form>
