import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input, OnDestroy,
  OnInit
} from '@angular/core';

import { InfoModalComponent } from './info-modal.component';

@Directive({
  selector: '[itsInfo]'
})
export class InfoDirective implements OnInit, OnDestroy {
  showed = false;
  @Input() itsInfo = '';
  @Input() itsInfoTitle = '';
  @Input() itsInfoButton = '';
  _position = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: 10
  };
  @Input() set position(value: any) {
    this._position = {
      ...this._position,
      ...value
    }
  };

  get position() {
    return this._position;
  }

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private elRef: ElementRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.position]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy() {
    this.showed = false;
    this.overlayRef.detach();
  }

  @HostListener('click')
  show() {
    if (!this.showed) {
      this.showed = true;
      const tooltipRef: ComponentRef<InfoModalComponent> =
        this.overlayRef.attach(new ComponentPortal(InfoModalComponent));
      tooltipRef.instance.title = this.itsInfoTitle;
      tooltipRef.instance.text = this.itsInfo.trim();
      tooltipRef.instance.button = this.itsInfoButton;
    }
  }

  @HostListener('document:click', ['$event, $event.target'])
  hide(event, targetElement) {
    if (!this.elRef.nativeElement.contains(targetElement)) {
      this.showed = false;
      this.overlayRef.detach();
    }
  }
}
