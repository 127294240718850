import { Observable } from 'rxjs';

import { ISelectOption } from '@client/shared/components/select';
import { TemplateTypeEnum } from '@client/shared/enums';
import { InviteStatusEnum } from '@common/shared/enums';

export interface ICommentModal {
  header?: string;
  subTitle?: string;
  confirm?: string;
  cancel?: string;
  messageRequired?: boolean;
  reasons?: Observable<ISelectOption[]>;
  userName?: string;
  type?: TemplateTypeEnum;
  status?: InviteStatusEnum;
}
