<div class="auth-container">
  <h1 class="t-a_c">{{ 'auth.signUp.title' | translate }}</h1>
  <h2 *ngIf="agentInvite">
    {{ 'auth.agentConfirm.title' | translate }} {{ agentInvite.company.name }}
  </h2>

  <div>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      variant="stroked"
      type="button"
      color="primary"
      (click)="googleSignIn()"
    >
      <its-icon svgIcon="google"></its-icon>
      <span class="m-l_8">{{ 'auth.button.googleSignin' | translate }}</span>
    </its-button>
  </div>

  <div class="or-block">
    <span>
      {{ 'auth.text.or' | translate }}
    </span>
  </div>

  <form
    *ngIf="form && !agentInvite?.user; else onlyConfirmBtn"
    novalidate
    #formInstance
    [formGroup]="form"
    (ngSubmit)="send(formInstance); formSubmitted = true"
    (keyup.enter)="send(formInstance)"
  >
    <div class="form-control">
      <its-input
        formControlName="firstName"
        placeholder="{{ 'auth.firstName.placeholder' | translate }}"
      ></its-input>
    </div>
    <div class="form-control">
      <its-input
        formControlName="lastName"
        placeholder="{{ 'auth.lastName.placeholder' | translate }}"
      ></its-input>
    </div>
    <div class="form-control">
      <its-input
        type="email"
        formControlName="username"
        placeholder="{{ 'auth.email.placeholder' | translate }}"
      ></its-input>
    </div>
    <div class="form-control">
      <its-input
        [toggleVisibility]="true"
        type="password"
        formControlName="password"
        placeholder="{{ 'auth.password.placeholder' | translate }}"
      >
      </its-input>
    </div>

    <div class="form-group agree-form-group">
      <its-checkbox [showError]="checkAgree" formControlName="agree">
        {{ 'auth.text.agree' | translate }}
        <a [href]="ROUTES_DATA.PUBLIC.children.LEGAL.url" target="_blank">{{
          'auth.text.termsOfService' | translate
        }}</a>
        {{ 'auth.text.and' | translate }}
        <a [href]="ROUTES_DATA.PUBLIC.children.POLICY.url" target="_blank">{{
          'auth.text.privacyPolicy' | translate
        }}</a>
      </its-checkbox>
    </div>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      color="primary"
      type="submit"
    >
      {{ 'auth.button.signUp' | translate }}
    </its-button>

    <!--    <button (click)="createTestUsers()">qweqwe</button>-->
  </form>

  <ng-template #onlyConfirmBtn>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      color="primary"
      type="button"
      (click)="confirm()"
    >
      {{ 'auth.button.confirmInvite' | translate }}
    </its-button>
  </ng-template>

  <p *ngIf="emailWasSend" class="c-green-active t-a_c">
    {{ 'auth.message.createAccountSent' | translate }}
  </p>

  <div class="j-c_c">
    <its-button
      *ngIf="!agentInvite"
      color="primary"
      variant="basic"
      (click)="backToLogIn()"
    >
      {{ 'auth.button.backToLogIn' | translate }}
    </its-button>
  </div>

  <div class="frame-icon-onboarding">
    <img src="/assets/v1/images/onboarding.png" alt="Kupno auth" />
  </div>

  <div class="t-a_c">
    <small>
      This site is protected by reCAPTCHA and the Google
      <a target="_blank" href="https://policies.google.com/privacy"
        >Privacy Policy</a
      >
      and
      <a target="_blank" href="https://policies.google.com/terms"
        >Terms of Service</a
      >
      apply.
    </small>
  </div>
</div>
