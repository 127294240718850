<mat-form-field class="its-chips" appearance="outline">
  <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-chip-list
    class="common-drop-list"
    cdkDropList
    [cdkDropListOrientation]="dragDirection"
    (cdkDropListDropped)="drop($event)"
    [class.column-list]="list"
    [formControl]="control"
    #chipList
  >
    <ng-container
      *ngFor="let option of control.value; let l = last; trackBy: trackByFn"
    >
      <div [cdkDragDisabled]="control.disabled || !drag" cdkDrag>
        <mat-chip
          [class.c_mi]="drag && control.enabled"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(option)"
        >
          {{ option }}
          <its-icon
            class="mat-chip-edit"
            (click)="editChip(option)"
            size="20"
            *ngIf="control.enabled"
            svgIcon="edit"
            matTooltip="{{ 'common.tooltip.edit' | translate }}"
            matTooltipPosition="above"
          ></its-icon>
          <its-icon
            size="20"
            matChipRemove
            *ngIf="removable && control.enabled"
            svgIcon="cancel"
            matTooltip="{{ 'common.tooltip.delete' | translate }}"
            matTooltipPosition="above"
          ></its-icon>
        </mat-chip>

        <ng-container *ngIf="list">
          <br />
        </ng-container>
      </div>
    </ng-container>

    <input
      #inputEl
      [formControl]="inputControl"
      [matAutocomplete]="opt"
      [maxLength]="maxlength"
      [class.column-list-input]="control.value?.length > 0"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      (paste)="onPaste($event)"
    />

    <mat-autocomplete
      #opt="matAutocomplete"
      (optionSelected)="selectOption($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-list>
  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
</mat-form-field>
