import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { GlobalObjectService } from '@core/services/global-object.service';

import { DARK_THEME } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkTheme$ = new BehaviorSubject<boolean>(false);
  constructor(readonly globalObjectService: GlobalObjectService) {}

  initTheme(): void {
    if (this.globalObjectService.isPlatformBrowser()) {
      const prefersDark = this.globalObjectService
        .getWindow()
        ?.matchMedia('(prefers-color-scheme: dark)').matches;
      const darkTheme = this.globalObjectService.getStorageData(DARK_THEME);
      if ((prefersDark || darkTheme === 'true') && darkTheme !== 'false') {
        this.setDarkTheme(true);
        this.globalObjectService.getDocument().body.classList.add('dark-theme');
      }
    }
  }

  toggleTheme(): void {
    this.setDarkTheme(!this.darkTheme$.getValue());
    this.globalObjectService.getDocument().body.classList.toggle('dark-theme');
  }

  setDarkTheme(dark: boolean) {
    this.globalObjectService.setStorageData(
      DARK_THEME,
      dark ? 'true' : 'false'
    );
    this.darkTheme$.next(dark);
  }

  getDarkTheme() {
    return this.darkTheme$.asObservable();
  }
}
