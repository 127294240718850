import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IJob } from '@modules/jobs/interfaces';
import { JobsService } from '@modules/jobs/services';
import { PlanService } from '@modules/settings/services';

import {
  HOT_VACANCY_DURATION_D,
  VACANCY_DURATION_D
} from '@common/shared/constants';

import { ROUTES_DATA } from '@client/shared/constants';

@Component({
  selector: 'its-modal-publish-vacancy',
  templateUrl: './modal-publish-vacancy.component.html',
  styleUrls: ['./modal-publish-vacancy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPublishVacancyComponent implements OnInit {
  job: IJob;
  redirect = true;
  form: FormGroup;
  readonly VACANCY_DURATION_D = VACANCY_DURATION_D;
  readonly HOT_VACANCY_DURATION_D = HOT_VACANCY_DURATION_D;
  hotServices$ = this.planService.getHotServices().pipe(
    filter(Boolean),
    tap((hots) => {
      if (!hots?.length) {
        this.form.get('hot').disable({
          onlySelf: true
        });
      }
    })
  );
  constructor(
    readonly dialogRef: MatDialogRef<ModalPublishVacancyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { job: IJob; redirect?: boolean },
    readonly changeDetector: ChangeDetectorRef,
    readonly planService: PlanService,
    readonly jobsService: JobsService,
    readonly router: Router
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onNoClick();
    });
  }

  ngOnInit(): void {
    this.setData();
    this.buildForm();
  }

  onNoClick(publish = false): void {
    this.dialogRef.close();
    if (this.redirect || publish) {
      setTimeout(() => {
        this.router.navigateByUrl(
          `/${ROUTES_DATA.JOBS.url}/${this.job.id}${
            publish ? '?publish=1' : ''
          }`
        );
      }, 10);
    }
  }

  buildForm() {
    this.form = new FormGroup({
      hot: new FormControl(false)
    });
    this.changeDetector.markForCheck();
  }

  publish() {
    this.jobsService.publishJob({ ...this.job, ...this.form.value }).subscribe(
      () => {
        this.onNoClick(true);
      },
      () => {
        this.onNoClick();
      }
    );
  }

  setData() {
    this.job = this.data.job;
    this.redirect = this.data.hasOwnProperty('redirect')
      ? this.data.redirect
      : true;
  }
}
