import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CancelInviteReasonEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const CANCEL_INVITE_REASONS: ISelectOption[] = [
  {
    id: CancelInviteReasonEnum.OtherProposition,
    value: _('cancelInviteReason.otherProposition')
  },
  {
    id: CancelInviteReasonEnum.NotMySpecialty,
    value: _('cancelInviteReason.notMySpecialty')
  },
  {
    id: CancelInviteReasonEnum.NotInterestingProject,
    value: _('cancelInviteReason.notInterestingProject')
  },
  {
    id: CancelInviteReasonEnum.Expired,
    value: _('cancelInviteReason.expired'),
    hidden: true
  },
  {
    id: CancelInviteReasonEnum.Other,
    value: _('cancelInviteReason.other')
  }
];
