import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, tap } from 'rxjs';
import {
  debounceTime,
  map,
  pluck,
  share,
  take,
  takeUntil
} from 'rxjs/operators';

import { ICompany } from '@modules/companies/interfaces';
import { CompaniesService, OfficesService } from '@modules/companies/services';
import { IJob } from '@modules/jobs/interfaces';
import { JobsService } from '@modules/jobs/services';
import { TagsService } from '@modules/settings/services';

import {
  GlobalObjectService,
  LocaleService,
  ModalService,
  NotificationService,
  ValuesService
} from '@core/services';

import {
  ENGLISH_NOT_REQUIRED,
  INCLUDE_LOCATION,
  MAX_QUERY_LIMIT
} from '@common/shared/constants';
import {
  clickAllowed,
  getSalary,
  getSearchArray,
  setCookie
} from '@common/shared/helpers';

import { Destroyable } from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import {
  HIDE_FAVORITE_KEY,
  HIDE_INV_KEY,
  HIDE_INV_REQ_KEY,
  HIDE_REQ_KEY,
  HIDE_RESUME_WITH_REQ_INV_DEFAULT_D,
  HIDE_VIEWED_KEY
} from '@client/shared/constants';
import { BooleanSearchTypeEnum, SearchTypeEnum } from '@client/shared/enums';
import { IValues } from '@client/shared/interfaces';
import { getLocationValue } from '@client/shared/utils';

import { ModalSaveFilterComponent } from './components';

@Component({
  selector: 'its-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent extends Destroyable implements OnInit {
  BooleanSearchTypeEnum = BooleanSearchTypeEnum;
  searchForm: FormGroup;
  jobEnglish: ISelectOption[];
  values$: Observable<IValues> = this.valuesService.getValues().pipe(
    tap((values) => {
      this.jobEnglish = [
        {
          id: ENGLISH_NOT_REQUIRED,
          value: this.localeService.getInstant(
            _('common.englishLevel.notRequired')
          )
        },
        ...values.english
      ];
    })
  );
  jobsLocations$: Observable<ISelectOption<string>[]> =
    this.officesService.getSearchOffices({ jobs: true });
  companyLocations$: Observable<ISelectOption<string>[]> =
    this.officesService.getSearchOffices({ companies: true });
  SearchTypeEnum = SearchTypeEnum;
  excludedCompanies: Partial<ICompany>[] = [];

  currentJobId = null;
  currentJob: IJob = null;
  filterJobs: IJob[] = [];
  jobs$: Observable<ISelectOption[]> = this.jobsService.getMyActiveJobs().pipe(
    share(),
    map((jobs) => {
      const jobsSelect = jobs.map((j) => ({
        id: j.id,
        value: `${j.title} (${getSalary(j.salaryFrom, j.salaryTo)})`,
        ...j
      }));
      this.filterJobs = jobsSelect;
      return jobsSelect;
    })
  );

  allTags$ = this.tagsService.getAllTags();

  @Input() drawer = false;
  @Input() mapFilter = false;
  @Input() set selectedJobCategory(value: number) {
    if (value) {
      this.searchForm.patchValue({ categories: `${value}` });
    }
  }
  @Input() type: SearchTypeEnum;
  @Input() contacts = false;
  @Input() favorites = false;

  @Output() onSearch = new EventEmitter<any>();
  excludedCompaniesControl = new FormControl();

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly valuesService: ValuesService,
    readonly officesService: OfficesService,
    readonly router: Router,
    readonly changeDetector: ChangeDetectorRef,
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService,
    readonly companiesService: CompaniesService,
    readonly jobsService: JobsService,
    readonly tagsService: TagsService,
    readonly modalService: ModalService,
    readonly globalObjectService: GlobalObjectService
  ) {
    super();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.buildForm();
    }, 0);
  }

  buildForm() {
    const {
      category = null,
      categories,
      workTypes = [],
      domains = [],
      languages = [],
      title = '',
      name = '',
      location = '',
      offices = '',
      employees = [],
      english = '',
      experience = '',
      types = '',
      ex_com = '',
      position = '',
      readyToWork = '',
      hide_invited = this.globalObjectService.getServerCookies(HIDE_INV_KEY) ||
        HIDE_RESUME_WITH_REQ_INV_DEFAULT_D,
      hide_requested = this.globalObjectService.getServerCookies(
        HIDE_REQ_KEY
      ) || HIDE_RESUME_WITH_REQ_INV_DEFAULT_D,
      hide_inv_req = this.globalObjectService.getServerCookies(
        HIDE_INV_REQ_KEY
      ),
      hide_viewed = this.globalObjectService.getServerCookies(HIDE_VIEWED_KEY),
      hide_favorite = this.globalObjectService.getServerCookies(
        HIDE_FAVORITE_KEY
      ),
      veteran = '',
      job = '',
      tags = [],
      jobs = ''
    } = { ...this.activatedRoute.snapshot.queryParams } as any;

    const wTypes = getSearchArray(workTypes).map((c) => +c);
    const dom = getSearchArray(domains).map((c) => +c);
    const lang = getSearchArray(languages).map((c) => +c);
    const employ = getSearchArray(employees).map((c) => +c);
    const allTags = getSearchArray(tags).map((c) => +c);
    const cTypes = getSearchArray(types).map((c) => +c);

    if (ex_com) {
      this.getExcludedCompanies(ex_com);
    }

    switch (this.type) {
      case SearchTypeEnum.Jobs: {
        this.searchForm = new FormGroup({
          region: new FormControl({
            value: {
              value: getLocationValue(location)
            },
            disabled: false
          }),
          location: new FormControl(location),
          categories: new FormControl(categories || ''),
          domains: new FormControl(dom),
          languages: new FormControl(lang),
          english: new FormControl(+english || null),
          experience: new FormControl(+experience || null),
          title: new FormControl(title),
          workTypes: new FormControl(wTypes),
          hide_inv_req: new FormControl(hide_inv_req === 'true'),
          hide_viewed: new FormControl(hide_viewed === 'true'),
          hide_favorite: new FormControl(hide_favorite === 'true')
        });
        break;
      }
      case SearchTypeEnum.Companies: {
        this.searchForm = new FormGroup({
          region: new FormControl(offices),
          name: new FormControl(name),
          employees: new FormControl(employ),
          types: new FormControl(cTypes),
          jobs: new FormControl(jobs === 'true')
        });
        break;
      }
      case SearchTypeEnum.Resume: {
        this.searchForm = new FormGroup({
          job: new FormControl(+job || null),
          location: new FormControl(location),
          position: new FormControl(position),
          categories: new FormControl(categories || ''),
          workTypes: new FormControl(wTypes),
          domains: new FormControl(dom),
          languages: new FormControl(lang),
          readyToWork: new FormControl(+readyToWork),
          tags: new FormControl(allTags),
          english: new FormControl(+english || null),
          experience: new FormControl(+experience || null),
          hide_invited: new FormControl(+hide_invited),
          hide_requested: new FormControl(+hide_requested),
          veteran: new FormControl(veteran === 'true')
        });
        if (job) {
          this.currentJobId = +job;
          this.jobs$.pipe(take(1)).subscribe(() => {
            this.detectFilterResumeByJob();
          });
        }

        break;
      }
    }
    this.changeDetector.detectChanges();

    if (this.type === SearchTypeEnum.Jobs && this.searchForm.get('region')) {
      this.searchForm
        .get('region')
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          this.searchForm.patchValue({
            location: data?.locationQuery || ''
          });
        });
    }

    if (!this.drawer) {
      this.searchForm.valueChanges
        .pipe(takeUntil(this.destroyed$), debounceTime(500))
        .subscribe((value) => {
          this.search();
        });
    } else {
      if (this.type === SearchTypeEnum.Resume) {
        this.searchForm
          .get('job')
          .valueChanges.pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.detectFilterResumeByJob(true);
          });
      }
    }
  }

  async search() {
    const value = { ...this.searchForm.getRawValue() };

    if (this.type === SearchTypeEnum.Resume) {
      value.hide_invited = value.hide_invited || 0;
      value.hide_requested = value.hide_requested || 0;
      setCookie(HIDE_INV_KEY, value.hide_invited);
      setCookie(HIDE_REQ_KEY, value.hide_requested);

      if (this.currentJobId !== value?.job) {
        this.detectFilterResumeByJob();
        return;
      }
    }

    if (this.type === SearchTypeEnum.Jobs) {
      setCookie(HIDE_INV_REQ_KEY, value.hide_inv_req);
      setCookie(HIDE_VIEWED_KEY, value.hide_viewed);
      setCookie(HIDE_FAVORITE_KEY, value.hide_favorite);
    }

    if (this.excludedCompanies?.length) {
      value.ex_com = this.excludedCompanies.map((c) => c.id).join(',');
    } else {
      value.ex_com = null;
    }

    if (this.type === SearchTypeEnum.Jobs) {
      delete value?.region;
    }

    if (this.mapFilter) {
      await this.updateQueryForWorldMap(value).then(() => {
        this.onSearch.emit(value);
      });
    } else {
      this.onSearch.emit(value);
    }
  }

  clearForm() {
    this.excludedCompanies = [];
    this.changeDetector.detectChanges();
    this.searchForm.reset();
    if (
      this.searchForm.value.hasOwnProperty('hide_invited') &&
      this.searchForm.value.hasOwnProperty('hide_requested')
    ) {
      this.searchForm.patchValue(
        {
          hide_invited: 0,
          hide_requested: 0
        },
        { onlySelf: true }
      );
    }

    if (
      this.searchForm.value.hasOwnProperty('hide_viewed') &&
      this.searchForm.value.hasOwnProperty('hide_inv_req') &&
      this.searchForm.value.hasOwnProperty('hide_favorite') &&
      this.searchForm.value.hasOwnProperty('veteran')
    ) {
      this.searchForm.patchValue(
        {
          hide_viewed: 0,
          hide_inv_req: 0,
          hide_favorite: 0,
          veteran: 0
        },
        { onlySelf: true }
      );
    }

    if (this.mapFilter && this.drawer) {
      this.search();
    }
  }

  followFilter(ev: any) {
    if (clickAllowed(ev)) {
      let filterWithData = false;
      const form = this.searchForm.getRawValue();
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          const value = form[key];
          const notRequiredFields = [
            'hide_favorite',
            'hide_viewed',
            'hide_inv_req',
            'hide_invited',
            'hide_requested'
          ];
          if (!notRequiredFields.includes(key)) {
            if (typeof value !== 'object' ? value : value?.length) {
              filterWithData = true;
              break;
            }
          }
        }
      }
      if (filterWithData) {
        this.modalService.openComponent(
          ModalSaveFilterComponent,
          (saved: boolean) => {
            if (saved) {
              this.notificationService.showSuccess(
                this.localeService.getInstant(
                  _('common.notification.filterSaved')
                ),
                4000
              );
            }
          },
          {
            width: '422px',
            data: {
              ...form,
              excludedCompanies: this.excludedCompanies.map((c) => c.id),
              search: this.type,
              currentJob:
                this.type === SearchTypeEnum.Resume ? this.currentJob : null
            }
          }
        );
      } else {
        this.notificationService.showMessage(
          this.localeService.getInstant(_('common.notification.emptyFilter'))
        );
      }
    }
  }

  async updateQueryForWorldMap(value = {}) {
    const query = {
      ...this.activatedRoute.snapshot.queryParams,
      ...value
    };
    for (const key in query) {
      if (
        query.hasOwnProperty(key) &&
        (query[key] === '' ||
          query[key] === null ||
          query[key] === undefined ||
          (Array.isArray(query[key]) && !query[key]?.length))
      ) {
        delete query[key];
      } else {
        if (typeof query[key] === 'object' && Array.isArray(query[key])) {
          query[key] = query[key].join(',');
        }
      }
    }

    return await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...query
      },
      replaceUrl: true
    });
  }

  authActions(ev: any) {
    if (!clickAllowed(ev)) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  detectFilterResumeByJob(drawer = false) {
    const value = { ...this.searchForm.getRawValue() };

    if (value?.job) {
      this.currentJobId = value?.job;
      const job = this.filterJobs.find((j) => j.id === value.job);
      if (job) {
        if (this.currentJob?.id !== job.id) {
          this.currentJob = job;
          this.searchForm.get('categories').disable({ onlySelf: true });
          this.searchForm.get('english').disable({ onlySelf: true });
          this.searchForm.get('experience').disable({ onlySelf: true });

          const formValue = {
            categories: job.categoryConditions,
            english: job.english,
            experience: job.experience
          } as any;

          if (job.userLocationRequired && job.userLocation) {
            this.searchForm.get('location').disable({ onlySelf: true });
            formValue.location = `${INCLUDE_LOCATION}=${job.userLocation}`;
          }

          this.searchForm.patchValue(formValue, {
            onlySelf: drawer,
            emitEvent: !drawer
          });
        }
      } else {
        this.currentJobId = null;
        this.currentJob = null;
        this.searchForm.patchValue(
          {
            job: null
          },
          {
            onlySelf: true
          }
        );
        this.changeDetector.markForCheck();
      }
    } else {
      this.searchForm.patchValue(
        {
          job: null
        },
        {
          onlySelf: drawer,
          emitEvent: !drawer
        }
      );
      this.currentJobId = null;
      this.currentJob = null;
      this.searchForm.get('categories').enable({ onlySelf: true });
      this.searchForm.get('english').enable({ onlySelf: true });
      this.searchForm.get('experience').enable({ onlySelf: true });
      this.searchForm.get('location').enable({ onlySelf: true });
      this.changeDetector.markForCheck();
    }
  }

  /**
   * EXCLUDED COMPANIES
   */

  companiesOptionsFn = (name: string) =>
    this.companiesService
      .getCompanies({
        name,
        size: 20,
        page: 1
      })
      .pipe(pluck('data'));

  companyLabelFn = (val: { name: string }) => {
    return val instanceof Object ? val.name : val;
  };

  getExcludedCompanies(ids: number[]) {
    this.companiesService
      .getCompanies({ ids, page: 1, size: MAX_QUERY_LIMIT })
      .subscribe((res) => {
        this.excludedCompanies = res?.data || [];
        this.changeDetector.markForCheck();
      });
  }

  onCompanyChanged(company: Partial<ICompany>) {
    if (company && !this.excludedCompanies.find((c) => c.id === company.id)) {
      this.excludedCompanies.push(company);
      this.changeDetector.markForCheck();
      this.searchForm.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true
      });
    }
  }

  removeExcludedCompany(company: Partial<ICompany>) {
    this.excludedCompanies = this.excludedCompanies.filter(
      (c) => c.id !== company.id
    );
    this.changeDetector.markForCheck();
    this.searchForm.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true
    });
  }
}
