import {
  HttpClient,
  HttpParameterCodec,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';

import { IApiResponse } from '@common/shared/interfaces';

import { TIME_STAMP_PARAM } from '@client/shared/constants';

import { environment } from '@env';

import { DefaultQueryEncoder } from './default-query.encoder';

export abstract class BaseHttpService {
  protected readonly serverUrl: string;
  protected readonly encoder: HttpParameterCodec;
  public httpClient = inject(HttpClient);

  protected constructor(path: string) {
    this.serverUrl = `${environment.serverUrl}${path ? '/' + path : path}`;
    this.encoder = new DefaultQueryEncoder();
  }

  get<IResponse>(params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.serverUrl}/${path}`;
    return this.httpClient.get<IApiResponse<IResponse>>(url, {
      ...config,
      params: new HttpParams({
        fromObject: { ...params, [TIME_STAMP_PARAM]: Date.now() },
        encoder: this.encoder
      })
    });
  }

  delete<TResponse>(params?: {}, path: string | number = '') {
    const url = `${this.serverUrl}/${path}`;

    return this.httpClient.delete<IApiResponse<TResponse>>(url, {
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  post<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.serverUrl}/${path}`;

    return this.httpClient.post<IApiResponse<TResponse>>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  put<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.serverUrl}/${path}`;

    return this.httpClient.put<IApiResponse<TResponse>>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  patch<TRequest, TResponse = TRequest>(
    body: TRequest,
    params?: {},
    path: string | number = '',
    config?: {}
  ) {
    const url = `${this.serverUrl}/${path}`;

    return this.httpClient.patch<IApiResponse<TResponse>>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  request<TRequest, TResponse = TRequest>(request: HttpRequest<TRequest>) {
    return this.httpClient.request<IApiResponse<TResponse>>({
      params: new HttpParams({
        fromObject: request.params as {},
        encoder: this.encoder
      })
    } as HttpRequest<unknown>);
  }
}
