<form
  class="modal"
  [formGroup]="form"
  (ngSubmit)="saveFilter()"
  (keyup.enter)="saveFilter()"
>
  <its-button class="close" variant="icon" mat-dialog-close>
    <its-icon size="18" svgIcon="clear"></its-icon>
  </its-button>
  <div mat-dialog-title>{{ 'saveFilter.modal.title' | translate }}</div>
  <div mat-dialog-content>
    <p class="c-violet-active m-b_24" *ngIf="data.job">
      <its-icon class="budget-info" svgIcon="info" size="20"></its-icon>
      <span>{{ 'saveFilter.jobSalary.info' | translate }}</span>
    </p>

    <div *ngIf="values$ | async as values">
      <div class="form-control">
        <its-input
          [maxlength]="70"
          formControlName="filterName"
          placeholder="{{
            'saveFilter.form.filterName.placeholder' | translate
          }}"
        ></its-input>
      </div>
      <div class="form-control">
        <small class="interval-info">{{
          'saveFilter.form.interval.info' | translate
        }}</small>
        <its-select
          formControlName="interval"
          placeholder="{{ 'saveFilter.form.interval.placeholder' | translate }}"
          [optional]="false"
          [options]="values.filterInformInterval"
        ></its-select>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      type="button"
      variant="stroked"
      color="accent"
      (click)="onNoClick()"
    >
      {{ 'common.form.button.cancel' | translate }}
    </its-button>

    <its-button type="submit" color="primary">
      {{ 'common.form.button.save' | translate }}
    </its-button>
  </div>
</form>
