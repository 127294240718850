<mat-card class="tooltip-card">
  <h3 *ngIf="title">
    <b>{{ title }}</b>
  </h3>
  <mat-card-content *ngIf="text">{{text}}</mat-card-content>
  <mat-card-actions *ngIf="button" align="start">
    <its-button
      color="accent"
      classes="btn-md"
    >
      {{ button | translate }}
    </its-button>
  </mat-card-actions>
</mat-card>
