import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CategoryGroupEnum } from '@common/shared/enums';

export const CATEGORY_GROUPS = Object.freeze([
  /*{
    id: CategoryGroupEnum.SoftwareDevelopment,
    value: _('categoryGroup.softwareDevelopment')
  },
  {
    id: CategoryGroupEnum.Cybersecurity,
    value: _('categoryGroup.cybersecurity')
  },
  {
    id: CategoryGroupEnum.DataAnalysis,
    value: _('categoryGroup.dataAnalysis')
  },
  {
    id: CategoryGroupEnum.QualityAssurance,
    value: _('categoryGroup.gualityAssurance')
  },
  {
    id: CategoryGroupEnum.TechnicalSupport,
    value: _('categoryGroup.technicalSupport')
  },
  {
    id: CategoryGroupEnum.TechnicalWriting,
    value: _('categoryGroup.technicalWriting')
  },
  {
    id: CategoryGroupEnum.DatabaseAdministration,
    value: _('categoryGroup.databaseAdministration')
  },
  {
    id: CategoryGroupEnum.UserExperience,
    value: _('categoryGroup.userExperience')
  },*/
]);
