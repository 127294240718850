import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { ValuesService } from '@core/services';

import { CloseVacancyReasonEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

@Component({
  selector: 'its-modal-close-vacancy',
  templateUrl: './modal-close-vacancy.component.html',
  styleUrls: ['./modal-close-vacancy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCloseVacancyComponent implements OnInit {
  form: FormGroup;
  reasons$: Observable<ISelectOption[]> = this.valuesService
    .getValues()
    .pipe(pluck('closeVacancyReasons'));
  constructor(
    readonly dialogRef: MatDialogRef<ModalCloseVacancyComponent>,
    readonly valuesService: ValuesService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  buildForm() {
    this.form = new FormGroup({
      reason: new FormControl(
        CloseVacancyReasonEnum.WithKupno,
        Validators.required
      )
    });
    this.changeDetector.markForCheck();
  }

  selectReason() {
    this.onNoClick(this.form.value.reason || CloseVacancyReasonEnum.WithKupno);
  }
}
