<div class="share-buttons">
  <its-button shareButton="facebook" variant="mini-fab" classes="share-facebook">
    <its-icon svgIcon="share-facebook"></its-icon>
  </its-button>
  <its-button shareButton="twitter" variant="mini-fab" classes="share-twitter">
    <its-icon svgIcon="share-twitter"></its-icon>
  </its-button>
  <its-button shareButton="linkedin" variant="mini-fab" classes="share-linkedin">
    <its-icon svgIcon="share-linkedin"></its-icon>
  </its-button>
  <its-button shareButton="telegram" variant="mini-fab" classes="share-telegram">
    <its-icon svgIcon="share-telegram"></its-icon>
  </its-button>
<!--  <its-button shareButton="viber" variant="mini-fab" classes="share-viber">
    <its-icon svgIcon="share-viber"></its-icon>
  </its-button>-->
  <its-button matTooltip="{{'common.tooltip.copyLink' | translate}}" shareButton="copy" variant="mini-fab" classes="share-link">
    <its-icon svgIcon="share-link"></its-icon>
  </its-button>
</div>
