import { Injectable } from '@angular/core';

import { AWSMediaPathEnum } from '@common/shared/enums';

import { companyFromResponse } from '@client/shared/utils';

import { environment } from '@env';

import { ICompany, ICompanyResponse, ICompanyRequest } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompaniesFactory {
  toRequest(company: ICompanyRequest): ICompanyRequest {
    return {
      name: company.name,
      founded: company.founded,
      description: company.description,
      site: company.site === 'https://' ? '' : company.site,
      employees: company.employees,
      logo: company.logo.replace(
        `${environment.storageUrl}/${AWSMediaPathEnum.CompanyLogos}/`,
        ''
      ),
      ogLogo: company.ogLogo.replace(
        `${environment.storageUrl}/${AWSMediaPathEnum.CompanyLogos}/`,
        ''
      ),
      socialLinks: company.socialLinks.filter(s => !!s && s !== 'https://'),
      technologyStack: company.technologyStack,
      types: company.types,
      advantages: company.advantages,
      followersCount: company.followersCount,
      phone: company?.phone
    };
  }

  fromResponse(company: ICompanyResponse): ICompany {
    return companyFromResponse(company);
  }
}
