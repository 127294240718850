<form
  class="modal"
  *ngIf="resumesOptions$ | async as options"
  [formGroup]="applyForm"
  (ngSubmit)="applyJob()"
>
  <h1 mat-dialog-title>{{ 'applyJob.modal.title' | translate }}</h1>
  <div mat-dialog-content>
    <p>{{ 'applyJob.modal.description' | translate }}</p>

    <ng-container *ngIf="options?.length; else fillResume">
      <div [class.d_n]="options?.length === 1" class="form-control">
        <its-select
          formControlName="resume"
          placeholder="{{ 'applyJob.selectResume.placeholder' | translate }}"
          [required]="true"
          [optional]="false"
          [options]="options"
        ></its-select>
      </div>
      <div class="form-control">
        <its-textarea
          *ngIf="!job.applicationUrl"
          [template]="true"
          [templateUserName]="job.user.firstName"
          [templateType]="[
            TemplateTypeEnum.CoverLetter,
            TemplateTypeEnum.Custom
          ]"
          [templateAutoSelect]="TemplateTypeEnum.CoverLetter"
          formControlName="message"
          placeholder="{{ 'applyJob.coverLetter.placeholder' | translate }}"
        ></its-textarea>
      </div>
    </ng-container>
    <ng-template #fillResume>
      <div class="m-b_24">
        <div>{{ 'applyJob.notification.noResume' | translate }}</div>
        <its-button
          variant="basic"
          [mat-dialog-close]="false"
          [link]="
            '/' + ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url
          "
          color="primary"
          classes="btn-sm"
          ><span class="title">{{
            'applyJob.button.goToProfile' | translate
          }}</span></its-button
        >
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.form.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary" [disabled]="applyForm.invalid">
      {{ 'common.form.button.applyJob' | translate }}
    </its-button>
  </div>
</form>
