<div class="modal">
  <div class="bell-logo">
    <img src="/assets/v1/images/notification-bell.png" alt="Notifications" />
  </div>
  <div mat-dialog-content>
    <div class="web-push-modal-content">
        <h3>{{ 'web-push.modal.title' | translate }}</h3>
        <p>{{ 'web-push.modal.description' | translate }}</p>
    </div>
  </div>
  <div mat-dialog-actions class="web-push-modal-actions">
    <its-button classes="btn-md" type="button" variant="stroked" color="accent" [mat-dialog-close]="false">
      {{ 'common.form.button.cancel' | translate }}
    </its-button>

    <its-button classes="btn-md" (click)="confirm()" color="primary">
      {{ 'web-push.button.confirm' | translate }}
    </its-button>
  </div>
</div>
