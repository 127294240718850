import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@core/auth';
import { SeoService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  emailWasSend = false;

  constructor(
    readonly fb: FormBuilder,
    readonly authService: AuthService,
    readonly activatedRoute: ActivatedRoute,
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      username: new FormControl(
        '',
        Validators.compose([Validators.email, Validators.required, CustomValidators.plusInEmail])
      )
    });
  }

  send() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      return this.authService.forgotPassword(this.form.value).subscribe(() => {
        this.emailWasSend = true;
        // form.reset();
        this.buildForm();
        this.changeDetector.markForCheck();
        setTimeout(() => {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { auth: null }
          });
        }, 3000);
      });
    }
  }

  backToLogIn() {
    this.authService.toAuth();
  }
}
