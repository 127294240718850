import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { WorkTypeGroupEnum, WorkTypesEnum } from '@common/shared/enums';

export const WORK_TYPES_TRANSLATES = {
  [WorkTypesEnum.FullTime]: _('workType.fullTime'),
  [WorkTypesEnum.PartTime]: _('workType.partTime'),
  [WorkTypesEnum.Remote]: _('workType.remote'),
  [WorkTypesEnum.OnSiteWork]: _('workType.onSiteWork'),
  [WorkTypesEnum.Hybrid]: _('workType.hybrid'),
  [WorkTypesEnum.FourDayWeek]: _('workType.fourDayWeek'),
}

export const MODE_WORK_GROUPS = {
  [WorkTypeGroupEnum.Time]: _('modeWorkGroup.time'),
  [WorkTypeGroupEnum.Place]: _('modeWorkGroup.place'),
}
