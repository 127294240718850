import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IntegrationEnum } from '@common/shared/enums';
import { IGreenhouseUser } from '@common/shared/interfaces';

import { ISelectOption } from '@client/shared/components/select';

@Component({
  selector: 'its-modal-ats-users',
  templateUrl: './modal-ats-users.component.html',
  styleUrls: ['./modal-ats-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalAtsUsersComponent implements OnInit {
  form: FormGroup;
  userOptions: ISelectOption[] = [];

  constructor(
    readonly dialogRef: MatDialogRef<ModalAtsUsersComponent>,
    @Inject(MAT_DIALOG_DATA)
    public users: IGreenhouseUser[]
  ) {}

  ngOnInit(): void {
    this.getUserOptions();
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  save() {
    if (this.form.valid) {
      this.onNoClick(this.form.getRawValue());
    }
  }

  getUserOptions() {
    this.userOptions = this.users.map((u) => ({
      id: u.id,
      value: u.name
    }));
  }

  buildForm() {
    this.form = new FormGroup({
      integrationId: new FormControl('', Validators.required),
      type: new FormControl(IntegrationEnum.Greenhouse)
    });
  }
}
