import {
  CurrencyEnum,
  PlanPeriodEnum,
  PlanTypeEnum,
  PlanStatusEnum,
  CompanyPageViewEnum, InvoicePaymentTypeEnum
} from '@common/shared/enums';

export interface IPlan {
  id: number;
  planType: PlanTypeEnum;
  status: PlanStatusEnum;
  period: PlanPeriodEnum;
  paymentType: InvoicePaymentTypeEnum;
  currency: CurrencyEnum;
  trialOver: Date;
  expiredDate: Date;
  featureExpired: Date;
  amount: number;
  feature: IFeature;

  bannedAllowed?: boolean;
  newCompanyPage?: boolean;
}

export interface IFeature {
  publications: number;
  hotPublications: number;
  customBanner: boolean;
  jobVideo: boolean;
  homePageLogo: boolean;
  companyPageView: CompanyPageViewEnum;
}
