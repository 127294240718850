export interface ISelectOption<I = string | number, N = string> {
  id: I;
  value?: N;
  disabled?: boolean;
  hidden?: boolean;
  level?: number;
  dn?: boolean; // display none
  [key: string]: any;
}

export interface ISelectGroupOption<I = string | number, N = string> {
  title: string;
  options: ISelectOption<I, N>[];
  disabled?: boolean;
  dn?: boolean; // display none
}
