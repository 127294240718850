<ng-container *ngIf="locale$ | async as locale">
  <ng-container [ngSwitch]="locale">
    <ng-container *ngSwitchCase="LanguageEnum.Ukraine">
      <its-legal-uk></its-legal-uk>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <its-legal-en></its-legal-en>
    </ng-container>
  </ng-container>
</ng-container>
