import { Directive, HostListener, Input } from '@angular/core';

import { IJob } from '@modules/jobs/interfaces';

import { ModalService } from '@core/services';

import { ModalPublishVacancyComponent } from './components';

@Directive({
  selector: '[publishJob]'
})
export class PublishJobDirective {
  @Input() publishJob: IJob;
  @Input() redirect = true;

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (this.publishJob) {
      this.modalService.openComponent(ModalPublishVacancyComponent, null, {
        data: {
          job: this.publishJob,
          redirect: this.redirect
        },
        disableClose: true
      });
    }
  }
  constructor(readonly modalService: ModalService) {}
}
