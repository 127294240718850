import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ICompany } from '@modules/companies/interfaces';
import { CompaniesFactory } from '@modules/companies/services';

import { BaseHttpService } from '@client/shared/abstracts';
import {
  IRequestDate,
  ICandidatesStats,
  IVacancyStats
} from '@client/shared/interfaces';
import { IQuery } from '@common/shared/interfaces';
import { NO_LOADER_PARAM } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class StatisticService extends BaseHttpService {
  constructor(readonly companiesFactory: CompaniesFactory) {
    super('statistic');
  }

  getTopCompanies(query: IQuery) {
    return this.get<ICompany[]>({...query, [NO_LOADER_PARAM]: true }, 'top-companies').pipe(
      map(({ content }) => content.map(this.companiesFactory.fromResponse))
    );
  }

  widgetVacanciesStats() {
    return this.get<IVacancyStats>({}, 'vacancies-general').pipe(
      map(({ content }) => content)
    );
  }

  widgetResumeStats() {
    return this.get<ICandidatesStats>({}, 'resume-general').pipe(
      map(({ content }) => content)
    );
  }
}
