import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { IBilling } from '@modules/settings/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import { BillingFactory } from './billing.factory';

@Injectable({
  providedIn: 'root'
})
export class BillingService extends BaseHttpService {
  constructor(readonly factory: BillingFactory) {
    super('billing');
  }

  getCompanyBilling() {
    return this.get<IBilling>({}, '').pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  saveBilling(data: IBilling) {
    return this.post<IBilling>(data, {}, '').pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }
}
