import {
  CompanyPageViewEnum,
  CurrencyEnum,
  PlanPeriodEnum,
  PlanTypeEnum
} from '../enums';
import { UAH_CURRENCY_RATE } from './values';

const MONTHLY_PRICE = {
  [PlanTypeEnum.Basic]: 100,
  [PlanTypeEnum.Standard]: 250,
  [PlanTypeEnum.Premium]: 500
};

const getDiscount = (
  planType: PlanTypeEnum,
  period: PlanPeriodEnum,
  uah = false
) => {
  const monthlyPrice = MONTHLY_PRICE[planType];
  let result = monthlyPrice;
  if (period === PlanPeriodEnum.ThreeMonths) {
    result = monthlyPrice * 3 * 0.9;
  }
  if (period === PlanPeriodEnum.SixMonths) {
    result = monthlyPrice * 6 * 0.8;
  }
  if (period === PlanPeriodEnum.Annual) {
    result = monthlyPrice * 12 * 0.7;
  }
  return uah ? result * UAH_CURRENCY_RATE : result;
};

export const PLANS = Object.freeze([
  {
    planType: PlanTypeEnum.Basic,
    name: 'Basic',
    periods: {
      [PlanPeriodEnum.Monthly]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.Monthly
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.Monthly,
            true
          )
        },
        features: {
          publications: 5,
          hotPublications: 1,
          customBanner: false,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Basic
        }
      },

      [PlanPeriodEnum.ThreeMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.ThreeMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.ThreeMonths,
            true
          )
        },
        features: {
          publications: 15,
          hotPublications: 3,
          customBanner: false,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Basic
        }
      },
      [PlanPeriodEnum.SixMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.SixMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.SixMonths,
            true
          )
        },
        features: {
          publications: 30,
          hotPublications: 6,
          customBanner: false,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Basic
        }
      },
      [PlanPeriodEnum.Annual]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.Annual
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Basic,
            PlanPeriodEnum.Annual,
            true
          )
        },
        features: {
          publications: 60,
          hotPublications: 12,
          customBanner: false,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Basic
        }
      }
    }
  },
  {
    planType: PlanTypeEnum.Standard,
    name: 'Standard',
    periods: {
      [PlanPeriodEnum.Monthly]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.Monthly
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.Monthly,
            true
          )
        },
        features: {
          publications: 15,
          hotPublications: 3,
          customBanner: true,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Standard
        }
      },
      [PlanPeriodEnum.ThreeMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.ThreeMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.ThreeMonths,
            true
          )
        },
        features: {
          publications: 45,
          hotPublications: 9,
          customBanner: true,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Standard
        }
      },
      [PlanPeriodEnum.SixMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.SixMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.SixMonths,
            true
          )
        },
        features: {
          publications: 90,
          hotPublications: 18,
          customBanner: true,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Standard
        }
      },
      [PlanPeriodEnum.Annual]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.Annual
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Standard,
            PlanPeriodEnum.Annual,
            true
          )
        },
        features: {
          publications: 180,
          hotPublications: 36,
          customBanner: true,
          jobVideo: true,
          homePageLogo: false,
          companyPageView: CompanyPageViewEnum.Standard
        }
      }
    }
  },
  {
    planType: PlanTypeEnum.Premium,
    name: 'Premium',
    periods: {
      [PlanPeriodEnum.Monthly]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.Monthly
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.Monthly,
            true
          )
        },
        features: {
          publications: null,
          hotPublications: 30,
          customBanner: true,
          jobVideo: true,
          homePageLogo: true,
          companyPageView: CompanyPageViewEnum.Premium
        }
      },
      [PlanPeriodEnum.ThreeMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.ThreeMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.ThreeMonths,
            true
          )
        },
        features: {
          publications: null,
          hotPublications: 90,
          customBanner: true,
          jobVideo: true,
          homePageLogo: true,
          companyPageView: CompanyPageViewEnum.Premium
        }
      },
      [PlanPeriodEnum.SixMonths]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.SixMonths
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.SixMonths,
            true
          )
        },
        features: {
          publications: null,
          hotPublications: 180,
          customBanner: true,
          jobVideo: true,
          homePageLogo: true,
          companyPageView: CompanyPageViewEnum.Premium
        }
      },
      [PlanPeriodEnum.Annual]: {
        prices: {
          [CurrencyEnum.USD]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.Annual
          ),
          [CurrencyEnum.UAH]: getDiscount(
            PlanTypeEnum.Premium,
            PlanPeriodEnum.Annual,
            true
          )
        },
        features: {
          publications: null,
          hotPublications: 360,
          customBanner: true,
          jobVideo: true,
          homePageLogo: true,
          companyPageView: CompanyPageViewEnum.Premium
        }
      }
    }
  }
]);
