<div class="contacts">
  <div>
    <h1>{{ 'contacts.title' | translate }}</h1>

    <p>{{ 'contacts.description' | translate }}</p>

    <p>{{ 'contacts.emailInfo' | translate }}</p>

    <div class="d_f">
      <a href="mailto:{{ HELLO_EMAIL }}" class="a-i_c c-g_12 m-b_24">
        <its-icon size="25" svgIcon="s-email"></its-icon>
        <span>{{ HELLO_EMAIL }}</span>
      </a>
    </div>

    <div class="m-b_8">Khrystyna Lisna</div>
    <div class="d_f c-g_24">
      <a
        href="https://wa.me/380931346558"
        target="_blank"
        class="a-i_c c-g_12 m-b_8"
      >
        <its-icon size="25" svgIcon="s-whatsapp"></its-icon>
        <span>WhatsApp</span>
      </a>
      <a
        href="https://t.me/KhrystynaLisna"
        target="_blank"
        class="a-i_c c-g_12 m-b_8"
      >
        <its-icon size="25" svgIcon="s-telegram"></its-icon>
        <span>Telegram</span>
      </a>
      <a
        href="https://www.linkedin.com/in/khrystyna-lisna-81972129a/"
        target="_blank"
        class="a-i_c c-g_12 m-b_8"
      >
        <its-icon size="25" svgIcon="s-linkedin"></its-icon>
        <span>Linkedin</span>
      </a>
    </div>
  </div>
  <div>
    <its-book-demo></its-book-demo>
  </div>
</div>
