import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '@core/auth';

import { setCookie } from '@common/shared/helpers';

import { AUTH_REDIRECT } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(readonly authService: AuthService, readonly router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.isLoggedIn()) {
      setCookie(AUTH_REDIRECT, state.url, 0.0138); // for 20 min
      this.authService.logOut();
      return false;
    }
    return true;
  }
}
