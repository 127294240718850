export const SOCIAL_ICONS = {
  linkedin: 's-linkedin',
  facebook: 's-facebook',
  't.me': 's-telegram',
  github: 's-github',
  youtube: 's-youtube',
  instagram: 's-instagram',
  twitter: 's-twitter',
  tiktok: 's-tiktok',
  dribbble: 's-dribbble',
  behance: 's-behance',
  figma: 's-figma',
  gitlab: 's-gitlab',
  bitbucket: 's-bitbucket',
  threads: 's-threads',
  pinterest: 's-pinterest'
};
