import { AbstractControl } from '@angular/forms';

import { regExps } from '@common/shared/utils';

export function CheckYoutubeURL({ value }: AbstractControl) {
  const error = { youtube: true };

  if (!value) {
    return null;
  }

  try {
    new URL(value);
    if (!value.startsWith('https://www.youtube.com')) {
      return error;
    }
  } catch (e) {
    return error;
  }
}
