import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ReadyToWorkEnum } from '@common/shared/enums/ready-to-work.enum';

export const READY_TO_WORK = Object.freeze([
  {
    id: ReadyToWorkEnum.Asap,
    value: _('readyToWork.asap')
  },
  {
    id: ReadyToWorkEnum.OneWeek,
    value: _('readyToWork.oneWeek')
  },
  {
    id: ReadyToWorkEnum.TwoWeeks,
    value: _('readyToWork.twoWeeks')
  },
  {
    id: ReadyToWorkEnum.OneMonth,
    value: _('readyToWork.oneMonth')
  },
  {
    id: ReadyToWorkEnum.MoreOneMonths,
    value: _('readyToWork.moreOneMonths')
  }
]);
