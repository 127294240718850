import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '@core/auth';
import {
  LocaleService,
  NotificationService,
  ValuesService
} from '@core/services';

import { BaseHttpService } from '@client/shared/abstracts';
import { SearchTypeEnum } from '@client/shared/enums';
import { IFilterForm } from '@client/shared/interfaces';

import { SettingsFactory } from './settings.factory';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseHttpService {
  constructor(
    readonly settingsFactory: SettingsFactory,
    readonly authService: AuthService,
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService,
    readonly valuesService: ValuesService,
    readonly router: Router
  ) {
    super('');
  }

  stopWorkingOnCampaign() {
    return this.put(null, {}, 'agents/stop-work').pipe(
      switchMap(() => this.authService.loadProfile())
    );
  }

  changePassword(data: { password: string; newPassword: string }) {
    return this.post(data, {}, 'auth/change-password');
  }

  deleteCompany(id: number) {
    return this.delete({}, `companies/${id}`).pipe(
      switchMap(() => this.authService.loadProfile())
    );
  }

  deleteAccount() {
    return this.delete<any>({}, 'users').pipe(
      tap((res) => {
        this.notificationService.showSuccess(
          this.localeService.getInstant(
            _('userProfile.notification.accountDeleted'),
            { hours: res.content.wait }
          ),
          8000
        );
        this.authService.logOut();
        this.router.navigateByUrl('/');
      })
    );
  }

  getFilters(type: SearchTypeEnum) {
    const urls = {
      [SearchTypeEnum.Jobs]: 'job',
      [SearchTypeEnum.Resume]: 'resume'
    };
    return this.get<IFilterForm[]>({}, `settings/${urls[type]}-filters`).pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([{ content }, values]) =>
        content.map((f) => this.settingsFactory.responseFilter(f, values, type))
      )
    );
  }

  saveResumeFilter(data: IFilterForm): Observable<any> {
    return this.post(data, {}, 'settings/resume-filter');
  }

  saveJobFilter(data: IFilterForm): Observable<any> {
    return this.post(data, {}, 'settings/job-filter');
  }

  updateResumeFilter(
    id: number,
    filter: Partial<IFilterForm>
  ): Observable<any> {
    return this.patch(filter, {}, `settings/resume-filter/${id}`).pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([{ content }, values]) =>
        this.settingsFactory.responseFilter(
          content,
          values,
          SearchTypeEnum.Resume
        )
      )
    );
  }

  updateJobFilter(id: number, filter: Partial<IFilterForm>): Observable<any> {
    return this.patch(filter, {}, `settings/job-filter/${id}`).pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([{ content }, values]) =>
        this.settingsFactory.responseFilter(
          content,
          values,
          SearchTypeEnum.Jobs
        )
      )
    );
  }

  deleteFilter(id: number, type: SearchTypeEnum) {
    const urls = {
      [SearchTypeEnum.Jobs]: `settings/job-filter/${id}`,
      [SearchTypeEnum.Resume]: `settings/resume-filter/${id}`
    };
    return this.delete({}, urls[type]);
  }

  setUserEmailNotification(data: {
    mailingRecommendation?: boolean;
    mailingAppUpdate?: boolean;
  }) {
    return this.patch(data, {}, `settings/notifications`).pipe(
      switchMap(() => this.authService.loadProfile()),
      tap(() => {
        this.notificationService.showSuccess(
          this.localeService.getInstant(_('company.notification.settingsSaved'))
        );
      })
    );
  }
}
