import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { TemplateTypeEnum } from '@client/shared/enums';

const DEFAULT_ROWS = 5;

@Component({
  selector: 'its-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class TextareaComponent<T>
  extends BaseControlComponent<string>
  implements OnInit
{
  @ViewChild('textarea') textarea: ElementRef;
  readonly destroyed$: Observable<unknown>;

  @Input() template = false;
  @Input() templateUserName = '';
  @Input() templateType: TemplateTypeEnum[] = [];
  @Input() excludeType: TemplateTypeEnum[] = [];
  @Input() templateAutoSelect: TemplateTypeEnum;

  @Input() placeholder: string;
  @Input() autoHeight = false;
  @Input() rows = DEFAULT_ROWS;
  @Input() trimValue = true;
  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    // const trimmedValue = value ? value.trim().replace(/\s{2,}/gm, ' ') : value;
    const trimmedValue = value ? value.trim() : value;

    if (trimmedValue !== value && this.trimValue) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    this.changeDetector.markForCheck();
  }

  onTemplateSelected(value: string) {
    this.control.setValue(value);
  }

  onSelectEmoji(emoji: any) {
    const textarea = this.textarea.nativeElement;
    textarea.focus();
    if (document.execCommand) {
      document.execCommand('insertText', false, emoji);
    } else {
      // insert emoji on carrot position
      try {
        const [start, end] = [textarea.selectionStart, textarea.selectionEnd];
        textarea.setRangeText(emoji, start, end, 'end');
      } catch (e) {
        console.log(e);
      }
    }
  }
}
