import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { combineLatest, Observable } from 'rxjs';
import { pluck, startWith, takeUntil } from 'rxjs/operators';

import { LocaleService, ModalService, ValuesService } from '@core/services';

import {
  getExcludeLocation,
  getIncludeLocation,
  getResultCategoriesBooleanConditions
} from '@common/shared/helpers';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import {
  ModalBooleanSearchCategoriesComponent,
  ModalBooleanSearchLocationComponent
} from '@client/shared/components/search';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { BooleanSearchTypeEnum } from '@client/shared/enums';
import {
  getLocationValue,
  getBooleanLocationValueWithTitle
} from '@client/shared/utils';

@Component({
  selector: 'its-boolean-search-textarea',
  templateUrl: './boolean-search-textarea.component.html',
  styleUrls: ['./boolean-search-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class BooleanSearchTextareaComponent<T>
  extends BaseControlComponent<string>
  implements OnInit
{
  categoriesMap$: Observable<{ [key: number]: string }> = this.valuesService
    .getValues()
    .pipe(pluck('categoriesMap'));

  @ViewChild('textarea') textarea: ElementRef;
  readonly destroyed$: Observable<unknown>;

  booleanSearch = '';
  @Input() type: BooleanSearchTypeEnum = BooleanSearchTypeEnum.Categories;
  @Input() placeholder: string;
  @Input() maxConditions: number;
  @Input() rows = 1;
  @Input() trimValue = true;
  @Input() clear = false;
  readonly control = new FormControl(null);
  readonly textAreaControl = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null,
    readonly modalService: ModalService,
    readonly localeService: LocaleService,
    readonly valuesService: ValuesService
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.textAreaControl.markAsTouched();
        this.control.markAsTouched();
        this.control.updateValueAndValidity();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;
        this.textAreaControl.setErrors(errors);
        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });

    if (this.type === BooleanSearchTypeEnum.Categories) {
      this.listenCategoryChange();
    }

    if (this.type === BooleanSearchTypeEnum.Location) {
      this.listenLocationChange();
    }
  }

  listenCategoryChange() {
    combineLatest([
      this.ctrl.control?.valueChanges.pipe(startWith(this.ctrl.value)),
      this.categoriesMap$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([value, categoriesMap]) => {
        if (categoriesMap) {
          const booleanSearch = getResultCategoriesBooleanConditions(
            value,
            categoriesMap
          );
          this.textAreaControl.setValue(booleanSearch);
        }
      });
  }

  listenLocationChange() {
    this.ctrl.control?.valueChanges
      .pipe(startWith(this.ctrl.value))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const booleanSearch = this.getResultLocationBooleanConditions(value);
        this.textAreaControl.setValue(booleanSearch);
      });
  }

  getResultLocationBooleanConditions(value: string) {
    const incl = getIncludeLocation(value);
    const excl = getExcludeLocation(value);

    const inclValue = getBooleanLocationValueWithTitle(
      incl,
      this.localeService.getInstant('booleanSearch.text.include')
    );
    const exclValue = getBooleanLocationValueWithTitle(
      excl,
      this.localeService.getInstant('booleanSearch.text.exclude')
    );

    return inclValue && exclValue
      ? `${inclValue}\n${exclValue}`
      : inclValue || exclValue;
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    if (disabled) {
      this.textAreaControl.disable({ emitEvent: false });
    } else {
      this.textAreaControl.enable({ emitEvent: false });
    }
    this.changeDetector.markForCheck();
  }

  clearValue() {
    this.textAreaControl.setValue('');
    this.textAreaControl.markAsTouched();
    this.textAreaControl.markAsDirty();
    this.textAreaControl.updateValueAndValidity();
    this.control.setValue('');
  }

  openSearch(ev: any) {
    if (this.control.disabled) {
      return;
    }
    const modals = {
      [BooleanSearchTypeEnum.Categories]: ModalBooleanSearchCategoriesComponent,
      [BooleanSearchTypeEnum.Location]: ModalBooleanSearchLocationComponent
    };
    ev.preventDefault();
    ev.stopPropagation();
    this.modalService.openComponent(
      modals[this.type],
      (value) => {
        if (typeof value === 'string') {
          this.control.setValue(value);
        }
        this.textarea.nativeElement.blur();
        this.control.updateValueAndValidity();
      },
      {
        width: '780px',
        data: {
          value: this.control.value,
          maxConditions: this.maxConditions
        }
      }
    );
  }
}
