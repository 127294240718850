import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfirmOptions } from '@core/services';

@Component({
  selector: 'its-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {
  answer = false;

  constructor(
    readonly dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOptions
  ) {
    this.dialogRef.beforeClosed().subscribe(() => {
      this.dialogRef.close(this.answer);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.answer);
  }

  confirm() {
    this.answer = true;
    this.onNoClick();
  }
}
