import { Injectable } from '@angular/core';

import { map, withLatestFrom } from 'rxjs/operators';

import { IMessageRequest, IMessageResponse } from '@modules/chat/interfaces';

import { AuthService } from '@core/auth';
import { SocketService } from '@core/services';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import { MessageFactory } from './message.factory';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseHttpService {
  constructor(
    readonly factory: MessageFactory,
    readonly authService: AuthService,
    readonly socketService: SocketService
  ) {
    super('chat');
  }

  getChatMessages(chatId: number, params: IQuery, prev: boolean) {
    return this.get<ICountResponse<IMessageResponse[]>>(
      params,
      `${chatId}/messages`
    ).pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([{ content }, profile]) => ({
        data: (prev ? content.data : content.data.reverse()).map((m) =>
          this.factory.fromResponse(m, profile.id)
        ),
        count: content.count
      }))
    );
  }

  readShatMessages() {
    return this.get<IMessageResponse[]>({}, 'new-messages').pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([{ content }, profile]) =>
        content.map((m) => this.factory.fromResponse(m, profile.id))
      )
    );
  }

  sendMessage(chatId: number, message: IMessageRequest) {
    return this.post<IMessageRequest>(message, {}, `${chatId}/messages`);
  }

  getNewMessage() {
    return this.socketService.getMessage().pipe(
      withLatestFrom(this.authService.getProfile()),
      map(([message, profile]) => {
        return this.factory.fromResponse(message, profile.id);
      })
    );
  }
}
