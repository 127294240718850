import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validator,
  ControlValueAccessor
} from '@angular/forms';

import { Observable, identity } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Memoise } from '@client/shared/decorators';

import { IDestroyable } from '../../lifecycle';
import { BaseControl } from '../base';

export abstract class BaseControlComponent<T, R = T>
  extends BaseControl<T>
  implements Validator, IDestroyable, ControlValueAccessor
{
  readonly destroyed$: Observable<unknown>;
  abstract readonly control: FormControl;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value?: T | R | null) => void;
  protected onTouched?: () => void;

  protected readonly modelToViewFormatter: (value: T | null) => T | R | null =
    identity;
  protected readonly viewToModelParser: (value: R | null) => T | R | null =
    identity;

  get value(): T {
    return this.control.value;
  }

  writeValue(value: T | null): void {
    this.control.setValue(this.modelToViewFormatter(value), {
      emitEvent: false
    });
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChanged?.(this.viewToModelParser(value));
        this.onTouched?.();
      });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : control.errors;
  }

  get isRequired() {
    const { validator } = this.control;
    if (validator) {
      const validation = validator(new FormControl());
      return validation && validation.required;
    }
    return false;
  }
}
