import { Injectable } from '@angular/core';

import { of, Subject } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private requests = 0;
  private loader$ = new Subject<boolean>();

  setLoader(show: boolean) {
    if (show) {
      ++this.requests;
    } else {
      --this.requests;
    }
    of(null)
      .pipe(take(1), delay(50))
      .subscribe(() => {
        this.loader$.next(this.requests > 0);
      });
  }

  getLoader() {
    return this.loader$.asObservable();
  }
}
