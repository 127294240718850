<mat-card *ngIf="values$ | async as values">
  <mat-card-content>
    <h3 class="hot-title">
      <its-icon class="m-r_8" svgIcon="interests"></its-icon>
      <span>{{ 'hotJobs.widget.title' | translate }}</span>
    </h3>
    <ul class="hot-items">
      <li *ngFor="let job of jobs">
        <a
          class="t-d_n"
          [routerLink]="['/', ROUTES_DATA.JOBS.url, job.id]"
          [queryParams]="{ j_view: JobViewTypeEnum.FromHot }"
        >
          <b class="a-i_b">
            <its-icon
              *ngIf="job.hot"
              class="hot"
              svgIcon="hot"
              size="16"
            ></its-icon
            ><span>{{ job.title }}&nbsp;</span
            ><span class="salary" *ngIf="job?.salary">{{ job?.salary }}</span>
          </b>
        </a>
        <span class="hot-items__data">
          <a
            *ngIf="job.company.logo"
            class="t-d_n a-i_c"
            routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{ job.company.slug }}"
          >
            <img
              class="hot-items__data-logo"
              [src]="job.company.logo"
              [alt]="job.company.name"
            />
          </a>
          <ng-container *ngIf="job.cities"
            ><ng-container>
              <span>
                {{ job.cities }}
              </span></ng-container
            >
            <ng-container *ngIf="job.workTypes?.length">
              <ng-container
                *ngTemplateOutlet="separator"
              ></ng-container></ng-container
          ></ng-container>
          <ng-container *ngFor="let type of job.workTypes; let l = last">
            <span>{{ values.workTypesTranslate[type.value] | translate }}</span
            ><ng-container *ngIf="!l">
              <ng-container *ngTemplateOutlet="separator"></ng-container>
            </ng-container>
          </ng-container>
        </span>
      </li>
    </ul>
  </mat-card-content>
</mat-card>

<ng-template #separator>
  <span class="separator"></span>
</ng-template>
