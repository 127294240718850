import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';

import { ICompany } from '@modules/companies/interfaces';
import { CompaniesService } from '@modules/companies/services';

import { clickAllowed } from '@common/shared/helpers';

import { ROUTES_DATA } from '@client/shared/constants';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-item-company',
  templateUrl: './item-company.component.html',
  styleUrls: ['./item-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemCompanyComponent {
  ROUTES_DATA = ROUTES_DATA;
  @Input() values: IValues = {} as IValues;
  @Input() company: ICompany;
  constructor(
    readonly companiesService: CompaniesService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  toggleFollow(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (clickAllowed(ev)) {
      const follow = !this.company.isFollow;
      this.companiesService
        .followCompanyStatus(follow, this.company.id)
        .subscribe(() => {
          this.company.isFollow = follow;
          this.changeDetector.markForCheck();
        });
    }
  }
}
