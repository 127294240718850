<a
  class="link-empty"
  routerLink="/{{ ROUTES_DATA.JOBS.url }}/{{ job.id }}"
  [queryParams]="{
    j_view: job?.hot ? JobViewTypeEnum.FromHot : JobViewTypeEnum.FromList
  }"
>
  <mat-card class="job-item card-hover grey-category">
    <div class="mob-published j-c_f-e">
      <small>{{ job.published | ago }}</small>
    </div>
    <div class="job-item__header">
      <h3 class="job-item__title a-i_b">
        <its-icon
          *ngIf="job.hot"
          class="icon-hot"
          size="24"
          svgIcon="hot"
        ></its-icon>
        <span class="f-w_e-b">
          <span class="highlight-hover" [class.m-l_8]="job.hot">{{
            job.title
          }}</span
          ><span class="salary" *ngIf="job?.salary && job?.showSalary"
            >&nbsp;{{ job?.salary }}</span
          >
        </span>
        <div
          class="bonus"
          *ngIf="job?.bonus"
          matTooltip="{{ 'itemJob.tooltip.bonus' | translate }}"
          matTooltipPosition="above"
        >
          <its-icon size="16" svgIcon="bonus"></its-icon>
        </div>

        <span *ngIf="job.domainsValue" class="job-item__domains">
          ({{ job.domainsValue }})
        </span>
      </h3>
      <h4 class="m-b_0 m-l_a" *ngIf="job?.company">
        <a
          class="a-i_c w_f-c"
          (click)="$event.stopPropagation()"
          routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{ job.company.slug }}"
        >
          <b class="job-item__company-name">{{ job.company.name }}</b>
          <img
            *ngIf="job.company.logo"
            class="job-item__company-logo"
            [src]="job.company.logo"
            [alt]="job.company.name"
          />
        </a>
      </h4>
    </div>
    <mat-card-content class="m-b_0">
      <its-categories [categories]="job.categories"></its-categories>

      <div
        class="job-item__info c-default-white"
        *ngIf="values$ | async as values"
      >
        <div>
          <div>
            {{ 'itemJob.label.experience' | translate }} -
            <b>{{ values.experiencesMap[job.experience] }}</b>
          </div>
          <div>
            {{ 'itemJob.label.englishLevel' | translate }} -
            <b>{{
              values.englishMap[job.english] ||
                ('itemJob.label.englishNotRequired' | translate)
            }}</b>
          </div>

          <div *ngIf="job.languagesValue">
            {{ 'itemJob.label.languagesToKnow' | translate }} -
            <b>{{ job.languagesValue }}</b>
          </div>
        </div>
        <div>
          <div class="mode-block">
            <its-icon
              *ngIf="
                job.cities || job.workTypesMap[WorkTypeGroupEnum.Place]?.length
              "
              size="20"
              svgIcon="place"
            ></its-icon>
            <div>
              <ng-container *ngIf="job.cities">
                <span>{{ job.cities }}</span>
              </ng-container>
              <ng-container
                *ngIf="
                  job.cities &&
                  job.workTypesMap[WorkTypeGroupEnum.Place]?.length
                "
                >&nbsp;/&nbsp;
              </ng-container>
              <ng-container
                *ngFor="
                  let type of job.workTypesMap[WorkTypeGroupEnum.Place];
                  let l = last
                "
              >
                <span
                  >{{ values.workTypesTranslate[type.value] | translate
                  }}<ng-container
                    *ngIf="
                      type.value === WorkTypesEnum.Remote && job.userLocation
                    "
                    >&nbsp;({{ job.userLocationValue }})</ng-container
                  ></span
                >{{ l ? '' : '&nbsp;/&nbsp;' }}
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="job.workTypesMap[WorkTypeGroupEnum.Time]?.length"
            class="mode-block"
          >
            <its-icon svgIcon="time"></its-icon>
            <div>
              <ng-container
                *ngFor="
                  let type of job.workTypesMap[WorkTypeGroupEnum.Time];
                  let l = last
                "
              >
                <span>{{
                  values.workTypesTranslate[type.value] | translate
                }}</span
                >{{ l ? '' : '&nbsp;/&nbsp;' }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="job-item__stats">
        <its-job-short-stats [job]="job" [small]="true"></its-job-short-stats>
        <div class="job-item__date">
          <small class="desk-published">{{ job.published | ago }}</small>
          <its-button
            itsNeedAuth
            variant="icon"
            color="{{ job.isFollow ? 'accent' : '' }}"
            (click)="toggleFollow($event, job)"
            [matTooltip]="
              job.isFollow
                ? ('common.tooltip.removeFavorites' | translate)
                : ('common.tooltip.addFavorites' | translate)
            "
            matTooltipPosition="above"
          >
            <its-icon
              svgIcon="{{ job.isFollow ? 'star' : 'star_border' }}"
            ></its-icon>
          </its-button>
        </div>
      </div>

      <div class="action-label">
        <ng-container *ngFor="let req of job.requests">
          <div
            class="action-label__item"
            [ngClass]="{
              'bg-status-green': req.status === InviteStatusEnum.Approve,
              'bg-status-yellow': req.status === InviteStatusEnum.Pending,
              'bg-status-black': req.status === InviteStatusEnum.Canceled
            }"
          >
            {{ 'itemJob.text.yourRequest' | translate }}
            &nbsp;-&nbsp;
            <its-status [inviteStatus]="req.status"></its-status>
          </div>
        </ng-container>
        <ng-container *ngFor="let inv of job.invites">
          <div
            class="action-label__item"
            [ngClass]="{
              'bg-status-green': inv.status === InviteStatusEnum.Approve,
              'bg-status-yellow': inv.status === InviteStatusEnum.Pending,
              'bg-status-black': inv.status === InviteStatusEnum.Canceled
            }"
          >
            {{ 'itemJob.text.yourInvite' | translate }}
            &nbsp;-&nbsp;
            <its-status [inviteStatus]="inv.status"></its-status>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</a>
