<div
  class="req-inv-state"
  *ngIf="reqInvStateMap$ | async as reqInvStateMap"
  (click)="openModal($event)"
  [matTooltip]="request?.stateDescription || invite?.stateDescription"
  matTooltipPosition="above"
>
  <span
    [title]="reqInvStateMap[request?.state] || reqInvStateMap[invite?.state]"
    class="t-o_e"
    >{{
      reqInvStateMap[request?.state] ||
        reqInvStateMap[invite?.state] ||
        ('reqInvState.button.addState' | translate)
    }}
  </span>
  <its-icon
    svgIcon="{{
      edit ? (!request?.state && !invite?.state ? 'plus' : 'pencil') : 'history'
    }}"
    size="12"
  ></its-icon>
</div>
