import { IChatResponse } from '@modules/chat/interfaces';
import { IJob } from '@modules/jobs/interfaces';
import { IResume } from '@modules/resumes/interfaces';

import { IUserEntity } from '@core/auth';

import { InviteStatusEnum, ReqInvStateEnum } from '@common/shared/enums';
import { IReqInvState } from '@common/shared/interfaces';

interface ICommonJobInvite {
  id?: number;
  status: InviteStatusEnum;
  user: IUserEntity;
  resume: IResume;
  job: IJob;
  created: Date;
  reason?: number;
  chat?: IChatResponse;
  jobOwner?: boolean;
  userArchived?: boolean;
  companyArchived?: boolean;
  priority?: number;
  selected?: boolean;
  state?: ReqInvStateEnum;
  stateDescription?: string;
  stateHistory?: IReqInvState[];
}
export interface IJobInvite extends ICommonJobInvite {
  isAuthor?: boolean;
}

export interface IJobInviteRequest {
  jobs: string[];
  message?: string;
}

export interface IJobInviteResponse extends ICommonJobInvite {}

export interface IJobInviteAnswer {
  status?: InviteStatusEnum;
  message?: string;
  reason?: number;
}
