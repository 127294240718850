<ng-container *ngIf="inviteStatus">
  <ng-container [ngSwitch]="inviteStatus">
    <span class="c-yellow-active" *ngSwitchCase="InviteStatusEnum.Pending">{{
      'common.status.pending' | translate
    }}</span>
    <span class="c-green-active" *ngSwitchCase="InviteStatusEnum.Approve">{{
      'common.status.approve' | translate
    }}</span>
    <span *ngSwitchCase="InviteStatusEnum.Canceled">{{
      'common.status.canceled' | translate
    }}</span>
  </ng-container>
</ng-container>
<ng-container *ngIf="activeStatus">
  <ng-container [ngSwitch]="activeStatus">
    <span class="c-green-active" *ngSwitchCase="ActiveStatusEnum.Active">{{
      'common.status.active' | translate
    }}</span>
    <span class="c-red-active" *ngSwitchCase="ActiveStatusEnum.Inactive">{{
      'common.status.inactive' | translate
    }}</span>
    <span *ngSwitchCase="ActiveStatusEnum.Deleted">{{
      'common.status.deleted' | translate
    }}</span>
    <span class="c-yellow-active" *ngSwitchCase="ActiveStatusEnum.Pending">{{
      'common.status.pending' | translate
    }}</span>
  </ng-container>
</ng-container>
<ng-container *ngIf="jobStatus !== undefined">
  <ng-container [ngSwitch]="jobStatus">
    <span
      class="c-green-active"
      [class.bg-status-green]="bg"
      *ngSwitchCase="JobStatusEnum.Active"
      >{{ 'common.status.active' | translate }}</span
    >
    <span
      class="c-red-active"
      [class.bg-status-red]="bg"
      *ngSwitchCase="JobStatusEnum.Inactive"
      >{{ 'common.status.inactive' | translate }}</span
    >
    <span
      class="c-yellow-active"
      [class.bg-status-yellow]="bg"
      *ngSwitchCase="JobStatusEnum.Pending"
      >{{ 'jobStatus.status.pending' | translate }}</span
    >
    <span
      class="c-violet-active"
      [class.bg-status-violet]="bg"
      *ngSwitchCase="JobStatusEnum.Paused"
      >{{ 'common.status.paused' | translate }}</span
    >
    <span [class.bg-status-black]="bg" *ngSwitchCase="JobStatusEnum.Done">{{
      'common.status.closed' | translate
    }}</span>
  </ng-container>
</ng-container>
<ng-container *ngIf="invoiceStatus">
  <ng-container [ngSwitch]="invoiceStatus">
    <span class="c-green-active" *ngSwitchCase="InvoiceStatusEnum.Success">{{
      'companyOrders.status.payed' | translate
    }}</span>
    <span
      class="c-yellow-active"
      *ngSwitchCase="InvoiceStatusEnum.InvoiceWait"
      >{{ 'companyOrders.status.wait' | translate }}</span
    >
  </ng-container>
</ng-container>
<ng-container *ngIf="planStatus">
  <ng-container [ngSwitch]="planStatus">
    <span class="c-yellow-active" *ngSwitchCase="PlanStatusEnum.Pending">{{
      'common.planStatus.pending' | translate
    }}</span>
    <span class="c-green-active" *ngSwitchCase="PlanStatusEnum.Active">{{
      'common.planStatus.active' | translate
    }}</span>
    <span *ngSwitchCase="PlanStatusEnum.Canceled">{{
      'common.planStatus.canceled' | translate
    }}</span>
    <a
      [routerLink]="
        '/' + ROUTES_DATA.COMPANIES.children.STATISTIC.children.ORDERS.url
      "
      class="c-red-active t-d_n"
      *ngSwitchCase="PlanStatusEnum.WaitPayment"
      >{{ 'common.planStatus.waitPayment' | translate }}</a
    >
  </ng-container>
</ng-container>
