import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService implements OnDestroy {
  // NOT used yet
  readonly destroyed$ = new Subject<boolean>();

  constructor(
    readonly titleService: Title,
    readonly route: ActivatedRoute,
    readonly router: Router
  ) {
    /*this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.route.firstChild?.snapshot.data;
        })
      )
      .subscribe((data) => {
        // this.titleService.setTitle(data?.title);
      });*/
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
