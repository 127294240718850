import {
  Directive, ElementRef,
  HostListener, Input, Renderer2,
} from '@angular/core';
import { ChatService, MessageService } from '@modules/chat/services';
import { IChat } from '@modules/chat/interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { Destroyable } from '@client/shared/abstracts';
import { IUserEntity } from '@core/auth';

@Directive({
  selector: '[itsChatDrawer]'
})
export class ChatDrawerDirective extends Destroyable {
  _itsChatDrawer: IChat;
  @Input() recipient: IUserEntity = null;
  @Input() set itsChatDrawer(value: IChat) {
    this._itsChatDrawer = {
      ...value,
      recipient: this.recipient
    };
    if (value?.newMessagesCount) {
      this.renderer.addClass(this.elementRef.nativeElement, 'chat-counter');
      this.renderer.setAttribute(this.elementRef.nativeElement, 'data-counter', `${value.newMessagesCount}`);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'chat-counter');
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'data-counter');
    }
  };

  get itsChatDrawer() {
    return this._itsChatDrawer;
  }

  constructor(
    readonly chatService: ChatService,
    readonly elementRef: ElementRef,
    readonly messageService: MessageService,
    readonly renderer: Renderer2
  ) {
    super();
    this.messageService
    .getNewMessage()
    .pipe(
      takeUntil(this.destroyed$),
      filter(m => !m.owner && m?.chat?.id === this.itsChatDrawer?.id)
    )
    .subscribe(() => {
      this.setChatCount(this.itsChatDrawer.newMessagesCount + 1);
    });
  }

  @HostListener('click')
  show() {
    if (this.itsChatDrawer) {
      this.setChatCount();
      this.chatService.openChat(this.itsChatDrawer);
    }
  }

  setChatCount(count = 0) {
    this.itsChatDrawer = {
      ...this.itsChatDrawer,
      newMessagesCount: count
    };
  }
}
