import { StatusCodes } from 'http-status-codes/build/es';
import * as moment from 'moment';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';

import { AuthService } from '@core/auth';
import { GlobalObjectService, SeoService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import {
  AuthQueryEnum,
  StickerAnimationEnum,
  StickerNameEnum
} from '@client/shared/enums';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  notConfirmed = false;
  showMissSticker = false;
  StickerAnimationEnum = StickerAnimationEnum;
  StickerNameEnum = StickerNameEnum;
  constructor(
    readonly fb: FormBuilder,
    readonly authService: AuthService,
    readonly changeDetectorRef: ChangeDetectorRef,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly globalObjectService: GlobalObjectService,
    readonly seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      username: new FormControl(
        '',
        Validators.compose([
          Validators.email,
          Validators.required,
          CustomValidators.plusInEmail
        ])
      ),
      password: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  send() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      this.notConfirmed = false;
      return this.authService.signIn(this.form.value).subscribe(
        (lastActivity) => {
          if (
            this.globalObjectService.isDesktop() &&
            moment().diff(moment(lastActivity), 'days') >= 30
          ) {
            this.showMissSticker = true;
            this.changeDetectorRef.markForCheck();
            setTimeout(() => {
              this.authNavigate(null);
            }, 3000);
          } else {
            this.authNavigate(null);
          }
        },
        (err) => {
          this.notConfirmed = err.error?.statusCode === StatusCodes.FORBIDDEN;
          if (err.error?.statusCode !== StatusCodes.FORBIDDEN) {
            throw err;
          }
          this.changeDetectorRef.markForCheck();
        }
      );
    }
  }

  goTpSignUp() {
    this.authNavigate(AuthQueryEnum.SignUp);
  }

  goToResendConfirm() {
    this.authNavigate(AuthQueryEnum.ResendConfirm);
  }

  goTpForgotPassword() {
    this.authNavigate(AuthQueryEnum.ForgotPass);
  }

  authNavigate(route: AuthQueryEnum) {
    this.authService.toAuth(route);
  }

  googleSignIn() {
    this.authService.googleSignIn();
  }
}
