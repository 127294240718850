import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IProfileResponse, AuthService } from '@core/auth';
import { LocaleService, NotificationService } from '@core/services';

import { ApplicationOptionEnum } from '@common/shared/enums';
import {
  IQuery,
  ICountResponse,
  ICompanyInfoCounts
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';

import { ICompanyResponse, ICompanyRequest, IFaq } from '../interfaces';
import { CompaniesFactory } from './companies.factory';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseHttpService {
  constructor(
    readonly factory: CompaniesFactory,
    readonly authService: AuthService,
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService
  ) {
    super('companies');
  }

  getCompanies(params: IQuery) {
    return this.get<ICountResponse<ICompanyResponse[]>>(params).pipe(
      map(({ content }) => ({
        data: content.data.map((com) => this.factory.fromResponse(com)),
        count: content.count
      }))
    );
  }

  getCompany(slug: string) {
    return this.get<ICompanyResponse>({}, slug).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  getCompanyOverview(slug: string) {
    return this.get<ICompanyResponse>({}, `${slug}/overview`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  getCompanyInfoCounts(slug: string) {
    return this.get<ICompanyInfoCounts>({}, `${slug}/info-counts`).pipe(
      map(({ content }) => content)
    );
  }

  getFaqs(slug: string) {
    return this.get<IFaq[]>({}, `${slug}/faqs`).pipe(
      map(({ content }) => content)
    );
  }

  getCompanyFaqs() {
    return this.get<IFaq[]>({}, `faqs`).pipe(map(({ content }) => content));
  }

  saveFaqs(id: number, faqs: IFaq[]) {
    return this.post<{ faqs: IFaq[] }>({ faqs }, {}, `${id}/faqs`).pipe(
      map(({ content }) => content)
    );
  }

  createCompany(company: ICompanyRequest) {
    const request = this.factory.toRequest(company);

    return this.post<ICompanyRequest, IProfileResponse>(request).pipe(
      tap(({ content }) => this.authService.setProfile(content))
    );
  }

  updateCompany(company: ICompanyRequest, id: number) {
    const request = this.factory.toRequest(company);

    return this.put<ICompanyRequest, IProfileResponse>(request, {}, id).pipe(
      tap(({ content }) => this.authService.setProfile(content))
    );
  }

  deleteCompany(id: number) {
    return this.delete({}, id);
  }

  getFollowedCompanies(params: IQuery) {
    return this.get<ICountResponse<ICompanyResponse[]>>(
      params,
      'followed'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  followCompanyStatus(follow: boolean, id: number) {
    const body = { follow };
    return this.post(body, { [NO_LOADER_PARAM]: true }, `${id}/follow`);
  }

  getFollowersCount() {
    return this.get<number>(
      { [NO_LOADER_PARAM]: true },
      `followers-count`
    ).pipe(map(({ content }) => content));
  }

  reactivateCompany(id: number) {
    return this.put(null, {}, `reactivate/${id}`).pipe(
      switchMap(() => this.authService.loadProfile()),
      tap(() => {
        this.notificationService.showSuccess(
          this.localeService.getInstant(_('company.notification.reactivated'))
        );
      })
    );
  }

  removeBanner(id: number) {
    return this.patch(null, {}, `${id}/remove-banner`);
  }

  changeApplicationOption(body: { applicationOption: ApplicationOptionEnum }) {
    return this.patch(body, {}, 'application-option');
  }
}
