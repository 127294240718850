import {
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { ISnackbar } from '@client/shared/interfaces';
import { SnackbarTypeEnum } from '@client/shared/enums';

@Component({
  selector: 'its-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent {
  SnackbarTypeEnum = SnackbarTypeEnum;
  constructor(@Inject(MAT_SNACK_BAR_DATA) readonly data: ISnackbar) {}

  dismiss() {
    this.data.close();
  }
}
