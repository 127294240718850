import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, tap } from 'rxjs';

import { AuthService, IProfile } from '@core/auth';
import { GlobalObjectService, LocaleService, SeoService } from '@core/services';

import {
  HOT_VACANCY_DURATION_D,
  JOBS_COUNT,
  VACANCY_DURATION_D
} from '@common/shared/constants';
import {
  CurrencyEnum,
  PlanPeriodEnum,
  PlanTypeEnum
} from '@common/shared/enums';

import { ROUTES_DATA } from '@client/shared/constants';

@Component({
  selector: 'our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurServicesComponent implements OnInit {
  VACANCY_DURATION_D = VACANCY_DURATION_D;
  HOT_VACANCY_DURATION_D = HOT_VACANCY_DURATION_D;
  ROUTES_DATA = ROUTES_DATA;
  jobsCount: any[] = JOBS_COUNT;
  currency = CurrencyEnum.USD;
  havePlan = false;
  profile$: Observable<IProfile> = this.authService.getProfile().pipe(
    tap((profile) => {
      this.havePlan = !!profile?.company?.plan || profile?.company?.trialUsed;
    })
  );
  constructor(
    readonly seoService: SeoService,
    readonly authService: AuthService,
    readonly router: Router,
    readonly localeService: LocaleService,
    readonly globalObjectService: GlobalObjectService
  ) {}

  ngOnInit() {
    this.seoService.updateMetaData({
      windowTitle: this.localeService.getInstant(_('windowTitle.pricing')),
      ogTitle: this.localeService.getInstant(_('seo.pricing.title')),
      ogUrl: this.globalObjectService.buildUrl(ROUTES_DATA.PRICING.url),
      description: this.localeService.getInstant(_('seo.pricing.description')),
      keywords: this.localeService.getInstant(_('seo.pricing.keywords'))
    });
  }

  planSelected(data: { planType: PlanTypeEnum; period: PlanPeriodEnum }) {
    this.router.navigate(['/' + ROUTES_DATA.SETTINGS.children.PRICING.url], {
      queryParams: data
    });
  }
}
