import { io, Socket } from 'socket.io-client';

import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { IMessageResponse } from '@modules/chat/interfaces';

import { getCookie } from '@common/shared/helpers';

import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  message$ = new Subject<IMessageResponse>();
  socket!: Socket;
  constructor() {
    /* setInterval(() => {
       this.setMessage(`{"message":"Send","files":[],"chat":{"lastMessage":"hi","id":"8c5592df-9bbd-4c4c-bf62-841f30f6f6de","created":"2021-09-20T11:12:06.842Z","updated":"2021-10-07T17:25:49.000Z","enabled":true,"expired":null},"user":{"avatar":"","id":"9201890f-d45e-4851-b384-4a59f5894c8b","created":"2021-09-20T10:06:32.351Z","updated":"2021-10-07T17:26:15.000Z","status":1,"firstName":"Roman","lastName":"Lisnyi","isAdmin":false,"lastActivity":"2021-10-07T20:26:16.000Z"},"reason":null,"id":"c1cfa9e0-cea8-40ce-b2a3-056fc0205687","created":"2021-10-07T17:26:29.421Z","updated":"2021-10-07T17:26:29.421Z","new":true}`);
     }, 500);*/
  }

  connectSocket() {
    this.socket = io(`${environment.serverUrl}/messenger`, {
      extraHeaders: {
        Authorization: `Bearer ${getCookie(environment.authKey)}`
      }
    });

    this.socket.on('connect', () => {
      console.log('Connected to WS server');
    });

    this.socket.on('new_message', (message) => {
      this.setMessage(message);
    });

    this.socket.on('disconnect', (resp) => {
      console.log('disconnect', resp);
    });
  }

  sendMessage() {
    this.socket.emit('send_message', { sender: 4343, message: 'Hi' });
  }

  readMessages(ids: number[]) {
    this.socket.emit('read_message', ids);
  }

  setMessage(message: string) {
    this.message$.next(JSON.parse(message));
  }

  getMessage() {
    return this.message$.asObservable();
  }

  disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
