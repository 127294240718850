<form *ngIf="form" class="modal" [formGroup]="form" (ngSubmit)="save()">
  <h1 mat-dialog-title>{{ 'atsJodId.modal.title' | translate }}</h1>
  <div mat-dialog-content>
    <div class="m-b_24">
      <p>{{ 'atsJodId.text.info' | translate }}</p>
    </div>
    <div class="m-b_24">
      <its-input
        formControlName="id"
        type="number"
        placeholder="{{ 'atsJodId.placeholder.jobId' | translate }}"
      ></its-input>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.form.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary" [disabled]="form.invalid">
      {{ 'common.form.button.save' | translate }}
    </its-button>
  </div>
</form>
