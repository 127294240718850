import { TranslatePipe } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';

import { GlobalObjectService } from '@core/services';

@Pipe({
  name: 'translateTODO'
})
export class ITSTranslatePipe implements PipeTransform {
  constructor(
    readonly globalObjectService: GlobalObjectService,
    readonly changeDetector: ChangeDetectorRef // public translateService: TranslateService
  ) {}

  transform(
    query: string,
    data: {
      pluralization?: { [key: string]: string };
      textOrigin?: string;
    } = null
  ): string {
    return '';
    // return new TranslatePipe(this.translateService, this.changeDetector)
    //   .transform(query, data?.pluralization ? JSON.stringify(data.pluralization) : '') || data?.textOrigin || query;
  }
}
