import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Directive,
  Inject,
  inject,
  InjectionToken,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

export const IS_PLATFORM_BROWSER = new InjectionToken<boolean>('Is server?', {
  factory() {
    return isPlatformBrowser(inject(PLATFORM_ID));
  }
});

@Directive({
  selector: '[ifIsServer]'
})
export class IfIsServerDirective {
  constructor(
    @Inject(IS_PLATFORM_BROWSER) isBrowser: boolean,
    readonly templateRef: TemplateRef<any>,
    readonly viewContainer: ViewContainerRef
  ) {
    if (!isBrowser) {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}

@Directive({
  selector: '[ifIsBrowser]'
})
export class IfIsBrowserDirective {
  constructor(
    @Inject(IS_PLATFORM_BROWSER) isBrowser: boolean,
    readonly templateRef: TemplateRef<any>,
    readonly viewContainer: ViewContainerRef
  ) {
    if (isBrowser) {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}
