<div>
  <div class="j-c_s-b a-i_c" *ngIf="data.close">
    <div class="snackbar__title">
      <ng-container [ngSwitch]="data.type">
        <ng-container *ngSwitchCase="SnackbarTypeEnum.Error">
          <its-icon svgIcon="alert" size="25"></its-icon>
          <span>{{'snackbar.title.whoops' | translate}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="SnackbarTypeEnum.Success">
          <its-icon svgIcon="success" size="25"></its-icon>
          <span>{{'snackbar.title.success' | translate}}</span>
        </ng-container>
        <ng-container *ngSwitchCase="SnackbarTypeEnum.Message">
          <its-icon svgIcon="attention" size="25"></its-icon>
          <span>{{'snackbar.title.attention' | translate}}</span>
        </ng-container>
      </ng-container>
    </div>

    <its-button variant="icon" (click)="dismiss()">
      <its-icon size="15" svgIcon="clear-thicker"></its-icon>
    </its-button>
  </div>
  <div class="snackbar__description">{{data.message}}</div>
</div>
