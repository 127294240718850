<ul class="images-items">
  <ng-container *ngFor="let image of images; let i = index">
    <li (click)="openPhoto(i)">
      <img [src]="image.path" alt="" />
    </li>
  </ng-container>
</ul>

<div *ngIf="open || previewImage" class="image-preview" (click)="blockClick($event)">
  <its-button
    *ngIf="images.length > 1"
    class="prev-btn"
    (click)="prevPhoto()"
    variant="icon-fab"
    [attr.data-count]="currentIndex + 1 + '/' + images.length"
  >
    <its-icon svgIcon="chevron_left"></its-icon>
  </its-button>

  <div class="image-preview__content">
    <img
      [src]="images[currentIndex]?.originPath || previewImage?.originPath"
      alt=""
    />
  </div>

  <its-button
    *ngIf="images.length > 1"
    class="next-btn"
    (click)="nextPhoto()"
    variant="icon-fab"
    [attr.data-count]="currentIndex + 1 + '/' + images.length"
  >
    <its-icon svgIcon="chevron_right"></its-icon>
  </its-button>

  <its-button class="close-btn" (click)="closeSlider()" variant="icon-fab">
    <its-icon size="18" svgIcon="clear"></its-icon>
  </its-button>
</div>
