import { environment } from '@env';
import { AWSMediaPathEnum } from '@common/shared/enums';

export function getAvatarPath(path: string) {
  if (path) {
    if (path.startsWith('http')) {
      return path;
    } else {
      return `${environment.storageUrl}/${AWSMediaPathEnum.UserAvatars}/${path}`;
    }
  }
  return '';
}
