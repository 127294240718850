import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { StateOptionEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const STATE_OPTIONS: ISelectOption[] = [
  {
    id: StateOptionEnum.Current,
    value: _('filterStateOption.current')
  },
  {
    id: StateOptionEnum.Archived,
    value: _('filterStateOption.archived')
  }
];
