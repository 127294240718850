import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'its-cut-text',
  templateUrl: './cut-text.component.html',
  styleUrls: ['./cut-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CutTextComponent {
  showedText = '';
  allText = '';
  showMore = false;
  @Input() count: number;

  @Input() set text(text: string) {
    if (this.count && text.length > this.count) {
      this.showMore = true;
      this.showedText = text.substr(0, this.count) + '...';
    } else {
      this.showedText = text;
    }
    this.allText = text;
  }

  showAll(ev) {
    ev.preventDefault();
    this.showedText = this.allText;
    this.showMore = false;
  }
}
