import { Injectable } from '@angular/core';

import { IAgentRequest, IAgentResponse, IAgent } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AgentsFactory {
  toRequest(agent: IAgentRequest): IAgentRequest {
    return {
      email: agent.email,
      role: agent.role
      // offices: agent.offices
    };
  }

  fromResponse(agent: IAgentResponse): IAgent {
    return {
      id: agent.id,
      email: agent.email,
      // offices: agent.offices
      created: agent.created,
      role: agent.role,
      user: agent.user,
      status: agent.status,
      company: agent.company
    };
  }
}
