<ng-container *ngIf="file">
  <ng-container *ngIf="chatForm; else standardTemplate">
    <div class="form-file">
      <its-icon size="27" class="file-icon" [svgIcon]="icon"></its-icon>
      <span class="form-file__name">{{ file.name }}</span>
      <button (click)="onRemoveFile()" matRipple class="button-empty">
        <its-icon size="15" svgIcon="clear"></its-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #standardTemplate>
    <div class="a-i_c item-file" [class.edit-active]="active">
      <ng-container *ngIf="file.path; else newFile">
        <a
          [class.disabled]="file.status !== ActiveStatusEnum.Active"
          [href]="file.path"
          (click)="openFile($event)"
          [matTooltip]="
            file.status !== ActiveStatusEnum.Active
              ? ('chatFile.tooltip.notAvailable' | translate)
              : ''
          "
          [matTooltipPosition]="chatOwner ? 'before' : 'after'"
          target="_blank"
          class="a-i_c c-g_8"
        >
          <its-icon class="file-icon" [size]="40" [svgIcon]="icon"></its-icon>
          <span class="item-file__name c-primary">{{ file.originalName }}</span>
        </a>
      </ng-container>
      <ng-template #newFile>
        <div class="a-i_c c-g_8">
          <its-icon class="file-icon" [size]="40" [svgIcon]="icon"></its-icon>
          <span class="item-file__name">{{ file.name }}</span>
        </div>
      </ng-template>
      <its-button
        *ngIf="active"
        matSuffix
        (click)="onRemoveFile()"
        variant="icon"
        color="warn"
      >
        <its-icon svgIcon="delete"></its-icon>
      </its-button>
    </div>
  </ng-template>
</ng-container>
