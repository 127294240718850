import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { GlobalObjectService } from '@core/services';

import { ROUTES_DATA } from '@client/shared/constants';
import { BackTypeEnum } from '@client/shared/enums';

const REDIRECTS = {
  [BackTypeEnum.Company]: `/${ROUTES_DATA.COMPANIES.url}`,
  [BackTypeEnum.Job]: `/${ROUTES_DATA.JOBS.url}`,
  [BackTypeEnum.Resume]: `/${ROUTES_DATA.RESUME.url}`
};

@Component({
  selector: 'its-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackComponent {
  @Input() type: BackTypeEnum;
  isMobile = !this.globalObjectService.isDesktop();
  constructor(
    readonly location: Location,
    readonly router: Router,
    readonly globalObjectService: GlobalObjectService
  ) {}

  back() {
    const stateId =
      this.globalObjectService.getWindow()?.history?.state?.navigationId;
    if (stateId && stateId - 1 > 0) {
      this.location.back();
    } else {
      const url = REDIRECTS[this.type];
      if (url) {
        this.router.navigateByUrl(url);
      } else {
        this.location.back();
      }
    }
  }
}
