import {
  Component,
  OnInit,
  Inject,
  Optional,
  ChangeDetectorRef,
  SkipSelf,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseControlComponent, FormStateDispatcher } from '../../abstracts';
import { Features, AutoCleanupFeature } from '../../decorators';

@Component({
  selector: 'its-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class CheckboxComponent<T extends boolean>
  extends BaseControlComponent<T>
  implements OnInit
{
  readonly destroyed$: Observable<unknown>;
  readonly control = new FormControl(null);

  @Input() bg: 'white' | 'color' | string = 'color';
  @Input() color: ThemePalette = 'accent';
  _showError = false;
  @Input() set showError(value) {
    this._showError = value;
  }
  get showError() {
    return this._showError;
  }
  @Output() readonly changed = new EventEmitter<T>();

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  get checked() {
    return this.value;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;
        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }
}
