<a
  class="link-empty"
  routerLink="/{{ ROUTES_DATA.RESUME.url }}/{{
    resume.id || resume.anonymUuid
  }}"
>
  <mat-card class="resume-item card-hover grey-category">
    <mat-card-content>
      <div class="j-c_s-b a-i_b m-b_8 c-g_16 r-g_8">
        <h3 class="resume-item__position">
          <span class="f-w_e-b highlight-hover">{{ resume.position }}</span>
          <span *ngIf="resume.user?.firstName" class="c-black"
            >&nbsp;-&nbsp;{{ resume.user?.firstName }}</span
          >
        </h3>
        <div class="resume-item__domains" *ngIf="resume.domainsValue">
          ({{ resume.domainsValue }})
        </div>
      </div>
      <mat-card-content class="m-b_0">
        <its-categories [categories]="resume?.categories"></its-categories>
        <div class="resume-item__info c-default-white">
          <div>
            <div>
              <div>
                <ng-container *ngIf="resume.experienceFrom === null; else exp">
                  <b>{{ 'resume.text.noExperience' | translate }}</b>
                </ng-container>
                <ng-template #exp>
                  <span>{{ 'resume.text.experience' | translate }}</span>
                  -
                  <b
                    translate
                    [translateParams]="{ experience: resume.experience }"
                    >resume.value.experience</b
                  >
                </ng-template>
              </div>
            </div>
            <div>
              <span>{{ 'resume.text.englishLevel' | translate }}</span
              >&nbsp;-&nbsp;<b>{{ resume.englishLabel }}</b>
            </div>
            <div *ngIf="resume.languagesValue">
              <span>{{ 'resume.text.additionalLanguages' | translate }}</span
              >&nbsp;-&nbsp;<b>
                {{ resume.languagesValue }}
              </b>
            </div>
          </div>
          <div *ngIf="values$ | async as values">
            <div class="data-item" *ngIf="resume.locationValue">
              <its-icon svgIcon="place"></its-icon>&nbsp;
              <span class="a-i_c">
                <span>{{ resume.locationValue }}</span>
                <ng-container
                  *ngIf="
                    resume.locationValue &&
                    resume.workTypesMap[WorkTypeGroupEnum.Place]?.length
                  "
                  >&nbsp;/&nbsp;</ng-container
                >
                <ng-container
                  *ngFor="
                    let type of resume.workTypesMap[WorkTypeGroupEnum.Place];
                    let l = last
                  "
                >
                  <span>{{
                    values.workTypesTranslate[type.value] | translate
                  }}</span
                  >{{ l ? '' : '&nbsp;/&nbsp;' }}
                </ng-container>
              </span>
            </div>
            <div class="data-item">
              <its-icon svgIcon="time"></its-icon>&nbsp;
              <span class="a-i_c">
                <ng-container
                  *ngIf="resume.workTypesMap[WorkTypeGroupEnum.Time]?.length"
                >
                  <ng-container
                    *ngFor="
                      let type of resume.workTypesMap[WorkTypeGroupEnum.Time];
                      let l = last
                    "
                  >
                    <span>{{
                      values.workTypesTranslate[type.value] | translate
                    }}</span
                    >{{ l ? ',&nbsp;' : '&nbsp;/&nbsp;' }}
                  </ng-container>
                </ng-container>
                <span
                  matTooltip="{{ 'resume.text.readyToWork' | translate }}"
                  >{{
                    values.readyToWorkMap[resume.readyToWork] | translate
                  }}</span
                >
              </span>
            </div>
          </div>
        </div>
        <div
          class="resume-item__footer"
          [class.no-skills]="!resume.hardSkills?.length"
        >
          <ng-container *ngIf="resume.hardSkills?.length">
            <its-skills [small]="true" [count]="5" [skills]="resume.hardSkills">
            </its-skills>
          </ng-container>

          <its-button
            class="m-l_a"
            variant="icon"
            color="{{ resume.isFollow ? 'accent' : '' }}"
            (click)="toggleFollow($event, resume)"
            [matTooltip]="
              resume.isFollow
                ? ('common.tooltip.removeFavorites' | translate)
                : ('common.tooltip.addFavorites' | translate)
            "
            matTooltipPosition="above"
          >
            <its-icon
              svgIcon="{{ resume.isFollow ? 'star' : 'star_border' }}"
            ></its-icon>
          </its-button>
        </div>
      </mat-card-content>
    </mat-card-content>
  </mat-card>
</a>
