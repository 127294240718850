import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { IJobResponse } from '@modules/jobs/interfaces';
import { JobsFactory } from '@modules/jobs/services/jobs.factory';

import {
  IDateRange,
  ICountResponse,
  ICompanyStats,
  IQuery,
  IAgentStats,
  ISpendingStats
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import { CompanyStatsFactory } from './company-stats.factory';

@Injectable({
  providedIn: 'root'
})
export class CompanyStatsService extends BaseHttpService {
  constructor(
    readonly factory: CompanyStatsFactory,
    readonly jobFactory: JobsFactory
  ) {
    super('company-stats');
  }

  getGeneralStats(dates: IDateRange[]) {
    return this.post<{ dates: IDateRange[] }, ICompanyStats[]>(
      { dates },
      {},
      'general'
    ).pipe(map(({ content }) => content));
  }

  getVacanciesStats(data: IDateRange, params: IQuery = {}) {
    return this.post<IDateRange, ICountResponse<IJobResponse[]>>(
      data,
      params,
      'vacancies'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((j) => this.jobFactory.fromResponse(j)),
        count: content.count
      }))
    );
  }

  getAgentsStats(data: IDateRange, params: IQuery = {}) {
    return this.post<IDateRange, IAgentStats[]>(
      data,
      params,
      'agents'
    ).pipe(map(({ content }) => content));
  }

  getSpendingStats() {
    return this.get<ISpendingStats>({}, 'spending').pipe(
      map(({ content }) => content)
    );
  }
}
