import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LocaleService, SeoService } from '@core/services';

import { LanguageEnum } from '@common/shared/enums';

@Component({
  selector: 'its-policy-privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent implements OnInit {
  locale$ = this.localeService.getLocale();
  LanguageEnum = LanguageEnum;
  constructor(
    readonly seoService: SeoService,
    readonly localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.seoService.updateMetaData({
      windowTitle: this.localeService.getInstant(_('windowTitle.policy')),
      ogTitle: this.localeService.getInstant(_('seo.policy.title')),
      description: this.localeService.getInstant(_('seo.policy.description'))
    });
  }
}
