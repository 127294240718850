import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { filter, take, takeUntil } from 'rxjs/operators';

import { SortEnum } from '@common/shared/enums';

import { Destroyable } from '@client/shared/abstracts';
import { SortTypeEnum } from '@client/shared/enums/sort-type.enum';
import { ISort, ISortOptions } from '@client/shared/interfaces';

const TODO = [
  {
    value: 'viewsCount',
    title: _('jobsSort.viewsCount')
  },
  {
    value: 'requestsCount',
    title: _('jobsSort.requestsCount')
  },
  {
    value: 'invitesCount',
    title: _('jobsSort.invitesCount')
  }
];
const OPTIONS = {
  [SortTypeEnum.AllCompanies]: [
    {
      value: 'jobsCount',
      title: _('companiesSort.jobsCount')
    },
    {
      value: 'employees',
      title: _('companiesSort.employees')
    }
  ],
  [SortTypeEnum.AllJobs]: [
    {
      value: 'published',
      title: _('jobsSort.published')
    },
    {
      value: 'english',
      title: _('jobsSort.english')
    },
    {
      value: 'experience',
      title: _('jobsSort.experience')
    }
  ],
  [SortTypeEnum.AllResumes]: [
    {
      value: 'activated',
      title: _('resumesSort.activated')
    },
    {
      value: 'english',
      title: _('resumesSort.english')
    },
    {
      value: 'experienceFrom',
      title: _('resumesSort.experience')
    }
  ],
  [SortTypeEnum.AllResumesContacts]: [
    {
      value: 'added',
      title: _('resumesSort.added')
    },
    {
      value: 'english',
      title: _('resumesSort.english')
    },
    {
      value: 'experienceFrom',
      title: _('resumesSort.experience')
    }
  ],
  [SortTypeEnum.CompanyInvites]: [
    {
      value: 'created',
      title: _('reqInviteSort.created')
    },
    {
      value: 'priority',
      title: _('reqInviteSort.priority')
    }
  ],
  [SortTypeEnum.CompanyRequests]: [
    {
      value: 'created',
      title: _('reqInviteSort.created')
    },
    {
      value: 'priority',
      title: _('reqInviteSort.priority')
    }
  ]
};

export const DEFAULT_SORTS = {
  [SortTypeEnum.AllCompanies]: '-jobsCount',
  [SortTypeEnum.AllJobs]: '-published',
  [SortTypeEnum.AllResumes]: '-activated',
  [SortTypeEnum.AllResumesContacts]: '-added',
  [SortTypeEnum.CompanyInvites]: '-created',
  [SortTypeEnum.CompanyRequests]: '-created'
};

@Component({
  selector: 'its-sorts',
  templateUrl: './sorts.component.html',
  styleUrls: ['./sorts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SortsComponent extends Destroyable {
  SortEnum = SortEnum;
  params = this.activatedRoute.snapshot.queryParamMap;
  sort = {} as ISort;
  options: ISortOptions[] = [];
  @Input() noMobile = false;
  @Input() set type(type: SortTypeEnum) {
    if (type) {
      this.options = OPTIONS[type] || [];
      if (!this.sort?.sort && DEFAULT_SORTS[type]) {
        this.sort.sort = DEFAULT_SORTS[type];
      }

      this.changeDetector.markForCheck();
    }
  }
  @Output() onSort = new EventEmitter<ISort>();
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly changeDetector: ChangeDetectorRef
  ) {
    super();
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroyed$),
        filter((p) => p?.sort),
        take(1)
      )
      .subscribe((param) => {
        this.sort = {
          sort: param?.sort || null,
          page: 1
        };
        this.changeDetector.markForCheck();
      });
  }

  sortElement(key: string) {
    const isDest = this.sort.sort.startsWith('-');
    if (this.sort.sort.includes(key)) {
      this.sort.sort = isDest ? key : `-${key}`;
    } else {
      this.sort.sort = isDest ? `-${key}` : key;
    }
    this.onSort.emit(this.sort);
  }
}
