import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { IJobApplyRequest, IJobInvite } from '@modules/jobs/interfaces';

import { ModalService, ValuesService } from '@core/services';

import { ReqInvStateHistoryModalComponent } from './components';

@Component({
  selector: 'its-req-inv-state',
  templateUrl: './req-inv-state.component.html',
  styleUrls: ['./req-inv-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReqInvStateComponent {
  @Input() edit = true;
  @Input() invite: IJobInvite = null;
  @Input() request: IJobApplyRequest = null;

  reqInvStateMap$: Observable<{ [key: number]: string }> = this.valuesService
    .getValues()
    .pipe(pluck('reqInvStateMap'));
  constructor(
    readonly modalService: ModalService,
    readonly valuesService: ValuesService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  openModal(ev: any) {
    ev.stopPropagation();
    this.modalService.openComponent(
      ReqInvStateHistoryModalComponent,
      (res) => {
        if (res) {
          if (this.request) {
            this.request = {
              ...this.request,
              ...res
            };
          }
          if (this.invite) {
            this.invite = {
              ...this.invite,
              ...res
            };
          }
          this.changeDetector.detectChanges();
        }
      },
      {
        width: '550px',
        data: {
          invite: this.invite,
          request: this.request,
          edit: this.edit
        }
      }
    );
  }
}
