<div class="auth-container">
  <h1 class="t-a_c">{{ 'auth.signIn.title' | translate }}</h1>
  <form [formGroup]="form" (ngSubmit)="send()" (keyup.enter)="send()">
    <div class="form-control">
      <its-input
        formControlName="username"
        placeholder="{{ 'auth.email.placeholder' | translate }}"
      ></its-input>
    </div>
    <div class="form-control">
      <its-input
        type="password"
        formControlName="password"
        placeholder="{{ 'auth.password.placeholder' | translate }}"
      ></its-input>
    </div>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      type="submit"
      color="primary"
    >
      {{ 'auth.button.signIn' | translate }}
    </its-button>
  </form>
  <div>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      variant="stroked"
      type="button"
      color="primary"
      (click)="googleSignIn()"
    >
      <its-icon svgIcon="google"></its-icon>
      <span class="m-l_8">{{ 'auth.button.googleSignin' | translate }}</span>
    </its-button>
  </div>

  <div *ngIf="notConfirmed" class="resend-confirm">
    <p class="c-red-active">{{ 'auth.message.notConfirmed' | translate }}</p>
    <its-button variant="stroked" color="accent" (click)="goToResendConfirm()">
      {{ 'auth.button.goToResend' | translate }}
    </its-button>
  </div>

  <div class="j-c_c">
    <its-button variant="basic" color="primary" (click)="goTpSignUp()">
      {{ 'auth.button.createAccount' | translate }}
    </its-button>

    <its-button variant="basic" (click)="goTpForgotPassword()">
      {{ 'auth.button.forgotPassword' | translate }}
    </its-button>
  </div>

  <div *ngIf="showMissSticker" class="j-c_c">
    <its-sticker
      class="sticker-long-wait"
      [round]="true"
      [animation]="StickerAnimationEnum.Rotate"
      [name]="StickerNameEnum.LongWaiting"
    ></its-sticker>
  </div>
</div>
