import {
  Component,
  OnInit,
  Inject,
  Optional,
  HostBinding,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SkipSelf,
  ElementRef,
  Input
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  switchMap,
  debounceTime,
  takeUntil,
  map
} from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { Features, AutoCleanupFeature } from '@client/shared/decorators';

import { ISelectOption } from '../select';

const DEBOUNCE_TIME = 500;

@Component({
  selector: 'its-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class MultiSelectComponent
  extends BaseControlComponent<ISelectOption[]>
  implements OnInit
{
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }
  readonly destroyed$: Observable<unknown>;
  @Input() optional = true;
  @Input() readonly = false;
  @Input() optionsFn: (search: string) => Observable<ISelectOption[]>;
  options: ISelectOption[];

  @Input() placeholder: string;
  @Input() searchPlaceholder: string;

  readonly control = new FormControl(null);
  readonly searchControl = new FormControl(null);
  private initialized = false;
  readonly trackByFn = (i: number, option: ISelectOption) => option;
  @Input() filterByFn = (option: ISelectOption) => option?.id;
  @Input() valueFn = (option: ISelectOption | null) => option;
  @Input() labelFn = (option: ISelectOption) => option?.value;
  @Input() compareFn = (option: ISelectOption, selected: ISelectOption) => {
    if (selected === null) {
      return false;
    }

    if (typeof option === 'object' && typeof selected === 'object') {
      return option?.id === selected?.id;
    } else {
      return option === selected;
    }
  };

  readonly modelToViewFormatter = (value: ISelectOption[] | null) => {
    if (value) {
      this.options = value;
    }

    return value;
  };

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged((prev, curr) => prev === curr && prev),
        debounceTime(DEBOUNCE_TIME),
        switchMap((value) => {
          return this.optionsFn(value).pipe(
            map((options) => {
              if (value) {
                return options;
              }
              const selected: ISelectOption[] = this.control.value ?? [];
              const rest =
                options?.filter((el) => {
                  return !selected.some(
                    (selectedEl) =>
                      this.filterByFn(selectedEl) === this.filterByFn(el)
                  );
                }) ?? [];

              return [...selected, ...rest];
            })
          );
        })
      )
      .subscribe((options) => {
        this.options = options as ISelectOption[];
        this.changeDetector.markForCheck();
      });
  }

  onClearValue() {
    this.searchControl.setValue(null);
  }

  onOpenPanel(opened: boolean) {
    if (opened && !this.initialized) {
      this.initialized = true;
      this.searchControl.updateValueAndValidity();
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
}
