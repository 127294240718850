<div class="messenger" (click)="readUnreadMessages()">
  <div class="scroll-container">
    <perfect-scrollbar
      [style.minHeight.px]="MIN_SCROLL_CONTAINER_HEIGHT"
      [style.height]="heightScrollbar"
      [config]="config"
      (psYReachStart)="onScrollTop($event)"
    >
      <ul class="messages" #messagesList>
        <ng-container
          *ngFor="let m of messages; let i = index; trackBy: trackByFn"
        >
          <li
            class="not-read"
            [class.not-read]="!m.owner && m.new && i !== 0"
            [class.owner]="m.owner"
            [class.last-group-el]="messages[i + 1]?.owner !== m.owner"
          >
            <div class="message-frame">
              <span
                *ngIf="m.message"
                class="text-el"
                [class.first-in-group]="messages[i + 1]?.owner === m.owner"
                [class.in-group]="
                  messages[i + 1]?.owner === m.owner &&
                  messages[i - 1]?.owner === m.owner
                "
                [class.last-in-group]="messages[i - 1]?.owner === m.owner"
              >
                <span>{{ m.message }}</span>
              </span>
              <ul class="message-files" *ngIf="m.files?.length">
                <li *ngFor="let file of m.files; let i = index">
                  <its-file [file]="file" [chatOwner]="m.owner"></its-file>
                </li>
              </ul>
            </div>

            <small class="created">{{ m.created }}</small>
          </li>
        </ng-container>
      </ul>
    </perfect-scrollbar>
  </div>
  <div class="form-container">
    <form
      *ngIf="form"
      class="messenger__form"
      [formGroup]="form"
      (ngSubmit)="onSendMessage()"
    >
      <div
        class="messenger__form__content"
        [class.disabled-placeholder]="!chat.enabled"
        #frameChatForm
      >
        <div>
          <its-uploader
            [disableUpload]="!chat.enabled"
            [multiple]="true"
            (selectedFile)="onFileSelected($event)"
          >
            <div style="height: 100%" #uploadFrame>
              <button
                (click)="uploadFrame.click()"
                [disabled]="!chat.enabled"
                type="button"
                class="button-empty add-file-button"
              >
                <its-icon svgIcon="add-file"></its-icon>
              </button>
            </div>
          </its-uploader>
        </div>
        <div class="w_100">
          <div class="messenger__textarea-container" (click)="textarea.focus()">
            <textarea
              [style.maxHeight.px]="MAX_TEXTAREA_HEIGHT"
              #textarea
              (keyup)="textAreaAdjust()"
              (keydown)="textareaChange($event)"
              placeholder="{{
                chat.enabled ? ('chat.textarea.placeholder' | translate) : ''
              }}"
              formControlName="message"
              rows="2"
              class="messenger__textarea"
            ></textarea>
            <div *ngIf="chat.enabled" class="emoji-actions">
              <its-emoji (selectEmoji)="onSelectEmoji($event)"></its-emoji>
              <its-template-btn
                [templateType]="[TemplateTypeEnum.Custom]"
                [templateUserName]="
                  chat?.recipient?.firstName || chatDrawer?.recipient?.firstName
                "
                (templateSelected)="onTemplateSelected($event)"
              ></its-template-btn>
            </div>

            <div class="chat-expires" *ngIf="!chat.enabled">
              {{ 'chat.message.chatDisabled' | translate }}
            </div>
          </div>
          <ul *ngIf="files?.length" class="messenger__files">
            <li *ngFor="let file of files; let i = index">
              <its-file
                [chatForm]="true"
                [file]="file"
                [active]="true"
                (removeFile)="onRemoveFile(i)"
              ></its-file>
            </li>
          </ul>
        </div>
      </div>
      <div class="messenger__form__action">
        <button
          [class.disabled-placeholder]="!chat.enabled"
          class="button-empty"
          matRipple
          [disabled]="!chat.enabled"
          type="submit"
        >
          <its-icon color="primary" size="35" svgIcon="send"></its-icon>
        </button>
      </div>
    </form>
  </div>
</div>
