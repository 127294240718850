<its-header role="banner"></its-header>
<div class="site-layout" [class.map-page]="map" [class.home-page]="home">
  <main>
    <its-loader></its-loader>
    <div [class.container]="!map && !home">
      <router-outlet></router-outlet>
      <its-auth></its-auth>
    </div>
  </main>
  <div *ngIf="haveFooter && !map" class="h-footer"></div>
</div>
<its-footer
  [class.logged]="profile$ | async"
  *ngIf="haveFooter && !map"
></its-footer>
<ng-container *ngIf="drawerChat$ | async"></ng-container>
<its-drawer
  class="drawer-md"
  [opened]="!!chat"
  (closedStart)="closedChat($event)"
>
  <ng-container *ngIf="chat">
    <div class="chat-recipient">
      <its-avatar
        [avatar]="recipient?.avatar"
        [firstName]="recipient?.firstName"
        [lastName]="recipient?.lastName"
      ></its-avatar>
      <div>
        <div class="user-name">
          {{ recipient?.firstName }}&nbsp;<ng-container
            *ngIf="recipient?.lastName"
            >{{ recipient.lastName }}</ng-container
          >
        </div>
        <small *ngIf="chat.hoursLeft && chat.enabled" class="c-red"
          ><b translate [translateParams]="{ hoursLeft: chat.hoursLeft }"
            >chatList.chat.expire</b
          ></small
        >
      </div>
    </div>
    <its-messenger [chatDrawer]="chat"></its-messenger>
  </ng-container>
</its-drawer>
