import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';

import { LocaleService, NotificationService } from '@core/services';

@Component({
  selector: 'its-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() drop = false;
  @Input() disableUpload = false;
  @Input() accept = '*';
  @Input() multiple = false;
  @Input() maxFileSize = 1; // in MB

  @Output() selectedFile = new EventEmitter<File | File[] | any>();

  constructor(
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService
  ) {}

  fileUploaded(ev, drag = false) {
    if (this.disableUpload) {
      return;
    }
    const files = [];
    (drag ? [ev] : [...ev.target.files]).forEach((el) => {
      if (el.size > this.maxFileSize * 1000 * 1024) {
        this.notificationService.showError(
          `${this.localeService.getInstant(_('uploader.maxFileSize'))} ${
            this.maxFileSize
          }MB`
        );
      } else {
        files.push(el);
      }
    });

    if (files.length) {
      if (this.multiple) {
        this.selectedFile.emit(files);
      } else {
        this.selectedFile.emit(files[0]);
      }
    }

    this.fileInput.nativeElement.value = '';
  }
}
