import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { IJob, IJobApply, IJobApplyRequest } from '@modules/jobs/interfaces';
import { JobsRequestService, JobsService } from '@modules/jobs/services';

import {
  GlobalObjectService,
  ModalService,
  ValuesService
} from '@core/services';

import {
  JobStatusEnum,
  WorkTypeGroupEnum,
  WorkTypesEnum
} from '@common/shared/enums';
import {
  clickAllowed,
  getResultCategoriesBooleanConditions
} from '@common/shared/helpers';

import { ModalApplyJobComponent } from '@client/shared/components/job/components';
import { COUNTRIES, ROUTES_DATA } from '@client/shared/constants';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobComponent {
  WorkTypeGroupEnum = WorkTypeGroupEnum;
  JobStatusEnum = JobStatusEnum;
  WorkTypesEnum = WorkTypesEnum;
  ROUTES_DATA = ROUTES_DATA;
  COUNTRIES = COUNTRIES;
  origin = this.globalObjectService.getLocation()?.origin;
  categoryCondition = '';
  companyType = '';
  values$: Observable<IValues> = this.valuesService.getValues();

  @Input() onlyView = false;
  _drawerId = null;
  @Input() set drawerId(id: number) {
    this._drawerId = id;
    this.getJob(id);
  }
  get drawerId() {
    return this._drawerId;
  }
  _job: IJob;
  @Input() set job(job: IJob) {
    if (job) {
      this._job = job;
      this.getCompanyType(job);
      this.getCategoryCondition(job);
    }
  }

  get job() {
    return this._job;
  }

  @Output() devRequest = new EventEmitter<IJobApplyRequest>();
  constructor(
    readonly jobsService: JobsService,
    readonly changeDetector: ChangeDetectorRef,
    readonly jobsRequestService: JobsRequestService,
    readonly globalObjectService: GlobalObjectService,
    readonly valuesService: ValuesService,
    readonly modalService: ModalService,
    readonly location: Location,
    readonly router: Router
  ) {}

  getJob(id: number) {
    this.jobsService.getJob(id).subscribe((job) => {
      this.job = job;
      this.getCompanyType(job);
      this.changeDetector.markForCheck();
    });
  }

  toggleFollow(ev, job: IJob) {
    if (clickAllowed(ev)) {
      const follow = !job.isFollow;
      this.jobsService.followJobStatus(follow, job.id).subscribe(() => {
        job.isFollow = follow;
        this.changeDetector.markForCheck();
      });
    }
  }

  applyJob(ev, job: IJob) {
    if (clickAllowed(ev)) {
      const startApplyJob = () => {
        this.modalService.openComponent(
          ModalApplyJobComponent,
          (data: IJobApply) => {
            if (data) {
              if (job.applicationUrl) {
                window.open(job.applicationUrl, '_blank');
              } else {
                this.jobsRequestService
                  .applyRequest(job.id, data)
                  .subscribe((request) => {
                    this.devRequest.emit(request);
                    this.globalObjectService.scrollTop();
                    this.changeDetector.markForCheck();
                  });
              }
            }
          },
          { data: job }
        );
      };

      this.checkQuestionnaire(job, (result) => {
        if (result) {
          startApplyJob();
        }
      });
    }
  }

  checkQuestionnaire(job: IJob, cb: (result: boolean) => void) {
    const questionnaire = job?.questionnaire;
    if (questionnaire?.length) {
      const openQuestion = (index) => {
        const question = questionnaire[index];
        this.modalService.openModal(
          {
            header: `Question ${index + 1}/${questionnaire.length}`,
            body: question.question,
            buttons: [
              { name: question.cancel },
              {
                name: question.confirm
              }
            ]
          },
          (answer) => {
            if (answer) {
              if (index < questionnaire.length - 1) {
                openQuestion(index + 1);
              } else {
                cb(true);
              }
            } else {
              cb(false);
            }
          }
        );
      };

      openQuestion(0);
    } else {
      cb(true);
    }
  }

  deleteJob(id: number) {
    this.jobsService.deleteJob(id).subscribe(() => {
      this.location.back();
    });
  }

  pauseJob(job: IJob) {
    this.jobsService.pauseJob(job.id).subscribe(() => {
      job.status = JobStatusEnum.Paused;
      this.changeDetector.markForCheck();
    });
  }

  continuePublishJob(job: IJob) {
    this.jobsService.continuePublishJob(job.id).subscribe(() => {
      job.status = JobStatusEnum.Active;
      this.changeDetector.markForCheck();
    });
  }

  closeVacancy(id: number) {
    this.jobsService.closeVacancy(id).subscribe(() => {
      this.router.navigateByUrl(
        `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.JOBS.url}`
      );
    });
  }

  reactivateJob(job: IJob) {
    this.jobsService.publishJob(job, true).subscribe((res) => {
      this.job = res;
      this.changeDetector.markForCheck();
    });
  }

  getCompanyType(job: IJob) {
    this.companyType = job?.company?.types?.map((el) => el.value).join(', ');
    this.changeDetector.markForCheck();
  }

  getCategoryCondition(job: IJob) {
    this.values$
      .pipe(
        filter((values) => !!values.categoriesMap),
        take(1)
      )
      .subscribe((values) => {
        this.categoryCondition = getResultCategoriesBooleanConditions(
          job.categoryConditions,
          values.categoriesMap
        );
        this.changeDetector.markForCheck();
      });
  }
}
