<ng-container *ngIf="isPlatformBrowser">
  <mat-card>
    <mat-card-content>
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'companies'">
          <ng-container *ngTemplateOutlet="companies"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'jobs'">
          <ng-container *ngTemplateOutlet="jobs"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'resumes'">
          <ng-container *ngTemplateOutlet="resumes"></ng-container>
        </ng-container>

        <div class="j-c_f-e">
          <div matTooltip="{{ 'widget.tooltip.comingSoon' | translate }}">
            <its-button variant="basic" [disabled]="true">
              <!--    <its-icon>{{ item.icon }}</its-icon>-->
              <span>{{ 'widget.button.moreStats' | translate }}</span>
            </its-button>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #companies>
  <div class="a-i_c j-c_s-b m-b_16">
    <h3 class="m-b_0 c-black">
      {{ 'widget.title.topCompanies' | translate }}
    </h3>
    <span class="w-icon green">
      <its-icon svgIcon="trending_up"></its-icon>
    </span>
  </div>
  <ul class="companies-items">
    <ng-container *ngFor="let company of topCompanies$ | async as companies">
      <li *ngIf="company.logo">
        <a routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{ company.slug }}">
          <img [src]="company.logo" [alt]="company.name" />
        </a>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #jobs>
  <h3 class="c-black">{{ 'widget.title.lastPeriod' | translate }}</h3>
  <div class="a-i_c j-c_s-b">
    <h3 class="m-b_0 f-w_s-b">{{ 'widget.title.jobsData' | translate }}</h3>
    <span class="w-icon">
      <its-icon svgIcon="badge"></its-icon>
    </span>
  </div>
  <ng-container *ngIf="vacanciesStats$ | async as stats">
    <ul class="widget-items m-l_16 m-b_24">
      <li>
        <div class="title">
          {{ 'widget.jobs.publishedVacancies' | translate }}
        </div>
        <div class="a-i_b">
          <div class="value">{{ stats.published[0] }}</div>
          <div class="differ {{ stats.publishedInfo.dir }}">
            <span>{{ stats.publishedInfo.percent | percent: '1.0-2' }}</span>
            <its-icon
              svgIcon="trending_{{ stats.publishedInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.jobs.acceptedInvites' | translate }}
        </div>
        <div class="a-i_b">
          <div class="value">{{ stats.acceptedInvites[0] }}</div>
          <div class="differ {{ stats.acceptedInvitesInfo.dir }}">
            <span>{{
              stats.acceptedInvitesInfo.percent | percent: '1.0-2'
            }}</span>
            <its-icon
              svgIcon="trending_{{ stats.acceptedInvitesInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.jobs.acceptedRequests' | translate }}
        </div>
        <div class="a-i_b">
          <div class="value">{{ stats.acceptedRequests[0] }}</div>
          <div class="differ {{ stats.acceptedRequestsInfo.dir }}">
            <span>{{
              stats.acceptedRequestsInfo.percent | percent: '1.0-2'
            }}</span>
            <its-icon
              svgIcon="trending_{{ stats.acceptedRequestsInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
      <li>
        <div class="title">{{ 'widget.jobs.withRemote' | translate }}</div>
        <div class="a-i_b">
          <div class="value">{{ stats.withRemote[0] }}</div>
          <div class="differ {{ stats.withRemoteInfo.dir }}">
            <span>{{ stats.withRemoteInfo.percent | percent: '1.0-2' }}</span>
            <its-icon
              svgIcon="trending_{{ stats.withRemoteInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
    </ul>
    <div class="a-i_c j-c_s-b">
      <h3 class="m-b_0 f-w_s-b">
        {{ 'widget.title.mostPopular' | translate }}
      </h3>
      <span class="w-icon violet">
        <its-icon svgIcon="query_stats"></its-icon>
      </span>
    </div>
    <ul class="widget-items m-l_16">
      <li>
        <div class="title">
          {{ 'widget.jobs.minExperience' | translate }}
        </div>
        <div>
          <div class="value">{{ stats.minExperienceValue }}</div>
        </div>
      </li>
      <li>
        <div class="title">{{ 'widget.jobs.minEnglish' | translate }}</div>
        <div>
          <div class="value">{{ stats.minEnglishValue }}</div>
        </div>
      </li>
    </ul>
  </ng-container>
</ng-template>

<ng-template #resumes>
  <div class="a-i_c j-c_s-b m-b_16">
    <h3 class="m-b_0 c-black">
      {{ 'widget.title.lastPeriod' | translate }}
    </h3>
    <span class="w-icon green">
      <its-icon svgIcon="query_stats"></its-icon>
    </span>
  </div>
  <ng-container *ngIf="resumesStats$ | async as stats">
    <ul class="widget-items m-l_16">
      <li>
        <div class="title">
          {{ 'widget.resumes.publicResume' | translate }}
        </div>
        <div class="a-i_b">
          <div class="value">{{ stats.publicResumes[0] }}</div>
          <div class="differ {{ stats.publicResumesInfo.dir }}">
            <span>{{
              stats.publicResumesInfo.percent | percent: '1.0-2'
            }}</span>
            <its-icon
              svgIcon="trending_{{ stats.publicResumesInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.resumes.anonymousResume' | translate }}
        </div>
        <div class="a-i_b">
          <div class="value">{{ stats.anonymousResumes[0] }}</div>
          <div class="differ {{ stats.anonymousResumesInfo.dir }}">
            <span>{{
              stats.anonymousResumesInfo.percent | percent: '1.0-2'
            }}</span>
            <its-icon
              svgIcon="trending_{{ stats.anonymousResumesInfo.dir }}"
            ></its-icon>
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.resumes.middleEnglishLevel' | translate }}
        </div>
        <div>
          <div class="value">
            {{ stats.middleEnglishLevelInfo.title }}
            {{ stats.middleEnglishLevelInfo.percent | percent: '1.0-2' }}
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.resumes.middleExperience' | translate }}
        </div>
        <div>
          <div class="value">
            {{ stats.middleExperienceInfo.title | translate }}
            {{ stats.middleExperienceInfo.percent | percent: '1.0-2' }}
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          {{ 'widget.resumes.popularCategory' | translate }}
        </div>
        <div>
          <div class="value">{{ stats.mostPopularCategoriesStr }}</div>
        </div>
      </li>
      <!--<li>
        <h3 class="m-b_0 c-black f-w_700">
          Очікування вказані у 20% їх середній досвід 3 роки і середні вимоги до
          ЗП від $3000
        </h3>
      </li>-->
    </ul>
  </ng-container>
</ng-template>
