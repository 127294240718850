import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { LocaleService } from '@core/services';

import { LogicConditionEnum } from '@common/shared/enums';
import {
  getExcludeLocation,
  getIncludeLocation,
  getResultCategoriesBooleanConditions,
  getSalary,
  parseLocation
} from '@common/shared/helpers';

import { COUNTRIES } from '@client/shared/constants';
import { SearchTypeEnum } from '@client/shared/enums';
import { IFilterForm, IValues } from '@client/shared/interfaces';
import {
  getLocationValue,
  getBooleanLocationValueWithTitle
} from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class SettingsFactory {
  constructor(readonly localeService: LocaleService) {}
  responseFilter(
    res: IFilterForm,
    values: IValues,
    type: SearchTypeEnum
  ): IFilterForm {
    const filter = { ...res };
    const { countryCode, regionCode, city } = parseLocation(res.location);
    const categoriesValue = getResultCategoriesBooleanConditions(
      filter?.categories,
      values.categoriesMap
    );
    let modeValue = '';
    if (filter?.workTypes?.length) {
      const typesIds = filter?.workTypes.map((t) => t.id);
      modeValue = values.workTypes
        .filter((t) => typesIds.includes(t.id as string))
        .map((c) => c.value)
        .join(', ');
    }

    const readyToWork = filter.readyToWork
      ? values?.readyToWorkMap[filter.readyToWork]
      : '';
    modeValue = modeValue?.length
      ? `${modeValue}, ${readyToWork}`
      : readyToWork;

    filter.intervalValue = values.filterInformIntervalMap[filter.interval];
    const experienceValue = values.experiencesMap[filter.experience];
    const englishValue = values.englishMap[filter.english];
    const otherLanguages = (filter.languages || [])
      .map((el) => el.value)
      .join(', ');
    const domains = (filter.domains || []).map((el) => el.value).join(', ');
    let address = '';
    if (type === SearchTypeEnum.Resume) {
      const inclValue = getBooleanLocationValueWithTitle(
        res.includeLocation,
        this.localeService.getInstant('booleanSearch.text.include')
      );
      const exclValue = getBooleanLocationValueWithTitle(
        res.excludeLocation,
        this.localeService.getInstant('booleanSearch.text.exclude')
      );

      address =
        inclValue && exclValue
          ? `${inclValue}\n${exclValue}`
          : inclValue || exclValue;
    } else {
      address = res.location
        ? [COUNTRIES[countryCode], regionCode, city].filter(Boolean).join(', ')
        : '';
    }

    filter.excludedCompaniesValue = (res?.excludedCompanies || [])
      .map((c) => c.name)
      .join(', ');
    const salary = getSalary(filter.salaryFrom, filter.salaryTo);
    const veteran = filter?.veteran
      ? this.localeService.getInstant(_('resume.text.veteran'))
      : '';
    filter.filterValue = [
      categoriesValue,
      domains,
      filter.title,
      experienceValue,
      englishValue ? 'English ' + englishValue : null,
      otherLanguages,
      modeValue,
      salary,
      veteran,
      address
    ]
      .filter(Boolean)
      .join('\n');

    return filter;
  }
}
