<article class="public-text">
  <mat-card>
    <mat-card-content>
      <h2 class="t-a_c">
        <b>Умови користування</b>
      </h2>
      <div class="m-b_12">
        Будь ласка, уважно прочитайте Умови користування сервісом із пошуку
        роботи Kupno (далі – Умови) перед використанням вебсайту
        {{ origin }} (далі – Сайт). Ви маєте ознайомитись із цими Умовами та
        прийняти їх, якщо ви хочете користуватися Сайтом. Якщо Ви не згодні з
        цими Умовами чи з окремими пунктами, викладеними в них, будь ласка, ви
        можете відмовитися від використання Сайту та не використовувати його.
      </div>

      <ol>
        <li>
          <h3>1. Вступ</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">1.1.</div>
              <div>
                Ці Умови є юридично обов’язковою угодою між користувачем Сайту
                та фізичною особою – підприємцем Лісним Романом Васильовичем
                (реєстраційний номер платника податків – 3284219853), який
                зареєстрований за адресою: Львівська обл., Золочівський р-н, с.
                Закомар’я (далі – Власник Сайту).
              </div>
            </li>
            <li>
              <div class="point-number">1.2.</div>
              <div>
                Ці Умови регулюють взаємні права та обов’язки Користувачів та
                Власника Сайту щодо користування Сайтом і послугами, які
                надаються за його допомогою.
              </div>
            </li>
            <li>
              <div class="point-number">1.3.</div>
              <div>
                Користувачами Сайту відповідно до зареєстрованого профілю можуть
                бути Користувач (фізична особа, яка шукає роботу) та Користувач
                з компанією (роботодавець, який шукає працівників). Користувачем
                Сайту в жодному випадку не може бути фізична особа чи юридична
                особа, яка зареєстрована на території Російської Федерації
                та/або Республіки Білорусь або співпрацює з ними.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>2. Основні положення</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">2.1.</div>
              <div>
                Для використання усіх функціональних можливостей і послуг Сайту
                Користувачу необхідно зареєструватися. Використання послуг Сайту
                та реєстрація на Сайті означає повне та беззастережне прийняття
                цих Умов.
              </div>
            </li>
            <li>
              <div class="point-number">2.2.</div>
              <div>
                Ці Умови та всі зміни до них набувають чинності з моменту їх
                опублікування на Сайті. Зміни до Умов можуть бути внесені до неї
                Власником Сайту в будь-який час. Якщо Користувач не згодний зі
                змінами, він зобов’язаний припинити використання Сайту. Факт
                продовження використання Сайту є підтвердженням згоди і
                прийняттям Користувачем відповідної редакції Умов.
              </div>
            </li>
            <li>
              <div class="point-number">2.3.</div>
              <div>
                Реєстрація на Сайті безкоштовна. Для подальшого користування
                Сайтом та усіма його функціями Користувач має заповнити
                відповідну реєстраційну форму – профіль Користувача. Для цього
                необхідно заповнити відповідні поля у профілі Користувача, у
                тому числі логін та пароль, а також адресу електронної пошти, на
                яку надійде посилання для активації облікового запису
                Користувача, результати пошуку чи сповіщення від Власника Сайту.
                Публікація Користувачем власного резюме є безкоштовною.
                Публікація Користувачем з компанією оголошень про вакансію є
                платною в межах придбаного пакету або поштучних публікацій.
                Додаткові послуги на Сайті є платними. Оплата послуг
                здійснюється відповідно до розділу 4 цих Умов.
              </div>
            </li>
            <li>
              <div class="point-number">2.4.</div>
              <div>
                Метою використання Сайту є забезпечення якісного та ефективного
                розміщення інформації Користувачем з компанією (оголошень,
                замовлень, пропозицій тощо) з пошуку кандидатів на вакансії чи
                пошуку вакансій Користувачем в сфері ІТ-технологій.
              </div>
            </li>
            <li>
              <div class="point-number">2.5.</div>
              <div>
                <div class="m-b_8">За допомогою Сайту</div>
                <div>
                  <b>Користувач може:</b>
                </div>
                <ol class="bullet-items">
                  <li>публікувати власні резюме;</li>
                  <li>
                    отримувати доступ до всіх оголошень про вакансії на Сайті;
                  </li>
                  <li>
                    здійснювати підбір оголошень про вакансії за допомогою
                    пошуку оголошень на Сайті, встановлювати контакт із
                    Користувачем з компанією та вести переписку за допомогою
                    профілю на Сайті;
                  </li>
                  <li>
                    покращувати власний профіль на Сайті шляхом придбання більш
                    розширеного пакету послуг;
                  </li>
                  <li>
                    отримувати доступ до розміщеної інформації за допомогою
                    власного профілю на Сайті.
                  </li>
                </ol>
                <div>
                  <b>Користувач з компанією може:</b>
                </div>
                <ol class="bullet-items">
                  <li>
                    розміщувати свої вакансії, які будуть доступні для перегляду
                    іншим Користувачам Сайту;
                  </li>
                  <li>отримувати відгуки на вакансії;</li>
                  <li>
                    знаходити резюме у базі резюме за різними критеріями (за
                    сферами діяльності, за професіями тощо);
                  </li>
                  <li>
                    здійснювати підбір працівників за допомогою пошуку резюме на
                    Сайті, встановлювати контакт з Користувачем та вести
                    переписку за допомогою профілю на Сайті;
                  </li>
                  <li>
                    надсилання пропозиції Користувачу, який має активне резюме
                    на Сайті та/або який був доданий у список контактів
                    Користувача з компанією, після успішного встановлення
                    контакту на Сайті з Користувачем;
                  </li>
                  <li>
                    покращувати власний профіль на Сайті шляхом придбання більш
                    розширеного пакету послуг, а також розміщувати банер у
                    власному профілі та логотип на Сайті;
                  </li>
                  <li>
                    отримувати доступ до розміщеної інформації за допомогою
                    власного профіля на Сайті.
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>3. Права та обов'язкт сторін</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">3.1.</div>
              <div>
                Права та обов’язки Користувачів
                <ol class="point-items">
                  <li>
                    <div class="point-number">3.1.1.</div>
                    <div>
                      Користувач несе відповідальність за правдивість і точність
                      заповнення свого профілю, також всієї інформації, що
                      розміщується ним на Сайті.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.2.</div>
                    <div>
                      На Сайті заборонені:
                      <ol class="bullet-items">
                        <li>
                          заклики до насильницької зміни або повалення
                          конституційного ладу або захоплення державної влади;
                          заклики до зміни адміністративних кордонів чи
                          державного кордону України, порушення порядку,
                          встановленого Конституцією України; заклики до
                          погромів, підпалів, знищення майна, захоплення
                          будівель або споруд, насильницького виселення
                          громадян; заклики до агресії або розв’язання
                          військового конфлікту;
                        </li>
                        <li>
                          нецензурні прямі й непрямі образи в чий-небудь бік,
                          зокрема ті, що ґрунтуються на етнічній, расовій чи
                          релігійній приналежності, а також висловлювання, які
                          мають шовіністичний характер;
                        </li>
                        <li>
                          використовувати чужу адресу, електронну пошту або
                          адресу, на використання якої Користувач не має прав;
                        </li>
                        <li>
                          образлива поведінка, публікація завідомо неправдивої
                          інформації, а також інформації, яка може завдати шкоди
                          Власнику Сайту та/або іншим Користувачам або порушити
                          їхні права;
                        </li>
                        <li>
                          розсилання спаму, а також розробка програмного
                          забезпечення для здійснення таких розсилок;
                        </li>
                        <li>
                          розміщувати інформацію, яка суперечить укладеним і
                          належним чином ратифікованим міжнародним договорам
                          України, чинному законодавству України, а також
                          оголошення, пов’язані з гральним бізнесом (за винятком
                          випадків, коли діяльність, пов’язана з організацією,
                          проведенням та наданням можливості доступу до азартних
                          ігор, здійснюється у країні, де гральний бізнес не
                          заборонений), фінансовими пірамідами, релігійними
                          сектами, окультними та еротичними послугами, а також
                          троянськими програми, вірусами, інтернет-хробаками,
                          програмами для злому чи несанкціонованого доступу до
                          інформації та іншими «хакерськими» програмами, які
                          можуть завдати шкоди програмному забезпеченню та/або
                          комплектувальним частинам інформаційних систем;
                        </li>
                        <li>
                          використовувати Сайт з метою пошуку співробітників для
                          роботи на території Російської Федерації, Республіки
                          Білорусь та тимчасово окупованих територій України, а
                          також розміщувати резюме Користувачам, які
                          зареєстровані у цих країнах або співпрацюють з ними;
                        </li>
                        <li>
                          зміна фото в профілі за допомогою рамок або інших
                          графічних елементів, а також використання фотографій
                          третіх осіб;
                        </li>
                        <li>
                          будь-які дії, які прямо заборонені чинним
                          законодавством України.
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.3.</div>
                    <div>
                      На Сайті заборонено публікувати, зокрема, але не виключно,
                      наступну інформацію:
                      <ol class="bullet-items">
                        <li>
                          будь-які повідомлення та оголошення, пов’язані з
                          отриманням незаконного доходу;
                        </li>
                        <li>
                          будь-які повідомлення та оголошення, що містять
                          інформацію про незаконне розповсюдження наркотичних
                          засобів, психотропних речовин, їхніх аналогів та
                          прекурсорів;
                        </li>
                        <li>
                          явну та приховану рекламу сторонніх сайтів, включно з
                          реферальними посиланнями та посиланнями на сайти
                          аналогічної спрямованості;
                        </li>
                        <li>рекламу швидкого заробітку;</li>
                        <li>
                          що провокує конфлікти на політичній, расовій,
                          релігійній, національній або іншій неприязні;
                        </li>
                        <li>що містить нецензурну лексику;</li>
                        <li>
                          оголошення про продаж будь-яких товарів та послуг;
                        </li>
                        <li>
                          нецільове розсилання реклами в особистих
                          повідомленнях;
                        </li>
                        <li>
                          інформацію, зазначену у резюме/вакансіях, в
                          невідповідних рубриках;
                        </li>
                        <li>
                          вакансії, в описі яких відсутня детальна інформація
                          про функціональні обов’язки та/або завдання;
                        </li>
                        <li>
                          вакансії, які містять у собі інформацію щодо істотно
                          меншого бюджету у порівнянні з аналогічними
                          пропозиціями;
                        </li>
                        <li>
                          вакансії, що не відповідають спеціалізації Сайту;
                        </li>
                        <li>
                          вакансії, які передбачають написання чи розміщення
                          відгуків про продукти, послуги або сервіси;
                        </li>
                        <li>
                          вакансії, які передбачають пошук спонсорів для
                          проведення акцій, розіграшів чи схожих заходів;
                        </li>
                        <li>
                          вакансії, що створені з метою залучення до пірамідних
                          схем;
                        </li>
                        <li>
                          вакансії, пов’язані зі здаванням іспитів, виконанням
                          контрольних робіт, а також проходженням тестів за
                          третіх осіб;
                        </li>
                        <li>
                          вакансії, пов’язані з наданням інтимних послуг, а
                          також виробництвом, транслюванням або ретранслюванням
                          еротичних, порнографічних фото-, відео- та
                          аудіоматеріалів;
                        </li>
                        <li>
                          вакансії, пов’язані з діяльністю релігійних сект, а
                          також з наданням окультних послуг;
                        </li>
                        <li>
                          резюме/вакансії пов’язаних з пропозицією інтим-,
                          ескорт-послуг, будь-яким вкладенням коштів, з роботою,
                          що порушує чинне законодавство України, участю в
                          фінансових пірамідах та інших сумнівних структурах.
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.4.</div>
                    <div>
                      Користувач з компанією має право публікувати оголошення як
                      звичайне, так і «гаряче» відповідно до умов придбаного
                      пакету надання послуг на Сайті.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.5.</div>
                    <div>
                      Користувач може реєструвати не більш ніж один обліковий
                      запис. Реєстрація декількох облікових записів одного типу
                      заборонена, так само як і передача параметрів входу на
                      Сайт іншим Користувачам. Заборонена передача облікових
                      записів третім особам. Один обліковий запис належить одній
                      особі. Заборонені купівля та продаж облікових записів.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.6.</div>
                    <div>
                      Користувач не має права намагатися отримати
                      несанкціонований доступ до будь-якої частини або функції
                      Сайту чи будь-яких інших систем або мереж, підключених до
                      Сайту, чи до будь-якого сервера Сайту будь-якими
                      незаконними засобами.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.7.</div>
                    <div>
                      Користувач має право надати Власнику Сайту власні
                      побажання чи пропозиції щодо покращення роботи Сайту.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.8.</div>
                    <div>
                      Користувач надає згоду Власнику Сайту на використання
                      фото/відео та інших зображень, створених у рамках
                      співпраці на Сайті, у будь-який спосіб та на будь-яких
                      сайтах, соціальних мережах, YouTube тощо для реклами
                      Сайту.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.1.9.</div>
                    <div>
                      Користувач визнає та погоджується з тим, що Власник Сайту
                      має доступ до всього вмісту Сайту, включно з особистими
                      повідомленнями на Сайті.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">3.2.</div>
              <div>
                Права та обов’язки Власника Сайту.
                <ol class="point-items">
                  <li>
                    <div class="point-number">3.2.1.</div>
                    <div>
                      Власник Сайту визначає правила поведінки на Сайті та
                      залишає за собою право вимагати їх виконання від
                      Користувача.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.2.</div>
                    <div>
                      Власник Сайту залишає за собою право вносити зміни до Умов
                      та до всіх їхніх невіддільних частин без будь-якого
                      спеціального повідомлення Користувача про такі зміни.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.3.</div>
                    <div>
                      Власник Сайту залишає за собою право в будь-який час
                      вносити зміни до інформації, опублікованої на Сайті.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.4.</div>
                    <div>
                      Власник Сайту має право заблокувати оголошення про
                      вакансію або резюме у разі отримання на нього значної
                      кількості скарг від інших Користувачів.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.5.</div>
                    <div>
                      Власник Сайту не несе відповідальності за правдивість
                      інформації, опублікованої в межах Сайту, та/або
                      коректність висловлювань його Користувачів.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.6.</div>
                    <div>
                      У випадку, якщо Користувач не згоден з рішенням Власника
                      Сайту, він має право написати листа з обґрунтуванням своєї
                      незгоди на адресу Власника Сайту
                      <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
                      >.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.7.</div>
                    <div>
                      Власник Сайту може позбавити права користування Сайтом
                      Користувача, який порушує ці Умови.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.8.</div>
                    <div>
                      Власник Сайту має право заблокувати або видалити акаунт
                      Користувача, а також заборонити доступ з використанням
                      будь-якої інформації до Сайту та видалити будь-який
                      контент Користувача без пояснення причин, зокрема в
                      будь-якому з таких випадків: порушення Користувачем Умов
                      або положень інших документів, передбачених цими Умовами;
                      вчинення Користувачем дій, що спричинили чи здатні
                      спричинити нанесення шкоди діловій репутації Сайту та/або
                      іншим Користувачам; одержання значної кількості скарг від
                      інших Користувачів; надання неправдивої інформації у
                      публічному профілі Користувача; публікація приватного
                      листування з іншими Користувачами на публічно доступних
                      ресурсах; порушення принципів справедливої співпраці та
                      взаємоповаги і взаєморозуміння.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.9.</div>
                    <div>
                      Власник Сайту має право переглядати, контролювати,
                      видаляти публічну інформацію, зазначену Користувачем на
                      Сайті, якщо вона порушує ці Умови. Власник Сайту не несе
                      відповідальності, не схвалює та не гарантує інформації
                      щодо думок, поглядів, порад або рекомендацій, розміщених
                      чи надісланих Користувачами.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.10.</div>
                    <div>
                      Власник Сайту не несе відповідальності за будь-які
                      помилки, неточності, упущення під час реєстрації або
                      завантаження контенту на Сайті та не може бути притягнутий
                      до відповідальності за будь-які збитки (включно з упущеною
                      вигодою), що виникають у зв’язку з такими помилками,
                      неточностями або упущеннями.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.11.</div>
                    <div>
                      Власник Сайту залишає за собою право рекламувати послуги
                      Сайту, на свій розсуд, шляхом обробки контенту та
                      відображення його на відповідних сторінках Сайту і за його
                      межами, з правом його структурування, редагування,
                      інтеграції, поділу, перегрупування, перекладу текстової
                      частини контенту іншими мовами тощо. Власник Сайту не несе
                      відповідальності за будь-які помилки, перекручення,
                      упущення, неточності, видалення, дефекти контенту у
                      зв’язку з перекладом текстової частини контенту іншими
                      мовами.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.12.</div>
                    <div>
                      Порядок обробки персональних даних Користувачів Сайту
                      передбачений в Політиці конфіденційності
                      <a
                        [routerLink]="
                          '/' + ROUTES_DATA.PUBLIC.children.POLICY.url
                        "
                        >{{ origin }}/{{
                          ROUTES_DATA.PUBLIC.children.POLICY.url
                        }}</a
                      >, яка є невід’ємною складовою цих Умов. Ознайомтеся з
                      нею.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.13.</div>
                    <div>
                      Власник Сайту має право надсилати Користувачу інформаційні
                      повідомлення виключно в рамках користування Сайтом.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.14.</div>
                    <div>
                      Власник Сайту залишає за собою право вимагати здійснення
                      верифікації профілю Користувача у разі виявлення
                      підозрілої активності в згаданому профілі.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.15.</div>
                    <div>
                      Власник Сайту має право запитувати та отримувати від
                      Користувача будь-які дані, необхідні для його верифікації.
                    </div>
                  </li>
                  <li>
                    <div class="point-number">3.2.16.</div>
                    <div>
                      Власник Сайту має право верифікувати інформацію про
                      Користувача за допомогою будь-яких загальнодоступних та
                      офіційних баз даних, зокрема баз даних платіжних систем та
                      їхніх аналогів. Водночас Користувач відшкодовує Власнику
                      Сайту витрати за здійснення такої перевірки. Вартість
                      верифікації попередньо узгоджується з Користувачем.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>4. Порядок оплати</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">4.1.</div>
              <div>
                Власник Сайту публікує перелік своїх платних послуг на Сайті,
                включаючи опис таких послуг та інформацію про їхню вартість.
                Користувач має право замовляти та отримувати платні послуги
                Сайту, обравши бажану відповідну послугу на Сайті.
              </div>
            </li>
            <li>
              <div class="point-number">4.2.</div>
              <div>
                Власник Сайту має право на свій розсуд вводити та змінювати
                плату за ті чи інші платні послуги Сайту, про що Користувачі
                будуть попередньо сповіщені.
              </div>
            </li>
            <li>
              <div class="point-number">4.3.</div>
              <div>
                У разі порушення правил Сайту кошти, використані на купівлю
                послуги на Сайті, не повертаються. Проте, якщо Користувач не
                зміг скористатися придбаною послугою повною мірою вини Власника
                Сайту, кошти підлягають поверненню на платіжну картку, з якої
                був зроблений платіж, у розмірі, що відповідає повній вартості
                цієї послуги.
              </div>
            </li>
            <li>
              <div class="point-number">4.4.</div>
              <div>
                Користувач зобов’язаний оплатити послуги Сайту. Способи оплати
                зазначені на Сайті, у тому числі за допомогою платіжної системи
                LiqPay.
              </div>
            </li>
            <li>
              <div class="point-number">4.5.</div>
              <div>
                Оплата здійснюється Користувачем на рахунок Власника Сайту і
                вважається здійсненою в момент надходження грошових коштів на
                рахунок Власника Сайту.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>5. Публікація оголошення про вакансію та резюме</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">5.1.</div>
              <div>
                Користувач з компанією має право опублікувати оголошення про
                вакансію. Оголошення про вакансію має містити інформацію лише
                про одну вакансію. Користувач з компанією не може розміщувати
                одночасно декілька однакових оголошень (оголошень, що мають
                однаковий смисловий зміст).
              </div>
            </li>
            <li>
              <div class="point-number">5.2.</div>
              <div>
                Користувач з компанією оплачує послугу з публікації оголошення.
              </div>
            </li>
            <li>
              <div class="point-number">5.3.</div>
              <div>
                Забороняється в оголошенні про вакансію або у резюме вказувати
                вік кандидатів, пропонувати роботу тільки жінкам чи тільки
                чоловікам, за винятком специфічної роботи, яка може виконуватися
                виключно особами відповідної статі, пред’являти вимоги, що
                надають переваги жіночій чи чоловічій статі (крім випадків,
                визначених чинним законодавством України), представникам
                відповідної раси, кольору шкіри, за політичними, релігійними та
                іншими переконаннями, членам профспілкових або інших об’єднань
                громадян, етнічного та соціального походження, майнового стану,
                місця проживання, за мовними чи іншими ознаками, а також
                інформацію зазначену у пункті 3.1.3. цих Умов.
              </div>
            </li>
            <li>
              <div class="point-number">5.4.</div>
              <div>
                Користувач має право опублікувати власне резюме на Сайті.
              </div>
            </li>
            <li>
              <div class="point-number">5.5.</div>
              <div>
                Користувач має право безплатного доступу до всіх оголошень про
                вакансії на Сайті.
              </div>
            </li>
            <li>
              <div class="point-number">5.6.</div>
              <div>
                Власник Сайту залишає за собою право в будь-який момент видаляти
                оголошення про вакансії та резюме, якщо вони не відповідають
                вимогам цих Умов, а також на свій власний розсуд без зазначення
                причин.
              </div>
            </li>
            <li>
              <div class="point-number">5.7.</div>
              <div>
                Власник Сайту не несе відповідальності за зміст оголошень про
                вакансії та резюме, а також за спричинення збитку чи інших
                збитків, які можуть виникати у Користувача у зв’язку з
                публікацією та використанням інформації, що міститься в
                оголошенні про вакансії та резюме.
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>6. Права інтелектуальної власності</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">6.1.</div>
              <div>
                Усі компоненти Сайту і Сайт в цілому належать Власнику Сайту та
                охороняються законодавством у сфері права інтелектуальної
                власності. Усі права захищені.
              </div>
            </li>
            <li>
              <div class="point-number">6.2.</div>
              <div>
                Користувач підтверджує та погоджується з тим, що весь вміст і
                матеріали, які доступні на Сайті, захищені, включаючи, але не
                обмежуючись, авторським правом, торговельними марками,
                комерційною таємницею. Відтворення, копіювання або поширення в
                комерційних цілях будь-яких матеріалів або елементів Сайту без
                попереднього дозволу Власника Сайту заборонено.
              </div>
            </li>
            <li>
              <div class="point-number">6.3.</div>
              <div>
                Користувач з компанією надає Власнику Сайту безстрокове
                невиключне право публічно демонструвати належні Користувачу з
                компанією знаки для товарів та послуг та/або логотипи,
                розміщуючи їх на Сайті (до моменту відкликання Користувачем з
                компанією такої згоди).
              </div>
            </li>
          </ol>
        </li>
        <li>
          <h3>7. Відповідальність сторін</h3>
          <ol class="point-items">
            <li>
              <div class="point-number">7.1.</div>
              <div>
                За невиконання або неналежне виконання своїх зобов’язань за цими
                Умовами сторони цих Умов несуть відповідальність відповідно до
                чинного законодавства України.
              </div>
            </li>
            <li>
              <div class="point-number">7.2.</div>
              <div>
                Власник Сайту не бере участі в спорах (зокрема судових) між
                Користувачами, а також за участю третіх осіб.
              </div>
            </li>
            <li>
              <div class="point-number">7.3.</div>
              <div>
                Власник Сайту не несе відповідальності:
                <ol class="bullet-items">
                  <li>за якість послуг, що надаються Користувачами;</li>
                  <li>
                    за будь-які можливі збитки та/або упущену вигоду
                    Користувачів, завдані внаслідок використання або
                    неможливості використання Сайту;
                  </li>
                  <li>
                    за дії Користувачів, що порушують чинне законодавство
                    України;
                  </li>
                  <li>
                    за інформацію та матеріали, що розміщуються Користувачами на
                    Сайті.
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="point-number">7.4.</div>
              <div>
                Власник Сайту не гарантує, що робота Сайту буде безперервною,
                безпомилковою та позбавленою шкідливих програм або інших
                дефектів, але зобов’язується докласти всіх зусиль для
                безпомилкового та безперервного функціонування Сайту. Якщо
                Користувач не задоволений умовами та/або якістю Сайту, він може
                припинити використання Сайту або звернутися до служби підтримки
                Сайту.
              </div>
            </li>
            <li>
              <div class="point-number">7.5.</div>
              <div>
                Користувач зобов’язується не порушувати та не намагатися
                порушувати положення цих Умов. Якщо Власник Сайту на свій розсуд
                визначить, що Користувач порушив або намагався порушити ці
                Умови, то доступ Користувача до Сайту може бути припинений.
              </div>
            </li>
            <li>
              <div class="point-number">7.6.</div>
              <div>
                Користувач несе відповідальність за всі дії, здійснені від імені
                Користувача на Сайті.
              </div>
            </li>
            <li>
              <div class="point-number">7.7.</div>
              <div>
                Користувач не може передавати свої логін і пароль для входу на
                Сайт третім особам. Користувач несе відповідальність за дії
                третіх осіб з його облікового запису так само, як ніби він
                вчинив їх особисто.
              </div>
            </li>
            <li>
              <div class="point-number">7.8.</div>
              <div>
                Користувач несе відповідальність за якість і своєчасність
                виконання прийнятих на Сайті зобов’язань.
              </div>
            </li>
            <li>
              <div class="point-number">7.9.</div>
              <div>
                Користувач погоджується звільнити та убезпечити Власника Сайту
                від будь-яких претензій чи вимог з боку будь-яких третіх осіб у
                зв’язку з/або в результаті порушення Користувачем цих Умов,
                чинного законодавства України або прав третіх осіб.
              </div>
            </li>
          </ol>
        </li>
      </ol>
      <div>
        Якщо у Вас виникли будь-які запитання та/або пропозиції щодо цих Умов,
        звертайтеся до служби підтримки Сайту за електронною адресою:
        <a href="mailto:{{ HELLO_EMAIL }}">{{ HELLO_EMAIL }}</a
        >.
      </div>
    </mat-card-content>
  </mat-card>
</article>
