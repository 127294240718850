import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BookDemoService, ModalService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class BookDemoComponent implements OnInit {
  form: FormGroup;
  constructor(
    readonly formStateDispatcher: FormStateDispatcher,
    readonly bookDemoService: BookDemoService,
    readonly modalService: ModalService,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = new FormGroup<any>({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          CustomValidators.plusInEmail
        ])
      ),
      phone: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required)
    });
    this.changeDetectorRef.detectChanges();
  }

  bookDemo() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      this.bookDemoService.bookDemo(this.form.value).subscribe(() => {
        const options = {
          header: _('common.modal.title.congratulation'),
          body: _('bookDemo.modal.demoSuccessfullyBooked'),
          buttons: [null, { name: _('common.modal.button.gotIt') }]
        };
        this.modalService.openModal(options, () => {
          this.form = null;
          this.changeDetectorRef.detectChanges();
          this.buildForm();
        });
      });
    }
  }
}
