import { EXPIRED_REASON, OTHER_REASON } from '../constants/values';

export enum CancelRequestReasonEnum {
  Experience = 1,
  Skills = 2,
  English = 3,
  VacancyClosed = 4,
  Expired = EXPIRED_REASON,
  Other = OTHER_REASON
}
