import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';

import { FACEBOOK_LINK, INSTAGRAM_LINK, LINKEDIN_LINK, TELEGRAM_LINK } from '@common/shared/constants';

import { ROUTES_DATA } from '@client/shared/constants';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { GlobalObjectService } from '@core/services';

@Component({
  selector: 'its-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  footerLogo = '/assets/v1/images/logo-white.png';
  ROUTES_DATA = ROUTES_DATA;
  year = new Date().getFullYear();
  constructor(
    // readonly globalObjectService: GlobalObjectService,
    // @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  ngOnInit(): void {
  /*  if (this.request && !this.globalObjectService.isPlatformBrowser()) {
      this.logo = `https://${this.request.get('host')}${this.logo}`;
    }*/
  }
}
