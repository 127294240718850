<div class="short-stats" [class.in-table]="small">
  <div matTooltip="{{'jobIte.tooltip.views' | translate}}">
    <its-icon class="c-pre-primary" size="{{small ? '15' : '20'}}"  svgIcon="visibility"></its-icon>
    {{ job.viewsCount }}
  </div>
  <div matTooltip="{{'jobIte.tooltip.requests' | translate}}">
    <its-icon class="c-secondary" size="{{small ? '15' : '20'}}" svgIcon="request"></its-icon>
    {{ job.requestsCount }}
  </div>
  <div matTooltip="{{'jobIte.tooltip.invites' | translate}}">
    <its-icon class="c-red" size="{{small ? '15' : '20'}}" svgIcon="invite"></its-icon>
    {{ job.invitesCount }}
  </div>
</div>
