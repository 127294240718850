import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyStatsFactory {
  fromResponse(data: any) {
    return {
      ...data
    };
  }
}
