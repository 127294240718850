<header class="header">
  <a routerLink="/" class="logo">
    <img [src]="(darkMode$ | async) ? logoWhite : logoBlack" alt="Kupno" />
  </a>
  <div *ngIf="profile$ | async as profile" class="header-content">
    <!--    <button (click)="testEmail()">Test email</button>-->
    <div class="header-nav">
      <its-nav [invReqCounters]="invReqCounters"></its-nav>

      <ng-container
        *ngIf="profile?.company?.isActive && !profile?.company?.trialUsed"
      >
        <its-button
          class="start-trial"
          color="primary"
          [link]="'/' + ROUTES_DATA.SETTINGS.children.PRICING.url"
          [queryParams]="{ startTrial: '1' }"
          >{{ 'header.button.startTrial' | translate }}</its-button
        >
      </ng-container>
    </div>

    <div *ngIf="isMobile" class="a-i_c c-g_12">
      <ng-container *ngTemplateOutlet="languageSwitcher"></ng-container>

      <ng-container *ngTemplateOutlet="resumeCounter"></ng-container>

      <ng-container *ngTemplateOutlet="companyCounter"></ng-container>

      <div *ngIf="!profile.exist" class="mobile-sign-in">
        <ng-container *ngTemplateOutlet="signInBtn"></ng-container>
      </div>
    </div>

    <its-button
      *ngIf="admin"
      (click)="backToAdmin()"
      variant="stroked"
      color="primary"
    >
      {{ 'header.button.backToAdmin' | translate }}
    </its-button>

    <div class="header-menu" [class.logged]="profile.exist">
      <div *ngIf="!isMobile" class="a-i_c c-g_16">
        <ng-container *ngTemplateOutlet="languageSwitcher"></ng-container>

        <ng-container *ngTemplateOutlet="resumeCounter"></ng-container>
      </div>
      <ng-container *ngIf="profile.exist; else noAuth">
        <!-- <a mat-icon-button aria-label="Notifications">
           <its-icon>notifications_none</its-icon>
         </a>
         <a
           [routerLink]="'/' + ROUTES_DATA.CALENDAR.url"
           mat-icon-button
           aria-label="Calendar"
         >
           <its-icon>event_available</its-icon>
         </a>-->
        <its-button
          variant="icon"
          [link]="'/' + ROUTES_DATA.CHAT.url"
          aria-label="Chat"
          matTooltip="{{ 'header.tooltip.chat' | translate }}"
          color="accent"
        >
          <its-icon class="mob-icon" size="24" svgIcon="messages"></its-icon>
          <its-icon
            class="desk-icon"
            size="24"
            svgIcon="messages-gradient"
          ></its-icon>
          <span
            *ngIf="notReadChatsCount$ | async"
            class="new-messages-marker"
          ></span>
        </its-button>

        <ng-container *ngIf="isMobile; else desktop">
          <its-button
            *ngIf="profile?.company?.isActive"
            variant="icon"
            [link]="ROUTES_DATA.COMPANIES.children.PROFILE.children.JOBS.url"
          >
            <its-icon class="c-violet" size="24" svgIcon="vacancy"></its-icon>
          </its-button>

          <its-button
            variant="icon"
            color="accent"
            [link]="
              profile?.company?.isActive
                ? '/' +
                  ROUTES_DATA.COMPANIES.children.PROFILE.children.REQUESTS.url
                : '/' +
                  ROUTES_DATA.RESUME.children.PROFILE.children.REQUESTS.url
            "
          >
            <its-icon size="24" svgIcon="request"></its-icon>
            <span class="mobile-counter" *ngIf="invReqCounters.requests">{{
              invReqCounters.requests
            }}</span>
          </its-button>

          <its-button
            variant="icon"
            color="warn"
            [link]="
              profile?.company?.isActive
                ? '/' +
                  ROUTES_DATA.COMPANIES.children.PROFILE.children.INVITES.url
                : '/' + ROUTES_DATA.RESUME.children.PROFILE.children.INVITES.url
            "
          >
            <its-icon size="24" svgIcon="invite"></its-icon>
            <span class="mobile-counter" *ngIf="invReqCounters.invites">{{
              invReqCounters.invites
            }}</span>
          </its-button>
        </ng-container>
        <ng-template #desktop>
          <ng-container *ngTemplateOutlet="companyCounter"></ng-container>
        </ng-template>

        <its-button variant="icon" [matMenuTriggerFor]="menu">
          <div class="account-icon-content">
            <its-icon size="27" svgIcon="account"></its-icon>
          </div>
        </its-button>
        <mat-menu #menu="matMenu" xPosition="before">
          <div class="profile-menu">
            <ng-container *ngIf="profile?.company">
              <a
                class="c_ii"
                mat-menu-item
                [routerLink]="
                  ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children
                    .FORM.url
                "
              >
                <span class="profile-menu__icon">
                  <its-icon svgIcon="company-profile"></its-icon>
                </span>
                <span>{{ 'header.button.companyProfile' | translate }}</span>
              </a>
              <!--              <ng-container *ngIf="profile?.company?.isActive">-->
              <ng-container *ngIf="false">
                <a
                  class="c_ii"
                  mat-menu-item
                  [routerLink]="
                    ROUTES_DATA.COMPANIES.children.PROFILE.children.JOBS.url
                  "
                >
                  <span class="profile-menu__icon">
                    <its-icon svgIcon="vacancy"></its-icon>
                  </span>
                  <span>{{ 'header.button.vacancies' | translate }}</span>
                </a>
                <a
                  class="c_ii"
                  mat-menu-item
                  [routerLink]="
                    ROUTES_DATA.COMPANIES.children.PROFILE.children.REQUESTS.url
                  "
                >
                  <span class="profile-menu__icon">
                    <its-icon svgIcon="request"></its-icon>
                  </span>
                  <span>{{ 'header.button.companyRequests' | translate }}</span>
                </a>
                <a
                  class="c_ii"
                  mat-menu-item
                  [routerLink]="
                    ROUTES_DATA.COMPANIES.children.PROFILE.children.INVITES.url
                  "
                >
                  <span class="profile-menu__icon">
                    <its-icon svgIcon="invite"></its-icon>
                  </span>
                  <span>{{ 'header.button.companyInvites' | translate }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="profile?.company?.isActive">
                <ng-container *ngIf="profile?.company?.isOwnerAccess">
                  <a
                    class="c_ii"
                    mat-menu-item
                    [routerLink]="ROUTES_DATA.SETTINGS.children.PRICING.url"
                  >
                    <span class="profile-menu__icon">
                      <its-icon
                        size="20"
                        class="c-green-active"
                        svgIcon="by-vacancy"
                      ></its-icon>
                    </span>
                    <span>{{ 'header.button.byVacancy' | translate }}</span>
                  </a>
                  <a
                    class="c_ii"
                    mat-menu-item
                    [routerLink]="
                      ROUTES_DATA.COMPANIES.children.STATISTIC.children.ORDERS
                        .url
                    "
                  >
                    <span class="profile-menu__icon">
                      <its-icon svgIcon="paid"></its-icon>
                    </span>
                    <span>{{ 'header.button.orders' | translate }}</span>
                  </a>
                </ng-container>

                <a
                  class="c_ii"
                  mat-menu-item
                  routerLink="{{
                    profile?.company?.isOwnerAccess
                      ? ROUTES_DATA.COMPANIES.children.STATISTIC.children
                          .GENERAL.url
                      : ROUTES_DATA.COMPANIES.children.STATISTIC.children.JOBS
                          .url
                  }}"
                >
                  <span class="profile-menu__icon">
                    <its-icon
                      size="20"
                      class="c-primary"
                      svgIcon="stats"
                    ></its-icon>
                  </span>
                  <span>{{
                    profile?.company?.isOwnerAccess
                      ? ('header.button.companyStatistic' | translate)
                      : ('header.button.agentStatistic' | translate)
                  }}</span>
                </a>
                <a
                  class="c_ii"
                  mat-menu-item
                  routerLink="{{ ROUTES_DATA.TALENT_POOL.url }}"
                >
                  <span class="profile-menu__icon">
                    <its-icon svgIcon="contacts"></its-icon>
                  </span>
                  <span>{{ 'header.button.companyContacts' | translate }}</span>
                </a>
              </ng-container>
              <div class="menu-line"></div>
            </ng-container>
            <a
              class="c_ii"
              mat-menu-item
              [routerLink]="
                ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url
              "
            >
              <span class="profile-menu__icon">
                <its-icon svgIcon="resume-menu"></its-icon>
              </span>
              <span>{{
                profile?.company?.isActive
                  ? ('header.button.userProfile' | translate)
                  : ('header.button.resumeProfile' | translate)
              }}</span>
            </a>
            <ng-container *ngIf="!profile?.company?.isActive">
              <a
                class="c_ii"
                mat-menu-item
                [routerLink]="
                  ROUTES_DATA.RESUME.children.PROFILE.children.REQUESTS.url
                "
              >
                <span class="profile-menu__icon">
                  <its-icon svgIcon="request"></its-icon>
                </span>
                <span>{{ 'header.button.resumeRequests' | translate }}</span>
              </a>
              <a
                class="c_ii"
                mat-menu-item
                [routerLink]="
                  ROUTES_DATA.RESUME.children.PROFILE.children.INVITES.url
                "
              >
                <span class="profile-menu__icon">
                  <its-icon svgIcon="invite"></its-icon>
                </span>
                <span>{{ 'header.button.resumeInvites' | translate }}</span>
              </a>
            </ng-container>
            <a
              class="c_ii"
              mat-menu-item
              [routerLink]="
                ROUTES_DATA.RESUME.children.STATISTIC.children.GENERAL.url
              "
            >
              <span class="profile-menu__icon">
                <its-icon
                  size="20"
                  class="c-primary"
                  svgIcon="resume-stats"
                ></its-icon>
              </span>
              <span>{{ 'header.button.resumeStatistic' | translate }}</span>
            </a>
            <div class="menu-line"></div>
            <a
              class="c_ii"
              mat-menu-item
              [routerLink]="ROUTES_DATA.SETTINGS.children.ACCOUNT.url"
            >
              <span class="profile-menu__icon">
                <its-icon svgIcon="settings-menu"></its-icon>
              </span>
              <span>{{ 'header.button.settings' | translate }}</span>
            </a>
            <button mat-menu-item (click)="switchTheme()">
              <span class="profile-menu__icon">
                <its-icon
                  svgIcon="{{
                    (darkMode$ | async) ? 'light-mode' : 'dark-mode'
                  }}"
                ></its-icon>
              </span>
              <span>{{
                (darkMode$ | async)
                  ? ('header.button.lightTheme' | translate)
                  : ('header.button.darkTheme' | translate)
              }}</span>
            </button>
            <button mat-menu-item (click)="logOut()">
              <span class="profile-menu__icon">
                <its-icon
                  svgIcon="{{
                    (darkMode$ | async) ? 'log-out-dark' : 'log-out'
                  }}"
                ></its-icon>
              </span>
              <span>{{ 'header.button.logOut' | translate }}</span>
            </button>
          </div>
        </mat-menu>
      </ng-container>
      <ng-template #noAuth>
        <ng-container
          *ngTemplateOutlet="signInBtn; context: { $implicit: true }"
        ></ng-container>
      </ng-template>
    </div>
  </div>
</header>

<ng-template #signInBtn let-desktop>
  <ng-container *ngIf="desktop; else mobile">
    <its-button
      (click)="toAuth()"
      variant="raised"
      color="primary"
      classes="{{ desktop ? 'btn-w_150' : '' }}"
    >
      <span>{{ 'header.button.signIn' | translate }}</span>
    </its-button>
  </ng-container>
  <ng-template #mobile>
    <its-button variant="icon" (click)="toAuth()">
      <its-icon size="24" svgIcon="log-in"></its-icon>
    </its-button>
  </ng-template>
</ng-template>

<ng-template #languageSwitcher>
  <its-button
    variant="icon"
    *ngIf="!userProfile"
    matTooltip="{{
      (darkMode$ | async)
        ? ('header.tooltip.lightTheme' | translate)
        : ('header.tooltip.darkTheme' | translate)
    }}"
    (click)="switchTheme()"
  >
    <its-icon
      size="{{ (darkMode$ | async) ? '26' : '24' }}"
      svgIcon="{{
        (darkMode$ | async) ? 'light-mode-color' : 'dark-mode-color'
      }}"
    ></its-icon>
  </its-button>
  <ng-container *ngIf="locale$ | async as locale">
    <button class="btn-value button-empty" [matMenuTriggerFor]="langMenu">
      <its-icon size="24" svgIcon="language"></its-icon>
      <span class="title">{{ localeShort[locale] }}</span>
    </button>
    <mat-menu #langMenu="matMenu" xPosition="before">
      <button
        *ngIf="locale !== LanguageEnum.English"
        mat-menu-item
        (click)="changeLanguage(LanguageEnum.English)"
      >
        <its-icon
          size="24"
          [svgIcon]="localeIcons[LanguageEnum.English]"
        ></its-icon>
        <span> English </span>
      </button>
      <button
        *ngIf="locale !== LanguageEnum.Ukraine"
        mat-menu-item
        (click)="changeLanguage(LanguageEnum.Ukraine)"
      >
        <its-icon
          size="24"
          [svgIcon]="localeIcons[LanguageEnum.Ukraine]"
        ></its-icon>
        <span> Українська </span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>

<ng-template #resumeCounter>
  <ng-container *ngIf="counterIsRun">
    <ng-container *ngIf="getResumeViews$ | async as view">
      <a
        class="resume-view-counter"
        [routerLink]="
          '/' + ROUTES_DATA.RESUME.children.STATISTIC.children.GENERAL.url
        "
        matTooltip="{{ view.tooltip | translate }}{{ view.tooltipData }}"
      >
        <its-icon size="24" svgIcon="visibility-gradient"></its-icon>
        <span>{{ view.count }}</span>
      </a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #companyCounter>
  <ng-container
    *ngIf="userProfile?.company?.status === ActiveStatusEnum.Active"
  >
    <a
      *ngIf="hotServices$ | async as services"
      class="btn-value"
      matTooltip="{{ 'header.tooltip.hotServicesTt' | translate }}"
    >
      <its-icon size="24" svgIcon="hot"></its-icon>
      <span class="title">
        {{ services?.length }}
      </span>
    </a>
    <a
      (click)="goToPricing(userProfile?.company?.isOwnerAccess)"
      class="btn-value"
      [class.c_p]="userProfile?.company?.isOwnerAccess"
      *ngIf="jobServices$ | async as services"
      matTooltip="{{ 'header.tooltip.jobServicesTt' | translate }}"
    >
      <its-icon size="24" svgIcon="vacancy-gradient"></its-icon>
      <span
        class="title"
        [class.no-services]="
          services?.length === 0 && !userProfile?.company?.premiumPlan
        "
      >
        <ng-container *ngIf="userProfile?.company?.premiumPlan; else count"
          ><its-icon svgIcon="infinity"></its-icon
        ></ng-container>
        <ng-template #count>
          {{ services?.length }}
        </ng-template>
      </span>
    </a>
  </ng-container>
</ng-template>
