import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ActiveStatusEnum } from '@common/shared/enums';
import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { IBookDemo } from '@client/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class BookDemoService extends BaseHttpService {
  constructor() {
    super('book-demo');
  }

  bookDemo(data: IBookDemo) {
    return this.post(data);
  }

  adminGetBookings(query: IQuery) {
    return this.get<ICountResponse<IBookDemo[]>>(query).pipe(
      map(({ content }) => content)
    );
  }

  adminUpdateBooking(id: number, data: { status: ActiveStatusEnum }) {
    return this.put(data, {}, id);
  }
}
