import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';

import { IJob } from '@modules/jobs/interfaces';
import { IResume } from '@modules/resumes/interfaces';

import { EXPERIENCES_MAP } from '@common/shared/constants';
import { JobViewTypeEnum } from '@common/shared/enums';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor(
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute
  ) {}

  changeQueryParam(query = {}, queryParamsHandling: QueryParamsHandling = '') {
    const data = this.filterEmptyQueryData(query);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling,
      queryParams: {
        ...data
      },
      replaceUrl: true
    });
  }

  filterEmptyQueryData(query: any) {
    for (const key in query) {
      if (
        query.hasOwnProperty(key) &&
        (query[key] === '' ||
          query[key] === null ||
          query[key] === undefined ||
          (Array.isArray(query[key]) && !query[key]?.length))
      ) {
        delete query[key];
      } else {
        if (typeof query[key] === 'object' && Array.isArray(query[key])) {
          query[key] = query[key].join(',');
        }
      }
    }

    return query;
  }

  setJobViewParams(from: JobViewTypeEnum) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        j_view: from
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
