<ng-container *ngIf="drop; else add">
  <label
    itsDragDrop
    style="margin-bottom: 2px"
    [class.disabled]="disableUpload"
    [disableUpload]="disableUpload"
    (fileDropped)="fileUploaded($event, true)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </label>
</ng-container>
<ng-template #add>
  <label>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </label>
</ng-template>

<ng-template #content>
  <input
    #fileInput
    class="d_n"
    [disabled]="disableUpload"
    (change)="fileUploaded($event)"
    [accept]="accept"
    [multiple]="multiple"
    type="file"
  />
  <ng-content></ng-content>
</ng-template>
