import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';

import { Observable } from 'rxjs';

import { IResume } from '@modules/resumes/interfaces';
import { ResumeService } from '@modules/resumes/services';

import { ValuesService } from '@core/services';

import { WorkTypeGroupEnum } from '@common/shared/enums';

import { ROUTES_DATA } from '@client/shared/constants';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-item-resume',
  templateUrl: './item-resume.component.html',
  styleUrls: ['./item-resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemResumeComponent {
  WorkTypeGroupEnum = WorkTypeGroupEnum;
  ROUTES_DATA = ROUTES_DATA;
  @Input() resume: IResume;
  values$: Observable<IValues> = this.valuesService.getValues();

  constructor(
    readonly valuesService: ValuesService,
    readonly resumeService: ResumeService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  toggleFollow(ev: any, resume: IResume) {
    ev.preventDefault();
    ev.stopPropagation();
    const follow = !resume.isFollow;
    this.resumeService
      .followResumeStatus(follow, resume.anonymUuid || resume.id)
      .subscribe(() => {
        resume.isFollow = follow;
        this.changeDetector.markForCheck();
      });
  }
}
