import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';

import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnInit, Output
} from '@angular/core';

@Component({
  selector: 'its-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnInit {
  _options: Options = {
    floor: 0,
    showTicks: true,
    pushRange: true,
    getPointerColor: (value, pointerType) => {
      return '#0a46a5';
    },
    getSelectionBarColor: (minValue: number, maxValue?: number) => {
      return '#31b355';
    },
    translate: (value: number, label: LabelType): string => {
      return `$${value}`;
      /*switch (label) {
        case LabelType.Low:
          return `$${value}`;
        case LabelType.High:
          return `$${value}/mo`;
        default:
          return `$${value}`;
      }*/
    }
  };
  @Input() set options(options: Options) {
    if (options) {
      this._options = {
        ...this.options,
        ...options
      };
    }
  }

  get options() {
    return this._options;
  }

  @Input() value: number;
  @Input() highValue: number;

  @Output() valueChangeEnd = new EventEmitter<ChangeContext>();

  constructor() {}

  ngOnInit(): void {}

  onValueChangeEnd(ev: ChangeContext) {
    this.valueChangeEnd.emit(ev);
  }
}
