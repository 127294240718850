import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { LocaleService, ValuesService } from '@core/services';

import { parseLocation } from '@common/shared/helpers';

import { Destroyable } from '@client/shared/abstracts';
import { IValues } from '@client/shared/interfaces';

const example_query = {
  categories: '5,7',
  experience: '2',
  english: '3',
  workTypes: '2,4',
  region: 'c_Lviv+rc_Lviv Oblast+cc_UA',
  domains: '7,9',
  languages: '2,3',
  employees: '4,5',
  types: '3,4', // Company types
  title: 'we',
  position: 'werwer',
  name: 'wer'
};

interface IMobFilterResult {
  key: string;
  value: string;
  id?: number;
}
@Component({
  selector: 'its-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileFilterComponent extends Destroyable {
  showMore = false;
  showedResults: IMobFilterResult[] = [];
  allResults: IMobFilterResult[] = [];
  queryParams = {};

  @Input() count = 5;
  @Output() newQuery = new EventEmitter<any>();

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly activatedRoute: ActivatedRoute,
    readonly localeService: LocaleService,
    readonly valuesService: ValuesService
  ) {
    super();
    combineLatest([
      this.activatedRoute.queryParams,
      this.valuesService.getValues()
    ])
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(([params, values]) => this.getResult(params, values));
  }

  getResult(params: any, values: IValues) {
    this.queryParams = { ...params };
    const sortObject = {};
    for (const key in params) {
      switch (key) {
        case 'categories': {
          sortObject[1] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values?.categories?.find((i) => i.id === +id)?.value
          }));
          break;
        }
        case 'experience': {
          sortObject[2] = {
            key,
            value: values?.experiencesMap?.[+params[key]]
          };
          break;
        }
        case 'english': {
          sortObject[3] = {
            key,
            value: values?.englishMap?.[+params[key]]
          };
          break;
        }
        case 'workTypes': {
          sortObject[4] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values.workTypes.find((t) => +t.id === +id).value
          }));
          break;
        }
        case 'region': {
          sortObject[5] = {
            key,
            value: Object.values(parseLocation(params[key])).join(', ')
          };
          break;
        }
        case 'domains': {
          sortObject[6] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values?.domains?.find((el) => el.id === +id)?.value
          }));
          break;
        }
        case 'languages': {
          sortObject[7] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values?.languages?.find((el) => el.id === +id)?.value
          }));
          break;
        }
        case 'employees': {
          sortObject[8] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values?.experiencesMap?.[+id]
          }));
          break;
        }
        case 'types': {
          // Company types
          sortObject[9] = params[key].split(',').map((id) => ({
            key,
            id,
            value: values?.companyTypes?.find((el) => el.id === +id)?.value
          }));
          break;
        }
        case 'title': {
          sortObject[10] = {
            key,
            value: params[key]
          };
          break;
        }
        case 'position': {
          sortObject[11] = {
            key,
            value: params[key]
          };
          break;
        }
        case 'name': {
          sortObject[12] = {
            key,
            value: params[key]
          };
          break;
        }
      }
    }
    const results = (
      Object.values(sortObject).flat() as IMobFilterResult[]
    ).filter((el) => !!el.value);

    this.getList(results);
  }

  removeFilter(filter: IMobFilterResult) {
    if (filter.id) {
      const value = this.queryParams[filter.key]
        .split(',')
        .filter((id) => +id !== +filter?.id);
      if (value?.length) {
        this.queryParams[filter.key] = value.join(',');
      } else {
        this.queryParams[filter.key] = null;
      }
    } else {
      this.queryParams[filter.key] = null;
    }
    this.newQuery.emit(this.queryParams);
  }

  getList(results: IMobFilterResult[]) {
    if (this.count && results.length > this.count) {
      this.showMore = true;
      this.showedResults = results.slice(0, this.count);
    } else {
      this.showedResults = results;
    }
    this.allResults = results;
    this.changeDetector.markForCheck();
  }

  showAll() {
    this.showedResults = this.allResults;
    this.showMore = false;
    this.changeDetector.markForCheck();
  }
}
