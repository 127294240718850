<div class="auth-container">
  <form
    novalidate
    #formInstance
    [formGroup]="form"
    (ngSubmit)="send(formInstance)"
    (keyup.enter)="send(formInstance)"
  >
    <div class="form-control">
      <its-input
        formControlName="username"
        placeholder="{{ 'auth.email.placeholder' | translate }}"
      ></its-input>
    </div>
    <its-button
      class="m-b_16 d_b"
      color="primary"
      classes="w_100"
      type="submit"
    >
      {{ 'auth.button.resendConfirm' | translate }}
    </its-button>
  </form>

  <p *ngIf="emailWasSend" class="c-green-active t-a_c">
    {{ 'auth.message.confirmResent' | translate }}
  </p>

  <div class="j-c_c">
    <its-button color="primary" variant="basic" (click)="backToLogIn()">
      {{ 'auth.button.goToLogIn' | translate }}
    </its-button>
  </div>
</div>
