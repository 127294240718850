import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { TemplatesService } from '@modules/settings/services';

import { ActiveStatusEnum } from '@common/shared/enums';
import { getTemplateMessage } from '@common/shared/helpers';

import { ROUTES_DATA } from '@client/shared/constants';
import { TemplateTypeEnum } from '@client/shared/enums';
import { ITemplate } from '@client/shared/interfaces';

@Component({
  selector: 'its-template-btn',
  templateUrl: './template-btn.component.html',
  styleUrls: ['./template-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateBtnComponent {
  ROUTES_DATA = ROUTES_DATA;
  ActiveStatusEnum = ActiveStatusEnum;
  @Input() templateUserName = '';
  @Input() templateType: TemplateTypeEnum[] = [];
  @Input() excludeType: TemplateTypeEnum[] = [];
  @Input() templateAutoSelect: TemplateTypeEnum;

  @Output() templateSelected = new EventEmitter<string>();

  templates$: Observable<ITemplate[]> = this.templatesService
    .getTemplates()
    .pipe(
      map((templates) => {
        if (this.excludeType.length) {
          return templates.filter((t) => !this.excludeType.includes(t.type));
        }
        if (this.templateType.length) {
          return templates.filter((t) => this.templateType.includes(t.type));
        }
        return templates;
      }),
      tap((templates) => {
        if (this.templateAutoSelect) {
          const template = templates.find(
            (t) => t.type === this.templateAutoSelect
          );
          if (template) {
            this.selectTemplate(template);
          }
        }
      })
    );
  constructor(
    readonly templatesService: TemplatesService,
    readonly dialogRef: MatDialog
  ) {}

  selectTemplate(template: ITemplate) {
    const value = getTemplateMessage(
      template?.description,
      this.templateUserName
    );
    this.templateSelected.emit(value);
  }

  closeDialog() {
    this.dialogRef.closeAll();
  }

  createTemplate() {
    window
      .open(
        window.location.origin +
          '/' +
          ROUTES_DATA.SETTINGS.children.TEMPLATES.url +
          '?new=1',
        '_blank'
      )
      .focus();
  }
}
