import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

import { TagsService } from '@modules/settings/services';

import { ModalService } from '@core/services';

import { ITag } from '@client/shared/interfaces';

export const COLOR_OPTIONS = [
  'green-1',
  'green-2',
  'yellow-1',
  'yellow-2',
  'red-1',
  'red-2',
  'violet-1',
  'violet-2',
  'violet-3',
  'blue-1',
  'blue-2',
  'black',
];

@Component({
  selector: 'its-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent implements OnInit {
  @ViewChild('colorMenuTrigger') colorMenuTrigger: MatMenuTrigger;
  @ViewChild('tagMenuTrigger') tagMenuTrigger: MatMenuTrigger;
  colors = COLOR_OPTIONS;
  allTags$ = this.tagsService.getAllTags();
  @ViewChild('tagInput') tagInput: ElementRef;
  form: FormGroup;

  @Input() tableDots = false;
  @Input() owner = false;
  @Input() tags: ITag[] = [];
  @Input() resumeId: string;

  @Output() allTags = new EventEmitter<ITag[]>();

  constructor(
    readonly tagsService: TagsService,
    readonly modalService: ModalService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = new FormGroup({
      value: new FormControl('', Validators.required),
      color: new FormControl(COLOR_OPTIONS[1], Validators.required)
    });
    this.changeDetector.markForCheck();
  }

  createTag() {
    this.onBlur();
    if (this.form.valid) {
      this.tagsService.createTag(this.form.value).subscribe((tag) => {
        this.tagInput.nativeElement.value = '';
        this.form.patchValue({value: ''});
        this.setResumeTag(tag);
        this.changeDetector.markForCheck();
      });
    }
  }

  removeTag(ev: any, id: number) {
    ev.stopPropagation();
    const options = {
      header: _('common.modal.title.warning'),
      body: _('tagForm.modal.removeTag'),
      buttons: [
        { name: _('common.modal.button.cancel') },
        { name: _('common.modal.button.confirm') }
      ]
    };
    this.modalService.openModal(options, (answer) => {
      if (answer) {
        this.tagsService.deleteTag(id).subscribe(() => {
          this.tags = this.tags.filter((t) => t.id !== id);
          this.emitTags();
          this.changeDetector.markForCheck();
        });
      }
    });
  }

  setResumeTag(tag: ITag) {
    if (!this.tags.find((t) => t.id === tag.id)) {
      this.tagsService.setResumeTag(tag.id, this.resumeId).subscribe((tag) => {
        this.tags.push(tag);
        this.emitTags();
        this.changeDetector.markForCheck();
      });
    }
  }

  removeResumeTag(id: number) {
    this.tagsService.removeResumeTag(id, this.resumeId).subscribe(() => {
      this.tags = this.tags.filter((t) => t.id !== id);
      this.emitTags();
      this.changeDetector.markForCheck();
    });
  }

  onBlur() {
    const value = this.form.value.value as string | null;
    const trimmedValue =
      value && typeof value !== 'number'
        ? value.trim().replace(/\s{2,}/g, ' ')
        : value;

    if (trimmedValue !== value) {
      this.form.patchValue({ value: trimmedValue });
    }
  }

  focusInput() {
    this.tagInput?.nativeElement?.focus();
  }

  selectColor(color: string) {
    this.form.get('color').patchValue(color);
    this.colorMenuTrigger.closeMenu();
    if (this.form.get('value')?.value) {
      this.createTag();
      this.tagMenuTrigger.closeMenu();
    } else {
      this.focusInput();
    }
  }

  emitTags() {
    this.allTags.emit(this.tags);
  }
}
