import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';

import { Observable } from 'rxjs';

import { IJob } from '@modules/jobs/interfaces';
import { JobsService } from '@modules/jobs/services';

import { GlobalObjectService, ValuesService } from '@core/services';

import { JobViewTypeEnum } from '@common/shared/enums';

import { ROUTES_DATA } from '@client/shared/constants';
import { ICategory, IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-hot-jobs',
  templateUrl: './hot-jobs.component.html',
  styleUrls: ['./hot-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotJobsComponent {
  JobViewTypeEnum = JobViewTypeEnum;
  ROUTES_DATA = ROUTES_DATA;
  @Input() set job(job: IJob) {
    if (this.globalObjectService.isPlatformBrowser()) {
      this.getJobs(job.categories, job.id);
    }
  }
  jobs: IJob[] = [];
  values$: Observable<IValues> = this.valuesService.getValues();

  constructor(
    readonly jobsService: JobsService,
    readonly valuesService: ValuesService,
    readonly globalObjectService: GlobalObjectService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  getJobs(categories: ICategory[], jobId: number) {
    return this.jobsService
      .getHotJobs({
        page: 1,
        size: 10,
        categories: categories.map((c) => c.id).join(','),
        jobId
      })
      .pipe()
      .subscribe((data) => {
        this.jobs = data.map((j) => ({
          ...j,
          cities: [...new Set(j.offices.map((o) => o.city))].join(', ')
        }));
        this.changeDetector.markForCheck();
      });
  }
}
