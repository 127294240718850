<mat-slide-toggle
  [class.big]="big"
  [aria-label]="ariaLabel"
  [aria-labelledby]="ariaLabelledby"
  [checked]="checked"
  [color]="color"
  [disableRipple]="disableRipple"
  [id]="id"
  [labelPosition]="labelPosition"
  [name]="name"
  [required]="required"
  (change)="onChange($event)"
  [formControl]="control"
><ng-content></ng-content></mat-slide-toggle>
