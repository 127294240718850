import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

import { pluck } from 'rxjs/operators';

import {
  IJobApplyAnswer,
  IJobApplyRequest,
  IJobInviteAnswer
} from '@modules/jobs/interfaces';
import { JobsRequestService } from '@modules/jobs/services';

import { ModalService, ValuesService } from '@core/services';

import { InviteStatusEnum } from '@common/shared/enums';

import { CommentModalComponent } from '@client/shared/components/comment-modal';
import { TemplateTypeEnum } from '@client/shared/enums';

@Directive({
  selector: '[itsCompanyAnswerRequest]'
})
export class CompanyAnswerRequestDirective {
  @Input() request: IJobApplyRequest;
  @Input() status: InviteStatusEnum;

  @Output() statusChanged = new EventEmitter<Partial<IJobApplyRequest>>();

  constructor(
    readonly valuesService: ValuesService,
    readonly modalService: ModalService,
    readonly jobsRequestService: JobsRequestService
  ) {}

  @HostListener('click')
  answerRequest() {
    const data: IJobApplyAnswer = {
      status: this.status
    };

    if (this.request.status === InviteStatusEnum.Pending) {
      const header =
        this.status === InviteStatusEnum.Canceled
          ? _('jobRequest.modal.title.cancelRequest')
          : _('jobRequest.modal.title.approveRequest');
      const subTitle =
        this.status === InviteStatusEnum.Canceled
          ? _('jobRequest.modal.subTitle.cancelRequest')
          : _('jobRequest.modal.subTitle.approveRequest');

      this.modalService.openComponent(
        CommentModalComponent,
        (answer: IJobInviteAnswer) => {
          if (answer) {
            this.jobsRequestService
              .answerRequestByCompany(this.request.id, { ...data, ...answer })
              .subscribe((res) => {
                this.statusChanged.emit({
                  reason: res.reason,
                  status: res.status
                });
              });
          }
        },
        {
          width: '500px',
          data: {
            header,
            subTitle,
            messageRequired: this.status === InviteStatusEnum.Approve,
            reasons:
              this.status === InviteStatusEnum.Canceled
                ? this.valuesService
                    .getValues()
                    .pipe(pluck('cancelRequestReasons'))
                : null,
            userName: this.request?.user?.firstName,
            type: TemplateTypeEnum.CancelRequest
          }
        }
      );
    }
  }
}
