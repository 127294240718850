<ng-container [ngSwitch]="mobileType">
  <div class="j-c_f-e">
    <its-button (click)="close()" variant="icon">
      <its-icon size="18" svgIcon="clear"></its-icon>
    </its-button>
  </div>
  <ng-container *ngSwitchCase="PwaDeviceEnum.Ios">
    <div>
      <div class="m-b_12">
        {{ 'pwa.ios.description' | translate }}
      </div>
      <div class="j-c_c a-i_c">
        <its-icon class="ios-icons" svgIcon="ios-menu"></its-icon>
        <its-icon
          class="next-icon"
          size="16"
          svgIcon="chevron_right"
        ></its-icon>
        <its-icon class="ios-icons" svgIcon="ios-add-home"></its-icon>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="PwaDeviceEnum.Android">
    <div class="j-c_c m-b_24">
      <its-button (click)="installPwa()" color="primary">
        {{ 'pwa.button.addToHomeScreen' | translate }}
      </its-button>
    </div>
  </ng-container>
</ng-container>
