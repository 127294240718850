<div [class.social-icon]="social && control.disabled">
  <ng-container *ngIf="social && control.disabled">
    <a [href]="control?.value" target="_blank">
      <its-icon
        size="fit"
        class="c-pre-primary"
        [svgIcon]="getIcon(control?.value) || 's-unknown'"
      ></its-icon>
    </a>
  </ng-container>
  <mat-form-field appearance="outline" [@.disabled]="true">
    <mat-label *ngIf="!staticPlaceholder"
      >{{ placeholder }}
      <ng-container *ngIf="isRequired">*</ng-container>
    </mat-label>
    <input
      [placeholder]="staticPlaceholder ? placeholder : ''"
      [autocomplete]="autocomplete"
      [formControl]="control"
      [type]="type"
      matInput
      (keydown.enter)="onEnterKeyDown($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [maxlength]="maxlength"
    />
    <mat-hint>
      <ng-content></ng-content>
    </mat-hint>
    <its-icon
      *ngIf="prefixIcon"
      class="c-text-primary"
      size="20"
      matPrefix
      [svgIcon]="prefixIcon"
    ></its-icon>
    <its-button
      *ngIf="control.enabled && clear && control?.value"
      matSuffix
      variant="icon"
      aria-label="Clear"
      (click)="$event.stopPropagation(); clearValue()"
    >
      <its-icon size="15" svgIcon="clear"></its-icon>
    </its-button>
    <its-button
      *ngIf="toggleVisibility"
      matSuffix
      (mouseover)="type = 'text'"
      (mouseout)="type = 'password'"
      variant="icon"
    >
      <its-icon svgIcon="visibility"></its-icon>
    </its-button>
    <its-button *ngIf="icon" matSuffix (click)="onIconAction()" variant="icon">
      <its-icon [svgIcon]="icon" size="16"></its-icon>
    </its-button>
    <mat-error *ngIf="control.hasError('required')">
      {{ 'common.form.errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="control.hasError('phone')">
      {{ 'common.form.errors.phone' | translate }}
    </mat-error>
    <mat-error *ngIf="control.hasError('url')">
      {{ 'common.form.errors.url' | translate }}
    </mat-error>
    <mat-error *ngIf="control.hasError('youtube')">
      {{ 'common.form.errors.youtube' | translate }}
    </mat-error>
    <mat-error *ngIf="control.hasError('maxlength')">
      <span
        *ngIf="control.getError('maxlength') as error"
        translate
        [translateParams]="{ maxlength: error.requiredLength }"
        >common.form.errors.max-length</span
      >
    </mat-error>
    <mat-error *ngIf="control.hasError('minlength')">
      <span
        *ngIf="control.getError('minlength') as error"
        translate
        [translateParams]="{ minlength: error.requiredLength }"
        >common.form.errors.min-length</span
      >
    </mat-error>
    <mat-error *ngIf="control.hasError('email')">
      {{ 'common.form.errors.email' | translate }}
    </mat-error>
    <mat-error *ngIf="control.hasError('password')">
      {{ 'common.form.errors.pattern' | translate }}
    </mat-error>

    <!--<mat-error *ngIf="control.hasError('integer')">
      Use integers only
    </mat-error>
    <mat-error *ngIf="control.hasError('alphanum')">
      Use letters and numbers only
    </mat-error>
    <mat-error *ngIf="control.hasError('specialChars')">
      Can't contain any of these characters: &quot; / \ [ ] : | &lt; &gt; + = ; ? * ,
    </mat-error>
    <mat-error *ngIf="control.hasError('alphanumWithSymbols')">
      Can't use non-English letters
    </mat-error>
    <mat-error *ngIf="control.hasError('alphanumWithSymbols')" >
      Can't use non-English letters
    </mat-error>
    <mat-error *ngIf="control.hasError('unique')">
      This field should be unique
    </mat-error>-->
  </mat-form-field>
</div>
