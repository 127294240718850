<div class="no-data-placeholder">
  <div class="empty-logo" [class.chat]="chat">
  </div>
  <div class="empty-content">
    <h2>
      {{ title ? (title | translate) : ('common.title.emptyDataTitle' | translate) }}
    </h2>
    <p [innerHTML]="description ? (description | translate) : ('common.title.emptyDataDescription' | translate)"></p>
  </div>
  <ng-content></ng-content>
</div>
