import { Injectable } from '@angular/core';

import { IBilling } from '@modules/settings/interfaces';

@Injectable({
  providedIn: 'root'
})
export class BillingFactory {
  fromResponse(res: IBilling): IBilling {
    return res;
  }
}
