import { LanguageEnum, PlanPeriodEnum } from '../enums';

export const CHAT_DURATION_H = 48;
export const CHAT_DURATION_D = 90;
export const INVITE_REQUEST_DURATION_D = 10;
export const COMPANY_DELETE_DURATION_D = 5;
export const PROFILE_DELETE_DURATION_H = 48;

export const VACANCY_DURATION_D = 45;
export const HOT_VACANCY_DURATION_D = 14;

export const COOKIE_CID_KEY = 'kup_cid';

export const EXPIRED_REASON = 9998;
export const OTHER_REASON = 9999;
export const ENGLISH_NOT_REQUIRED = 1;

export const LINKEDIN_LINK = 'https://www.linkedin.com/company/kupno/about';
export const FACEBOOK_LINK = 'https://www.facebook.com/KupnoJobboard';
export const INSTAGRAM_LINK = 'https://www.instagram.com/kupno_jobboard';
export const TELEGRAM_LINK = 'https://t.me/kupno_io';
export const RECRUITER_TELEGRAM_LINK = 'https://t.me/+KnSvhV_wsps3ZjIy';
export const HELLO_EMAIL = 'hello@kupno.io';

export const MAX_QUERY_LIMIT = 50;

export const COOKIE_DOMAIN = '.kupno.io';

export const RESUME_LIMIT = 3;

export const ANONYMOUS_ID_PREFIX = 'secret_';
export const ANONYMOUS_VALUE = 'Anonymous';

export const INVOICE_ID_PREFIX = 'ID-';

export const MAIN_LANGUAGE = LanguageEnum.English;

export const SALARY_MAX = 10000;
export const SALARY_STEP = 500;
export const SALARY_STEP_INTERVAL = 4;

export const TRIAL_PLAN_PERIOD_D: PlanPeriodEnum = PlanPeriodEnum.ThreeMonths;
export const PLAN_FEATURES_DURATION_D = 10;
export const REVIEW_DURATION_D = 365;
export const ADMIN_WITH_USER = 'admin_auth_user';

export const EXPERIENCES_MAP = {
  // client/src/app/shared/constants/experiences.ts
  1: null,
  2: 1,
  3: 2,
  4: 3,
  5: 5,
  6: 7
};

export const UAH_CURRENCY_RATE = 40;

export const BULK_CANCEL_REQUEST_NAME_PLACEHOLDER = 'here_we_past_name';

export const INCLUDE_LOCATION = 'incl_l';
export const EXCLUDE_LOCATION = 'excl_l';
export const LOCATION_JOIN = '_-_';
