<its-button
  variant="icon"
  matTooltip="{{ 'emoji.tooltip.selectEmoji' | translate }}"
  [matMenuTriggerFor]="templateMenu"
  matTooltipPosition="above"
  (click)="onOpenMenu()"
>
  <its-icon svgIcon="emoji"></its-icon>
</its-button>

<mat-menu
  #templateMenu="matMenu"
  class="emoji-menu"
  xPosition="before"
  yPosition="above"
>
  <emoji-mart
    (click)="$event.stopPropagation()"
    emoji="point_up"
    set="apple"
    color="#0f5fdc"
    [darkMode]="darkMode$ | async"
    (emojiClick)="addEmoji($event)"
    [i18n]="translations"
  ></emoji-mart>
</mat-menu>
