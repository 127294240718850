import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ReqInvStateEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const REQ_INV_STATE: ISelectOption[] = [
  {
    id: ReqInvStateEnum.DiscussionOfVacancy,
    value: _('reqInvState.discussionOfVacancy')
  },
  {
    id: ReqInvStateEnum.CommunicationTerminated,
    value: _('reqInvState.communicationTerminated')
  },
  {
    id: ReqInvStateEnum.HRInterviewPlaned,
    value: _('reqInvState.HRInterviewPlaned')
  },
  {
    id: ReqInvStateEnum.HRInterviewPassed,
    value: _('reqInvState.HRInterviewPassed')
  },
  {
    id: ReqInvStateEnum.HRInterviewFailed,
    value: _('reqInvState.HRInterviewFailed')
  },
  {
    id: ReqInvStateEnum.TechnicalInterviewPlaned,
    value: _('reqInvState.technicalInterviewPlaned')
  },
  {
    id: ReqInvStateEnum.TechnicalInterviewPassed,
    value: _('reqInvState.technicalInterviewPassed')
  },
  {
    id: ReqInvStateEnum.TechnicalInterviewFailed,
    value: _('reqInvState.technicalInterviewFailed')
  },
  {
    id: ReqInvStateEnum.ClientInterviewPlaned,
    value: _('reqInvState.clientInterviewPlaned')
  },
  {
    id: ReqInvStateEnum.ClientInterviewPassed,
    value: _('reqInvState.clientInterviewPassed')
  },
  {
    id: ReqInvStateEnum.ClientInterviewFailed,
    value: _('reqInvState.clientInterviewFailed')
  },
  {
    id: ReqInvStateEnum.OfferSent,
    value: _('reqInvState.offerSent')
  },
  {
    id: ReqInvStateEnum.OfferAccepted,
    value: _('reqInvState.offerAccepted')
  },
  {
    id: ReqInvStateEnum.OfferDeclined,
    value: _('reqInvState.offerDeclined')
  },
  {
    id: ReqInvStateEnum.StartedWork,
    value: _('reqInvState.startedWork')
  }
];
