import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalObjectService } from '@core/services';

@Component({
  selector: 'its-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {
  constructor(
    readonly router: Router,
    readonly globalObjectService: GlobalObjectService
  ) {}

  ngOnInit(): void {
    if (!this.globalObjectService.isPlatformBrowser()) {
      console.log(
        '404 - redirect - ',
        this.globalObjectService.getCurrentUrl()
      );
    }
    this.router.navigateByUrl('/');
  }
}
