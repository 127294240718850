<mat-card class="pricing-plans">
  <mat-card-content>
    <mat-tab-group
      class="m-b_32"
      [selectedIndex]="selectedIndex"
      (selectedIndexChange)="selectPeriod($event)"
    >
      <ng-container *ngFor="let period of periods">
        <mat-tab>
          <ng-template mat-tab-label>
            <div
              class="plan-tab"
              [innerHTML]="
                'package.period.interval'
                  | translate
                    : {
                        interval: period.value,
                        discount: discounts[period.value]
                      }
              "
            ></div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

    <div class="plans">
      <ng-container *ngFor="let plan of PLANS">
        <div class="plan">
          <div class="plan-header">
            {{ plan.name }}
            <div *ngIf="plan.planType === recommended" class="recommended">
              {{ 'package.text.recommended' | translate }}
            </div>
          </div>
          <div class="plan-content">
            <div class="d_f">
              <div class="plan-price">
                <ng-container *ngIf="currency === CurrencyEnum.USD">
                  <span class="currency">$</span>
                  <b>{{
                    plan.periods[selectedPeriod].prices[CurrencyEnum.USD]
                      | number
                  }}</b>
                </ng-container>
                <ng-container *ngIf="currency === CurrencyEnum.UAH">
                  <b>{{
                    plan.periods[selectedPeriod].prices[CurrencyEnum.UAH]
                      | number
                  }}</b>
                  <span class="currency">₴</span>
                </ng-container>
              </div>
            </div>
            <ul class="plan-features">
              <li
                class="active"
                [innerHTML]="
                  'package.feature.publications'
                    | translate
                      : {
                          count:
                            +plan.periods[selectedPeriod].features.publications
                        }
                "
              ></li>
              <li
                class="active"
                [innerHTML]="
                  'package.feature.hotPublications'
                    | translate
                      : {
                          count:
                            plan.periods[selectedPeriod].features
                              .hotPublications
                        }
                "
              ></li>
              <ng-container *ngFor="let f of plansTexts[plan.planType]">
                <li
                  [class.active]="f.active"
                  [innerHTML]="f.value | translate"
                ></li>
              </ng-container>
            </ul>
          </div>
          <div *ngIf="havePlan || admin" class="plan-action">
            <its-button
              color="primary"
              classes="w_100 btn-lg"
              itsNeedAuth
              (click)="selectPlan($event, plan.planType)"
            >
              {{ 'package.button.selectPackage' | translate }}
              {{ admin ? ' - Admin' : '' }}
            </its-button>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!havePlan" class="free-trial">
      <h2 translate [translateParams]="{ days: TRIAL_PLAN_PERIOD_D }">
        package.text.startTrialInfo
      </h2>
      <div class="j-c_c">
        <its-button
          color="primary"
          classes="w_100 btn-lg"
          itsNeedAuth
          (click)="selectPlan($event, PlanTypeEnum.Standard, true)"
        >
          {{ 'package.button.startTrial' | translate }}
        </its-button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
