import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'its-share-social',
  templateUrl: './share-social.component.html',
  styleUrls: ['./share-social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareSocialComponent implements OnInit {
  @Input() url = '';

  constructor() {}

  ngOnInit(): void {}
}
