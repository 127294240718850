import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  Optional,
  ViewChild,
  ChangeDetectorRef,
  SkipSelf,
  Input,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import {
  ISelectGroupOption,
  ISelectOption
} from '@client/shared/components/select/select-option.interface';
import { Features, AutoCleanupFeature } from '@client/shared/decorators';

@Component({
  selector: 'its-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class SelectComponent<T>
  extends BaseControlComponent<T>
  implements OnInit
{
  @ViewChild('searchInput') searchInput: ElementRef;
  selectFilterCtrl = new FormControl();
  readonly destroyed$: Observable<unknown>;
  @Input() clear = false;
  @Input() staticClear = false;
  @Input() maxValues: number = null;
  @Input() showAllSelected = true;
  @Input() search = false;
  @Input() multiple = false;
  @Input() optional = true;
  @Input() readonly = false;
  @Input() group = false;
  @Input() options: ISelectOption[] = [];
  @Input() optionsGroup: ISelectGroupOption[] = [];
  @Input() placeholder: string;
  @Input() fullSize = false;
  @Input() fullHeight = false;

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly onClear = new EventEmitter<boolean>();

  @ViewChild(MatSelect, { static: true }) readonly select: MatSelect;

  readonly control = new FormControl(null);

  @Input() displayFn: (value: ISelectOption[], id: number) => void;
  @Input() valueFn = (option: ISelectOption | null) => option?.id;
  @Input() labelFn = (option: ISelectOption | null) => option?.value;
  @Input() compareFn = (
    option: ISelectOption | string,
    selected: ISelectOption | number | null
  ) => {
    if (selected === null) {
      return false;
    }

    if (typeof option === 'object' && typeof selected === 'object') {
      return option.id === selected.id;
    } else {
      return option === selected;
    }
  };

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.patchSelectOpen();
    // this.selectHeight();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        if (this.maxValues && (value as any)?.length > this.maxValues) {
          this.control.setErrors({
            maxValues: { maxValues: this.maxValues }
          });
        }
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  /*selectHeight() {
    this.select.openedChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isOpen) => {

      });
  }*/

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(i: number, option: ISelectOption) {
    return option.id;
  }

  private patchSelectOpen() {
    const open = this.select.open;

    this.select.open = () => {
      if (!this.readonly) {
        open.call(this.select);
      }
    };
  }

  clearValue(event: any) {
    event.stopPropagation();
    const value = this.multiple ? [] : '';
    this.control.setValue(value);
    this.onClear.emit(true);
  }

  checkEmptyValue() {
    const value = this.control?.value;
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return !!value?.length;
      } else {
        return !!value;
      }
    } else {
      return !!value;
    }
  }

  onOpenedChange(open: boolean) {
    if (open) {
      this.searchInput?.nativeElement?.focus();
    } else {
      this.selectFilterCtrl.setValue('', {
        emitViewToModelChange: true
      });
    }
  }
}
