export enum ReqInvStateEnum {
  DiscussionOfVacancy = 1,
  CommunicationTerminated = 2,
  HRInterviewPlaned = 3,
  HRInterviewPassed = 4,
  HRInterviewFailed = 5,
  TechnicalInterviewPlaned = 6,
  TechnicalInterviewPassed = 7,
  TechnicalInterviewFailed = 8,
  ClientInterviewPlaned = 9,
  ClientInterviewPassed = 10,
  ClientInterviewFailed = 11,
  OfferSent = 12,
  OfferAccepted = 13,
  OfferDeclined = 14,
  StartedWork = 15
}
