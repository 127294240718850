<div>
  <label>
    <input
      #fileInput
      class="d_n"
      [disabled]="disableUpload"
      (change)="fileUploaded($event)"
      accept=".png, .jpg, .jpeg"
      type="file"
    />
    <ng-content></ng-content>
  </label>
</div>

<its-drawer class="drawer-md" [opened]="openDrawer" (closedStart)="onClosedStart($event)">
  <ng-container *ngIf="openDrawer">
    <its-cropper
      [imageType]="imageType"
      (croppedResult)="getImageResult($event)"
      [imageChangedEvent]="imageChangedEvent"
      [imageBase64]="imageBase64"
    >
    </its-cropper>
  </ng-container>
</its-drawer>
