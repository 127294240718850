import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactsComponent, OurServicesComponent } from '@modules/public/pages';

import { AuthGuard, AdminGuard } from '@core/guards';

import { MAIN_LANGUAGE } from '@common/shared/constants';
import { LanguageEnum } from '@common/shared/enums';

import { ConfirmationComponent } from '@client/shared/components/auth';
import { NotFoundComponent } from '@client/shared/components/not-found';
import { ROUTES_DATA } from '@client/shared/constants';

import { environment } from '@env';

const languages = Object.values(LanguageEnum).filter(
  (l) => l !== MAIN_LANGUAGE
);

const routes: Routes = [
  {
    path: ROUTES_DATA.CONFIRMATION.url,
    component: ConfirmationComponent
  },
  {
    path: ROUTES_DATA.HOME.url,
    loadChildren: () =>
      import('@modules/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: ROUTES_DATA.ADMIN.url,
    loadChildren: () =>
      import('@modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: ROUTES_DATA.CALENDAR.url,
    loadChildren: () =>
      import('@modules/calendar/calendar.module').then((m) => m.CalendarModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES_DATA.COMPANIES.url,
    loadChildren: () =>
      import('@modules/companies/companies.module').then(
        (m) => m.CompaniesModule
      )
  },
  {
    path: ROUTES_DATA.EVENTS.url,
    loadChildren: () =>
      import('@modules/events/events.module').then((m) => m.EventsModule)
  },
  {
    path: ROUTES_DATA.JOBS.url,
    loadChildren: () =>
      import('@modules/jobs/jobs.module').then((m) => m.JobsModule)
  },
  {
    path: ROUTES_DATA.NEWS.url,
    loadChildren: () =>
      import('@modules/news/news.module').then((m) => m.NewsModule)
  },
  {
    path: ROUTES_DATA.RESUME.url,
    loadChildren: () =>
      import('@modules/resumes/resumes.module').then((m) => m.ResumesModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES_DATA.TALENT_POOL.url,
    loadChildren: () =>
      import('@modules/contacts/contacts.module').then((m) => m.ContactsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES_DATA.SETTINGS.url,
    loadChildren: () =>
      import('@modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES_DATA.CHAT.url,
    loadChildren: () =>
      import('@modules/chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuard]
  },
  {
    path: ROUTES_DATA.ANALYTICS.url,
    loadChildren: () =>
      import('@modules/analytics/analytics.module').then(
        (m) => m.AnalyticsModule
      )
  },
  {
    path: ROUTES_DATA.PUBLIC.url,
    loadChildren: () =>
      import('@modules/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: ROUTES_DATA.PRICING.url,
    component: OurServicesComponent
  },
  {
    path: ROUTES_DATA.CONTACTS.url,
    component: ContactsComponent
  }
]
  .map((p) => [
    p,

    ...languages.map((l) => ({
      ...p,
      path: p.path ? `${l}/${p.path}` : `${l}`
    }))
  ])
  .flat();
routes.push({ path: '**', component: NotFoundComponent });

if (!environment.production) {
  routes.unshift({
    path: 'ui',
    loadChildren: () => import('@modules/ui/ui.module').then((m) => m.UiModule)
  });
  routes.unshift({
    path: 'uk/ui',
    loadChildren: () => import('@modules/ui/ui.module').then((m) => m.UiModule)
  });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class RoutingModule {}
