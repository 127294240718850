<form *ngIf="form" [formGroup]="form" (ngSubmit)="bookDemo()">
  <mat-card>
    <mat-card-content>
      <h2>
        <b>{{ 'bookDemo.form.title' | translate }}</b>
      </h2>

      <p>{{ 'bookDemo.form.description' | translate }}</p>

      <div class="two-col">
        <div class="form-control">
          <its-input
            formControlName="firstName"
            required
            placeholder="{{ 'bookDemo.fistName.placeholder' | translate }}"
          ></its-input>
        </div>
        <div class="form-control">
          <its-input
            formControlName="lastName"
            required
            placeholder="{{ 'bookDemo.lastName.placeholder' | translate }}"
          ></its-input>
        </div>
      </div>
      <div class="two-col">
        <div class="form-control">
          <its-input
            formControlName="email"
            required
            placeholder="{{ 'bookDemo.email.placeholder' | translate }}"
          ></its-input>
        </div>
        <div class="form-control">
          <its-phone formControlName="phone" required></its-phone>
        </div>
      </div>
      <div class="form-control">
        <its-input
          formControlName="companyName"
          required
          placeholder="{{ 'bookDemo.companyName.placeholder' | translate }}"
        ></its-input>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <div class="j-c_f-e">
        <its-button type="submit" color="primary">
          {{ 'bookDemo.button.bookDemo' | translate }}
        </its-button>
      </div>
    </mat-card-actions>
  </mat-card>
</form>
