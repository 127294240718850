import { ActiveStatusEnum } from '@common/shared/enums';

export interface IFile extends File {
  created: number;
  filename: string;
  id: number;
  mimetype: string;
  originalName: string;
  path: string;
  originPath?: string;
  size: number;
  preview?: string;
  status: ActiveStatusEnum;
}
