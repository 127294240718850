import { ChartOptions, ChartConfiguration } from 'chart.js';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, ViewChild
} from '@angular/core';

import { IChartData } from '@modules/analytics/interfaces';

import { LocaleService } from '@core/services';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'its-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent {
  greyColor = '#dae2ec';
  greyText = '#304476';
  lineChartOptions: ChartOptions<'line'>;
  lineChartData: ChartConfiguration<'line'>['data'];

  @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;
  @Input() height = 450;
  _graph: IChartData;
  @Input() set graph(graph: IChartData) {
    if (graph) {
      this._graph = graph;
      this.lineChartData = {
        labels: graph.labels,
        datasets: graph.datasets.map((el, i) => ({
          data: el.data,
          label: el.label,
          borderColor: el.borderColor,
          ...this.getChartColor(el.borderColor),
        }))
      };
      this.getChartOptions();
      this.changeDetector.markForCheck();
    }
  }

  get graph() {
    return this._graph;
  }

  constructor(
    readonly localeService: LocaleService,
    readonly changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
  }

  getChartColor(color: any) {
    return {
      backgroundColor: 'transparent',
      borderWidth: 3,
      borderColor: color,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointBorderWidth: 0,
      pointRadius: 6,
      pointHoverBackgroundColor: '#fff',
      pointHoverRadius: 6,
      pointHoverBorderWidth: 4,
      pointHoverBorderColor: color,

      pointStyle: 'rect'
      // pointStyle: PointStyle = "circle" | "cross" | "crossRot" | "dash" | "line" | "rect" | "rectRounded" | "rectRot" | "star" | "triangle" | false | HTMLImageElement | HTMLCanvasElement
      // borderDash: [15]
    };
  }

  getChartOptions() {
    this.lineChartOptions = {
      responsive: false,
      elements: {
        line: {
          tension: 0
        }
      },
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          backgroundColor: '#0a46a5',
          caretPadding: 10,
          bodyColor: '#ffffff',
          bodyAlign: 'center',
          bodyFont: {
            family: '"Nunito Sans", sans-serif',
            weight: '600',
            size: 14
          },
          padding: {
            top: 5,
            bottom: 5,
            left: 10,
            right: 10
          },
          displayColors: false,
          position: 'nearest',
          yAlign: 'bottom',
          callbacks: {
            beforeLabel: (tooltipItem) => {
              return `${this.graph.prefix} ${tooltipItem.dataset.data[tooltipItem.dataIndex]}`
            },
            title: () => null,
            label: () => null
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false
          },
          ticks: {
            // padding: 15,
            color: this.greyText
          }
        },
        /* xAxes: {
           afterFit: (axis) => {
             axis.paddingRight = 100;
             return axis;
           }
         },*/
        y: {
          // min: 0,
          offset: true,
          grid: {
            color: this.greyColor,
          },
          border: {
            display: false,
            dash: [7],
          },
          ticks: {
            padding: 15,
            color: this.greyText,
            maxTicksLimit: 6,
            precision: 0,

            // precision: option?.valuePercent ? null : 0
          }
        },
      },
    };
  }
}
