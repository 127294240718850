import { ICompany } from '@modules/companies/interfaces';
import { IJob } from '@modules/jobs/interfaces';

import { FilterInformIntervalEnum } from '@common/shared/enums';
import { ReadyToWorkEnum } from '@common/shared/enums/ready-to-work.enum';

import { SearchTypeEnum } from '@client/shared/enums';
import {
  ICategory,
  IDomain,
  ILanguage,
  IWorkType
} from '@client/shared/interfaces';

export interface IFilterForm {
  id?: number;
  categories?: number[] | ICategory[] | string | any;
  workTypes?: number[] | IWorkType[];
  domains?: number[] | IDomain[];
  languages?: number[] | ILanguage[];
  title?: string;
  position?: string;
  location?: string;
  includeLocation?: string;
  excludeLocation?: string;
  name?: string;
  english?: number;
  experience?: number;
  excludedCompanies?: string[] | ICompany[];
  excludedCompaniesValue?: string;
  search?: SearchTypeEnum;
  filterValue?: string;
  job?: number;
  salaryFrom?: number;
  salaryTo?: number;
  currentJob?: IJob;
  veteran?: boolean;
  readyToWork?: ReadyToWorkEnum;

  interval?: FilterInformIntervalEnum;
  intervalValue?: string;
  filterName?: string;
}
