<form [formGroup]="form" class="modal">
  <its-button class="close" variant="icon" mat-dialog-close>
    <its-icon size="18" svgIcon="clear"></its-icon>
  </its-button>

  <h1 mat-dialog-title>{{ data.header | translate }}</h1>
  <div mat-dialog-content>
    <p *ngIf="data.subTitle">{{ data.subTitle | translate }}</p>
    <div *ngIf="data.reasons | async as reasons" class="form-control">
      <its-select
        formControlName="reason"
        placeholder="{{ 'commentModal.reason.placeholder' | translate }}"
        [optional]="false"
        [options]="reasons"
        (changed)="reasonSelected($event, reasons)"
      ></its-select>
    </div>
    <div class="form-control">
      <its-textarea
        [template]="true"
        [excludeType]="[
          data.type === TemplateTypeEnum.CancelRequest
            ? TemplateTypeEnum.CancelInvite
            : TemplateTypeEnum.CancelRequest
        ]"
        [templateUserName]="data?.userName"
        formControlName="message"
        placeholder="{{ 'commentModal.comment.placeholder' | translate }}"
      >
      </its-textarea>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button [mat-dialog-close]="false" variant="basic">
      {{ data.cancel | translate }}
    </its-button>

    <its-button (click)="confirm()" [disabled]="form.invalid" color="primary">
      {{ data.confirm | translate }}
    </its-button>
  </div>
</form>
