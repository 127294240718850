import { ActiveStatusEnum } from '@common/shared/enums';

export interface IBookDemo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  status: ActiveStatusEnum;
  created: Date;
}
