<h1 class="c-default-white j-c_s-b">
  <div>
    <span class="m-r_16">{{ 'public.pricing.title' | translate }}</span
    ><small class="f-w_r"
      >({{ 'pricing.text.recommendations' | translate }})</small
    >
  </div>
  <div>
    <its-button [link]="'/' + ROUTES_DATA.CONTACTS.url" color="accent">
      {{ 'bookDemo.button.bookDemo' | translate }}
    </its-button>
  </div>
</h1>
<div class="m-b_16">
  {{
    'pricing.text.explanation'
      | translate: { days: VACANCY_DURATION_D, hot: HOT_VACANCY_DURATION_D }
  }}
</div>

<div class="m-b_24" *ngIf="profile$ | async"></div>

<its-currency-switcher (change)="currency = $event"> </its-currency-switcher>
<div class="m-b_32">
  <its-packages
    [currency]="currency"
    [havePlan]="havePlan"
    (planSelected)="planSelected($event)"
  ></its-packages>
</div>

<mat-card>
  <mat-card-content>
    <div class="m-b_24">
      <h3 class="c-default-white">
        {{ 'pricing.title.vacancyServices' | translate }}
      </h3>
      <p class="c-black">
        <span
          [innerHTML]="
            'pricing.description.placementOfVacancies'
              | translate: { days: VACANCY_DURATION_D }
          "
        ></span>
      </p>
    </div>
    <div class="pricing-table m-b_16">
      <div class="table-list-container">
        <ul class="table-list">
          <li class="table-title">
            <div>{{ 'pricing.table.quantity' | translate }}</div>
            <div>{{ 'pricing.table.price' | translate }}, {{ currency }}</div>
            <div>
              {{ 'pricing.table.pricePerJob' | translate }}, {{ currency }}
            </div>
            <div></div>
          </li>

          <ng-container *ngFor="let public of jobsCount; let i = index">
            <li class="not-hover">
              <div>
                <div class="table-first-row">{{ public.id }}</div>
              </div>
              <div>
                <div class="table-first-row">
                  {{ public.prices[currency] * public.id | number }}
                </div>
              </div>
              <div>
                <div class="table-first-row">
                  {{ public.prices[currency] | number }}&nbsp;
                  <ng-container *ngIf="i > 0"
                    >{{ 'pricing.vacancy.text.saveMany' | translate }}
                    <span>{{
                      public.id * jobsCount[0].prices[currency] -
                        public.id * public.prices[currency] | number
                    }}</span
                    ><span class="currency">{{ currency }}</span>
                  </ng-container>
                </div>
              </div>
              <div></div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </mat-card-content>
</mat-card>
