import { LogicConditionEnum } from '@common/shared/enums';
import { parseLocation } from '@common/shared/helpers';

import { COUNTRIES } from '@client/shared/constants';

export function getLocationValue(region: string) {
  const { countryCode, regionCode, city } = parseLocation(region);
  return region
    ? `${[city, regionCode, COUNTRIES[countryCode]].filter(Boolean).join(', ')}`
    : '';
}

export function getBooleanLocationValueWithTitle(
  location: string,
  label: string
) {
  if (location) {
    return `${label}: ${location
      .split('__')
      .map((r) => getLocationValue(r))
      .join(` ${LogicConditionEnum.And} `)}`;
  }
  return '';
}
