import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';

import { AuthService, IProfile } from '@core/auth';
import { LocaleService } from '@core/services';

import { LanguageEnum } from '@common/shared/enums';
import { setCookie } from '@common/shared/helpers';

import { Destroyable } from '@client/shared/abstracts';
import { AUTH_REDIRECT, ROUTES_DATA } from '@client/shared/constants';
import { AuthQueryEnum } from '@client/shared/enums';

@Component({
  selector: 'its-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent extends Destroyable implements OnInit {
  @Input() invReqCounters: {
    invites: number;
    requests: number;
  };
  currentUrl = '';
  ROUTES_DATA = ROUTES_DATA;
  navMenu = [];
  profile: IProfile;

  constructor(
    readonly router: Router,
    readonly authService: AuthService,
    readonly localeService: LocaleService,
    readonly changeDetector: ChangeDetectorRef
  ) {
    super();
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((route: NavigationEnd) => {
        this.currentUrl = route.url
          .split('?')[0]
          .replace(`/${LanguageEnum.Ukraine}`, '')
          .replace(`/${LanguageEnum.English}`, '');
        this.changeDetector.markForCheck();
      });
  }

  getLinkActive(url) {
    return url === this.currentUrl;
  }

  toAuth(ev: any, company = false) {
    ev.preventDefault();
    const url = company
      ? `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children.FORM.url}`
      : `/${ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url}`;

    if (this.profile) {
      this.router.navigateByUrl(url);
    } else {
      setCookie(AUTH_REDIRECT, url, 0.0138); // for 20 min
      this.authService.toAuth(AuthQueryEnum.SignUp);
    }
  }

  ngOnInit() {
    this.authService
      .getProfile(false)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((profile) => {
        this.profile = profile?.exist ? profile : null;
        this.navMenu = [
          {
            title: 'Admin panel',
            url: `/${ROUTES_DATA.ADMIN.url}`,
            show: profile?.isAdmin
          },
          {
            title: _('nawMenu.link.vacancies'),
            url: `/${ROUTES_DATA.JOBS.url}`,
            show: true
          },
          {
            title: _('nawMenu.link.companies'),
            url: `/${ROUTES_DATA.COMPANIES.url}`,
            show: true
          },
          {
            title: _('nawMenu.link.stats'),
            url: `/${ROUTES_DATA.ANALYTICS.children.GENERAL.url}`,
            show: false
          },
          {
            title: _('nawMenu.link.resume'),
            url: `/${ROUTES_DATA.RESUME.url}`,
            show: profile?.company?.isActive,
            class: 'separator-item desk-item resume-menu'
          },
          {
            title: _('nawMenu.link.myJobs'),
            url: `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.JOBS.url}`,
            show: profile?.company?.isActive,
            class: 'desk-item'
          },
          {
            title: _('nawMenu.link.requests'),
            url: `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.REQUESTS.url}`,
            show: profile?.company?.isActive,
            class: 'desk-item',
            counterKey: 'requests'
          },
          {
            title: _('nawMenu.link.invites'),
            url: `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.INVITES.url}`,
            show: profile?.company?.isActive,
            class: 'desk-item',
            counterKey: 'invites'
          }
        ];

        this.changeDetector.markForCheck();
      });
  }
}
