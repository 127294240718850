<mat-form-field appearance="outline" [@.disabled]="true">
  <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>

  <div class="d_f">
    <input
      *ngIf="onlyMonth"
      matInput
      [disabled]="control?.disabled"
      (focus)="openPicker()"
      [value]="control?.value ? (control.value | date: 'MMM y') : ''"
      readonly
    />
    <input
      [class.hide-picker]="onlyMonth"
      [formControl]="control"
      matInput
      readonly
      (focus)="onFocus()"
      (keydown.enter)="onEnterKeyDown($event)"
      [matDatepicker]="picker"
      [max]="maxDate"
      [min]="minDate"
    />
  </div>

  <its-icon
    *ngIf="prefixIcon"
    class="c-text-primary"
    size="20"
    matPrefix
    [svgIcon]="prefixIcon"
  ></its-icon>

  <its-button
    *ngIf="control.enabled && clear && control?.value"
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="$event.stopPropagation(); clearValue()"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>

  <mat-datepicker
    #picker
    (monthSelected)="onMonthSelected($event)"
    [startView]="startView"
  ></mat-datepicker>

  <!--<its-icon
    disabled
    color="light-gray"
    class="cursor-pointer"
    variant="small"
    *ngIf="icon && control.value"
    icon="action:cross-circle"
    matSuffix
    (click)="onClear()"
  ></its-icon>-->

  <!-- <its-icon
    [color]="control.disabled ? 'light-gray' : 'primary'"
    class="cursor-pointer"
    icon="action:calendar"
    matSuffix
    (click)="onFocus()"
  ></its-icon>-->

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
</mat-form-field>
