import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { delay, skip, tap } from 'rxjs/operators';

import { ICompanyType } from '@modules/companies/interfaces';

import { LocaleService } from '@core/services/locale.service';

import {
  LANGUAGE_LEVEL,
  AGENT_ROLES,
  ENGLISH_NOT_REQUIRED
} from '@common/shared/constants';
import {
  CurrencyEnum,
  InvoiceLangEnum,
  PlanTypeEnum,
  WorkTypeGroupEnum
} from '@common/shared/enums';

import { BaseHttpService } from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import {
  WORK_TYPES_TRANSLATES,
  MODE_WORK_GROUPS,
  CANCEL_INVITE_REASONS,
  CANCEL_REQUEST_REASONS,
  HIRING_STEPS,
  EXPERIENCES,
  EMPLOYEES,
  FILTER_INFORM_INTERVAL,
  STATE_OPTIONS,
  REQ_INV_FILTER,
  JOBS_FILTER,
  COMPANY_REVIEW_CATEGORIES,
  NO_LOADER_PARAM,
  CLOSE_VACANCY_REASONS,
  LOGIC_CONDITIONS,
  READY_TO_WORK,
  REQ_INV_STATE
} from '@client/shared/constants';
import {
  ICategory,
  IDomain,
  ILanguage,
  IValues,
  IWorkType,
  IWorkTypesGroup
} from '@client/shared/interfaces';
import { mapCategoryGroup } from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ValuesService extends BaseHttpService {
  workTypes: IWorkType[] = [];
  thisYear = new Date().getFullYear();
  experiencesFrom = [...new Array(40)].map(() => {
    const year = this.thisYear--;
    return {
      id: year,
      value: year
    };
  });
  private values$ = new BehaviorSubject<IValues>(this.createValues());

  constructor(readonly localeService: LocaleService) {
    super('');
    this.loadValues().subscribe();
    this.localeService
      .getLocale()
      .pipe(skip(1), delay(1))
      .subscribe(() => {
        this.rebuildValues();
      });
  }

  getValues(): Observable<IValues> {
    return this.values$.asObservable();
  }

  createValues() {
    return {
      ...this?.values$?.getValue(),
      english: [...LANGUAGE_LEVEL],
      englishMap: LANGUAGE_LEVEL.reduce(
        (acc, el) => {
          acc[el.id] = el.value;
          return acc;
        },
        {
          [ENGLISH_NOT_REQUIRED]: this.processValue(
            _('common.englishLevel.notRequired')
          )
        }
      ),
      hiringSteps: HIRING_STEPS.map((el) => this.processValueOption(el)),
      employees: EMPLOYEES.map((el) => this.processValueOption(el)),
      reqInvStatusFilterOptions: REQ_INV_FILTER.map((el) =>
        this.processValueOption(el)
      ),
      jobsStatusFilterOptions: JOBS_FILTER.map((el) =>
        this.processValueOption(el)
      ),
      employeesMap: EMPLOYEES.reduce((acc, el) => {
        acc[el.id] = this.processValue(el.value);
        return acc;
      }, {}),
      agentRoles: [...(AGENT_ROLES as any)],
      experiences: EXPERIENCES.map((el) => this.processValueOption(el)),
      experiencesMap: EXPERIENCES.reduce((acc, el) => {
        acc[el.id] = this.processValue(el.value);
        return acc;
      }, {}),
      experiencesFrom: this.experiencesFrom,
      cancelInviteReasons: CANCEL_INVITE_REASONS.filter(
        (el) => !el.disabled
      ).map((el) => this.processValueOption(el)),
      cancelInviteReasonsMap: CANCEL_INVITE_REASONS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      closeVacancyReasons: CLOSE_VACANCY_REASONS.filter(
        (el) => !el.disabled
      ).map((el) => this.processValueOption(el)),
      closeVacancyReasonsMap: CLOSE_VACANCY_REASONS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      reqInvState: REQ_INV_STATE.filter((el) => !el.disabled).map((el) =>
        this.processValueOption(el)
      ),
      reqInvStateMap: REQ_INV_STATE.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      cancelRequestReasons: CANCEL_REQUEST_REASONS.map((el) =>
        this.processValueOption(el)
      ),
      cancelRequestReasonsMap: CANCEL_REQUEST_REASONS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      filterInformInterval: FILTER_INFORM_INTERVAL.map((el) =>
        this.processValueOption(el)
      ),
      filterInformIntervalMap: FILTER_INFORM_INTERVAL.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      stateOptions: STATE_OPTIONS.map((el) => this.processValueOption(el)),
      invoiceLanguages: this.getInvoiceLanguages(),
      currencies: this.getCurrencies(),
      plansNameMap: this.getPlanNames(),
      companyReviewCategories: COMPANY_REVIEW_CATEGORIES.map((el) =>
        this.processValueOption(el)
      ),
      companyReviewCategoriesMap: COMPANY_REVIEW_CATEGORIES.reduce(
        (obj, el) => {
          obj[el.id] = this.processValue(el.value);
          return obj;
        },
        {} as any
      ),
      logicConditions: LOGIC_CONDITIONS.filter((el) => !el.disabled).map((el) =>
        this.processValueOption(el)
      ),
      logicConditionsMap: LOGIC_CONDITIONS.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      readyToWork: READY_TO_WORK.map((el) => this.processValueOption(el)),
      readyToWorkMap: READY_TO_WORK.reduce((obj, el) => {
        obj[el.id] = this.processValue(el.value);
        return obj;
      }, {} as any),
      ...this.mapWorkTypes()
    };
  }

  loadValues() {
    return forkJoin([
      this.get<ICategory[]>({ [NO_LOADER_PARAM]: true }, 'categories'),
      this.get<IDomain[]>({ [NO_LOADER_PARAM]: true }, 'domains'),
      this.get<ILanguage[]>({ [NO_LOADER_PARAM]: true }, 'languages'),
      this.get<IWorkType[]>({ [NO_LOADER_PARAM]: true }, 'work-types'),
      this.get<ICompanyType[]>(
        { [NO_LOADER_PARAM]: true },
        'companies/company-types'
      )
    ]).pipe(
      tap(([categories, domains, languages, workTypes, companyTypes]) => {
        this.workTypes = workTypes.content;
        this.values$.next({
          ...this.createValues(),
          categoriesGroup: mapCategoryGroup(categories.content),
          categories: categories.content,
          categoriesMap: categories.content.reduce((acc, el) => {
            acc[el.id] = this.processValue(el.value);
            return acc;
          }, {}),
          domains: domains.content,
          languages: languages.content,
          companyTypes: companyTypes.content,
          ...this.mapWorkTypes()
        } as IValues);
      })
    );
  }

  rebuildValues() {
    this.values$.next({ ...this.createValues() });
  }

  processValue(key: string) {
    return this.localeService.getInstant(key);
  }

  processValueOption(el: ISelectOption<any>) {
    return {
      ...el,
      value: this.processValue(el.value)
    };
  }

  getInvoiceLanguages(): ISelectOption<any>[] {
    return [
      {
        id: InvoiceLangEnum.UK,
        value: 'Українська'
      },
      {
        id: InvoiceLangEnum.EN,
        value: 'English'
      }
    ];
  }

  getCurrencies(): ISelectOption<CurrencyEnum>[] {
    return [
      {
        id: CurrencyEnum.USD,
        value: CurrencyEnum.USD
      },
      {
        id: CurrencyEnum.UAH,
        value: CurrencyEnum.UAH
      }
    ];
  }

  getPlanNames() {
    return {
      [PlanTypeEnum.Basic]: 'Basic',
      [PlanTypeEnum.Standard]: 'Standard',
      [PlanTypeEnum.Premium]: 'Premium'
    };
  }

  private mapWorkTypes(): {
    workTypesGroup: IWorkTypesGroup[];
    workTypes: ISelectOption[];
    workTypesTranslate: { [key: string]: string };
  } {
    const workTypes = this.workTypes || [];
    const getWorkTypeOption = (option: IWorkType) => {
      return {
        ...option,
        valueKey: option.value,
        value: this.processValue(WORK_TYPES_TRANSLATES[option.value])
      };
    };
    const data = {
      [WorkTypeGroupEnum.Time]: {
        group: WorkTypeGroupEnum.Time,
        title: this.processValue(MODE_WORK_GROUPS[WorkTypeGroupEnum.Time]),
        options: []
      },
      [WorkTypeGroupEnum.Place]: {
        group: WorkTypeGroupEnum.Place,
        title: this.processValue(MODE_WORK_GROUPS[WorkTypeGroupEnum.Place]),
        options: []
      }
    };
    workTypes.forEach((t) => {
      try {
        data[t.group]?.options.push(getWorkTypeOption(t));
      } catch (e) {}
    });
    return {
      workTypesGroup: Object.values(data),
      workTypes: workTypes.map((t) => getWorkTypeOption(t)),
      workTypesTranslate: WORK_TYPES_TRANSLATES
    };
  }

  getWorkTypesTranslations() {
    return WORK_TYPES_TRANSLATES;
  }
}
