<mat-paginator
  class="its-paginator"
  *ngIf="total > pageSize"
  [hidePageSize]="true"
  [length]="total"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pageEvent($event)"
>
</mat-paginator>
