import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'its-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent {
  title = '';
  text = '';
  button = '';
}
