<mat-form-field [class]="" appearance="outline" [@.disabled]="true">
  <!--   <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>-->
  <input
    #phoneInput
    [autocomplete]="autocomplete"
    [formControl]="control"
    [type]="type"
    [class.has-value]="!!control.value"
    matInput
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  />
  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <its-button
    *ngIf="control.enabled && clear && control?.value"
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="$event.stopPropagation(); clearValue()"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>
  <its-button *ngIf="icon" matSuffix (click)="onIconAction()" variant="icon">
    <its-icon [svgIcon]="icon" size="16"></its-icon>
  </its-button>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
  <mat-error *ngIf="control.hasError('phone')">
    {{ 'common.form.errors.phone' | translate }}
  </mat-error>
</mat-form-field>
