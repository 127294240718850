export interface IDataLocations {
  [count: string]: {
    [region: string]: {
      region: string;
      cities: string[];
      citiesMap?: Set<string>;
    };
  };
}

export interface Set<T> {
  add(value: T): this;
  clear(): void;
  delete(value: T): boolean;
  forEach(
    callbackfn: (value: T, value2: T, set: Set<T>) => void,
    thisArg?: any
  ): void;
  has(value: T): boolean;
  readonly size: number;
}
