import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'its-item-news',
  templateUrl: './item-news.component.html',
  styleUrls: ['./item-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemNewsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
