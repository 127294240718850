import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@core/auth';
import { GlobalObjectService } from '@core/services/global-object.service';

import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class StartUpService {
  constructor(
    readonly authService: AuthService,
    readonly httpClient: HttpClient,
    readonly globalObjectService: GlobalObjectService
  ) {}

  preloadUser() {
    return forkJoin([
      this.globalObjectService.isPlatformBrowser()
        ? this.httpClient
            .jsonp(
              `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&language=en`,
              'callback'
            )
            .pipe(catchError(() => of(null)))
        : of(null),
      this.authService.isLoggedIn()
        ? this.authService.loadProfile()
        : of(null).pipe(map(() => this.authService.setProfile(null)))
    ]);
  }
}
