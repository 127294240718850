import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';

import { LocaleService, ThemeService } from '@core/services';

@Component({
  selector: 'its-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiComponent implements OnInit {
  darkMode$ = this.themeService.getDarkTheme();
  translations = {
    search: this.localeService.getInstant(_('emoji.placeholder.search')),
    emojilist: this.localeService.getInstant(_('emoji.title.emojilist')),
    notfound: this.localeService.getInstant(_('emoji.title.notfound')),
    clear: this.localeService.getInstant(_('emoji.button.clear')),
    categories: {
      search: this.localeService.getInstant(_('emoji.category.search')),
      recent: this.localeService.getInstant(_('emoji.category.recent')),
      people: this.localeService.getInstant(_('emoji.category.people')),
      nature: this.localeService.getInstant(_('emoji.category.nature')),
      foods: this.localeService.getInstant(_('emoji.category.foods')),
      activity: this.localeService.getInstant(_('emoji.category.activity')),
      places: this.localeService.getInstant(_('emoji.category.places')),
      objects: this.localeService.getInstant(_('emoji.category.objects')),
      symbols: this.localeService.getInstant(_('emoji.category.symbols')),
      flags: this.localeService.getInstant(_('emoji.category.flags')),
      custom: this.localeService.getInstant(_('emoji.category.custom'))
    },
    skintones: {
      1: this.localeService.getInstant(_('emoji.skin.1')),
      2: this.localeService.getInstant(_('emoji.skin.2')),
      3: this.localeService.getInstant(_('emoji.skin.3')),
      4: this.localeService.getInstant(_('emoji.skin.4')),
      5: this.localeService.getInstant(_('emoji.skin.5')),
      6: this.localeService.getInstant(_('emoji.skin.6'))
    }
  };
  // https://github.com/scttcper/ngx-emoji-mart/tree/1291377118b0062ae73ffab838f51cdbfc8cddf2
  @Output() openMenu = new EventEmitter<boolean>();
  @Output() selectEmoji = new EventEmitter<any>();
  constructor(
    readonly localeService: LocaleService,
    readonly themeService: ThemeService
  ) {}

  ngOnInit(): void {}

  addEmoji(ev: any) {
    this.selectEmoji.emit(ev?.emoji?.native);
  }

  onOpenMenu() {
    this.openMenu.emit(true);
  }
}
