import { ChangeDetectorRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';

import { HelperService } from '@core/services';

import { EntityFilterActivityEnum } from '@common/shared/enums';
import { IQuery } from '@common/shared/interfaces';

import { Destroyable } from '../lifecycle';

export abstract class PaginatorClass extends Destroyable {
  defaultSort = null;
  selectAll = false;
  haveSelected = false;
  selectDontAllowed = false;

  pageSize: number;
  allDataCount = 0;
  total = 0;
  initComponent = false;
  query: IQuery;
  paginator = new Subject<IQuery>();
  activatedRoute = inject(ActivatedRoute);
  helperService = inject(HelperService);
  changeDetector = inject(ChangeDetectorRef);
  router = inject(Router);

  protected constructor(config?: {
    clear?: boolean;
    pageSize?: number;
    defaultSort?: string;
  }) {
    super();
    const { pageSize, clear, defaultSort } = config || {};

    this.defaultSort = defaultSort || null;
    this.pageSize = pageSize ?? 20;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.query = {
      ...queryParams,
      size: this.pageSize,
      page: clear ? 1 : queryParams?.page ?? 1,
      sort: queryParams?.sort ?? this.defaultSort
    };
  }

  pageEvent(ev: IQuery) {
    this.paginator.next({
      ...this.query,
      ...ev
    });
  }

  triggerPageEvent(query: IQuery = {}) {
    this.paginator.next({
      ...this.query,
      page: 1,
      ...query
    });
  }

  changeQueryParam() {
    this.query = this.helperService.filterEmptyQueryData(this.query);

    const data = JSON.parse(JSON.stringify(this.query));
    delete data.size;
    if (data.page === 1) {
      delete data.page;
    }
    if (data.sort === this.defaultSort) {
      delete data.sort;
    }
    this.helperService.changeQueryParam(data);
  }

  checkAvailableList(listLength: number) {
    if (!listLength && this.total) {
      const { page, size } = this.query;
      const query: IQuery = {
        size,
        page
      };

      if (page > 1) {
        query.page = page - 1;
      } else {
        query.page = 1;
      }
      this.paginator.next(query);
    }
  }

  trackByFn(i: number, el: any) {
    return el.id;
  }

  actionClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  /**
   * Bulk edit
   */

  checkSelectRegardingType(type: any, data: any[]) {
    if (type === EntityFilterActivityEnum.Archived) {
      data.forEach((o) => {
        o.selected = false;
      });
      this.selectAll = false;
      this.haveSelected = false;
      this.selectDontAllowed = true;
    } else {
      this.selectDontAllowed = false;
    }
    this.changeDetector.markForCheck();
  }

  onSelectAll(value: boolean, data: any[]) {
    this.haveSelected = value;
    if (value) {
      data.forEach((o) => {
        o.selected = true;
      });
    } else {
      data.forEach((o) => {
        o.selected = false;
      });
    }
    this.changeDetector.markForCheck();
  }

  onSelectOne(value: boolean, el: any, data: any[]) {
    const selectedLength = data.filter((o) => o.selected).length;

    if (value) {
      el.selected = value;
      if (selectedLength === data.length) {
        this.selectAll = true;
      }
    } else {
      el.selected = value;
      this.selectAll = false;
    }
    this.haveSelected = !!selectedLength;
    this.changeDetector.markForCheck();
  }
}
