import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InviteStatusEnum } from '@common/shared/enums';

export const REQ_INV_FILTER = Object.freeze([
  {
    id: InviteStatusEnum.Pending,
    value: _('common.status.pending')
  },
  {
    id: InviteStatusEnum.Approve,
    value: _('common.status.approve')
  },
  {
    id: InviteStatusEnum.Canceled,
    value: _('common.status.canceled')
  },
]);
