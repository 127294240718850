<mat-form-field
  class="its-textarea its-boolean-search-textarea"
  appearance="outline"
  [@.disabled]="true"
  (click)="openSearch($event)"
>
  <mat-label
    >{{ placeholder }}
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>

  <its-button
    *ngIf="control.enabled && clear && control?.value"
    matSuffix
    variant="icon"
    aria-label="Clear"
    (click)="$event.stopPropagation(); clearValue()"
  >
    <its-icon size="15" svgIcon="clear"></its-icon>
  </its-button>

  <input class="d_n" [formControl]="control" />

  <textarea
    #textarea
    class="textarea"
    [rows]="rows"
    matInput
    cdkTextareaAutosize
    [formControl]="textAreaControl"
    [cdkAutosizeMinRows]="1"
    [readonly]="true"
  >
  </textarea>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    {{ 'common.form.errors.required' | translate }}
  </mat-error>
</mat-form-field>
