import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HttpClient
} from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/uk';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { CoreModule } from '@core/core.module';
import { CustomTranslationHandler, LocaleService } from '@core/services';

import { LocalizationEnum } from '@common/shared/enums';

import { SharedModule } from '@client/shared/shared.module';

import { environment } from '@env';

import { AppComponent } from './app.component';
import { translateBrowserLoaderFactory } from './core/loaders/translate-browser.loader';
import { RoutingModule } from './routing';

registerLocaleData(localeUk);
registerLocaleData(localeEN);

const config: SocketIoConfig = { url: `${environment.serverUrl}`, options: {} };

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientJsonpModule,
    SocketIoModule.forRoot(config),
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      /*  loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      },*/
      // compiler configuration
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomTranslationHandler
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule,
    CoreModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: { locale: string }) =>
        LocalizationEnum[localeService.locale] // locale for date pipe
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
