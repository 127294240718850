import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IAgent } from '@modules/companies/interfaces';
import { AgentsService } from '@modules/companies/services';

import { AuthService } from '@core/auth';
import { RecaptchaService, SeoService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import { ROUTES_DATA } from '@client/shared/constants';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class SignUpComponent {
  formSubmitted = false;
  form: FormGroup;
  emailWasSend = false;
  agentInvite: IAgent = null;
  agentToken = null;
  ROUTES_DATA = ROUTES_DATA;
  checkAgree = false;

  constructor(
    readonly fb: FormBuilder,
    readonly authService: AuthService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly changeDetector: ChangeDetectorRef,
    readonly agentsService: AgentsService,
    readonly formState: FormStateDispatcher,
    readonly recaptchaService: RecaptchaService,
    readonly seoService: SeoService
  ) {
    const agentToken = this.activatedRoute.snapshot.queryParamMap.get('agent');
    if (agentToken) {
      this.agentsService.getAgentInvite(agentToken).subscribe((invite) => {
        this.agentInvite = invite;
        this.agentToken = agentToken;
        this.buildForm(invite.email);
      });
    } else {
      this.buildForm();
    }
  }

  /* createTestUsers() {
    this.companies.forEach((c, i) => {
      this.authService.signUp({
        username: c.email,
        firstName: c.name,
        lastName: `Com ${i + 1}`,
        password: `${c.password}`,
        isAgentConfirm: true
      }).subscribe()
    })
    this.resumes.forEach(c => {
      this.authService.signUp({
        username: c.email,
        firstName: c.name.split(' ')[0],
        lastName: c.name.split(' ')[1],
        password: `${c.password}`,
        isAgentConfirm: true
      }).subscribe()
    })
  }*/

  buildForm(username?: string) {
    this.form = this.fb.group({
      username: new FormControl(
        {
          value: username || '',
          disabled: !!this.agentInvite
        },
        Validators.compose([
          Validators.email,
          Validators.required,
          CustomValidators.plusInEmail
        ])
      ),
      firstName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(24)])
      ),
      lastName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.maxLength(24)])
      ),
      password: new FormControl(
        '',
        Validators.compose([Validators.required, CustomValidators.password])
      ),
      agree: new FormControl(null, Validators.requiredTrue)
    });
    this.changeDetector.markForCheck();
  }

  send(form) {
    this.emailWasSend = false;
    this.checkAgree = true;
    this.formState.onSubmit.notify();
    if (this.form.valid) {
      this.recaptchaService
        .getRecaptchaToken('SignUp')
        .subscribe((recaptcha) => {
          const model = { ...this.form.getRawValue(), recaptcha };
          if (this.agentInvite) {
            model.isAgentConfirm = true;
            return this.acceptInvite(model, form);
          } else {
            return this.authService.signUp(model).subscribe(() => {
              this.emailWasSend = true;
              this.checkAgree = false;
              this.form = null;
              this.changeDetector.detectChanges();
              this.buildForm();
            });
          }
        });
    }
  }

  acceptInvite(model = null, form = null) {
    return this.authService
      .acceptInvite(this.agentToken, model)
      .subscribe(() => {
        this.router.navigateByUrl(
          ROUTES_DATA.COMPANIES.children.PROFILE.children.JOBS.url
        );
        this.buildForm();
        this.changeDetector.markForCheck();
      });
  }

  confirm() {
    return this.acceptInvite({ isAgentConfirm: true });
  }

  backToLogIn() {
    this.authService.toAuth();
  }

  googleSignIn() {
    this.authService.googleSignIn();
  }
}
