import { AbstractControl } from '@angular/forms';

export function RequiredFileType(type: string) {
  return (control: AbstractControl) => {
    const file = control.value;
    if (file) {
      const extension = file.name.split('.')[1].toLowerCase();
      if (type.toLowerCase() !== extension.toLowerCase()) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }
    return null;
  };
}
