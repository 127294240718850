<ng-container *ngIf="tableDots; else general">
  <ul class="tags-dots">
    <ng-container *ngFor="let tag of tags">
      <li class="{{ tag.color }}"></li>
    </ng-container>
  </ul>
</ng-container>
<ng-template #general>
  <ul class="tags-items">
    <ng-container *ngFor="let tag of tags">
      <li class="tag {{ tag.color }}">
        <span class="a-i_b">
          <span class="title">
            {{ tag.value }}
          </span>
          <small *ngIf="!owner"
            >&nbsp;-&nbsp;{{ tag.user.firstName }}
            {{ tag.user.lastName }}</small
          >
        </span>
        <button
          *ngIf="owner"
          (click)="removeResumeTag(tag.id)"
          class="button-empty"
        >
          <its-icon size="15" svgIcon="clear"></its-icon>
        </button>
      </li>
    </ng-container>
    <li *ngIf="owner">
      <its-button
        variant="basic"
        color="primary"
        classes="btn-sm"
        #tagMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="tagMenu"
        (menuOpened)="focusInput()"
      >
        <its-icon svgIcon="bookmark"></its-icon>
        <span class="title">{{ 'tags.button.addNewTag' | translate }}</span>
      </its-button>

      <mat-menu #tagMenu="matMenu" xPosition="after" class="tags-menu">
        <ng-container *ngIf="allTags$ | async as allTags">
          <div
            class="form-item"
            [class.have-tags]="allTags?.length"
            mat-menu-item
            (click)="$event.stopPropagation()"
          >
            <form
              class="tag-form"
              [formGroup]="form"
              (keyup.enter)="createTag()"
            >
              <input
                #tagInput
                (blur)="onBlur()"
                (change)="focusInput()"
                formControlName="value"
                placeholder="{{ 'tagForm.tagName.placeholder' | translate }}"
              />
              <its-button
                variant="icon"
                matTooltip="{{ 'tagForm.tooltip.selectColor' | translate }}"
                matTooltipPosition="after"
                #colorMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="tagColorMenu"
              >
                <span
                  class="marker form-marker {{ form.get('color')?.value }}"
                ></span>
              </its-button>
            </form>
          </div>
          <ng-container *ngFor="let tag of allTags">
            <button class="tag-item" mat-menu-item (click)="setResumeTag(tag)">
              <span class="marker {{ tag.color }}"></span>
              <span class="title"> {{ tag.value }} </span>
              <its-button (click)="removeTag($event, tag.id)" variant="icon">
                <its-icon size="15" svgIcon="clear"></its-icon>
              </its-button>
            </button>
          </ng-container>
        </ng-container>
      </mat-menu>

      <mat-menu #tagColorMenu="matMenu" xPosition="after">
        <div class="color-menu" (click)="$event.stopPropagation()">
          <ng-container *ngFor="let color of colors">
            <span (click)="selectColor(color)" class="{{ color }}"></span>
          </ng-container>
        </div>
      </mat-menu>
    </li>
  </ul>
</ng-template>
