<div class="position-relative checkbox-{{bg}}">
  <mat-checkbox
    [formControl]="control"
    [color]="color"
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    [@.disabled]="true"
    [disableRipple]="true"
  >
    <ng-content></ng-content>
  </mat-checkbox>
  <mat-error
    class="checkbox-error"
    *ngIf="showError && control.hasError('required')">
    {{'common.form.errors.checkboxRequired' | translate}}
  </mat-error>
</div>
