<its-drawer class="drawer-md" [opened]="!!authComponent" (closedStart)="onClosedStart()">
  <div [ngSwitch]="authComponent">
    <its-sign-in *ngSwitchCase="AuthQueryEnum.SignIn"></its-sign-in>
    <its-sign-up *ngSwitchCase="AuthQueryEnum.SignUp"></its-sign-up>
    <its-confirmation
      *ngSwitchCase="AuthQueryEnum.Confirmation"
    ></its-confirmation>
    <its-forgot-password
      *ngSwitchCase="AuthQueryEnum.ForgotPass"
    ></its-forgot-password>
    <its-reset-password
      *ngSwitchCase="AuthQueryEnum.ResetPass"
    ></its-reset-password>
    <its-resend-confirm
      *ngSwitchCase="AuthQueryEnum.ResendConfirm"
    ></its-resend-confirm>
  </div>
</its-drawer>
