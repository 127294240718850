import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';

import {
  BehaviorSubject,
  fromEvent,
  identity,
  Observable,
  of,
  race
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  share,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';

const VALUE_CHANGE_DEBOUNCE_TIME = 500;
const SCROLL_DEBOUNCE_TIME = 300;
const NO_ITEMS = 0;
const SINGLE_ITEM = 1;
const DEFAULT_MINLENGTH = 1;
const SCROLL_THRESHOLD = 80;

@Component({
  selector: 'its-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class AutocompleteComponent<T>
  extends BaseControlComponent<T>
  implements OnInit
{
  @ViewChild('searchElement') searchElement: ElementRef;
  readonly destroyed$: Observable<unknown>;

  @Input() valueFn: (value: T | null) => T | null = identity as any;
  @Input() labelFn = identity as any;
  @Input() displayFn = identity as any;
  @Input() optionsFn: (
    search: string | null,
    length?: number
  ) => Observable<T[]>;
  @Input() placeholder: string | null;
  @Input() readonly: boolean;
  @Input() icon: string | null;
  @Input() clearable = true;
  @Input() autoSelect = false;
  @Input() onlyOneOption = false;

  @Output() readonly changed = new EventEmitter<T | null>();

  @ViewChild(MatAutocompleteTrigger, {
    static: true
  })
  readonly autocomplete: MatAutocompleteTrigger;
  @ViewChild(MatInput, {
    read: ElementRef,
    static: true
  })
  readonly input: ElementRef<HTMLInputElement>;

  readonly control = new FormControl(null);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  @Input() minlength = DEFAULT_MINLENGTH;
  @Input() makeInitialRequest = false;

  options$: Observable<T[] | null>;
  options: T[] = [];

  readonly trackByFn: TrackByFunction<T> = (_index: number, element: T) =>
    element;

  readonly modelToViewFormatter = (value: T | null) => {
    if (value === null) {
      this.emptyOptions();
    }

    return value;
  };

  readonly viewToModelParser = (value: T | null) => {
    return value ? this.valueFn(value) : null;
  };

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  get active() {
    return this.input?.nativeElement === document.activeElement;
  }

  get length() {
    return this.input?.nativeElement.value.length;
  }

  get open() {
    return this.autocomplete.panelOpen;
  }

  get loadOnScroll() {
    return this.optionsFn.length > 1;
  }

  ngOnInit() {
    if (!this.valueFn) {
      this.valueFn = this.autoSelect
        ? (value: T | null) => (value instanceof Object ? value : null)
        : identity;
    }

    const validator = this.ctrl.control?.validator ?? null;
    const asyncValidator = this.ctrl.control?.asyncValidator ?? null;

    this.control.setValidators(validator);
    this.control.setAsyncValidators(asyncValidator);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(VALUE_CHANGE_DEBOUNCE_TIME),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        const { value } = this.input.nativeElement;

        this.options$ = (
          this.length < this.minlength
            ? of([])
            : this.loadOptions(value, this.options?.length)
        ).pipe(
          tap((options) => {
            this.options = options;
          })
        );
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });

    if (this.makeInitialRequest) {
      this.control.updateValueAndValidity();
    }
  }

  onInput() {
    if (this.autoSelect) {
      this.control.setErrors({
        nomatch: null
      });
    }
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    const value = this.valueFn(event.option.value);

    this.closeAutocomplete();
    this.changed.emit(value);

    if (!this.onlyOneOption) {
      this.onClearValue();
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  onSelectValue() {
    const { value } = this.autoSelect ? this.input.nativeElement : this.control;
    if (this.control.disabled) {
      return;
    }

    this.emptyOptions();

    if (this.autoSelect) {
      return this.queryAndAutoSelect(value);
    }

    this.changed.emit(value);

    this.closeAutocomplete();
  }

  onClearValue() {
    if (this.readonly || this.control.disabled) {
      return;
    }

    this.control.setValue(null);

    this.closeAutocomplete();
    this.emptyOptions();
    this.changed.emit(null);
    this.changeDetector.markForCheck();
  }

  onAutocompleteOpen() {
    this.setAutocompleteMinWidth();
    this.setAutocompleteScrollBehaviour();
  }

  private setAutocompleteScrollBehaviour() {
    const { autocomplete } = this.autocomplete;
    const element = autocomplete?.panel?.nativeElement as HTMLElement;

    if (!this.loadOnScroll || !element) {
      return;
    }

    fromEvent(element, 'scroll', { passive: true })
      .pipe(
        startWith(element.scrollHeight - element.offsetHeight),
        distinctUntilChanged(),
        debounceTime(SCROLL_DEBOUNCE_TIME),
        map(
          () =>
            element.scrollHeight - (element.scrollTop + element.offsetHeight)
        ),
        pairwise(),
        filter(
          ([prevThreshold, currThreshold]) => currThreshold <= prevThreshold
        ),
        filter(([, currThreshold]) => currThreshold <= SCROLL_THRESHOLD),
        takeUntil(
          race([
            autocomplete.closed,
            autocomplete.opened,
            this.control.valueChanges,
            this.destroyed$
          ])
        )
      )
      .subscribe(() => {
        const { value } = this.input.nativeElement;

        this.options$ = this.loadOptions(value, this.options?.length).pipe(
          map((options) => {
            this.options = [...(this.options ?? []), ...options];

            return this.options;
          }),
          startWith(this.options)
        );
        this.changeDetector.markForCheck();
      });
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    if (this.makeInitialRequest) {
      this.control.updateValueAndValidity();
    }

    this.changeDetector.markForCheck();
  }

  private loadOptions(value: string | null, length?: number) {
    this.loading$.next(true);

    this.changeDetector.markForCheck();

    return this.optionsFn(value, length).pipe(
      share(),
      tap(() => this.loading$.next(false)),
      catchError(() => {
        this.loading$.next(false);

        return of([]);
      })
    );
  }

  private setAutocompleteMinWidth() {
    const { panel } = this.autocomplete.autocomplete;
    if (panel) {
      const panelElement = panel.nativeElement as HTMLElement;
      const elementRect = this.element.nativeElement.getBoundingClientRect();
      const minWidth = elementRect.width;

      panelElement.style.minWidth = `${minWidth}px`;

      this.autocomplete.updatePosition();
    }
  }

  private queryAndAutoSelect(value: string) {
    const { autocomplete } = this.autocomplete;

    this.options$ = this.loadOptions(value, this.options?.length).pipe(
      tap((options) => {
        this.options = options;
      })
    );

    this.options$
      .pipe(
        first(),
        takeUntil(
          race([
            autocomplete.closed,
            this.control.valueChanges,
            this.destroyed$
          ])
        )
      )
      .subscribe((options) => {
        this.changeDetector.markForCheck();

        switch (options?.length) {
          case NO_ITEMS:
            this.control.setErrors({
              nomatch: true
            });

            return this.closeAutocomplete();
          case SINGLE_ITEM:
            this.control.setValue(options[0]);
            this.changed.emit(options[0]);

            return this.closeAutocomplete();
          default:
            return this.openAutocomplete();
        }
      });
  }

  private emptyOptions() {
    this.options$ = of(null);
    this.options = [];
  }

  private openAutocomplete() {
    this.autocomplete.openPanel();
    this.input.nativeElement.focus();
  }

  private closeAutocomplete() {
    setTimeout(() => {
      this.autocomplete.closePanel();
      this.input.nativeElement.blur();
    });
  }
}
