import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { GlobalObjectService } from '@core/services';

@Component({
  selector: 'its-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input() classes = '';
  @Input() variant: 'small' | 'medium' | 'large';
  @Input() color: ThemePalette;
  @Input() size: '16' | '20' | '25' | 'auto' | 'fit' | any = '20';
  @Input() svgIcon = '';
  // https://icons8.com/
  constructor(
    readonly sanitizer: DomSanitizer,
    readonly registry: MatIconRegistry,
    readonly globalObjectService: GlobalObjectService,
    @Optional() @Inject(REQUEST) protected request: Request
  ) {}

  ngOnChanges() {
    const name = this.svgIcon;
    const svgUrl = `/assets/v1/icons/${name}.svg`;
    const domain = this.globalObjectService.isServerRequest()
      ? `${this.globalObjectService.getOrigin()}`
      : '';

    const safeIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      domain + svgUrl
    );
    this.registry.addSvgIcon(name, safeIconUrl);
  }
}
