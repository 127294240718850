import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { AuthService, IProfileResponse } from '@core/auth';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';
import { resumeFromResponse } from '@client/shared/utils';

import {
  IResumeRequest,
  IResumeResponse,
  IResumeUserRequest
} from '../interfaces';
import { ResumeFactory } from './resume.factory';

@Injectable({
  providedIn: 'root'
})
export class ResumeService extends BaseHttpService {
  constructor(
    readonly factory: ResumeFactory,
    readonly authService: AuthService
  ) {
    super('resumes');
  }

  getAllActive(params: IQuery) {
    return this.get<ICountResponse<IResumeResponse[]>>(params).pipe(
      map(({ content }) => ({
        data: content.data.map((i) => this.factory.fromResponse(i)),
        count: content.count
      }))
    );
  }

  getAllFavorites(params: IQuery) {
    return this.get<ICountResponse<IResumeResponse[]>>(
      params,
      'favorites'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((i) => this.factory.fromResponse(i)),
        count: content.count,
        allDataCount: content.allDataCount
      }))
    );
  }

  getOneActive(id: string) {
    return this.get<IResumeResponse>({}, id).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  getOneAnonymous(anonymUuid: string) {
    return this.get<IResumeResponse>({}, `${anonymUuid}/anonymous`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  preview(id: string) {
    return this.get<IResumeResponse>({}, `${id}/preview`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  updateResumeUser(user: IResumeUserRequest) {
    const request = this.factory.toRequestUser(user);

    return this.put<IResumeUserRequest, any>(request, {}, 'user');
  }

  updateResume(resume: IResumeRequest) {
    const request = this.factory.toRequest(resume);

    return this.put<IResumeRequest, IProfileResponse>(
      request,
      {},
      `${resume.id}`
    );
  }

  clearFavorites() {
    return this.put<any>(null, {}, 'clear-favorites');
  }

  addResume() {
    return this.post<any, IResumeResponse>(null, {}, 'create').pipe(
      map(({ content }) => resumeFromResponse(content))
    );
  }

  removeResume(id: string) {
    return this.delete({}, id);
  }

  getRequestResume(requestId: number, resId: string) {
    return this.get<IResumeResponse>({}, `request/${requestId}/${resId}`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  getInviteResume(inviteId: number, resId: string) {
    return this.get<IResumeResponse>({}, `invite/${inviteId}/${resId}`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  followResumeStatus(follow: boolean, id: string) {
    const body = { follow };
    return this.post(body, { [NO_LOADER_PARAM]: true }, `${id}/follow`);
  }
}
