<form
  class="modal"
  *ngIf="values$ | async as values"
  [formGroup]="form"
  (ngSubmit)="search()"
>
  <h1 mat-dialog-title>{{ 'booleanSearch.modal.title' | translate }}</h1>
  <div mat-dialog-content>
    <div class="m-b_32">
      <p>{{ 'booleanSearch.modal.description' | translate }}</p>
    </div>

    <h3 class="c-default-white">
      {{ 'booleanSearch.text.include' | translate }}:
    </h3>
    <div formArrayName="include" class="search-group">
      <ng-container
        *ngFor="let control of includeArray.controls; let i = index"
      >
        <ng-container *ngIf="isEven(i + 1); else locationInclude">
          <div class="condition">
            <its-select
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.condition.placeholder' | translate
              }}"
              [optional]="false"
              [options]="values.logicConditions"
            ></its-select>
          </div>
        </ng-container>
        <ng-template #locationInclude>
          <div class="search-value">
            <its-location
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.location.placeholder' | translate
              }}"
              autocompleteType="(regions)"
              [clearStatic]="true"
              (onClear)="clearSelect($event, i)"
            ></its-location>
          </div>
        </ng-template>
      </ng-container>
      <its-button
        *ngIf="includeArray.controls?.length < maxConditions * 2 - 1"
        variant="mini-fab"
        color="accent"
        classes="square"
        (click)="addCondition()"
      >
        <its-icon svgIcon="add"></its-icon>
      </its-button>
    </div>

    <h3 class="c-default-white">
      {{ 'booleanSearch.text.exclude' | translate }}:
    </h3>
    <div formArrayName="exclude" class="search-group">
      <ng-container
        *ngFor="let control of excludeArray.controls; let i = index"
      >
        <ng-container *ngIf="isEven(i + 1); else locationExclude">
          <div class="condition">
            <its-select
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.condition.placeholder' | translate
              }}"
              [optional]="false"
              [options]="values.logicConditions"
            ></its-select>
          </div>
        </ng-container>
        <ng-template #locationExclude>
          <div class="search-value">
            <its-location
              [formControlName]="i"
              placeholder="{{
                'booleanSearch.location.placeholder' | translate
              }}"
              autocompleteType="(regions)"
              [clearStatic]="true"
              (onClear)="clearSelect($event, i, false)"
            ></its-location>
          </div>
        </ng-template>
      </ng-container>
      <its-button
        *ngIf="excludeArray.controls?.length < maxConditions * 2 - 1"
        variant="mini-fab"
        color="accent"
        classes="square"
        (click)="addCondition(false)"
      >
        <its-icon svgIcon="add"></its-icon>
      </its-button>
    </div>
  </div>
  <div mat-dialog-actions>
    <its-button
      variant="stroked"
      color="accent"
      type="button"
      (click)="onNoClick()"
    >
      {{ 'common.form.button.cancel' | translate }}
    </its-button>
    <its-button type="submit" color="primary">
      {{ 'common.form.button.toApply' | translate }}
    </its-button>
  </div>
</form>
