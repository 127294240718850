<ng-container *ngIf="profile$ | async as profile">
  <ng-container *ngFor="let int of profile?.company?.integrations || []">
    <ng-container [ngSwitch]="int.type">
      <ng-container *ngSwitchCase="IntegrationEnum.Greenhouse">
        <its-button
          variant="basic"
          classes="btn-sm"
          (click)="!disabled && pushCandidate(int.type)"
          [disabled]="disabled"
        >
          <its-icon size="20" svgIcon="greenhouse"></its-icon>
          <span class="title"
            >Greenhouse
            {{
              disabled ? '(' + ('atsButton.info.added' | translate) + ')' : ''
            }}</span
          >
        </its-button>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
