import { OnDestroy, Type, ɵDirectiveDef } from '@angular/core';

import { Observable } from 'rxjs';

import { Destroyable, IDestroyable } from '../../../abstracts';
import { Writable } from '../../../types';

function AutoCleanupFeature<R>(selector?: (x: R) => Observable<unknown>) {
  return function AutoCleanup<T extends Type<unknown>>(
    directiveDef: Writable<ɵDirectiveDef<T>>
  ) {
    const { factory, type } = directiveDef;
    const original = directiveDef.type.prototype as Partial<OnDestroy>;

    directiveDef.factory = () => {
      const instance = factory?.(type) as T;
      const destroyable = Reflect.construct(Destroyable, []) as IDestroyable;

      Object.assign(instance, destroyable);

      Object.assign(directiveDef, {
        onDestroy() {
          original.ngOnDestroy?.call(instance);
          destroyable.ngOnDestroy?.call(instance);
        }
      });

      return instance;
    };
  };
}

export { AutoCleanupFeature };
