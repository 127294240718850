export enum LocalizationEnum {
  uk = 'uk',
  en = 'en-US-POSIX'
}

export enum LanguageEnum {
  Ukraine = 'uk',
  English = 'en'
}

export enum BrowserLanguageEnum {
  'uk-UA' = LanguageEnum.Ukraine,
  'en-GB' = LanguageEnum.English,
}
