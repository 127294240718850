import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { ValuesService } from '@core/services';

import { LogicConditionEnum } from '@common/shared/enums';
import { getResultCategoriesBooleanConditions } from '@common/shared/helpers';

import { Destroyable, FormStateDispatcher } from '@client/shared/abstracts';
import { IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-modal-boolean-search-categories',
  templateUrl: './modal-boolean-search-categories.component.html',
  styleUrls: ['./modal-boolean-search-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ModalBooleanSearchCategoriesComponent
  extends Destroyable
  implements OnInit
{
  form: FormGroup;
  values$: Observable<IValues> = this.valuesService.getValues();
  formValue = '';
  maxConditions: number;
  constructor(
    readonly dialogRef: MatDialogRef<ModalBooleanSearchCategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    readonly valuesService: ValuesService,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  buildForm() {
    this.maxConditions = this.data.maxConditions || 6;
    const value = this.data?.value?.split('_') || [];
    this.form = new FormGroup({
      search: new FormArray(
        value?.length
          ? value.map((v) => {
              const val = isNaN(v) ? v : +v;
              return new FormControl(val, Validators.required);
            })
          : [new FormControl('', Validators.required)]
      )
    });

    combineLatest([
      this.form.valueChanges.pipe(startWith(this.form.value)),
      this.values$
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([value, values]) => {
        if (values.categoriesMap) {
          this.formValue = getResultCategoriesBooleanConditions(
            value.search,
            values.categoriesMap
          );
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  get searchArray() {
    return this.form.get('search') as FormArray;
  }

  addCondition() {
    this.searchArray.push(
      new FormControl(LogicConditionEnum.And, Validators.required)
    );
    this.searchArray.push(new FormControl('', Validators.required));
  }

  clearSelect(ev: any, index: number) {
    if (this.searchArray?.length > 1) {
      this.searchArray.removeAt(index);
      this.searchArray.removeAt(index === 0 ? index : index - 1);
    }
  }

  search() {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      const searchValue = this.form.value.search.join('_');
      this.onNoClick(searchValue);
    }
  }

  isEven(num: number): boolean {
    return num % 2 === 0;
  }
}
