import { FormGroup } from '@angular/forms';

export function Location(key = 'location') {
  return (group: FormGroup) => {
    const control = group.controls[key];
    const { value } = control;
    if (value) {
      if (typeof value === 'string' || !value.coordinates) {
        control.setErrors({ notSelected: true });
        return {
          notSelected: true
        };
      }
      if (!control.getError('required')) {
        control.setErrors(null);
      }
      return null;
    }
    if (!control.getError('required')) {
      control.setErrors(null);
    }
    return null;
  };
}
