import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@core/auth';
import { GlobalObjectService } from '@core/services';

import { PLANS, TRIAL_PLAN_PERIOD_D } from '@common/shared/constants';
import {
  CurrencyEnum,
  PlanPeriodEnum,
  PlanTypeEnum
} from '@common/shared/enums';
import { clickAllowed } from '@common/shared/helpers';

import { ISelectOption } from '@client/shared/components/select';
import { ADMIN_TOKEN, ROUTES_DATA } from '@client/shared/constants';

@Component({
  selector: 'its-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PackagesComponent implements OnInit {
  discounts = {
    [PlanPeriodEnum.Monthly]: 0,
    [PlanPeriodEnum.ThreeMonths]: 10,
    [PlanPeriodEnum.SixMonths]: 20,
    [PlanPeriodEnum.Annual]: 30
  };
  TRIAL_PLAN_PERIOD_D = 90 || TRIAL_PLAN_PERIOD_D; // TODO remove in the end of May
  recommended = PlanTypeEnum.Standard;
  CurrencyEnum = CurrencyEnum;
  selectedIndex = 1;
  selectedPeriod: PlanPeriodEnum = PlanPeriodEnum.ThreeMonths;
  periods: ISelectOption<number, number>[] = [...Object.values(PlanPeriodEnum)]
    .filter((m) => !+PlanPeriodEnum?.[m])
    .map((p) => ({
      id: +p,
      value: +p
    }));
  PLANS = PLANS;
  PlanTypeEnum = PlanTypeEnum;

  plansTexts = {
    [PlanTypeEnum.Basic]: [
      {
        value: _('package.feature.recruiters'),
        active: true
      },
      {
        value: _('package.feature.jobVideo'),
        active: true
      },
      {
        value: _('package.feature.companyBanner'),
        active: false
      },
      {
        value: _('package.feature.newCompanyDesign'),
        active: false
      },
      {
        value: _('package.feature.homePageLogo'),
        active: false
      }
    ],
    [PlanTypeEnum.Standard]: [
      {
        value: _('package.feature.recruiters'),
        active: true
      },
      {
        value: _('package.feature.jobVideo'),
        active: true
      },
      {
        value: _('package.feature.companyBanner'),
        active: true
      },
      {
        value: _('package.feature.newCompanyDesign'),
        active: true
      },
      {
        value: _('package.feature.homePageLogo'),
        active: false
      }
    ],
    [PlanTypeEnum.Premium]: [
      {
        value: _('package.feature.recruiters'),
        active: true
      },
      {
        value: _('package.feature.jobVideo'),
        active: true
      },
      {
        value: _('package.feature.companyBanner'),
        active: true
      },
      {
        value: _('package.feature.newCompanyDesign'),
        active: true
      },
      {
        value: _('package.feature.homePageLogo'),
        active: true
      }
    ]
  };

  admin = !!this.globalObjectService.getStorageData(ADMIN_TOKEN);
  @Input() currency = CurrencyEnum.USD;
  _havePlan: boolean;
  @Input() set havePlan(havePlan: boolean) {
    this._havePlan = havePlan;
    if (!havePlan) {
      this.selectedIndex = 1;
      this.selectedPeriod = PlanPeriodEnum.ThreeMonths;
      this.changeDetector.detectChanges();
    }
  }
  get havePlan() {
    return this._havePlan;
  }
  @Output() planSelected = new EventEmitter<{
    planType: PlanTypeEnum;
    period: PlanPeriodEnum;
  }>();

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly authService: AuthService,
    readonly globalObjectService: GlobalObjectService
  ) {}

  ngOnInit() {
    this.checkRedirect();
  }

  selectPlan(ev: any, planType: PlanTypeEnum, trial = false) {
    if (clickAllowed(ev)) {
      const company = this.authService.getProfileValue()?.company;
      if (company?.isActive && company?.isOwnerAccess) {
        this.planSelected.emit({
          planType,
          period: trial ? TRIAL_PLAN_PERIOD_D : this.selectedPeriod
        });
      } else {
        this.router.navigateByUrl(
          `/${ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children.FORM.url}`
        );
      }
    }
  }

  checkRedirect() {
    const planType =
      +this.activatedRoute.snapshot.queryParamMap.get('planType');
    const period = +this.activatedRoute.snapshot.queryParamMap.get('period');
    if (planType && period) {
      this.router.navigate([], {
        replaceUrl: true,
        queryParams: {
          planType: null,
          period: null
        },
        queryParamsHandling: 'merge'
      });
      this.planSelected.emit({
        planType,
        period
      });
    }
  }

  selectPeriod(index: number) {
    this.selectedPeriod = this.periods[index]?.id;
    this.changeDetector.markForCheck();
  }
}
