import { StatusCodes } from 'http-status-codes/build/es';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, Observable, tap, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService } from '@core/auth';
import { LoaderService, NotificationService } from '@core/services';

import { NO_LOADER_PARAM, TIME_STAMP_PARAM } from '@client/shared/constants';

@Injectable()
export class HttpRequestInterceptor implements HttpRequestInterceptor {
  constructor(
    readonly authService: AuthService,
    readonly loaderService: LoaderService,
    readonly router: Router,
    readonly notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modifiedRequest = request;
    const noLoader =
      request.params.has(NO_LOADER_PARAM) || request.url.includes('assets/');

    if (!noLoader) {
      this.loaderService.setLoader(true);
    }

    if (
      request.params.has(NO_LOADER_PARAM) ||
      request.params.has(TIME_STAMP_PARAM)
    ) {
      const params = request.params
        .delete(NO_LOADER_PARAM)
        .delete(TIME_STAMP_PARAM);
      modifiedRequest = request.clone({ params });
    }

    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          this.authService.logOut(true);
          return EMPTY;
        }
        if (error.status === StatusCodes.INTERNAL_SERVER_ERROR) {
          this.notificationService.showError(error);
          return EMPTY;
        } else {
          return throwError(() => error);
        }
      }),
      finalize(() => {
        if (!noLoader) {
          this.loaderService.setLoader(false);
        }
      })
    );
  }
}
