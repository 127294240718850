import { ICompanyType, IOffice } from '@modules/companies/interfaces';
import { IOrder } from '@modules/settings/interfaces';

import { IUserEntity } from '@core/auth';

import {
  ActiveStatusEnum,
  ApplicationOptionEnum,
  IntegrationEnum
} from '@common/shared/enums';
import { ICompanyIntegration } from '@common/shared/interfaces';

import { IPlan } from '@client/shared/interfaces';

interface ICommonCompany {
  name: string;
  description: string;
  phone: string;
  site: string;
  siteValue?: string;
  employees: number;
  logo: string;
  freePublications?: number;
  banner?: string;
  bannerMobile?: string;
  ogLogo?: string;
  ogJobPlaceholder?: string;
  socialLinks: string[];
  technologyStack: string[];
  advantages: string[];
  founded: Date;
  integrations?: ICompanyIntegration[];
  applicationOption?: ApplicationOptionEnum;
  followersCount?: number;
  user?: IUserEntity;
  orders?: IOrder[];
}

export interface ICompany extends ICommonCompany {
  id: number;
  types: ICompanyType[];
  status?: ActiveStatusEnum;
  offices?: IOffice[];
  cities?: string;
  verified?: boolean;
  isOwnerAccess?: boolean;
  isOwner: boolean;
  created: any;
  comment: string;
  isActive?: boolean;
  isFollow?: boolean;
  followersCount?: number;
  jobsCount?: number;
  servicesCount?: number;
  activeDaysLeft?: number;
  dateToDelete?: Date;
  plan?: IPlan;
  plans?: IPlan[];
  premiumPlan?: boolean;
  trialUsed?: boolean;
  slug?: string;
}

export interface ICompanyRequest extends ICommonCompany {
  types: number[];
}

export interface ICompanyResponse extends ICompany {
  isFollow?: boolean;
  followersCount?: number;
  jobsCount?: number;
  dateToDelete?: Date;
  types: ICompanyType[];
  slug?: string;

  agents?: any; // for admin
}
