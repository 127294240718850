import { Injectable } from '@angular/core';

import { IOrder } from '@modules/settings/interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrdersFactory {
  fromResponse(res: IOrder): IOrder {
    return res;
  }
}
