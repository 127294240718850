import { UkrainianRegionsEnum } from '../enums';

export const UKRAINIAN_REGIONS_MAP = {
  'Vinn': UkrainianRegionsEnum.VinnytsiaOblast, // Vinnytsia Oblast
  'Volyn': UkrainianRegionsEnum.VolynOblast, // Volyn Oblast
  'Luhan': UkrainianRegionsEnum.LuhanskOblast, // Luhansk Oblast
  'Dnipro': UkrainianRegionsEnum.DniproOblast, // Dnipropetrovsk Oblast
  'Donet': UkrainianRegionsEnum.DonetskOblast, // Donetsk Oblast
  'Zhytom': UkrainianRegionsEnum.ZhytomyrOblast, // Zhytomyr Oblast
  'Zakar': UkrainianRegionsEnum.ZakarpattiaOblast, // Zakarpattia Oblast
  'Zapor': UkrainianRegionsEnum.ZaporizhiaOblast, // Zaporizhia Oblast
  'Ivano': UkrainianRegionsEnum.IvanoFrankivskOblast, // Ivano-Frankivsk Oblast
  'UA-KH': UkrainianRegionsEnum.KyivCity, // Kyiv
  'Kyiv': UkrainianRegionsEnum.KharkivOblast, // Kyiv Oblast
  'Kropyv': UkrainianRegionsEnum.KropyvnytskyiOblast, // Kropyvnytskyi / Kirovohrad Oblast
  'Kirovo': UkrainianRegionsEnum.KropyvnytskyiOblast, // Kropyvnytskyi / Kirovohrad Oblast
  'UA-KR': UkrainianRegionsEnum.Crimea, //
  'L\'viv': UkrainianRegionsEnum.LvivOblast, // Lviv Oblast
  'Lviv': UkrainianRegionsEnum.LvivOblast, // Lviv Oblast
  'Mykola': UkrainianRegionsEnum.MykolaivOblast, // Mykolaiv Oblast
  'Odes': UkrainianRegionsEnum.OdessaOblast, // Odessa Oblast
  'Polt': UkrainianRegionsEnum.PoltavaOblast, // Poltava Oblast
  'Rivn': UkrainianRegionsEnum.RivneOblast, // Rivne Oblast
  'Sum': UkrainianRegionsEnum.SumyOblast, // Sumy Oblast
  'Terno': UkrainianRegionsEnum.TernopilOblast, // Ternopil Oblast
  'Khar': UkrainianRegionsEnum.KharkivOblast, // Kharkiv Oblast
  'Kher': UkrainianRegionsEnum.KhersonOblast, // Kherson Oblast
  'Khmeln': UkrainianRegionsEnum.KhmelnytskOblast, // Khmelnytskyi Oblast
  'Cherk': UkrainianRegionsEnum.CherkasyOblast, // Cherkasy Oblast
  'Chernih': UkrainianRegionsEnum.ChernihivOblast, // Chernihiv Oblast
  'Cherniv': UkrainianRegionsEnum.ChernivtsiOblast // Chernivtsi Oblast
}


/*export const UKRAINIAN_REGIONS_MAP = {
  'UA-VI': 'UA-05', // Vinnytsia Oblast
  'UA-VO': 'UA-07', // Volyn Oblast
  'UA-LH': 'UA-09', // Luhansk Oblast
  'UA-DP': 'UA-12', // Dnipropetrovsk Oblast
  'UA-DT': 'UA-14', // Donetsk Oblast
  'UA-ZT': 'UA-18', // Zhytomyr Oblast
  'UA-ZK': 'UA-21', // Zakarpattia Oblast
  'UA-ZP': 'UA-23', // Zaporizhia Oblast
  'UA-IF': 'UA-26', // Ivano-Frankivsk Oblast
  // 'UA-KV': 'UA-30', // Kyiv
  'UA-KV': 'UA-32', // Kyiv Oblast
  'UA-KH': 'UA-35', // Kropyvnytskyi / Kirovohrad Oblast
  'UA-KR': 'UA-43', //
  'UA-LV': 'UA-46', // Lviv Oblast
  'UA-MY': 'UA-48', // Mykolaiv Oblast
  'UA-OD': 'UA-51', // Odessa Oblast
  'UA-PL': 'UA-53', // Poltava Oblast
  'UA-RV': 'UA-56', // Rivne Oblast
  'UA-SM': 'UA-59', // Sumy Oblast
  'UA-TP': 'UA-61', // Ternopil Oblast
  'UA-KK': 'UA-63', // Kharkiv Oblast
  'UA-KS': 'UA-65', // Kherson Oblast
  'UA-KM': 'UA-68', // Khmelnytskyi Oblast
  'UA-CK': 'UA-71', // Cherkasy Oblast
  'UA-CH': 'UA-74', // Chernihiv Oblast
  'UA-CV': 'UA-77' // Chernivtsi Oblast
}*/

/*export const  UKRAINIAN_REGIONS_MAP = {
  'UA-VI': 'UA-05', //
  'UA-VO': 'UA-07', //
  'UA-LH': 'UA-09', //
  'UA-DP': 'UA-12', //
  'UA-DT': 'UA-14', //
  'UA-ZT': 'UA-18', //
  'UA-ZK': 'UA-21', //
  'UA-ZP': 'UA-23', //
  'UA-IF': 'UA-26', //
  // 'UA-KV': 'UA-30', //
  'UA-KV': 'UA-32', // //	Flag of Kiev Oblast.svg Київська область	область
  'UA-KH': 'UA-35', //
  // 'UA-KR':'UA-40'	Flag of Sevastopol.svg Севастополь	місто
  'UA-KR': 'UA-43', //
  'UA-LV': 'UA-46', //
  'UA-MY': 'UA-48', //
  'UA-OD': 'UA-51', //
  'UA-PL': 'UA-53', //
  'UA-RV': 'UA-56', //
  'UA-SM': 'UA-59', //
  'UA-TP': 'UA-61', //
  'UA-KK': 'UA-63', //
  'UA-KS': 'UA-65', //
  'UA-KM': 'UA-68', //
  'UA-CK': 'UA-71', //
  'UA-CH': 'UA-74', //
  'UA-CV': 'UA-77'
}*/

