export enum JobViewTypeEnum {
  FromList = 1,
  FromHot = 2,
  FromMap = 3,
  FromChat = 4,
  FromCompany = 5,
  FromFollowed = 6,
  FromInvites = 7,
  FromRequests = 8,
  FromFacebook = 9,
  FromSubscribedFilter = 10
}
