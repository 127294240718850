<div class="auth-container">
  <h1 class="t-a_c">{{ 'auth.resetPassword.title' | translate }}</h1>
  <form [formGroup]="form" (ngSubmit)="send()" (keyup.enter)="send()">
    <div class="form-control">
      <its-input
        formControlName="password"
        type="password"
        placeholder="{{ 'auth.password.placeholder' | translate }}"
      ></its-input>
    </div>
    <div class="form-control">
      <its-input
        formControlName="confirmPassword"
        type="password"
        placeholder="{{ 'auth.confirmPassword.placeholder' | translate }}"
      ></its-input>
    </div>
    <its-button
      class="m-b_16 d_b"
      classes="w_100"
      type="submit"
      color="primary"
    >
      {{ 'auth.button.resetPassword' | translate }}
    </its-button>
  </form>
</div>
