import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  GlobalObjectService,
  LocaleService,
  NotificationService
} from '@core/services';

import { LocationCodesEnum } from '@common/shared/enums';
import { isNullOrUndefined } from '@common/shared/helpers';
import { ILocation } from '@common/shared/interfaces';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';

export type AutocompleteType =
  | 'address'
  | '(cities)'
  | '(country)'
  | '(regions)';

@Component({
  selector: 'its-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class LocationComponent<T extends ILocation>
  extends BaseControlComponent<ILocation>
  implements OnInit
{
  @ViewChild('search', { static: true }) searchAutocomplete: ElementRef;
  readonly destroyed$: Observable<unknown>;
  @Input() prefixIcon = '';
  @Input() staticPlaceholder = false;
  @Input() clear = false;
  @Input() clearStatic = false;
  @Input() type = 'text';
  @Input() icon: string;
  @Input() placeholder: string;
  @Input() autocompleteType: AutocompleteType = 'address';

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly iconAction = new EventEmitter<boolean>();
  @Output() readonly onClear = new EventEmitter<boolean>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null,
    readonly notificationService: NotificationService,
    readonly globalObjectService: GlobalObjectService,
    readonly localeService: LocaleService,
    private ngZone: NgZone
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });

    this.initLocation();
  }

  private initLocation() {
    if (this.globalObjectService.getWindow()?.['google']) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchAutocomplete.nativeElement,
        {
          types: [this.autocompleteType],
          fields: [
            'place_id',
            'geometry',
            'formatted_address',
            'url',
            'address_components',
            'types',
            'utc_offset_minutes'
          ]
        }
      );

      this.searchAutocomplete.nativeElement.placeholder = '';

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (isNullOrUndefined(place.geometry)) {
            return;
          }
          let address = '';
          let value = '';
          let city = '';
          let country = '';
          let countryCode = '';
          let region = '';
          let regionCode = '';
          let region2 = '';
          let regionCode2 = '';
          let postalCode = null;
          for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                // console.log('street_number', component.long_name); // 44
                address = `, ${component.long_name}`;
                break;
              }

              case 'route': {
                // console.log('route', component.short_name); // Zelena St
                address = `${component.short_name.trim()}${address}`;
                break;
              }

              case 'postal_code': {
                postalCode = +component.long_name;
                // console.log('postal_code', component.long_name); // 79000
                break;
              }

              case 'locality':
                // console.log('locality', component.long_name); // L'viv
                city = component.long_name;
                break;

              case 'postal_town':
                // console.log('locality', component.long_name); // L'viv
                city = !city ? component.long_name : city;
                break;

              case 'administrative_area_level_1': {
                // console.log('administrative_area_level_1', component.short_name); // L'vivs'ka oblast
                region = component.long_name;
                regionCode = component.short_name;
                break;
              }

              case 'administrative_area_level_2': {
                // console.log('administrative_area_level_2', component.short_name); // L'vivs'ka oblast
                region2 = component.long_name;
                regionCode2 = component.short_name;
                break;
              }

              case 'country':
                // console.log('country', component.long_name); // Ukraine
                // console.log('countryCode', component.short_name); // UA
                country = component.long_name;
                countryCode = component.short_name;
                break;
            }
          }

          if (city === region) {
            region = region2 || region;
            regionCode = regionCode2 || regionCode;
          }

          const replaceUaComa = (value) => {
            if (value && countryCode === 'UA') {
              return value.replaceAll("'", '');
            }
            return value;
          };

          address = replaceUaComa(address);
          city = replaceUaComa(city);
          regionCode = replaceUaComa(regionCode);

          const locationQuery = [
            address ? `${LocationCodesEnum.Address}_${address}` : '',
            city ? `${LocationCodesEnum.City}_${city}` : '',
            regionCode ? `${LocationCodesEnum.RegionCode}_${regionCode}` : '',
            countryCode ? `${LocationCodesEnum.CountryCode}_${countryCode}` : ''
          ]
            .filter(Boolean)
            .join('+');

          value = [address, city, regionCode, country]
            .filter(Boolean)
            .join(', ');

          if (
            country.toLowerCase() === 'russia' ||
            country.toLowerCase() === 'belarus' ||
            country.toLowerCase() === 'iran'
          ) {
            this.notificationService.showError(
              this.localeService.getInstant(
                _('common.notification.stopOccupiers')
              )
            );
            this.control.patchValue(this.modelToViewFormatter(null));
            this.writeValue(null);
            return;
          }

          const location = {
            countryCode,
            regionCode,
            region,
            address,
            city,
            value,
            mapUrl: place?.url,
            locationQuery,
            postalCode,
            coordinates: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          } as ILocation;
          this.control.patchValue(this.modelToViewFormatter(location));
          this.writeValue(location);
        });
      });
    }
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }

  clearValue() {
    this.control.setValue(null);
    this.onClear.emit(true);
  }

  onIconAction() {
    this.iconAction.emit(true);
  }

  writeValue(value: ILocation): void {
    // console.log('writeValue', value);
    this.control.setValue(this.modelToViewFormatter((value as any)?.value), {
      emitEvent: false
    });
  }
}
