export const ROUTES_DATA = {
  CONFIRMATION: {
    url: 'confirmation/:token'
  },
  ADMIN: {
    url: 'admin',
    title: 'Admin Panel',
    children: {
      COMPANIES: {
        path: 'companies',
        url: 'admin/companies'
      },
      USERS: {
        path: 'users',
        url: 'admin/users'
      },
      RESUMES: {
        path: 'resumes',
        url: 'admin/resumes'
      },
      ORDERS: {
        path: 'orders',
        url: 'admin/orders'
      },
      EVENTS: {
        path: 'events',
        url: 'admin/events'
      },
      JOBS: {
        path: 'vacancies',
        url: 'admin/vacancies'
      },
      NEWS: {
        path: 'news',
        url: 'admin/news'
      },
      EMAILS: {
        path: 'emails',
        url: 'admin/emails'
      },
      DEMO: {
        path: 'demo',
        url: 'admin/demo'
      },
      TRACKING: {
        path: 'tracking',
        url: 'admin/tracking'
      },
      ERROR_CLIENT: {
        path: 'error-client',
        url: 'admin/error-client'
      },
      ERROR_SERVER: {
        path: 'error-server',
        url: 'admin/error-server'
      }
    }
  },
  CALENDAR: {
    url: 'calendar'
  },
  COMPANIES: {
    url: 'companies',
    children: {
      PROFILE: {
        path: 'profile',
        url: 'companies/profile',
        children: {
          DATA: {
            path: 'data',
            url: 'companies/profile/data',
            children: {
              FORM: {
                path: 'form',
                url: 'companies/profile/data/form'
              },
              OFFICE: {
                path: 'offices'
                // url: 'companies/profile/offices',
              },
              FAQ: {
                path: 'faq'
                // url: 'companies/profile/faq',
              },
              REVIEWS: {
                path: 'reviews'
                // url: 'companies/profile/faq',
              }
            }
          },
          JOBS: {
            path: 'profile/vacancies',
            url: 'companies/profile/vacancies'
          },
          INVITES: {
            path: 'profile/invites',
            url: 'companies/profile/invites'
          },
          REQUESTS: {
            path: 'profile/requests',
            url: 'companies/profile/requests'
          },
          AGENTS: {
            path: 'agents'
            // url: 'companies/profile/data/form'
          },
          INSTRUCTIONS: {
            path: 'instructions'
            // url: 'companies/profile/instructions',
          }
        }
      },
      VIEW: {
        path: ':slug',
        children: {
          OVERVIEW: {
            path: 'overview'
            // url: 'companies/:id/vacancies'
          },
          JOBS: {
            path: 'vacancies'
            // url: 'companies/:id/vacancies'
          },
          OFFICE: {
            path: 'offices'
            // url: 'companies/:id/offices',
          },
          FAQ: {
            path: 'faq'
            // url: 'companies/:id/faq,
          },
          REVIEWS: {
            path: 'reviews'
            // url: 'companies/:id/faq,
          }
        }
      },
      STATISTIC: {
        path: 'statistic',
        url: 'companies/statistic',
        children: {
          GENERAL: {
            path: 'general',
            url: 'companies/statistic/general'
          },
          ORDERS: {
            path: 'orders',
            url: 'companies/statistic/orders'
          },
          JOBS: {
            path: 'vacancies',
            url: 'companies/statistic/vacancies'
          },
          AGENTS: {
            path: 'agents',
            url: 'companies/statistic/agents'
          }
        }
      }
    }
  },
  EVENTS: {
    url: 'events',
    children: {
      VIEW: {
        path: ':id'
      },
      CREATE: {
        path: 'create',
        url: 'events/create'
      }
    }
  },
  JOBS: {
    url: 'vacancies',
    children: {
      VIEW: {
        path: ':id',
        children: {
          REQUESTS: {
            path: 'requests'
          },
          INVITES: {
            path: 'invites'
          }
        }
      },
      CREATE: {
        path: 'create',
        url: 'vacancies/create'
      },
      EDIT: {
        path: 'edit/:id',
        url: 'vacancies/edit' // :id
      }
    }
  },
  NEWS: {
    url: 'news',
    children: {
      VIEW: {
        path: ':id'
      },
      CREATE: {
        path: 'create',
        url: 'news/create'
      }
    }
  },
  RESUME: {
    url: 'cvs',
    children: {
      FAVORITES_RESUMES: {
        path: 'favorites',
        url: 'cvs/favorites'
      },
      PROFILE: {
        path: 'profile',
        url: 'cvs/profile',
        children: {
          CV_FORM: {
            path: 'form',
            url: 'cvs/profile/form'
          },
          FOLLOWED_JOBS: {
            path: 'vacancies',
            url: 'cvs/profile/vacancies'
          },
          FOLLOWED_COMPANIES: {
            path: 'companies',
            url: 'cvs/profile/companies'
          },
          REQUESTS: {
            path: 'requests',
            url: 'cvs/profile/requests'
          },
          INVITES: {
            path: 'invites',
            url: 'cvs/profile/invites'
          }
        }
      },
      VIEW: {
        path: ':id'
      },
      VIEW_CONNECTED: {
        path: 'connected/:conId/:id',
        url: 'cvs/connected'
      },
      STATISTIC: {
        path: 'statistic',
        url: 'cvs/statistic',
        children: {
          GENERAL: {
            path: 'general',
            url: 'cvs/statistic/general'
          }
        }
      }
    }
  },
  TALENT_POOL: {
    url: 'talent-pool',
    children: {
      VIEW: {
        path: ':id'
      }
    }
  },
  SETTINGS: {
    url: 'settings',
    children: {
      PRICING: {
        path: 'pricing',
        url: 'settings/pricing'
      },
      BILLING: {
        path: 'billing',
        url: 'settings/billing'
      },
      TEMPLATES: {
        path: 'templates',
        url: 'settings/templates'
      },
      PRIVACY: {
        path: 'privacy',
        url: 'settings/privacy'
      },
      ACCOUNT: {
        path: 'account',
        url: 'settings/account'
      },
      FILTERING: {
        path: 'filtering',
        url: 'settings/filtering'
      },
      EMAIL_NEWSLETTER: {
        path: 'mailing',
        url: 'settings/mailing'
      },
      INTEGRATIONS: {
        path: 'integrations',
        url: 'settings/integrations',
        children: {
          GREENHOUSE: {
            path: 'greenhous',
            url: 'settings/integrations/greenhous'
          }
        }
      }
    }
  },
  HOME: {
    url: '',
    children: {
      JOBS_MAP: {
        url: 'vacancy-map'
      },
      COMPANIES_MAP: {
        url: 'companies-map'
      }
    }
  },
  PUBLIC: {
    url: 'public',
    children: {
      LEGAL: {
        path: 'legal',
        url: 'public/legal'
      },
      POLICY: {
        path: 'policy',
        url: 'public/policy'
      }
    }
  },
  PRICING: {
    url: 'pricing'
  },
  CONTACTS: {
    url: 'contacts'
  },
  CHAT: {
    url: 'chat',
    children: {
      REQUESTS: {
        path: 'requests',
        url: 'chat/requests'
      },
      INVITES: {
        path: 'invites',
        url: 'chat/invites'
      }
    }
  },
  ANALYTICS: {
    url: 'analytics',
    children: {
      GENERAL: {
        path: 'general',
        url: 'analytics/general'
      },
      JOBS: {
        path: 'vacancies',
        url: 'analytics/vacancies'
      },
      RESUME: {
        path: 'cv',
        url: 'analytics/cv'
      }
    }
  }
};
