import { Injectable } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig
} from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {
  constructor(readonly bottomSheet: MatBottomSheet) {}

  openSheet(
    component: any,
    result: (res) => void = null,
    config: MatBottomSheetConfig = {}
  ) {
    const sheet = this.bottomSheet.open(component, config);

    sheet.afterDismissed().subscribe((res) => {
      if (res) {
        result(res);
      }
    });
  }
}
