import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { IProfile } from '@core/auth';

import { ANONYMOUS_VALUE, CHAT_DURATION_H } from '@common/shared/constants';

import { getAvatarPath } from '@client/shared/utils';

import { IChat, IChatResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatFactory {
  constructor() {}

  fromResponse(chat: IChatResponse, profile: IProfile = null): IChat {
    const hoursLeft = chat.expired
      ? moment(chat.expired).diff(moment(), 'hours')
      : null;
    return {
      ...chat,
      recipient: chat?.recipient
        ? {
            ...chat.recipient,
            avatar: getAvatarPath(chat.recipient.avatar)
          }
        : {
            firstName: ANONYMOUS_VALUE,
            lastName: '',
            avatar: ''
          },
      hoursLeft: hoursLeft && hoursLeft <= CHAT_DURATION_H ? hoursLeft : null,
      invite: chat?.invite
        ? {
            ...chat.invite,
            isAuthor: profile.id !== chat.invite.user.id
          }
        : null,
      request: chat?.request
        ? {
            ...chat.request,
            isAuthor: profile.id === chat.request.user.id
          }
        : null
    };
  }
}
