import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '@core/services';

import { LanguageEnum, LocalizationEnum } from '@common/shared/enums';

@Pipe({
  name: 'date',
  pure: false
})
export class ITSDatePipe implements PipeTransform {
  constructor(readonly localeService: LocaleService) {}

  transform(date: Date | string, format: string = 'MMM d, y'): string {
    date = new Date(date);
    return new DatePipe(
      LocalizationEnum[this.localeService.locale || LanguageEnum.Ukraine]
    ).transform(date, format);
  }
}
