import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelect'
})
export class FilterSelectPipe implements PipeTransform {
  transform(list: any[], prop: string, value = '') {
    const trueValue = (value || '').trim();
    (list || []).forEach((el) => {
      if (trueValue) {
        el.dn = !el[prop].toLowerCase().includes(trueValue.toLowerCase());
      } else {
        el.dn = false;
      }
    });
    return list;
  }
}
