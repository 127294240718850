import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { GlobalObjectService, LocaleService, SeoService } from '@core/services';

import { HELLO_EMAIL } from '@common/shared/constants';

import { ROUTES_DATA } from '@client/shared/constants';

@Component({
  selector: 'its-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent implements OnInit {
  HELLO_EMAIL = HELLO_EMAIL;
  constructor(
    readonly seoService: SeoService,
    readonly localeService: LocaleService,
    readonly globalObjectService: GlobalObjectService
  ) {}

  ngOnInit(): void {
    this.seoService.updateMetaData({
      windowTitle: this.localeService.getInstant(_('windowTitle.contacts')),
      ogTitle: this.localeService.getInstant(_('seo.contacts.title')),
      description: this.localeService.getInstant(_('seo.contacts.description')),
      ogUrl: this.globalObjectService.buildUrl(ROUTES_DATA.CONTACTS.url),
      keywords: this.localeService.getInstant(_('seo.contacts.keywords'))
    });
  }
}
