import { QuillEditorComponent } from 'ngx-quill';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GlobalObjectService } from '@core/services';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { EmojiComponent } from '@client/shared/components/emoji';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { EditorToolbarTypeEnum, TemplateTypeEnum } from '@client/shared/enums';

const TOOLBARS = {
  [EditorToolbarTypeEnum.StrictDescription]: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'link']
  ],
  [EditorToolbarTypeEnum.PostDescription]: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button

    ['link', 'image', 'video']
  ]
};

@Component({
  selector: 'its-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class EditorComponent<T>
  extends BaseControlComponent<string>
  implements OnInit, AfterViewInit
{
  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;
  @ViewChild('toolbarContainer', { read: ViewContainerRef })
  toolbarContainer: ViewContainerRef;
  private savedSelection: any;
  modules = {
    toolbar: [],
    clipboard: {
      matchVisual: true
    }
  };
  readonly destroyed$: Observable<unknown>;
  private controlInit = false;

  @Input() toolbarType = EditorToolbarTypeEnum.StrictDescription;
  @Input() templateAutoSelect: TemplateTypeEnum;

  @Input() placeholder: string;
  @Input() trimValue = true;
  @Input() minHeight = '175px';
  readonly control = new FormControl('');
  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null,
    private injector: Injector,
    private globalObjectService: GlobalObjectService
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.modules.toolbar = TOOLBARS[this.toolbarType];
    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.initControlListeners();
        this.onRewriteValue();
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });
  }

  ngAfterViewInit() {
    if (this.globalObjectService.isPlatformBrowser()) {
      this.waitForQuillEditor().then((qe) => this.setupEmoji(qe));
    }
  }

  onFocus() {
    this.initControlListeners();
    this.onTouched?.();
  }

  initControlListeners() {
    if (!this.controlInit) {
      this.control.setValidators(this.ctrl.control?.validator ?? null);
      this.control.setAsyncValidators(
        this.ctrl.control?.asyncValidator ?? null
      );
      this.onValidatorChange?.();

      this.ctrl.control?.statusChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          const errors = this.ctrl.control?.errors ?? null;
          this.control.setErrors(errors);
          this.changeDetector.markForCheck();
        });
    }
    this.controlInit = true;
  }

  onBlur() {
    let html = this.control.value || '';
    let trimmedValue = html.replace(
      /^(<[^>]+>\s*(<br\s*\/?>\s*)*<\/[^>]+>)+/gi,
      ''
    );

    trimmedValue = trimmedValue.replace(
      /(<[^>]+>\s*(<br\s*\/?>\s*)*<\/[^>]+>)+$/gi,
      ''
    );

    this.onRewriteValue(trimmedValue || '');
  }

  onRewriteValue(value = '') {
    this.control.setValue(value || this.control.value || '');
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    this.changeDetector.markForCheck();
  }

  saveSelection() {
    const quill = this.editor.quillEditor;
    this.savedSelection = quill.getSelection();
  }

  onSelectEmoji(emoji: any) {
    const quill = this.editor.quillEditor;
    if (this.savedSelection) {
      quill.setSelection(this.savedSelection.index, this.savedSelection.length);
      quill.insertText(this.savedSelection.index, emoji);
    } else {
      quill.insertText(quill.getLength() - 1, emoji);
    }
    this.saveSelection();
  }

  setupEmoji(quillEditor: any) {
    try {
      const toolbar = quillEditor.getModule('toolbar');
      const toolbarElement = toolbar.container;

      const componentRef = this.toolbarContainer.createComponent(
        EmojiComponent,
        {
          injector: this.injector
        }
      );
      componentRef.instance.openMenu
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.saveSelection();
        });

      componentRef.instance.selectEmoji
        .pipe(takeUntil(this.destroyed$))
        .subscribe((emoji: any) => {
          this.onSelectEmoji(emoji);
        });
      toolbarElement.append(componentRef.location.nativeElement);
      this.changeDetector.markForCheck();
    } catch (e) {}
  }

  waitForQuillEditor(): Promise<any> {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const checkInterval = 100;
      const maxWaitTime = 3000;

      const intervalId = setInterval(() => {
        const quillEditor = this.editor?.quillEditor;
        if (quillEditor) {
          clearInterval(intervalId);
          resolve(quillEditor);
        } else if (Date.now() - startTime >= maxWaitTime) {
          clearInterval(intervalId);
          reject(null);
        }
      }, checkInterval);
    });
  }
}
