import { Injectable } from '@angular/core';

import { ICompanyReview } from '@modules/companies/interfaces';

import { getAvatarPath } from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class CompanyReviewsFactory {
  fromResponse(data: ICompanyReview) {
    if (data.user) {
      data.user.avatar = getAvatarPath(data.user.avatar);
    }

    (data.children || []).forEach((c) => {
      if (c.user) {
        c.user.avatar = getAvatarPath(c.user.avatar);
      }
    });

    return data;
  }
}
