import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IJob } from '@modules/jobs/interfaces';

@Component({
  selector: 'its-job-short-stats',
  templateUrl: './job-short-stats.component.html',
  styleUrls: ['./job-short-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobShortStatsComponent implements OnInit {
  @Input() small = false;
  @Input() job: IJob;
  constructor() { }

  ngOnInit(): void {
  }

}
