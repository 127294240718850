import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform
} from 'ngx-image-cropper';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { ImageTypeEnum } from '@client/shared/enums';

export const CROPPER_SETTINGS = {
  [ImageTypeEnum.CompanyLogo]: {
    aspectRatio: 2 / 1,
    resizeToWidth: 402,
    cropperMinWidth: 402,
    roundCropper: false,
    containWithinAspectRatio: true
  },
  [ImageTypeEnum.UserAvatar]: {
    aspectRatio: 1 / 1,
    resizeToWidth: 201,
    cropperMinWidth: 201,
    roundCropper: true,
    containWithinAspectRatio: false
  }
};

@Component({
  selector: 'its-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropperComponent implements OnInit {
  ImageTypeEnum = ImageTypeEnum;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};

  @Input() imageType: ImageTypeEnum = ImageTypeEnum.UserAvatar;
  @Input() imageChangedEvent: any = '';
  @Input() imageBase64: any = '';
  @Input() containWithinAspectRatio = false;

  @Output() croppedResult = new EventEmitter();
  settings: any;
  constructor() {}

  ngOnInit(): void {
    this.settings = CROPPER_SETTINGS[this.imageType];
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  onSave(save = true) {
    this.croppedResult.emit(save ? this.croppedImage : '');
  }
}
