import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ICreateOrder, IOrder } from '@modules/settings/interfaces';

import { GlobalObjectService } from '@core/services';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import { OrdersFactory } from './orders.factory';

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends BaseHttpService {
  constructor(
    readonly factory: OrdersFactory,
    readonly globalObjectService: GlobalObjectService
  ) {
    super('orders');
  }

  getCompanyOrders(params: IQuery) {
    return this.get<ICountResponse<IOrder[]>>(params, '').pipe(
      map(({ content }) => ({
        data: content.data.map((o) => this.factory.fromResponse(o)),
        count: content.count
      }))
    );
  }

  createPublicationLiqPayInvoice(data: ICreateOrder) {
    return this.post<ICreateOrder, IOrder>(data, {}, 'liqpay/publication').pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  createPublicationTransferInvoice(data: ICreateOrder) {
    return this.post<ICreateOrder, any>(data, {}, 'transfer/publication', {
      responseType: 'blob'
    });
    /*   .pipe(
      map((res: any) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          return true;
      })
    );*/
  }

  getTransferInvoice(id: number) {
    return this.get<string>({}, `${id}/pdf`, {
      responseType: 'blob'
    }).pipe(map((res: any) => this.openFile(res)));
  }

  getAdminTransferInvoice(id: number) {
    return this.get<string>({}, `${id}/pdf/admin`, {
      responseType: 'blob'
    }).pipe(map((res: any) => this.openFile(res)));
  }

  private openFile(res: any) {
    const file = new Blob([res], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    this.globalObjectService.getWindow()?.open(fileURL);
    return true;
  }

  getReceipt(data: { email: string }, orderId: number) {
    return this.post<{ email: string }, unknown>(
      data,
      {},
      `${orderId}/invoice/receipt`
    );
  }

  cancelInvoice(id: number) {
    return this.delete({}, `${id}`);
  }

  checkInvoiceStatus(id: number) {
    return this.get<IOrder>({}, `${id}/invoice/status`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  checkAdminInvoiceStatus(id: number) {
    return this.get<IOrder>({}, `${id}/invoice/status/admin`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }
}
