<ul *ngIf="showedResults?.length" class="mobile-filter-result">
  <ng-container *ngFor="let filter of showedResults">
    <li>
      <span>{{ filter.value }}</span>
      <button class="button-empty" (click)="removeFilter(filter)">
        <its-icon svgIcon="clear" size="10"></its-icon>
      </button>
    </li>
  </ng-container>
  <li *ngIf="showMore" (click)="showAll()" class="all-filters">
    <span>+ {{ allResults?.length - showedResults?.length }}</span>
  </li>
</ul>
