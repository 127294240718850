<nav class="desk-menu">
  <ng-container *ngFor="let item of navMenu">
    <a
      *ngIf="item.show"
      class="{{ item?.class }}"
      [routerLink]="item.url"
      [class.active]="getLinkActive(item.url)"
    >
      <span class="a-i_c">
        <span>{{ item.title | translate }}</span>
        <span class="menu-counter" *ngIf="invReqCounters[item?.counterKey]">{{
          invReqCounters[item?.counterKey]
        }}</span>
      </span>
    </a>
  </ng-container>
  <ng-container *ngIf="navMenu?.length">
    <a
      *ngIf="!profile?.hasResume && !profile?.company"
      (click)="toAuth($event, true)"
      [routerLink]="
        '/' +
        ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children.FORM.url
      "
      class="create-profile"
    >
      <span>{{ 'home.button.createCompany' | translate }}</span>
    </a>
    <a
      *ngIf="!profile"
      (click)="toAuth($event)"
      class="create-profile"
      [routerLink]="
        '/' + ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url
      "
    >
      <span>{{ 'home.button.createResume' | translate }}</span>
    </a>
  </ng-container>
</nav>

<div class="frame-mob-menu">
  <button class="button-empty" matRipple [matMenuTriggerFor]="mobMenu">
    <img src="/assets/v1/images/menu-icon.png" />
  </button>
  <mat-menu
    class="mob-menu"
    backdropClass="header-menu-overlay"
    #mobMenu="matMenu"
    xPosition="after"
  >
    <a mat-menu-item class="close-menu-item">
      <its-icon color="primary" svgIcon="clear" size="24"></its-icon>
    </a>
    <ng-container *ngFor="let item of navMenu">
      <a
        *ngIf="item.show"
        class="{{ item?.class }}"
        mat-menu-item
        [routerLink]="item.url"
        routerLinkActive="active"
      >
        <span class="a-i_c">
          <span>{{ item.title | translate }}</span>
          <span class="menu-counter" *ngIf="invReqCounters[item?.counterKey]">{{
            invReqCounters[item?.counterKey]
          }}</span>
        </span>
      </a>
    </ng-container>
    <a
      mat-menu-item
      *ngIf="!profile?.hasResume && !profile?.company"
      (click)="toAuth($event, true)"
      [routerLink]="
        '/' +
        ROUTES_DATA.COMPANIES.children.PROFILE.children.DATA.children.FORM.url
      "
      class="create-profile"
    >
      <span>{{ 'home.button.createCompany' | translate }}</span>
    </a>
    <a
      mat-menu-item
      *ngIf="!profile"
      (click)="toAuth($event)"
      [routerLink]="
        '/' + ROUTES_DATA.RESUME.children.PROFILE.children.CV_FORM.url
      "
      class="create-profile"
    >
      <span>{{ 'home.button.createResume' | translate }}</span>
    </a>

    <its-kupno-social class="kupno-social"></its-kupno-social>
  </mat-menu>
</div>
