import { ICompany } from '@modules/companies/interfaces';

import { IProfile, ISignUp } from '@core/auth';

import {
  ActiveStatusEnum,
  AgentRoleEnum,
  InviteStatusEnum
} from '@common/shared/enums';

interface ICommonAgent {
  email: string;
  offices?: string[];
  role: AgentRoleEnum;
}

export interface IAgent extends ICommonAgent {
  id?: number;
  created: Date;
  status: InviteStatusEnum;
  user: IProfile;
  company?: ICompany;
}

export interface IAgentRequest extends ICommonAgent {}

export interface IAgentResponse extends IAgent {}
