<div class="its-avatar {{size}}">
  <img [src]="avatar || '/assets/v1/bg/no-avatar.png'" alt=""/>
  <div *ngIf="!avatar" class="short-name">
    <ng-container *ngIf="!firstName && !lastName; else abbreviation">
      <its-icon svgIcon="anonymous"></its-icon>
    </ng-container>
    <ng-template #abbreviation>
      <b>
        {{ getNamePreview(firstName, lastName) }}
      </b>
    </ng-template>
  </div>
</div>
