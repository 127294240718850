import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalObjectService } from '@core/services';

@Injectable()
export class CookiesInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(REQUEST) protected request: Request,
    readonly globalObjectService: GlobalObjectService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers = this.globalObjectService.isServerRequest()
      ? {
          setHeaders: {
            cookie: this.globalObjectService.getServerCookies() || '' // required
          }
        }
      : {};

    request = request.clone({
      withCredentials: true,
      ...headers
    });

    return next.handle(request);
  }
}
