import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@core/auth';
import { GlobalObjectService, SeoService } from '@core/services';

@Component({
  selector: 'its-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationComponent implements OnInit {
  confirmed = false;
  constructor(
    readonly authService: AuthService,
    readonly activatedRoute: ActivatedRoute,
    readonly globalObjectService: GlobalObjectService,
    readonly seoService: SeoService
  ) {}

  ngOnInit(): void {
    if (this.globalObjectService.isPlatformBrowser()) {
      const token = this.activatedRoute.snapshot.paramMap.get('token');
      this.confirmation(token);
    }
  }

  confirmation(token: string) {
    return this.authService.confirmation(token).subscribe(() => {
      this.confirmed = true;
    });
  }
}
