import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CompanyReviewCategoryEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const COMPANY_REVIEW_CATEGORIES: ISelectOption[] = [
  {
    id: CompanyReviewCategoryEnum.Interview,
    value: _('companyReviewCategory.interview')
  },
  {
    id: CompanyReviewCategoryEnum.Communication,
    value: _('companyReviewCategory.communication')
  },
  {
    id: CompanyReviewCategoryEnum.Company,
    value: _('companyReviewCategory.company')
  }
];
