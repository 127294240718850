import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { InfoModalComponent, InfoComponent } from '../directives/info';
import { ModalPublishVacancyComponent } from '../directives/publish-job';
import {
  AtsButtonComponent,
  ModalAtsjobIdComponent,
  ModalAtsUsersComponent
} from './ats-button';
import { AttachmentComponent, CropperComponent } from './attachment';
import {
  AuthComponent,
  SignInComponent,
  SignUpComponent,
  ConfirmationComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  ResendConfirmComponent
} from './auth';
import { AutocompleteComponent } from './autocomplete';
import { AvatarComponent } from './avatar';
import { BackComponent } from './back';
import { BookDemoComponent } from './book-demo';
import { BooleanSearchTextareaComponent } from './boolean-search-textarea';
import { ButtonComponent } from './button';
import { CategoriesComponent } from './categories';
import { CheckboxComponent } from './checkbox';
import { ChipsComponent } from './chips';
import { CommentModalComponent } from './comment-modal';
import { CurrencySwitcherComponent } from './currency-switcher';
import { CutTextComponent } from './cut-text';
import { DatePickerComponent } from './date-picker';
import { DateRangeComponent } from './date-range';
import { DrawerComponent } from './drawer';
import { EditorComponent } from './editor';
import { EmojiComponent } from './emoji';
import { EmptyPageComponent } from './empty-page';
import { FileComponent } from './file';
import { FooterComponent, KupnoSocialComponent } from './footer';
import { HeaderComponent } from './header';
import { HotJobsComponent } from './hot-jobs';
import { IconComponent } from './icon';
import { ImageSliderComponent } from './image-slider';
import { InputComponent } from './input';
import {
  ItemCompanyComponent,
  ItemEventComponent,
  ItemNewsComponent,
  ItemResumeComponent,
  ItemJobComponent
} from './items';
import {
  JobComponent,
  ModalApplyJobComponent,
  JobShortStatsComponent
} from './job';
import { LineChartComponent } from './line-chart';
import { LoaderComponent } from './loader';
import { LocationComponent } from './location';
import { MessengerComponent } from './messenger';
import { MobileFilterComponent } from './mobile-filter';
import { ModalComponent } from './modal';
import { MultiSelectComponent } from './multi-select';
import { NavComponent } from './nav';
import { NotFoundComponent } from './not-found';
import { PackagesComponent } from './packages';
import { PaginatorComponent } from './paginator';
import { PhoneComponent } from './phone';
import { PriorityComponent } from './priority';
import { PwaAddPromptComponent, PwaUpdatePromptComponent } from './pwa';
import { RadioComponent } from './radio';
import {
  ReqInvStateComponent,
  ReqInvStateHistoryModalComponent
} from './req-inv-state';
import { ResumeComponent } from './resume';
import { RouterOutletComponent } from './router-outlet';
import {
  SearchComponent,
  ModalSaveFilterComponent,
  ModalBooleanSearchCategoriesComponent,
  ModalBooleanSearchLocationComponent
} from './search';
import { SelectComponent } from './select';
import { ShareSocialComponent } from './share-social';
import { SkillsComponent } from './skills';
import { SlideToggleComponent } from './slide-toggle';
import { SliderComponent } from './slider';
import { SnackbarComponent } from './snackbar';
import { SocialLinksComponent } from './social-links';
import { SortsComponent } from './sorts';
import { StatusComponent } from './status';
import { StickerComponent } from './sticker';
import { TableComponent } from './table';
import { TagsComponent } from './tags';
import { TemplateBtnComponent } from './template-btn';
import { TextareaComponent } from './textarea';
import { ToasterComponent } from './toaster';
import { ToolbarComponent } from './toolbar';
import { TooltipDirective } from './tooltip';
import { UploaderComponent } from './uploader';
import { WebPushConfirmComponent } from './web-push-confirm';
import { WidgetComponent } from './widget';

export const SHARED_COMPONENTS = [
  ButtonComponent,
  CheckboxComponent,
  AttachmentComponent,
  CropperComponent,
  DatePickerComponent,
  InputComponent,
  MultiSelectComponent,
  PaginatorComponent,
  RadioComponent,
  RouterOutletComponent,
  SelectComponent,
  TableComponent,
  TextareaComponent,
  ToasterComponent,
  ToolbarComponent,
  TooltipDirective,
  HeaderComponent,
  FooterComponent,
  KupnoSocialComponent,
  ItemCompanyComponent,
  ItemEventComponent,
  ItemJobComponent,
  ItemNewsComponent,
  ItemResumeComponent,
  NavComponent,
  ChipsComponent,
  IconComponent,
  DrawerComponent,
  AuthComponent,
  SignInComponent,
  SignUpComponent,
  ConfirmationComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  ResendConfirmComponent,
  StatusComponent,
  EmptyPageComponent,
  SlideToggleComponent,
  SearchComponent,
  LocationComponent,
  SocialLinksComponent,
  ResumeComponent,
  CategoriesComponent,
  SkillsComponent,
  BackComponent,
  InfoModalComponent,
  InfoComponent,
  UploaderComponent,
  FileComponent,
  PhoneComponent,
  CutTextComponent,
  ImageSliderComponent,
  AutocompleteComponent,
  ModalComponent,
  CommentModalComponent,
  MessengerComponent,
  WidgetComponent,
  ModalSaveFilterComponent,
  WebPushConfirmComponent,
  HotJobsComponent,
  LoaderComponent,
  ShareSocialComponent,
  JobComponent,
  LineChartComponent,
  ModalApplyJobComponent,
  JobShortStatsComponent,
  SortsComponent,
  PriorityComponent,
  SliderComponent,
  TemplateBtnComponent,
  EmojiComponent,
  PackagesComponent,
  TagsComponent,
  AvatarComponent,
  PwaAddPromptComponent,
  PwaUpdatePromptComponent,
  StickerComponent,
  NotFoundComponent,
  MobileFilterComponent,
  SnackbarComponent,
  DateRangeComponent,
  CurrencySwitcherComponent,
  AtsButtonComponent,
  ModalAtsUsersComponent,
  ModalAtsjobIdComponent,
  ModalPublishVacancyComponent,
  ModalBooleanSearchCategoriesComponent,
  ModalBooleanSearchLocationComponent,
  BooleanSearchTextareaComponent,
  ReqInvStateComponent,
  ReqInvStateHistoryModalComponent,
  BookDemoComponent,
  EditorComponent
];

export const MATERIAL_MODULES = [
  MatButtonModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatRadioModule,
  MatInputModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatDatepickerModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDividerModule,
  MatIconModule,
  MatCardModule,
  MatChipsModule,
  MatSidenavModule,
  MatMenuModule,
  MatTabsModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatRippleModule,
  MatExpansionModule,
  MatBottomSheetModule
];

/*
export * from './attachment';
export * from './button';
export * from './checkbox';
export * from './date-picker';
export * from './icon';
export * from './input';
export * from './multi-select';
export * from './radio';
export * from './router-outlet';
export * from './select';
export * from './table';
export * from './textarea';
export * from './toaster';
export * from './toolbar';
export * from './tooltip';*/
