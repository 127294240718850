<ng-container *ngIf="values$ | async as values">
  <ng-container *ngIf="job">
    <ng-container
      *ngIf="job.status !== JobStatusEnum.Active && job.wasPublished"
    >
      <div class="alert-warn not-active-title">
        <div>{{ 'jobView.status.notActive' | translate }}</div>
      </div>
    </ng-container>

    <div class="job-top-actions">
      <div>
        <div>
          <its-job-short-stats [job]="job"></its-job-short-stats>
        </div>
      </div>

      <ng-container
        *ngIf="job.companyOwnerOrManager || job.isAuthor; else viewActions"
      >
        <div class="table-action">
          <ng-container *ngIf="job.status !== JobStatusEnum.Done">
            <its-button
              *ngIf="job.isAuthor"
              variant="icon"
              matTooltip="{{ 'common.tooltip.edit' | translate }}"
              [link]="'/' + ROUTES_DATA.JOBS.children.EDIT.url + '/' + job.id"
            >
              <its-icon size="24" svgIcon="edit"></its-icon>
            </its-button>
          </ng-container>
          <its-button
            variant="icon"
            matTooltip="{{ 'common.tooltip.clone' | translate }}"
            [link]="'/' + ROUTES_DATA.JOBS.children.CREATE.url"
            [queryParams]="{ clone: job.id }"
          >
            <its-icon size="24" svgIcon="copy"></its-icon>
          </its-button>
          <its-button
            *ngIf="job.status === JobStatusEnum.Active"
            variant="icon"
            matTooltip="{{ 'common.tooltip.pause' | translate }}"
            (click)="pauseJob(job)"
          >
            <its-icon size="24" svgIcon="pause"></its-icon>
          </its-button>
          <its-button
            *ngIf="job.status === JobStatusEnum.Paused"
            variant="icon"
            matTooltip="{{ 'jobView.tooltip.continuePublish' | translate }}"
            (click)="continuePublishJob(job)"
          >
            <its-icon size="24" svgIcon="play_arrow"></its-icon>
          </its-button>

          <ng-container *ngIf="job.status === JobStatusEnum.Pending">
            <its-button
              variant="icon"
              (click)="deleteJob(job.id)"
              matTooltip="{{ 'common.tooltip.delete' | translate }}"
            >
              <its-icon size="24" svgIcon="delete_outline"></its-icon>
            </its-button>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #viewActions>
        <ng-container
          *ngTemplateOutlet="jobActions; context: { $implicit: true }"
        ></ng-container>
      </ng-template>
    </div>

    <div class="job">
      <mat-card [class.drawer-card]="drawerId">
        <mat-card-content class="read-content-size">
          <div
            class="frame-job-company"
            *ngIf="!job.isAuthor && !job.companyOwnerOrManager"
          >
            <div class="job__company" [class.is-logo]="job.company?.logo">
              <a
                *ngIf="job.company?.logo"
                class="job__company-logo"
                routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{
                  job.company.slug
                }}"
              >
                <img [src]="job.company?.logo" [alt]="job.company.name" />
              </a>
              <div>
                <div class="a-i_b">
                  <h3 class="m-b_0">
                    <a
                      class="t-d_n"
                      routerLink="/{{ ROUTES_DATA.COMPANIES.url }}/{{
                        job.company.slug
                      }}"
                    >
                      <span>{{ job.company.name }}</span>
                      <span *ngIf="job?.company.verified" class="verified-icon">
                        <its-icon svgIcon="verified"></its-icon>
                      </span>
                    </a>
                  </h3>
                </div>

                <div class="f-w_s-b">
                  <div *ngIf="values.employeesMap[job.company.employees]">
                    <span
                      >{{ 'companyView.text.employees' | translate }} -
                      {{ values.employeesMap[job.company.employees] }}</span
                    >
                  </div>

                  <div *ngIf="companyType">
                    <span
                      >{{ 'companyView.text.companyType' | translate }} -
                      {{ companyType }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="job__section">
            <div
              *ngIf="job.isAuthor || job.companyOwnerOrManager"
              class="m-b_16 j-c_s-b"
            >
              <its-status [bg]="true" [jobStatus]="job.status"></its-status>

              <ng-container *ngIf="job.status === JobStatusEnum.Pending">
                <its-button
                  color="primary"
                  type="button"
                  [redirect]="false"
                  [publishJob]="job"
                >
                  {{ 'common.button.publish' | translate }}
                </its-button>
              </ng-container>
            </div>
            <div *ngIf="job.user" class="recruiter-rate">
              <its-avatar
                size="small"
                [avatar]="job.user?.avatar"
                [firstName]="job.user?.firstName"
                [lastName]="job.user?.lastName"
              ></its-avatar>
              <span>
                {{
                  'jobView.text.recruiterRate'
                    | translate
                      : {
                          name: job.user?.firstName + ' ' + job.user?.lastName,
                          percent: job.recruiterRate | percent
                        }
                }}
              </span>
            </div>
            <div class="main-information">
              <small class="d_b t-t_u-f-l m-b_8 c-black">{{
                job.published | ago
              }}</small>
              <h1 class="title-lg a-i_b m-b_8">
                <ng-container *ngIf="!drawerId; else link">
                  <div>
                    <its-icon
                      *ngIf="job.hot"
                      class="hot"
                      svgIcon="hot"
                      size="fit"
                    ></its-icon>
                    <span>{{ job.title }} </span
                    ><span
                      class="salary"
                      *ngIf="job?.salary"
                      [class.c-text-primary]="!job?.showSalary"
                      >{{ job?.salary }}
                      <i
                        *ngIf="
                          (job.isAuthor || job.companyOwnerOrManager) &&
                          !job?.showSalary
                        "
                        >({{
                          'jobView.text.salaryOnlyForOwner' | translate
                        }})</i
                      >
                    </span>
                  </div>
                </ng-container>
                <ng-template #link>
                  <a
                    class="t-d_n"
                    [routerLink]="'/' + ROUTES_DATA.JOBS.url + '/' + job.id"
                  >
                    <its-icon
                      *ngIf="job.hot"
                      class="hot"
                      size="fit"
                      svgIcon="hot"
                    ></its-icon>
                    <span>{{ job.title }} </span
                    ><span
                      class="salary"
                      *ngIf="job?.salary"
                      [class.c-text-primary]="!job?.showSalary"
                      >{{ job?.salary }}
                      <i
                        *ngIf="
                          (job.isAuthor || job.companyOwnerOrManager) &&
                          !job?.showSalary
                        "
                        >({{
                          'jobView.text.salaryOnlyForOwner' | translate
                        }})</i
                      >
                    </span></a
                  >
                </ng-template>
              </h1>

              <div *ngIf="job.bonus" class="job__bonus">
                <div class="bonus">
                  <its-icon size="16" svgIcon="bonus"></its-icon>
                </div>
                <span>
                  {{ 'jobView.text.bonus' | translate }}:&nbsp;<span
                    class="text-el"
                    >${{ job.bonus }}</span
                  >
                </span>
              </div>

              <its-categories
                [white]="true"
                [categories]="job.categories"
              ></its-categories>

              <div [class.m-b_16]="job.offices?.length">
                <ng-container
                  *ngFor="
                    let type of job.workTypesMap[WorkTypeGroupEnum.Place];
                    let l = last
                  "
                >
                  {{ values.workTypesTranslate[type.value] | translate
                  }}<ng-container
                    *ngIf="
                      type.value === WorkTypesEnum.Remote && job.userLocation
                    "
                    >&nbsp;({{ job.userLocationValue }})</ng-container
                  >{{ l ? '' : ', ' }}
                </ng-container>
              </div>

              <div class="d_f" *ngFor="let office of job.offices">
                <a
                  class="location-item"
                  matTooltipPosition="after"
                  matTooltip="{{ 'jobView.tooltip.seeOnMap' | translate }}"
                  [href]="
                    origin +
                    '/' +
                    ROUTES_DATA.HOME.children.COMPANIES_MAP.url +
                    '?office=' +
                    office.id
                  "
                  target="_blank"
                >
                  <its-icon size="15" svgIcon="place"></its-icon>
                  <span> {{ office.city }} - {{ office.address }} </span>
                </a>
              </div>
            </div>
          </div>
          <div class="job__section">
            <h3>
              {{ 'jobView.title.mainInformation' | translate }}
            </h3>
            <div class="job__section__content">
              <div class="block">
                <div
                  class="m-b_8"
                  *ngIf="categoryCondition && job.categories?.length > 1"
                >
                  <span class="f-w_r">{{
                    'jobView.label.categoriesCondition' | translate
                  }}</span
                  >:&nbsp;<b>{{ categoryCondition }}</b>
                </div>
                <div class="m-b_8" *ngIf="job.userLocationValue">
                  <span class="f-w_r">{{
                    'jobView.label.userLocation' | translate
                  }}</span
                  >:&nbsp;<b>{{ job.userLocationValue }}</b>
                </div>
                <div class="m-b_8" *ngIf="job.domainsValue">
                  <span class="f-w_r">{{
                    'jobView.label.domain' | translate
                  }}</span
                  >:&nbsp;<b>{{ job.domainsValue }}</b>
                </div>
                <div
                  class="m-b_8"
                  *ngIf="job.workTypesMap[WorkTypeGroupEnum.Time]?.length"
                >
                  <span class="f-w_r">{{
                    'jobView.label.employment' | translate
                  }}</span
                  >&nbsp;:
                  <b>
                    <ng-container
                      *ngFor="
                        let type of job.workTypesMap[WorkTypeGroupEnum.Time];
                        let l = last
                      "
                    >
                      <b>{{
                        values.workTypesTranslate[type.value] | translate
                      }}</b
                      >{{ l ? '' : ', ' }}
                    </ng-container>
                  </b>
                </div>
                <div class="m-b_8">
                  <span class="f-w_r">{{
                    'jobView.label.experience' | translate
                  }}</span
                  >:&nbsp;<b>{{ values.experiencesMap[job.experience] }}</b>
                </div>
                <div class="m-b_8" *ngIf="job.english">
                  <span class="f-w_r">{{
                    'jobView.label.englishLevel' | translate
                  }}</span
                  >:&nbsp;<b>{{ values.englishMap[job.english] }}</b>
                </div>
                <div class="m-b_8" *ngIf="job.languagesValue">
                  <span class="f-w_r">{{
                    'jobView.label.languages' | translate
                  }}</span
                  >:&nbsp;<b>{{ job.languagesValue }}</b>
                </div>
              </div>
            </div>
            <div class="job__section" *ngIf="job.hardSkills?.length">
              <h3>
                {{ 'jobView.subTitle.hardSkills' | translate }}
              </h3>
              <div class="job__section__content">
                <div class="block">
                  <its-skills [skills]="job.hardSkills"></its-skills>
                </div>
              </div>
            </div>
            <div class="job__section" *ngIf="job?.requirements?.length">
              <h3>
                {{ 'jobView.subTitle.requirements' | translate }}
              </h3>
              <div class="job__section__content">
                <ul class="list-items">
                  <li *ngFor="let item of job.requirements">{{ item }}</li>
                </ul>
              </div>
            </div>
            <div class="job__section" *ngIf="job?.niceToHave?.length">
              <h3>
                {{ 'jobView.subTitle.niceToHave' | translate }}
              </h3>
              <div class="job__section__content">
                <ul class="list-items">
                  <li *ngFor="let item of job.niceToHave">{{ item }}</li>
                </ul>
              </div>
            </div>

            <div class="job__section" *ngIf="job?.responsibilities?.length">
              <h3>
                {{ 'jobView.subTitle.responsibilities' | translate }}
              </h3>
              <div class="job__section__content">
                <ul class="list-items">
                  <li *ngFor="let item of job.responsibilities">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="job__section" *ngIf="job.description">
              <h3>
                {{ 'jobView.subTitle.description' | translate }}
              </h3>
              <div
                class="job__section__content editor-html"
                [innerHTML]="job.description"
              ></div>
            </div>
            <div class="job__section" *ngIf="job.youtubeVideoUrl">
              <h3>
                {{ 'jobView.subTitle.youtubeVideo' | translate }}
              </h3>
              <div class="job__section__content">
                <iframe
                  class="job-video"
                  frameborder="0"
                  [src]="job.youtubeVideoUrl | safe"
                >
                </iframe>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              job.teamSize ||
              job.customerCountry ||
              job.projectDescription ||
              job.projectStack?.length
            "
            class="job__section"
          >
            <h3>
              {{ 'jobView.title.project' | translate }}
            </h3>
            <div class="job__section__content">
              <div class="m-b_16" *ngIf="job.teamSize || job.customerCountry">
                <div *ngIf="job.teamSize">
                  {{ 'jobView.text.teamSize' | translate }}
                  &nbsp;-&nbsp;<b>{{ job.teamSize }}</b>
                </div>

                <div *ngIf="job.customerCountry">
                  {{ 'jobView.text.customerFrom' | translate }}
                  &nbsp;-&nbsp;<b>{{ COUNTRIES[job.customerCountry] }}&nbsp;</b>
                  <small *ngIf="job.customerTrip"
                    >({{ 'jobView.text.customerTrip' | translate }})</small
                  >
                </div>
              </div>
              <div class="m-b_16" *ngIf="job.projectStack?.length">
                <div class="m-b_8">
                  {{ 'jobView.text.projectStack' | translate }}
                </div>
                <its-skills [skills]="job.projectStack"></its-skills>
              </div>
              <ng-container *ngIf="job.projectDescription">
                <div class="editor-html" [innerHTML]="job.projectDescription">
                  {{ job.projectDescription }}
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="job?.advantages?.length" class="job__section">
            <h3>
              {{ 'jobView.title.advantages' | translate }}
            </h3>
            <div class="job__section__content">
              <ul class="list-items">
                <li *ngFor="let item of job.advantages">{{ item }}</li>
              </ul>
            </div>
          </div>
          <div *ngIf="job?.hiringStepsSelected?.length" class="job__section">
            <h3>
              {{ 'jobView.title.hiringSteps' | translate }}
            </h3>
            <div class="job__section__content">
              <ul class="list-items">
                <li *ngFor="let item of job.hiringStepsSelected">
                  {{ item.value | translate }}
                </li>
              </ul>
            </div>
          </div>

          <div
            *ngIf="job.status === JobStatusEnum.Active"
            class="job__section m-b_24"
          >
            <h3 class="m-b_8">
              {{ 'common.title.shareInSocial' | translate }}
            </h3>
            <its-share-social></its-share-social>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="m-b_32 buttons-actions-l">
        <ng-container *ngTemplateOutlet="jobActions"></ng-container>

        <ng-container *ngIf="job.companyOwnerOrManager || job.isAuthor">
          <ng-container
            *ngIf="job.wasPublished && job.status !== JobStatusEnum.Done"
          >
            <its-button
              color="primary"
              type="button"
              (click)="closeVacancy(job.id)"
            >
              {{ 'jobView.button.closeVacancy' | translate }}
            </its-button>
          </ng-container>
          <ng-container
            *ngIf="job.isAuthor && job.status === JobStatusEnum.Inactive"
          >
            <its-button
              color="accent"
              type="button"
              (click)="reactivateJob(job)"
            >
              {{ 'jobView.button.reactivateVacancy' | translate }}
            </its-button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #jobActions let-top>
  <ng-container *ngIf="!job.companyOwnerOrManager && !job.isAuthor">
    <div class="a-i_c c-g_24 j-c_c r-g_8" [class.f-d_r-r]="top">
      <its-button
        *ngIf="!onlyView && job.status === JobStatusEnum.Active"
        itsNeedAuth
        color="primary"
        classes="btn-lg"
        (click)="applyJob($event, job)"
      >
        {{ 'jobView.button.apply' | translate }}
      </its-button>

      <its-button
        *ngIf="job.status === JobStatusEnum.Active"
        itsNeedAuth
        variant="basic"
        color="primary"
        class="d_f"
        classes="btn-sm no-border"
        type="button"
        (click)="toggleFollow($event, job)"
      >
        <its-icon
          svgIcon="{{ job.isFollow ? 'star' : 'star_border' }}"
        ></its-icon>
        <span class="title">{{
          job.isFollow
            ? ('common.button.removeFromFavorite' | translate)
            : ('common.button.addToFavorite' | translate)
        }}</span>
      </its-button>
    </div>
  </ng-container>
</ng-template>
