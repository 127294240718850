import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { take, takeUntil } from 'rxjs/operators';

import { TemplatesService } from '@modules/settings/services';

import { LocaleService } from '@core/services';

import { OTHER_REASON } from '@common/shared/constants';
import { getTemplateMessage } from '@common/shared/helpers';

import { Destroyable, FormStateDispatcher } from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import { TemplateTypeEnum } from '@client/shared/enums';
import { ICommentModal } from '@client/shared/interfaces';

@Component({
  selector: 'its-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class CommentModalComponent extends Destroyable implements OnInit {
  TemplateTypeEnum = TemplateTypeEnum;
  defaultData: ICommentModal = {
    cancel: _('common.form.button.cancel'),
    confirm: _('common.modal.button.confirm'),
    messageRequired: false
  };
  currentReasonText = '';
  form: FormGroup;

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly localeService: LocaleService,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly templatesService: TemplatesService,
    readonly dialogRef: MatDialogRef<CommentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICommentModal
  ) {
    super();
  }

  ngOnInit() {
    this.data = {
      ...this.defaultData,
      ...this.data
    };
    this.buildForm();
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  confirm() {
    this.form.markAsTouched();
    this.changeDetector.markForCheck();
    if (this.form.valid) {
      const data = { ...this.form.value };
      const message = data?.message.trim();
      data.message = message ? message : this.currentReasonText;
      this.onNoClick(data);
    }
  }

  buildForm() {
    this.form = new FormGroup({
      message: new FormControl(
        '',
        Validators.compose([
          this.data.messageRequired ? Validators.required : null
        ])
      )
    });

    if (this.data?.reasons) {
      this.form.addControl(
        'reason',
        new FormControl(null, Validators.required)
      );
      this.form.controls.reason.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((reason) => {
          const f = 'message';
          if (reason === OTHER_REASON) {
            this.form.get(f).setValidators(Validators.required);
            this.form.get(f).updateValueAndValidity();
            if (this.form.get(f)?.invalid) {
              this.form.get(f).setErrors({ required: {} });
            }
            this.formStateDispatcher.onSubmit.notify();
          } else {
            this.form.get(f).clearValidators();
            this.form.get(f).updateValueAndValidity();
            this.form.get(f).setErrors(null);
          }
          this.setTemplateMessage(reason);
        });
    }
  }

  reasonSelected(id: number, reasons: ISelectOption[]) {
    this.currentReasonText =
      (id !== OTHER_REASON && reasons.find((r) => r.id === id)?.value) || '';
  }

  setTemplateMessage(reason: number) {
    this.templatesService
      .getTemplates()
      .pipe(take(1))
      .subscribe((templates) => {
        const t = templates.find(
          (t) => t.type === this.data.type && t.reason === reason
        );
        this.form.patchValue({
          message: getTemplateMessage(t?.description, this.data?.userName)
        });
      });
  }
}
