import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscriber } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { ModalCloseVacancyComponent } from '@modules/jobs/components';
import { IJobResponse, IJobRequest, IJob } from '@modules/jobs/interfaces';
import { JobsFactory } from '@modules/jobs/services/jobs.factory';
import { PlanService } from '@modules/settings/services';

import { AuthService } from '@core/auth';
import {
  GlobalObjectService,
  LocaleService,
  ModalService,
  NotificationService,
  ValuesService
} from '@core/services';

import { JobViewTypeEnum } from '@common/shared/enums';
import { IQuery, ICountResponse } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class JobsService extends BaseHttpService {
  constructor(
    readonly factory: JobsFactory,
    readonly modalService: ModalService,
    readonly notificationService: NotificationService,
    readonly planService: PlanService,
    readonly valuesService: ValuesService,
    readonly authService: AuthService,
    readonly localeService: LocaleService,
    readonly router: Router,
    readonly globalObjectService: GlobalObjectService
  ) {
    super('jobs');
  }

  getJobs(params: IQuery) {
    return this.get<ICountResponse<IJobResponse[]>>(params).pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  getHotJobs(params: IQuery) {
    return this.get<IJobResponse[]>(params, 'hot').pipe(
      map(({ content }) => content.map((job) => this.factory.fromResponse(job)))
    );
  }

  /*getCompanyChatJobs(params: IQuery) {
    return this.get<ICountResponse<IJobResponse[]>>(params, 'company/messenger').pipe(
      map(({content}) => ({
        data: content.data.map(job => this.factory.fromResponse(job)),
        count: content.count,
      })),
    );
  }*/

  getCompanyAllJobs(params: IQuery = {}) {
    return this.get<ICountResponse<IJobResponse[]>>(params, 'company/all').pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  getMyActiveJobs() {
    return this.get<IJobResponse[]>({}, 'my/active').pipe(
      map(({ content }) => content.map((job) => this.factory.fromResponse(job)))
    );
  }

  getNotClosedJobs() {
    return this.get<IJobResponse[]>({}, 'my/not-archived').pipe(
      map(({ content }) => content.map((job) => this.factory.fromResponse(job)))
    );
  }

  getCompanyJobs(slug: string, params: IQuery) {
    return this.get<ICountResponse<IJobResponse[]>>(
      params,
      `company-jobs/${slug}`
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  getJob(id: number) {
    const params = new URL(this.globalObjectService.getCurrentUrl())
      ?.searchParams;
    const viewType = params?.get('j_view');
    if (viewType) {
      setTimeout(() => {
        this.router.navigate([], {
          replaceUrl: true,
          queryParamsHandling: 'merge',
          queryParams: {
            j_view: null
          }
        });
      }, 0);
    }
    return this.get<IJobResponse>({}, id, {
      headers:
        params?.get('fbclid') || viewType
          ? {
              'X-Job-View-Type': params?.get('fbclid')
                ? `${JobViewTypeEnum.FromFacebook}`
                : viewType
            }
          : {}
    }).pipe(
      withLatestFrom(this.valuesService.getValues()),
      map(([{ content }, values]) => {
        const job = this.factory.fromResponse(content);
        job.hiringStepsSelected = values.hiringSteps.filter(
          (el) => !!job.hiringSteps[el.id]
        );
        return job;
      })
    );
  }

  createJob(job: IJobRequest) {
    const request = this.factory.toRequest(job);

    return this.post<IJobRequest, IJobResponse>(request).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  updateJob(job: IJobRequest, id: number) {
    const request = this.factory.toRequest(job);
    return this.put<IJobRequest, IJobResponse>(request, {}, id).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  deleteJob(id: number) {
    return new Observable((observer) => {
      const options = {
        header: _('common.modal.title.warning'),
        body: _('job.modal.deleteVacancy'),
        buttons: [
          { name: _('common.modal.button.cancel') },
          {
            name: _('common.modal.button.delete'),
            warn: true
          }
        ]
      };
      this.modalService.openModal(options, (answer) => {
        if (answer) {
          return this.delete({}, id).subscribe(() => {
            observer.next(true);
          });
        }
        observer.complete();
      });
    });
  }

  pauseJob(id: number) {
    return new Observable((observer) => {
      const options = {
        header: _('common.modal.title.warning'),
        body: _('job.modal.pauseVacancy'),
        buttons: [
          { name: _('common.modal.button.cancel') },
          { name: _('common.modal.button.confirm') }
        ]
      };
      this.modalService.openModal(options, (answer) => {
        if (answer) {
          return this.put(null, {}, `${id}/pause`).subscribe(() => {
            this.notificationService.showSuccess(
              this.localeService.getInstant(
                _('common.notification.pauseVacancy')
              )
            );
            observer.next(true);
          });
        }
        observer.complete();
      });
    });
  }

  continuePublishJob(id: number) {
    return this.put(null, {}, `${id}/reactivate`);
  }

  closeVacancy(id: number) {
    return new Observable((observer) => {
      this.modalService.openComponent(ModalCloseVacancyComponent, (reason) => {
        if (reason) {
          return this.put({ reason }, {}, `${id}/close-vacancy`).subscribe(
            () => {
              this.notificationService.showSuccess(
                this.localeService.getInstant(
                  _('common.notification.closeVacancy')
                )
              );
              observer.next(true);
            }
          );
        }
        observer.complete();
      });
    });
  }

  getFollowedJobs(params: IQuery) {
    return this.get<ICountResponse<IJobResponse[]>>(
      params,
      'user/followed'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((job) => this.factory.fromResponse(job)),
        count: content.count
      }))
    );
  }

  followJobStatus(follow: boolean, id: number) {
    const body = { follow };
    return this.post(body, { [NO_LOADER_PARAM]: true }, `${id}/follow`);
  }

  publishJob(job: IJob, reactivate = false) {
    const params = {} as IQuery;
    if (job?.hot) {
      params.hot = true;
    }
    const publish = (observer: Subscriber<IJob> = null) =>
      this.put<IJobResponse>(null, params, `${job.id}/publish`).pipe(
        map(({ content }) => {
          const job = this.factory.fromResponse(content);
          if (observer) {
            observer.next(job);
          }
          return job;
        }),
        tap(() => {
          if (!this.authService.getProfileValue()?.company?.premiumPlan) {
            this.planService.loadJobServices().subscribe();
          }
        })
      );

    if (reactivate) {
      return new Observable((observer: Subscriber<IJob>) => {
        const options = {
          header: _('common.modal.title.warning'),
          body:
            job.hot && this.planService.getHotServicesValue()?.length
              ? _('job.modal.reactivateHotVacancy')
              : _('job.modal.reactivateVacancy'),
          buttons: [
            { name: _('common.modal.button.cancel') },
            { name: _('common.modal.button.confirm') }
          ]
        };
        this.modalService.openModal(options, (answer) => {
          if (answer) {
            return publish(observer).subscribe(() => {
              this.notificationService.showSuccess(
                this.localeService.getInstant(
                  _('common.notification.reactivateVacancy')
                )
              );
            });
          }
          observer.complete();
        });
      });
    } else {
      return publish().pipe(
        tap(() => {
          this.notificationService.showSuccess(
            this.localeService.getInstant(_('common.notification.jobPublished'))
          );
        })
      );
    }
  }
}
