import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { ValuesService } from '@core/services';

import { CurrencyEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

@Component({
  selector: 'its-currency-switcher',
  templateUrl: './currency-switcher.component.html',
  styleUrls: ['./currency-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencySwitcherComponent {
  currency = CurrencyEnum.USD;
  currencies$: Observable<ISelectOption<CurrencyEnum>[]> = this.valuesService
    .getValues()
    .pipe(pluck('currencies'));

  @Output() change = new EventEmitter<CurrencyEnum>();
  constructor(
    readonly valuesService: ValuesService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  changeCurrency(currency: CurrencyEnum) {
    this.currency = currency;
    this.change.emit(currency);
    this.changeDetector.markForCheck();
  }
}
