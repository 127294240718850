import * as moment from 'moment';

import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '@core/services';

import { LanguageEnum } from '@common/shared/enums';

@Pipe({
  name: 'remained',
  pure: false
})
export class ITSRemainedPipe implements PipeTransform {
  constructor(
    readonly localeService: LocaleService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  transform(date: Date | string): string {
    moment.locale(this.localeService.locale || LanguageEnum.Ukraine);
    return moment().to(moment(date));
  }
}
