import { Injectable } from '@angular/core';

import { ITag } from '@client/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TagsFactory {
  fromResponse(res: ITag): ITag {
    return res;
  }
}
