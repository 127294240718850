import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { JobStatusEnum } from '@common/shared/enums';

export const JOBS_FILTER = Object.freeze([
  {
    id: JobStatusEnum.Inactive,
    value: _('common.status.inactive')
  },
  {
    id: JobStatusEnum.Pending,
    value: _('jobStatus.status.pending')
  },
  {
    id: JobStatusEnum.Paused,
    value: _('common.status.paused')
  },
  {
    id: JobStatusEnum.Active,
    value: _('common.status.active')
  }
]);
