import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { IJob } from '@modules/jobs/interfaces';
import { IResume } from '@modules/resumes/interfaces';

import { AuthService } from '@core/auth';
import { ModalService, NotificationService } from '@core/services';

import { checkIfResumeIsMatchedToVacancy } from '@common/shared/helpers';

import { ISelectOption } from '@client/shared/components/select';
import { ROUTES_DATA } from '@client/shared/constants';
import { TemplateTypeEnum } from '@client/shared/enums';

@Component({
  selector: 'its-modal-apply-job',
  templateUrl: './modal-apply-job.component.html',
  styleUrls: ['./modal-apply-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalApplyJobComponent {
  ROUTES_DATA = ROUTES_DATA;
  applyForm: FormGroup;
  TemplateTypeEnum = TemplateTypeEnum;
  resumes: IResume[] = [];
  resumesOptions$: Observable<ISelectOption<string>[]> = this.authService
    .getMyResumes()
    .pipe(
      map((resumes) => {
        this.resumes = [...resumes];
        return resumes.map((r) => ({
          id: r.id,
          value: r.position
        }));
      }),
      tap((resumes) => {
        this.buildForm(resumes);
      })
    );
  constructor(
    readonly dialogRef: MatDialogRef<ModalApplyJobComponent>,
    readonly modalService: ModalService,
    readonly changeDetector: ChangeDetectorRef,
    readonly authService: AuthService,
    readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public job: IJob
  ) {}

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  buildForm(resumes: ISelectOption<string>[]) {
    this.applyForm = new FormGroup({
      resume: new FormControl(
        {
          value: resumes?.length === 1 ? resumes[0].id : null,
          disabled: resumes?.length === 1
        },
        Validators.required
      ),
      message: new FormControl('')
    });
    if (this.job?.applicationUrl && resumes?.length === 1) {
      this.applyJob();
    }
    this.changeDetector.markForCheck();
  }

  applyJob() {
    const value = this.applyForm?.getRawValue();
    if (value.resume) {
      const resume = this.resumes.find((r) => r.id === value.resume);
      const resumeMatchMessage = checkIfResumeIsMatchedToVacancy(
        resume,
        this.job
      );
      if (resumeMatchMessage?.length) {
        const options = {
          header: _('common.modal.title.warning'),
          body: resumeMatchMessage
            .map((m) => this.notificationService.getMessage(m))
            .join('\n\n'),
          buttons: [null, { name: _('common.modal.button.gotIt') }]
        };
        this.modalService.openModal(options, () => {});
        this.onNoClick();
      } else {
        this.onNoClick(value);
      }
    }
  }
}
