import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProp'
})
export class FilterProp implements PipeTransform {
  transform(list: any[], prop: string, value = '') {
    const trueValue = (value || '').trim();
    return trueValue
      ? list.filter((el) =>
          el[prop].toLowerCase().includes(trueValue.toLowerCase())
        )
      : list;
  }
}
