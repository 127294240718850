import { Injectable } from '@angular/core';

import { IPlan } from '@client/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PlanFactory {
  fromResponse(res: IPlan): IPlan {
    return res;
  }
}
