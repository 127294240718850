import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AuthService } from '@core/auth';

import { BaseHttpService } from '@client/shared/abstracts';
import { ITemplate } from '@client/shared/interfaces';

interface ITemplateResponse {
  templates: ITemplate[];
}

@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends BaseHttpService {
  private templates$: BehaviorSubject<ITemplate[]> = new BehaviorSubject<
    ITemplate[]
  >(null);
  constructor(readonly authService: AuthService) {
    super('templates');

    this.authService
      .getProfile()
      .pipe(
        filter((prof) => prof.exist),
        distinctUntilChanged((prev, curr) => prev.id === curr.id)
      )
      .subscribe(() => {
        this.loadTemplatesTemplates().subscribe();
      });
  }

  loadTemplatesTemplates() {
    return this.get<ITemplateResponse>().pipe(
      map((res) => {
        this.setTemplates(res.content?.templates || []);
      })
    );
  }

  saveTemplates(templates: ITemplate[]) {
    return this.post<ITemplateResponse>({ templates }, {}, '').pipe(
      map((res) => {
        this.setTemplates(res.content?.templates || []);
      })
    );
  }

  setTemplates(value: ITemplate[]) {
    this.templates$.next(value);
  }

  getTemplates() {
    return this.templates$.asObservable().pipe(filter((res) => !!res));
  }
}
