import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PwaMobileTypeEnum } from '@client/shared/enums';


@Component({
  selector: 'its-pwa-prompt',
  templateUrl: './pwa-add-prompt.component.html',
  styleUrls: ['./pwa-add-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaAddPromptComponent {
  PwaDeviceEnum = PwaMobileTypeEnum;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    readonly mobileType: PwaMobileTypeEnum,
    readonly bottomSheetRef: MatBottomSheetRef<PwaAddPromptComponent>
  ) {
  }

  installPwa(): void {
    this.close(true);
  }

  close(result = null) {
    this.bottomSheetRef.dismiss(result);
  }
}
