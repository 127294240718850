import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  COMPANY_DELETE_DURATION_D,
  PROFILE_DELETE_DURATION_H, HELLO_EMAIL
} from '@common/shared/constants';

import { ROUTES_DATA } from '@client/shared/constants';
import { GlobalObjectService } from '@core/services';

@Component({
  selector: 'its-policy-uk',
  templateUrl: './policy-uk.component.html',
  styleUrls: ['./policy-uk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyUkComponent implements OnInit {
  origin = this.globalObjectService.getLocation()?.origin;
  ROUTES_DATA = ROUTES_DATA;
  HELLO_EMAIL = HELLO_EMAIL;
  constructor(readonly globalObjectService: GlobalObjectService) {}

  ngOnInit(): void {}
}
