<div class="auth-container">
  <h1 class="t-a_c">{{'auth.forgotPassword.title' | translate}}</h1>
  <form [formGroup]="form" #formInstance (ngSubmit)="send()" (keyup.enter)="send()">
    <div class="form-control">
      <its-input
        formControlName="username"
        placeholder="{{'auth.email.placeholder' | translate}}"
      ></its-input>
    </div>
    <its-button class="m-b_16 d_b" classes="w_100" type="submit" color="primary">
      {{'auth.button.sendForgotPassword' | translate}}
    </its-button
    >
  </form>
  <p *ngIf="emailWasSend" class="c-green-active t-a_c">
    <span>{{'auth.message.resetSent' | translate}}</span>
  </p>

  <div class="j-c_c">
    <its-button color="primary" variant="basic" (click)="backToLogIn()"
    >
      {{'auth.button.resetToLogIn' | translate}}
    </its-button>
  </div>
</div>
