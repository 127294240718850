import { AbstractControl } from '@angular/forms';

import { environment } from '@env';

export function PlusInEmail({ value }: AbstractControl) {
  if (!environment.production) {
    return null;
  }
  if (value) {
    const valid = !value.includes('+');
    return valid ? null : { email: true };
  }
  return null;
}
