import {
  Component,
  OnInit,
  Inject,
  Optional,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SkipSelf,
  Input
} from '@angular/core';
import { NgControl, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { ISelectOption } from '@client/shared/components/select';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'its-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class RadioComponent<T>
  extends BaseControlComponent<T>
  implements OnInit
{
  readonly destroyed$: Observable<unknown>;
  @Input() color: ThemePalette = 'accent';
  @Input() vertical = false;
  @Input() options: ISelectOption[] = [];

  readonly control = new FormControl(null);

  @Input() valueFn = (option: ISelectOption | null) => option?.id;
  @Input() labelFn = (option: ISelectOption | null) => option?.value;

  constructor(
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(i: number, option: ISelectOption) {
    return option.id;
  }
}
