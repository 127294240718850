export interface ITracking {
  cid?: string;
  email?: string;
  user?: any;
  utm?: string;
  companyName?: string;
  data?: any;
  firstVisit?: any;
  accountCreated?: any;
  visitedPages?: string[];
}
