<mat-radio-group [class.vertical]="vertical" [class.its-radio-group]="!vertical" color="primary" [formControl]="control" (keydown.enter)="onEnterKeyDown($event)" [@.disabled]="true">
  <mat-radio-button
    [color]="color"
    class="margin-bottom-3 margin-right-3"
    *ngFor="let option of options; trackBy: trackByFn"
    [value]="valueFn(option)"
    [disabled]="option.disabled"
  >
    <div class="radio-label">
      <div>{{ labelFn(option) }}</div>
      <small *ngIf="option?.info" class="l-h_1" [innerHTML]="option?.info"></small>
    </div>
  </mat-radio-button>
</mat-radio-group>
