<div class="social">
  <ng-container *ngFor="let contact of contacts">
    <a
      [href]="contact.link"
      [matTooltip]="contact.tooltip ? (contact.tooltip | translate) : ''"
      [attr.target]="contact.target"
      [attr.aria-label]="contact.label"
    >
      <its-icon [svgIcon]="contact.icon" size="36"></its-icon>
    </a>
  </ng-container>
</div>
