import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'its-web-push-confirm',
  templateUrl: './web-push-confirm.component.html',
  styleUrls: ['./web-push-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class WebPushConfirmComponent implements OnInit {
  answer = false;
  constructor(readonly dialogRef: MatDialogRef<WebPushConfirmComponent>) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close(this.answer);
  }

  confirm() {
    this.answer = true;
    this.onNoClick();
  }
}
