import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GlobalObjectService } from '@core/services';

@Component({
  selector: 'its-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerComponent {
  destroy$ = new Subject();
  touchstartX = 0;
  touchendX = 0;

  @ViewChild('drawerContent', { static: true }) drawerContent: ElementRef;

  @Input() autoFocus = true;
  @Input() disableClose = false;
  @Input() mode: MatDrawerMode = 'over';
  @Input() opened = false;
  @Input() position: 'start' | 'end' = 'end';
  @Input() autosize = false;
  @Input() hasBackdrop = true;
  @Input() fixedInViewport = true;

  @Output() closedStart = new EventEmitter<boolean>();
  @Output() positionChanged = new EventEmitter<any>();
  @Output() openedChange = new EventEmitter<boolean>();
  @Output() openedStart = new EventEmitter<boolean>();
  @Output() backdropClick = new EventEmitter<boolean>();

  constructor(
    readonly globalObjectService: GlobalObjectService,
    readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  onBackdropClick(ev) {
    this.backdropClick.emit(ev);
  }

  onClosedStart(ev) {
    try {
      this.globalObjectService
        .getDocument()
        .getElementsByTagName('body')[0]
        .classList.remove('stop-scrolling');
    } catch (e) {}
    this.destroySubject();
    this.closedStart.emit(ev);
  }

  onPositionChanged(ev) {
    this.positionChanged.emit(ev);
  }

  onOpenedChange(ev) {
    this.openedChange.emit(ev);
  }

  onOpenedStart(ev) {
    try {
      this.globalObjectService
        .getDocument()
        .getElementsByTagName('body')[0]
        .classList.add('stop-scrolling');
    } catch (e) {}
    this.listenTouch();
    this.openedStart.emit(ev);
  }

  closeDrawer() {
    this.opened = false;
    this.changeDetectorRef.detectChanges();
  }

  listenTouch() {
    this.destroy$ = new Subject();
    fromEvent(this.drawerContent.nativeElement, 'touchstart')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
        this.touchstartX = e.changedTouches[0].screenX;
      });

    fromEvent(this.drawerContent.nativeElement, 'touchend')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: any) => {
        this.touchendX = e.changedTouches[0].screenX;
        if (this.touchendX - this.touchstartX > 100) {
          this.closeDrawer();
        }
        this.touchstartX = 0;
        this.touchendX = 0;
      });
  }

  destroySubject() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
