import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Memoise } from '@client/shared/decorators';
import { getNamePreview } from '@common/shared/helpers';

@Component({
  selector: 'its-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AvatarComponent implements OnInit {
  @Input() size: 'resume' | 'default' | 'small' | 'chat' = 'default';
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() avatar: string;

  constructor() { }

  ngOnInit(): void {
  }

  @Memoise()
  getNamePreview(firstName: string, lastName: string) {
    return getNamePreview(firstName, lastName);
  }

}
