import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { GlobalObjectService } from '@core/services/global-object.service';
import { LocaleService } from '@core/services/locale.service';

import { SnackbarComponent } from '@client/shared/components/snackbar';
import { SnackbarTypeEnum } from '@client/shared/enums';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    readonly snackBar: MatSnackBar,
    private zone: NgZone,
    readonly globalObjectService: GlobalObjectService,
    readonly localeService: LocaleService
  ) {}

  showSuccess(message: string, duration = 3000): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: ['success'],
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        duration,
        data: {
          message,
          type: SnackbarTypeEnum.Success,
          close: () => snackBar.dismiss()
        }
      });
    });
  }

  showError(error: HttpErrorResponse | string, duration = 5000): void {
    let message = this.getErrorMessage('error.server.error');
    if (error instanceof HttpErrorResponse && error?.error?.message) {
      message = this.getErrorMessage(error.error.message);
    } else if (error && typeof error === 'string') {
      message = error;
    }

    this.zone.run(() => {
      const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: ['error'],
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        duration,
        data: {
          message,
          type: SnackbarTypeEnum.Error,
          close: () => snackBar.dismiss()
        }
      });
    });
  }

  showMessage(message: any, duration = 3000): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: ['message'],
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        duration,
        data: {
          message,
          type: SnackbarTypeEnum.Message,
          close: () => snackBar.dismiss()
        }
      });
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  getErrorMessage(key: string | string[]) {
    if (key) {
      if (typeof key === 'string') {
        return this.getMessage(key);
      }
      if (Array.isArray(key)) {
        return key.map((k) => this.getMessage(k)).join('\n');
      }
    }
    return '';
  }

  public getMessage(key) {
    const message = this.localeService.getInstant(key);
    return message || key;
  }
}
