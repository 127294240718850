import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { StatusCodes } from 'http-status-codes/build/es';

import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from '@modules/chat/services';
import {
  IJobApply,
  IJobApplyAnswer,
  IJobApplyRequest,
  IJobInviteAnswer
} from '@modules/jobs/interfaces';
import { JobsFactory } from '@modules/jobs/services/jobs.factory';

import {
  LocaleService,
  ModalService,
  NotificationService
} from '@core/services';

import { InviteStatusEnum, MessageTypeEnum } from '@common/shared/enums';
import {
  ICountResponse,
  IQuery,
  IReqInvState
} from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';

import { JobsRequestFactory } from './jobs-request.factory';

@Injectable({
  providedIn: 'root'
})
export class JobsRequestService extends BaseHttpService {
  requestStatusChanged$ = new BehaviorSubject(null);

  constructor(
    readonly jobFactory: JobsFactory,
    readonly messageService: MessageService,
    readonly localeService: LocaleService,
    readonly notificationService: NotificationService,
    readonly modalService: ModalService,
    readonly factory: JobsRequestFactory
  ) {
    super('jobs-requests');
  }

  getRequestStatusChanged() {
    return this.requestStatusChanged$.asObservable();
  }

  setRequestStatusChanged() {
    this.requestStatusChanged$.next(true);
  }

  getJobOwnerRequests(id: number, params: IQuery) {
    return this.get<ICountResponse<IJobApplyRequest[]>>(
      params,
      `${id}/job-requests`
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getJobDevRequests(id: number, params: IQuery) {
    return this.get<IJobApplyRequest[]>(params, `${id}/dev-requests`).pipe(
      map(({ content }) => content.map((el) => this.factory.fromResponse(el)))
    );
  }

  getRequestsByUser(params: IQuery) {
    return this.get<ICountResponse<IJobApplyRequest[]>>(
      params,
      `user/requests`
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getPendingRequestsByUser() {
    return this.get<number>({ [NO_LOADER_PARAM]: true }, `user/pending`).pipe(
      map(({ content }) => content)
    );
  }

  getResumeRequests(resumeId: string, params: IQuery) {
    return this.get<IJobApplyRequest[]>(
      params,
      `resume/${resumeId}/requests`
    ).pipe(
      map(({ content }) => content.map((el) => this.factory.fromResponse(el)))
    );
  }

  getRequestsByCompany(params: IQuery) {
    return this.get<ICountResponse<IJobApplyRequest[]>>(
      params,
      'company/requests'
    ).pipe(
      map(({ content }) => ({
        data: content.data.map((el) => this.factory.fromResponse(el)),
        count: content.count
      }))
    );
  }

  getPendingRequestsByCompany() {
    return this.get<number>(
      { [NO_LOADER_PARAM]: true },
      'company/pending'
    ).pipe(map(({ content }) => content));
  }

  applyRequest(id: number, body: IJobApply) {
    return this.post<IJobApply, IJobApplyRequest>(
      body,
      {},
      `${id}/request/${body.resume}`
    ).pipe(
      catchError((error) => {
        if (error.status === StatusCodes.METHOD_NOT_ALLOWED) {
          const options = {
            header: _('common.modal.title.attention'),
            body: (error?.error || [])
              .map((m) => this.notificationService.getMessage(m))
              .join('\n\n'),
            buttons: [
              { name: _('common.modal.button.cancel') },
              { name: _('common.modal.button.gotIt') }
            ]
          };
          this.modalService.openModal(options);
          return EMPTY;
        }
        throw error;
      }),
      map(({ content }) => this.factory.fromResponse(content)),
      tap(() => {
        this.notificationService.showSuccess(
          this.localeService.getInstant(_('common.notification.applyRequest'))
        );
      })
    );
  }

  answerRequestByCompany(id: number, data: IJobApplyAnswer) {
    return this.put<IJobApplyAnswer, IJobApplyRequest>(
      data,
      {},
      `${id}/company-answer`
    ).pipe(
      map(({ content }) => this.factory.fromResponse(content)),
      tap((request) => {
        if (
          data?.status !== InviteStatusEnum.Archived &&
          data?.status !== InviteStatusEnum.Pending
        ) {
          this.messageService
            .sendMessage(request.chat.id, {
              ...data,
              invReqStatus: data.status,
              type: MessageTypeEnum.AnswerRequest
            })
            .subscribe();
        }
      })
    );
  }

  companyBulkArchiveRequests(data: number[]) {
    return this.put<number[]>(data, {}, 'company/bulk-archive');
  }

  resumeBulkArchiveRequests(data: number[]) {
    return this.put<number[]>(data, {}, 'resume/bulk-archive');
  }

  changeRequestPriority(id: number, priority: number) {
    return this.put<any>({ priority }, {}, `${id}/priority`);
  }

  getRequestState(id: number) {
    return this.get<IReqInvState[]>({}, `${id}/state`).pipe(
      map(({ content }) => content)
    );
  }

  saveRequestState(id: number, state: IReqInvState) {
    return this.put<any>(state, {}, `${id}/state`).pipe(
      map(({ content }) => content)
    );
  }

  deleteRequestState(id: number) {
    return this.delete<any>({}, `state/${id}`);
  }

  answerRequestByUser(
    id: number,
    data: IJobApplyAnswer,
    result: (res) => void = null
  ) {
    const request = () => {
      return this.put<IJobApplyAnswer, IJobApplyRequest>(
        data,
        {},
        `${id}/user-answer`
      )
        .pipe(map(({ content }) => this.factory.fromResponse(content) || true))
        .subscribe(() => {
          result(true);
        });
    };

    if (
      data.status === InviteStatusEnum.Canceled ||
      data.status === InviteStatusEnum.Archived
    ) {
      const options = {
        header: _('common.modal.title.warning'),
        body:
          data.status === InviteStatusEnum.Canceled
            ? _('userRequest.modal.cancelRequestWarning')
            : _('userRequest.modal.archiveRequestWarning'),
        buttons: [
          { name: _('common.modal.button.cancel') },
          { name: _('common.modal.button.confirm') }
        ]
      };
      this.modalService.openModal(options, (answer) => {
        if (answer) {
          if (data.status === InviteStatusEnum.Canceled) {
            this.setRequestStatusChanged();
          }
          return request();
        }
      });
    }
  }

  archiveSelectedAnswer(cb: (answer) => void) {
    const options = {
      header: _('common.modal.title.warning'),
      body: _('requests.modal.archiveSelected'),
      buttons: [
        { name: _('common.modal.button.cancel') },
        { name: _('common.modal.button.confirm') }
      ]
    };

    this.modalService.openModal(options, (answer) => {
      cb(answer);
    });
  }
}
