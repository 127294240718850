import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

import { IFile } from '@client/shared/interfaces';

@Component({
  selector: 'its-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageSliderComponent {
  @Input() previewImage: IFile;
  @Input() images: IFile[] = [];
  open = false;
  currentIndex: number;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeSlider();
  }

  @Output() close = new EventEmitter<boolean>();

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  openPhoto(index: number) {
    this.open = true;
    this.currentIndex = index;
  }

  closeSlider() {
    this.open = false;
    this.close.emit(true);
  }

  prevPhoto() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.images.length - 1;
    } else {
      --this.currentIndex;
    }
    this.changeDetector.markForCheck();
  }

  nextPhoto() {
    if (this.currentIndex === this.images.length - 1) {
      this.currentIndex = 0;
    } else {
      ++this.currentIndex;
    }
    this.changeDetector.markForCheck();
  }

  blockClick(ev: any) {
    if (ev.target.nodeName !== 'IMG' && (this.images.length === 1 || this.previewImage)) {
      this.closeSlider();
    }
  }
}
