import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDateRange, IResumeStats } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import { NO_LOADER_PARAM } from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ResumeStatsService extends BaseHttpService {
  constructor() {
    super('resume-stats');
  }

  getResumeViews(): Observable<{
    count: number;
    tooltip: string;
    tooltipData: string;
  }> {
    return this.get<
      {
        count: number;
        position: string;
        tooltipData: string;
        id?: string;
      }[]
    >({ [NO_LOADER_PARAM]: true }, 'views').pipe(
      map(({ content }) => {
        const res = content || [];

        return res.reduce(
          (acc, el) => {
            acc = {
              count: acc.count || el.count,
              tooltip: acc.tooltip,
              tooltipData:
                res?.length > 1
                  ? acc.tooltipData + `${el?.position} - ${el.count};\n `
                  : ''
            };
            return acc;
          },
          {
            count: 0,
            tooltipData: '; \n',
            tooltip: _('header.tooltip.resumeViewToday')
          } as any
        );
      })
    );
  }

  getGeneralStats(dates: IDateRange[], id: string) {
    return this.post<{ dates: IDateRange[] }, IResumeStats[]>(
      { dates },
      {},
      `${id}/general`
    ).pipe(map(({ content }) => content));
  }
}
