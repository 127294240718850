import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'its-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkillsComponent {
  showedSkills: string[] = [];
  allSills: string[] = [];
  showMore = false;
  @Input() small = false;
  @Input() count: number;

  @Input() set skills(skills: string[]) {
    if (this.count && skills.length > this.count) {
      this.showMore = true;
      this.showedSkills = skills.slice(0, this.count);
    } else {
      this.showedSkills = skills;
    }
    this.allSills = skills;
  }

  showAll(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.showedSkills = this.allSills;
    this.showMore = false;
  }
}
