import { Injectable } from '@angular/core';

import { AWSMediaPathEnum } from '@common/shared/enums';

import { resumeFromResponse } from '@client/shared/utils';

import { environment } from '@env';

import {
  IResumeRequest,
  IResumeResponse,
  IResume,
  IResumeUserRequest
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ResumeFactory {
  toRequest(resume: IResumeRequest): IResumeRequest {
    return {
      ...resume,
      description: resume?.description || '',
      lastExperience: resume?.lastExperience || '',
      sites: resume.sites.filter((s) => !!s && s !== 'https://')
    };
  }

  toRequestUser(user: IResumeUserRequest): IResumeUserRequest {
    return {
      ...user,
      avatar: user.avatar.replace(
        `${environment.storageUrl}/${AWSMediaPathEnum.UserAvatars}/`,
        ''
      )
    };
  }

  fromResponse(resume: IResumeResponse): IResume {
    return resumeFromResponse(resume);
  }
}
