import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';

import { AuthService } from '@core/auth';
import { SeoService } from '@core/services';

import { FormStateDispatcher } from '@client/shared/abstracts';
import { CustomValidators } from '@client/shared/validation';

@Component({
  selector: 'its-resend-confirm',
  templateUrl: './resend-confirm.component.html',
  styleUrls: ['./resend-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormStateDispatcher]
})
export class ResendConfirmComponent implements OnInit {
  form: FormGroup;
  emailWasSend = false;
  constructor(
    readonly fb: FormBuilder,
    readonly changeDetector: ChangeDetectorRef,
    readonly authService: AuthService,
    readonly formStateDispatcher: FormStateDispatcher,
    readonly seoService: SeoService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      username: new FormControl(
        '',
        Validators.compose([Validators.email, Validators.required, CustomValidators.plusInEmail])
      )
    });
  }

  send(form) {
    this.formStateDispatcher.onSubmit.notify();
    if (this.form.valid) {
      return this.authService.resendConfirm(this.form.value).subscribe(() => {
        this.emailWasSend = true;
        // form.reset();
        this.buildForm();
        this.changeDetector.markForCheck();
      });
    }
  }

  backToLogIn() {
    this.authService.toAuth();
  }
}
