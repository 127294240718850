import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { takeUntil } from 'rxjs/operators';

import { ActiveStatusEnum } from '@common/shared/enums';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';

@Component({
  selector: 'its-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class SlideToggleComponent<T extends ActiveStatusEnum>
  extends BaseControlComponent<T>
  implements OnInit
{
  @Input() big = false;
  @Input() ariaLabel: string | null;
  @Input() ariaLabelledby: string | null;
  @Input() checked: boolean;
  @Input() color: ThemePalette;
  @Input() disableRipple: boolean;
  @Input() id: string;
  @Input() labelPosition: 'before' | 'after';
  @Input() name: string | null;
  @Input() required: boolean;

  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Output() readonly changed = new EventEmitter<T | null>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }

  writeValue(value: number): void {
    const result: any = value
      ? ActiveStatusEnum.Active
      : ActiveStatusEnum.Inactive;
    this.control.setValue(this.modelToViewFormatter(result), {
      emitEvent: false
    });
  }

  onChange(ev) {
    const { checked } = ev;
    const result: any = checked
      ? ActiveStatusEnum.Active
      : ActiveStatusEnum.Inactive;
    this.control.setValue(this.modelToViewFormatter(result));
  }
}
