import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { AutoCleanupFeature, Features } from '@client/shared/decorators';
import { ImageTypeEnum } from '@client/shared/enums';

@Component({
  selector: 'its-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class AttachmentComponent<T extends string>
  extends BaseControlComponent<T>
  implements OnInit
{
  @ViewChild('fileInput') fileInput: ElementRef;
  openDrawer = false;
  imageChangedEvent: any;
  imageBase64: string;
  readonly destroyed$: Observable<unknown>;
  @Input() imageType: ImageTypeEnum = ImageTypeEnum.UserAvatar;
  @Input() placeholder: string;
  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }
  @Input() maxlength: number;
  @Input() disableUpload = false;

  @Output() readonly changed = new EventEmitter<T | null>();

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value ? value.trim().replace(/\s{2,}/g, ' ') : value;

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }

  fileUploaded(ev) {
    this.openDrawer = true;
    if (this.imageType === ImageTypeEnum.CompanyLogo) {
      // aspect ratio 2 / 1
      const file = ev.target.files[0] as File;
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = (e) => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const canvas = document.createElement('canvas');
            if (image.height > image.width / 2) {
              canvas.width = image.height * 2;
              canvas.height = image.height;

              canvas
                .getContext('2d')
                .drawImage(
                  image,
                  image.height - image.width / 2,
                  0,
                  image.width,
                  image.height
                );
              this.imageBase64 = canvas.toDataURL();
              resolve(null);
            } else if (image.width / 2 > image.height) {
              canvas.width = image.width;
              canvas.height = image.width / 2;

              canvas
                .getContext('2d')
                .drawImage(
                  image,
                  0,
                  image.width / 4 - image.height / 2,
                  image.width,
                  image.height
                );
              this.imageBase64 = canvas.toDataURL();
            } else {
              this.imageChangedEvent = ev;
            }
          };
        };
        reader.readAsDataURL(file);
      });
    } else {
      this.imageChangedEvent = ev;
    }
  }

  onClosedStart(ev) {
    this.clearUploader();
  }

  getImageResult(image: string) {
    if (image) {
      this.control.setValue(image);
    }
    this.clearUploader();
  }

  clearUploader() {
    this.fileInput.nativeElement.value = '';
    this.openDrawer = false;
  }
}
