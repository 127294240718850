import * as moment from 'moment';

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  SkipSelf,
  Input,
  Optional,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { Features, AutoCleanupFeature } from '@client/shared/decorators';

@Component({
  selector: 'its-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class DatePickerComponent<T extends string>
  extends BaseControlComponent<T>
  implements OnInit
{
  readonly destroyed$: Observable<void>;
  @Input() prefixIcon = '';
  @Input() clear = false;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'multi-year';
  @Input() minimumDate: Date | string;
  @Input() maximumDate: Date | string;
  @Input() icon: string;
  @Input() disableFuture: boolean;
  @Input() disablePast: boolean;
  @Input() placeholder: string;
  @Input() onlyMonth = false;
  @ViewChild(MatDatepicker) readonly picker: MatDatepicker<Date>;

  readonly viewToModelParser = (value: any) => {
    if (value instanceof Date) {
      return moment(value).toISOString();
      /*const utcDate = Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate()
      );
      return new Date(utcDate);*/
    }

    return value;
  };

  get maxDate() {
    return this.maximumDate
      ? new Date(this.maximumDate)
      : this.disableFuture
      ? new Date()
      : null;
  }

  get minDate() {
    return this.minimumDate
      ? new Date(this.minimumDate)
      : this.disablePast
      ? new Date()
      : null;
  }

  readonly control = new FormControl(null);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.picker.open();
    this.onTouched?.();
  }

  onClear() {
    this.control.setValue(null);
  }

  onMonthSelected(date: any) {
    if (this.onlyMonth) {
      this.control.setValue(moment(date).set('date', 10).toISOString());
      this.picker.close();
    }
  }

  openPicker() {
    this.picker.open();
  }

  clearValue() {
    this.control.setValue('');
  }
}
