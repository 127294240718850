import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LoaderService } from '@core/services';

@Component({
  selector: 'its-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {
  loader$ = this.loaderService.getLoader();
  constructor(readonly loaderService: LoaderService) {}

  ngOnInit(): void {}
}
