<ng-container *ngIf="resume">
  <ng-container *ngIf="resume.locked">
    <div class="alert-warn">
      <div>{{ 'resume.alert.locked' | translate }}</div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="!userPreview && !resume.fullDataNotAvailableForCurrentCompany"
  >
    <div class="tags-and-contact">
      <div class="d_f">
        <its-tags [tags]="resume.companyTags"></its-tags>
        <its-tags
          [resumeId]="resume.anonymUuid || resume.id"
          [tags]="resume.tags"
          [owner]="true"
          (allTags)="getAllTags($event)"
        >
        </its-tags>
      </div>

      <div class="a-i_c m-l_a c-g_8">
        <ng-container
          *ngIf="request || invite?.status === InviteStatusEnum.Approve"
        >
          <its-ats-button
            [disabled]="!!resume?.addedToGreenhouse"
            [isRequest]="!!request"
            [job]="request?.job || invite?.job"
            [resume]="resume"
          >
          </its-ats-button>
        </ng-container>
        <ng-container *ngIf="checkContact$ | async as checkContact">
          <div>
            <its-button
              variant="basic"
              color="{{ checkContact.id ? 'warn' : 'accent' }}"
              classes="btn-sm"
              (click)="
                checkContact.id
                  ? removeResumeContact(checkContact.id)
                  : addToContacts()
              "
              [disabled]="!checkContact.canControlContact"
            >
              <its-icon
                size="20"
                svgIcon="{{ checkContact.id ? 'person-remove' : 'person-add' }}"
              ></its-icon>
              <span class="title"
                >{{
                  checkContact.id
                    ? ('resume.button.removeFromContact' | translate)
                    : ('resume.button.saveToContact' | translate)
                }}
              </span>
            </its-button>
            <its-info
              class="talent-pull-info"
              *ngIf="!checkContact.canControlContact"
              [position]="{ overlayX: 'end', overlayY: 'top' }"
              text="{{
                'resume.infoContactDisabled.saveToTalentPool' | translate
              }}"
            ></its-info>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="priorityForm">
    <form [formGroup]="priorityForm" class="priority-form">
      <div
        class="label"
        translate
        [translateParams]="{
          request: request ? 1 : 0
        }"
      >
        resume.label.priority
      </div>
      <div class="form-control">
        <its-select
          formControlName="priority"
          [optional]="false"
          [options]="priorityOptions"
        ></its-select>
      </div>
    </form>
  </ng-container>

  <h2
    *ngIf="resume?.user?.status === ActiveStatusEnum.Deleted"
    class="c-red-active t-t_u a-i_c"
  >
    <its-icon svgIcon="warning"></its-icon>&nbsp;&nbsp;&nbsp;<b>{{
      'common.notification.profileDeleted' | translate
    }}</b
    >&nbsp;&nbsp;&nbsp;<its-icon svgIcon="warning"></its-icon>
  </h2>

  <div class="resume">
    <div class="resume__header">
      <div class="frame-avatar" [class.no-avatar]="!resume?.user?.avatar">
        <div class="avatar">
          <its-avatar
            [avatar]="resume?.user?.avatar"
            [firstName]="resume?.user?.firstName"
            [lastName]="resume?.user?.lastName"
            size="resume"
          ></its-avatar>
        </div>
        <div class="t-a_c">
          {{ 'resume.label.lastActivity' | translate }}&nbsp;{{
            resume.user?.lastActivity | ago
          }}
        </div>
      </div>
      <div>
        <div class="m-b_24 resume-title">
          <div>
            <h1 class="title-lg m-b_0">
              <ng-container
                *ngIf="
                  titleLink &&
                    !userPreview &&
                    (resume?.status === ActiveStatusEnum.Active ||
                      !!resume.anonymUuid);
                  else text
                "
              >
                <a
                  class="t-d_n"
                  routerLink="/{{ ROUTES_DATA.RESUME.url }}/{{
                    resume?.anonymUuid || resume?.id
                  }}"
                  >{{ resume.position }}</a
                >
              </ng-container>
              <ng-template #text>
                {{ resume.position }}
              </ng-template>
            </h1>
            <h2 class="m-b_0 c-black-default" *ngIf="resume?.user?.firstName">
              {{ resume?.user?.firstName }} {{ resume?.user?.lastName }}
            </h2>
          </div>
          <ng-content></ng-content>
        </div>
        <div>
          <div class="resume__data c-default-white">
            <div>
              <div class="data-item">
                <div>
                  <ng-container
                    *ngIf="resume.experienceFrom === null; else exp"
                  >
                    <b>{{ 'resume.text.noExperience' | translate }}</b>
                  </ng-container>
                  <ng-template #exp>
                    <span>{{ 'resume.text.experience' | translate }}</span>
                    -
                    <b>{{
                      'resume.value.experience'
                        | translate: { experience: resume.experience }
                    }}</b>
                  </ng-template>
                </div>
              </div>
              <div class="data-item">
                <span>{{ 'resume.text.englishLevel' | translate }}</span
                >&nbsp;-&nbsp;<b>{{ resume.englishLabel }}</b>
              </div>
              <div class="data-item" *ngIf="resume.languagesValue">
                <span>{{ 'resume.text.additionalLanguages' | translate }}</span
                >&nbsp;-&nbsp;<b>
                  {{ resume.languagesValue }}
                </b>
              </div>
              <div class="data-item" *ngIf="resume.domainsValue">
                <span>{{ 'resume.text.domains' | translate }}</span
                >&nbsp;-&nbsp;<b>
                  {{ resume.domainsValue }}
                </b>
              </div>
            </div>
            <div *ngIf="values$ | async as values">
              <div class="data-item" *ngIf="resume.locationValue">
                <its-icon svgIcon="place"></its-icon>
                <span class="a-i_c">
                  <span>{{ resume.locationValue }}</span>
                  <ng-container
                    *ngIf="
                      resume.locationValue &&
                      resume.workTypesMap[WorkTypeGroupEnum.Place]?.length
                    "
                    >&nbsp;/&nbsp;
                  </ng-container>
                  <ng-container
                    *ngFor="
                      let type of resume.workTypesMap[WorkTypeGroupEnum.Place];
                      let l = last
                    "
                  >
                    <span>{{
                      values.workTypesTranslate[type.value] | translate
                    }}</span
                    >{{ l ? '' : '&nbsp;/&nbsp;' }}
                  </ng-container>
                </span>
              </div>
              <div class="data-item">
                <its-icon svgIcon="time"></its-icon>
                <span class="a-i_c">
                  <ng-container
                    *ngIf="resume.workTypesMap[WorkTypeGroupEnum.Time]?.length"
                  >
                    <ng-container
                      *ngFor="
                        let type of resume.workTypesMap[WorkTypeGroupEnum.Time];
                        let l = last
                      "
                    >
                      <span>{{
                        values.workTypesTranslate[type.value] | translate
                      }}</span
                      >{{ l ? ',&nbsp;' : '&nbsp;/&nbsp;' }}
                    </ng-container>
                    <span>
                      <ng-container *ngIf="resume?.hourRate"
                        >&nbsp;-&nbsp;<span
                          class="hour-rate"
                          translate
                          [translateParams]="{ rate: resume.hourRate }"
                          >resume.text.hourRate</span
                        ></ng-container
                      >
                    </span>
                  </ng-container>
                  <span
                    >{{ 'resume.text.readyToWork' | translate }}:&nbsp;
                    <span
                      [class.c-green-active]="
                        resume.readyToWork === ReadyToWorkEnum.Asap
                      "
                      >{{
                        values.readyToWorkMap[resume.readyToWork] | translate
                      }}</span
                    ></span
                  >
                </span>
              </div>
              <div *ngIf="resume.email" class="data-item a-i_c">
                <its-icon svgIcon="email"></its-icon>
                <span>{{ resume.email }}</span>
              </div>
              <div *ngIf="resume.phone" class="data-item a-i_c">
                <its-icon svgIcon="phone"></its-icon>
                <span>{{ resume.phone }}</span>
              </div>
            </div>
          </div>
          <div class="j-c_s-b c-g_24">
            <its-categories [categories]="resume.categories"></its-categories>
            <its-social-links
              [disabled]="resume.fullDataNotAvailableForCurrentCompany"
              [socialLinks]="resume.sites"
            ></its-social-links>
          </div>
        </div>
      </div>
    </div>

    <mat-card [class.no-shadow]="titleLink">
      <mat-card-content>
        <div *ngIf="resume.hardSkills?.length" class="resume-section">
          <h3>
            {{ 'resume.text.hardSkills' | translate }}
          </h3>
          <div class="resume-section__content">
            <p class="resume__description">
              <its-skills [skills]="resume.hardSkills"></its-skills>
            </p>
          </div>
        </div>

        <div class="resume-section">
          <h3>
            {{ 'resume.text.lastExperience' | translate }}
          </h3>
          <div class="resume-section__content">
            <div class="editor-html" [innerHTML]="resume.lastExperience"></div>
          </div>
        </div>
        <div
          *ngIf="resume.description || resume.veteran"
          class="resume-section"
        >
          <h3>
            {{ 'resume.text.userInfo' | translate }}
          </h3>
          <div class="resume-section__content">
            <ng-container *ngIf="resume.description">
              <div class="editor-html" [innerHTML]="resume.description"></div>
            </ng-container>
            <ng-container *ngIf="resume.veteran">
              <p class="resume__description">
                <span>{{ 'resume.text.veteran' | translate }}</span>
              </p>
            </ng-container>
          </div>
        </div>
        <div *ngIf="resume?.files?.length" class="resume-section">
          <h3>
            {{ 'resume.text.attachedFiles' | translate }}
          </h3>
          <div class="resume-section__content">
            <ul>
              <li *ngFor="let file of resume.files; let i = index">
                <its-file [file]="file"></its-file>
              </li>
            </ul>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
