import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { LogicConditionEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const LOGIC_CONDITIONS: ISelectOption<string>[] = [
  {
    id: LogicConditionEnum.And,
    value: _('logicConditions.and')
  },
  {
    id: LogicConditionEnum.Or,
    value: _('logicConditions.or')
  }
];
