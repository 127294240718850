<ng-container *ngIf="templates$ | async as templates">
  <its-button
    variant="icon"
    matTooltip="{{ 'templateMenu.tooltip.templates' | translate }}"
    [matMenuTriggerFor]="templateMenu"
    matTooltipPosition="above"
  >
    <its-icon svgIcon="template"></its-icon>
  </its-button>

  <mat-menu #templateMenu="matMenu" xPosition="before" yPosition="above">
    <ng-container *ngIf="templates?.length">
      <ng-container *ngFor="let template of templates">
        <button mat-menu-item (click)="selectTemplate(template)">
          {{ template.title }}
          <ng-container *ngIf="!template.description">
            <small>({{ 'templateMenu.info.empty' | translate }})</small>
          </ng-container>
        </button>
      </ng-container>
    </ng-container>
    <button
      mat-menu-item
      (click)="createTemplate()"
      matTooltip="{{
        templates?.length
          ? ''
          : ('templateMenu.tooltip.createFirst' | translate)
      }}"
      matTooltipPosition="above"
    >
      <div class="a-i_c">
        <span>{{
          templates?.length
            ? ('templateMenu.option.createTemplates' | translate)
            : ('templateMenu.option.noTemplates' | translate)
        }}</span>
        <its-icon class="i-m-r_0 m-l_8" svgIcon="add"></its-icon>
      </div>
    </button>
  </mat-menu>
</ng-container>
