import {
  CdkDragDrop,
  DropListOrientation,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  Optional,
  SkipSelf,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { Observable } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/operators';

import { LocaleService, NotificationService } from '@core/services';

import {
  BaseControlComponent,
  FormStateDispatcher
} from '@client/shared/abstracts';
import { Features, AutoCleanupFeature } from '@client/shared/decorators';

const AUTOCOMPLETE_LIMIT = 10;

@Component({
  selector: 'its-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ChipsComponent<T extends string[]>
  extends BaseControlComponent<T>
  implements OnInit
{
  @ViewChild('inputEl', { static: false })
  inputEl: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  inputControl = new FormControl();
  autocompleteResult = false;
  @Input() drag = false;
  @Input() dragDirection: DropListOrientation = 'vertical';
  @Input() list: boolean;
  @Input() icon: string;
  @Input() placeholder: string;
  @Input() visible = true;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER];

  @Input()
  @HostBinding('attr.disabled')
  set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  @Input() maxlength = 200;
  _options: string[] = [];
  @Input() set options(options: string[]) {
    if (options?.length) {
      this._options = options;
      this.setupAutocompleteListener();
    }
  }

  get options() {
    return this._options;
  }

  @Output() readonly changed = new EventEmitter<T | null>();

  readonly control = new FormControl(null);
  filteredOptions$: Observable<string[]>;
  filteredOptions: string[] = [];

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: FormStateDispatcher | null,
    readonly notificationService: NotificationService,
    readonly localeService: LocaleService
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: T) => {
        this.changed.emit(this.viewToModelParser(value) as T);
      });

    this.formState?.onSubmit.listen
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.control.markAsTouched();
        this.changeDetector.markForCheck();
      });

    this.ctrl.control?.statusChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const errors = this.ctrl.control?.errors ?? null;

        this.control.setErrors(errors);
        this.changeDetector.markForCheck();
      });
  }

  onEnterKeyDown(event: Event) {
    const ev = event as KeyboardEvent;
    ev.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value ? value.trim().replace(/\s{2,}/g, ' ') : value;

    if (trimmedValue !== value) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }

  trackByFn(i: number, option: string) {
    return option;
  }

  onSetValue = (value, input = null) => {
    if ((value || '').trim()) {
      if (this.maxlength && value?.length > this.maxlength) {
        this.notificationService.showError(
          this.localeService.getInstant('common.form.errors.max-length', {
            maxlength: this.maxlength
          })
        );
        return;
      }

      this.control.setValue([...new Set([...this.control.value, value])]);
    }
    if (input) {
      input.value = '';
    }
  };

  add(event: MatChipInputEvent): void {
    const input = event.input;
    if (!this.autocompleteResult) {
      this.onSetValue(event.value, input);
    } else {
      if (this.filteredOptions?.length === 1) {
        this.onSetValue(this.filteredOptions[0], input);
        this.filteredOptions = [];
        this.inputControl.setValue(null);
        this.changeDetector.detectChanges();
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text') || '';

    let data = [];
    if (this.dragDirection === 'vertical') {
      data = pastedText.split('\n');
    } else {
      data = pastedText.split(', ');
    }

    if (data.length) {
      data.forEach((v) => {
        const value = v.trim().replace(/^[-—]/, '');
        this.onSetValue(value);
      });
      event.preventDefault();
    }
  }

  remove(option: string): void {
    const index = this.control.value.indexOf(option);

    if (index >= 0) {
      this.control.setValue([
        ...this.control.value.filter((el, i) => i !== index)
      ]);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    const items = this.control.value;
    moveItemInArray(items, event.previousIndex, event.currentIndex);
    this.control.setValue(items);
  }

  /**
   * AUTOCOMPLETE
   */

  setupAutocompleteListener() {
    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      map((fruit: string | null) => {
        const result = fruit
          ? this._filter(fruit).slice(0, AUTOCOMPLETE_LIMIT)
          : [];
        this.filteredOptions = result;
        return result;
      })
    );
  }

  selectOption(event: MatAutocompleteSelectedEvent) {
    const value = event?.option?.viewValue;
    this.autocompleteResult = true;
    if (value) {
      this.control.setValue([...this.control.value, value]);
      this.inputControl.setValue(null);
      this.inputEl.nativeElement.value = '';
      this.changeDetector.detectChanges();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const rest = [];
    const directFilter = this.options.filter((o) => {
      const condition = o.toLowerCase().indexOf(filterValue) === 0;
      if (!condition) {
        rest.push(o);
      }
      return condition;
    });

    this.autocompleteResult = !!directFilter?.length;

    return [
      ...directFilter,
      ...(directFilter?.length <= AUTOCOMPLETE_LIMIT
        ? rest.filter((o) => o.toLowerCase().indexOf(filterValue) !== -1)
        : [])
    ];
  }

  editChip(option: string) {
    this.control.setValue(
      (this.control?.value || []).filter((v) => v !== option)
    );
    setTimeout(() => {
      this.inputEl.nativeElement.value = option;
      this.inputEl.nativeElement.focus();
    }, 0);
  }
}
