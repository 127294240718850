import { Injectable } from '@angular/core';

import { BehaviorSubject, tap } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';

import { AuthService } from '@core/auth';

import { BaseHttpService } from '@client/shared/abstracts';
import { ITag } from '@client/shared/interfaces';

import { TagsFactory } from './tags.factory';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends BaseHttpService {
  allTags$ = new BehaviorSubject<ITag[]>([]);

  constructor(
    readonly factory: TagsFactory,
    readonly authService: AuthService
  ) {
    super('tags');
    this.authService
      .userAuthToggle()
      .pipe(startWith(true), withLatestFrom(this.authService.getProfile()))
      .subscribe(([login, profile]) => {
        if (login && profile?.company?.isActive) {
          this.loadAllTags();
        } else {
          this.setAllTags();
        }
      });
  }

  getTags() {
    return this.get<ITag[]>().pipe(
      map(({ content }) => content.map((t) => this.factory.fromResponse(t)))
    );
  }

  createTag(tag: ITag) {
    return this.post<ITag, ITag>(tag).pipe(
      map(({ content }) => {
        const tag = this.factory.fromResponse(content);
        this.setAllTags([tag, ...this.allTags$.getValue()]);
        return tag;
      })
    );
  }

  setResumeTag(tagId: number, resumeId: string) {
    return this.post<any>(null, {}, `${tagId}/resume/${resumeId}`).pipe(
      map(({ content }) => this.factory.fromResponse(content))
    );
  }

  removeResumeTag(tagId: number, resumeId: string) {
    return this.delete<any>({}, `${tagId}/resume/${resumeId}`);
  }

  deleteTag(id: number) {
    return this.delete<any>({}, id).pipe(
      tap(() => {
        this.setAllTags(this.allTags$.getValue().filter((t) => t.id !== id));
      })
    );
  }

  /**
   * Manage tags
   */
  private loadAllTags() {
    this.getTags().subscribe((tags) => {
      this.setAllTags(tags);
    });
  }

  getAllTags() {
    return this.allTags$.asObservable();
  }

  private setAllTags(value: ITag[] = []) {
    this.allTags$.next(value);
  }
}
