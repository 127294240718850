import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { AWSMediaPathEnum } from '@common/shared/enums';
import { createTextLinks } from '@common/shared/helpers';

import { environment } from '@env';

import { IMessage, IMessageRequest, IMessageResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MessageFactory {
  constructor() {}

  toRequest(message: IMessageRequest): IMessageRequest {
    return {
      ...message
    };
  }

  fromResponse(message: IMessageResponse, userId: string): IMessage {
    // console.log(moment().tz('Europe/Kiev').format('HH:mm'));
    // console.log(moment(message.created).tz('Europe/Kiev').format('HH:mm'));
    // console.log(moment().tz(moment.tz.guess()).format());
    // console.log(moment.tz.guess());
    return {
      ...message,
      message: createTextLinks(message.message),
      owner: message?.user?.id === userId,
      created: moment().isSame(message.created, 'day')
        ? moment(message.created).format('HH:mm')
        : moment(message.created).format('DD MMM YYYY, HH:mm'),
      files: (message?.files || []).map((f) => {
        f.path = `${environment.serverUrl}/${AWSMediaPathEnum.ChatFiles}/${f.filename}`;
        return f;
      })
    };
  }
}
