import {
  Component,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';

import { IQuery } from '@common/shared/interfaces';

@Component({
  selector: 'its-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent {
  @Input() color: ThemePalette;
  @Input() disabled: boolean;
  @Input() hidePageSize: boolean;
  @Input() pageSize = 20;
  @Input() total: number;
  _pageIndex: number | undefined;
  @Input() set pageIndex(index: number | undefined) {
    if (index) {
      this._pageIndex = index - 1;
    } else {
      this._pageIndex = 0;
    }
  };
  get pageIndex() {
    return this._pageIndex;
  }
  @Input() pageSizeOptions: number[];
  @Input() showFirstLastButtons = true;

  @Output() page = new EventEmitter<IQuery>();

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  pageEvent(ev: PageEvent) {
    this.page.emit({
      page: ev.pageIndex + 1,
      size: ev.pageSize
    });
  }
}
