import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { CompanyReviewsFactory } from '@modules/companies/services/company-reviews.factory';

import { ICountResponse, IQuery } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';

import { ICompanyReview } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CompanyReviewsService extends BaseHttpService {
  constructor(
    readonly router: Router,
    readonly factory: CompanyReviewsFactory
  ) {
    super('reviews');
  }

  getCompanyReviews(companyId: number, params: IQuery) {
    return this.get<ICountResponse<ICompanyReview[]>>(params, companyId).pipe(
      map(({ content }) => ({
        data: content.data.map((e) => this.factory.fromResponse(e)),
        count: content.count
      }))
    );
  }

  getReviewsReplies(companyId: number, parentId: number, params: IQuery) {
    return this.get<ICompanyReview[]>(params, `${companyId}/${parentId}`).pipe(
      map(({ content }) => content.map(e => this.factory.fromResponse(e)))
    );
  }

  createReview(review: ICompanyReview, companyId: number, parentId = null) {
    return this.post<ICompanyReview, ICompanyReview>(
      review,
      {},
      parentId ? `reply/${parentId}` : companyId
    ).pipe(map(({ content }) => this.factory.fromResponse(content)));
  }

  deleteReview(id: number) {
    return this.delete({}, id);
  }
}
