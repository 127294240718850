<div class="social-links">
  <ng-container *ngFor="let item of socialLinks">
    <a
      [class.disabled]="disabled"
      [href]="item"
      target="_blank"
      (click)="openLink($event)"
      [matTooltip]="item"
    >
      <its-icon
        [size]="size"
        [svgIcon]="getIcon(item) || 's-unknown'"
      ></its-icon>
    </a>
  </ng-container>
</div>
