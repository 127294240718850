import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  FACEBOOK_LINK,
  HELLO_EMAIL,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  TELEGRAM_LINK
} from '@common/shared/constants';

export const CONTACTS = [
  {
    icon: 's-linkedin',
    link: LINKEDIN_LINK,
    label: 'Linkedin',
    target: '_blank',
    tooltip: ''
  },
  {
    icon: 's-facebook',
    link: FACEBOOK_LINK,
    label: 'Facebook',
    target: '_blank',
    tooltip: ''
  },
  {
    icon: 's-instagram',
    link: INSTAGRAM_LINK,
    label: 'Instagram',
    target: '_blank',
    tooltip: ''
  },
  {
    icon: 's-telegram',
    link: TELEGRAM_LINK,
    label: 'Telegram',
    target: '_blank',
    tooltip: ''
  },
  {
    icon: 's-email',
    link: `mailto:${HELLO_EMAIL}`,
    label: 'Contact us with email',
    target: '',
    tooltip: _('social.tooltip.contactUsWithEmail')
  }
];

@Component({
  selector: 'its-kupno-social',
  templateUrl: './kupno-social.component.html',
  styleUrls: ['./kupno-social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KupnoSocialComponent implements OnInit {
  contacts = CONTACTS;

  constructor() {}

  ngOnInit(): void {}
}
