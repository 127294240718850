import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService, IProfile } from '@core/auth';

@Injectable({
  providedIn: 'root'
})
export class CompanyOwnerGuard implements CanActivate {
  constructor(readonly authService: AuthService, readonly router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.getProfile(false).pipe(
      take(1),
      map((profile: IProfile) => {
        if (profile?.company?.isOwner) {
          return true;
        }
        this.router.navigateByUrl('/');
        return false;
      })
    );
  }
}
