<footer>
  <div class="footer">
    <div class="footer__left">
      <img class="logo" [src]="footerLogo" alt="Kupno" />
      <div class="footer-pages">
        <a [routerLink]="'/' + ROUTES_DATA.CONTACTS.url">{{
          'public.page.contacts' | translate
        }}</a>
        <a [routerLink]="'/' + ROUTES_DATA.PRICING.url">{{
          'public.page.pricing' | translate
        }}</a>
        <a [routerLink]="'/' + ROUTES_DATA.PUBLIC.children.LEGAL.url">{{
          'public.page.termsOfService' | translate
        }}</a>
        <a [routerLink]="'/' + ROUTES_DATA.PUBLIC.children.POLICY.url">{{
          'public.page.privacyPolicy' | translate
        }}</a>
      </div>
    </div>
    <div class="footer__right">
      <div class="mastercard">
        <img src="/assets/v1/icons/mastercard.svg" alt="Mastercard" />
      </div>
      <div class="visa">
        <img src="/assets/v1/icons/visa.svg" alt="Visa" />
      </div>
      <!-- <div class="liqpay">
         <img src="/assets/icons/liqpay.svg" alt="Visa" />
       </div>-->
      <span>© {{ year }} Kupno</span>
    </div>
  </div>
  <its-kupno-social></its-kupno-social>
</footer>
