<div class="modal">
  <its-button
    *ngIf="!data.disableClose"
    class="close"
    variant="icon"
    mat-dialog-close
  >
    <its-icon size="18" svgIcon="clear"></its-icon>
  </its-button>
  <div *ngIf="data.html; else default" [innerHTML]="data.html"></div>
  <ng-template #default>
    <div mat-dialog-title>{{ data.header | translate }}</div>
    <div mat-dialog-content>
      <p>{{ data.body | translate }} {{ data?.plusBody }}</p>
    </div>
    <div mat-dialog-actions>
      <its-button
        *ngIf="data.buttons[0]"
        [mat-dialog-close]="false"
        variant="stroked"
        color="accent"
      >
        {{ data.buttons[0].name | translate }}
      </its-button>

      <its-button
        *ngIf="data.buttons[1]"
        (click)="confirm()"
        [color]="data.buttons[1]?.warn ? 'warn' : 'primary'"
      >
        {{ data.buttons[1].name | translate }}
      </its-button>
    </div>
  </ng-template>
</div>
