<ng-container *ngIf="values$ | async as values">
  <div class="modal">
    <its-button class="close" variant="icon" mat-dialog-close>
      <its-icon size="18" svgIcon="clear"></its-icon>
    </its-button>
    <h1 mat-dialog-title>
      {{
        (data.invite
          ? 'reqInvStateForm.modal.title.invite'
          : 'reqInvStateForm.modal.title.request'
        ) | translate
      }}
    </h1>
    <div mat-dialog-content>
      <ul class="list-items state-list">
        <ng-container *ngFor="let state of stateHistory; let i = index">
          <li>
            <div class="j-c_s-b a-i_c">
              <h4 class="m-b_0 a-i_f-e c-g_16">
                <span>{{
                  values.reqInvStateMap[state.state] | translate
                }}</span>
                <span *ngIf="state.date" class="date">
                  <its-icon svgIcon="calendar"></its-icon>
                  <small>{{ state.date | date }}</small>
                </span>
              </h4>
              <div class="a-i_c">
                <its-button
                  class="state-actions"
                  [class.disabled]="!data.edit"
                  (click)="data.edit && editState(state)"
                  variant="icon"
                >
                  <its-icon size="16" svgIcon="pencil"></its-icon>
                </its-button>
                <its-button
                  class="state-actions"
                  [class.disabled]="!data.edit"
                  (click)="data.edit && deleteState(state.id, i)"
                  variant="icon"
                  color="warn"
                >
                  <its-icon size="16" svgIcon="delete_outline"></its-icon>
                </its-button>
              </div>
            </div>

            <div class="m-l_12 state-description">
              {{ state.stateDescription }}
            </div>
          </li>
        </ng-container>
      </ul>

      <ng-container *ngIf="data.edit">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="saveState()">
          <div class="two-col">
            <div class="form-control">
              <its-select
                formControlName="state"
                placeholder="{{
                  'reqInvStateForm.state.placeholder' | translate
                }}"
                [optional]="false"
                [options]="values.reqInvState"
                [clear]="true"
              ></its-select>
            </div>

            <div class="form-control">
              <div class="date-control">
                <its-date-picker
                  formControlName="date"
                  startView="month"
                  [clear]="true"
                  prefixIcon="calendar"
                  placeholder="{{
                    'reqInvStateForm.date.placeholder' | translate
                  }}"
                ></its-date-picker>
                <its-info
                  text="{{ 'reqInvStateForm.date.info' | translate }}"
                ></its-info>
              </div>
            </div>
          </div>

          <div class="form-control">
            <its-textarea
              formControlName="stateDescription"
              [rows]="1"
              [autoHeight]="true"
              placeholder="{{
                'reqInvStateForm.stateDescription.placeholder' | translate
              }}"
            ></its-textarea>
          </div>

          <div class="j-c_c">
            <ng-container *ngIf="form.get('id').value; else create">
              <div class="a-i_c c-g_16">
                <its-button
                  (click)="resetForm()"
                  variant="stroked"
                  color="accent"
                >
                  {{ 'common.form.button.cancel' | translate }}
                </its-button>
                <its-button type="submit" color="primary">
                  {{ 'common.form.button.update' | translate }}
                </its-button>
              </div>
            </ng-container>
            <ng-template #create>
              <its-button type="submit" color="primary">
                {{ 'reqInvStateForm.button.addState' | translate }}
              </its-button>
            </ng-template>
          </div>
        </form>
      </ng-container>
    </div>
    <div mat-dialog-actions>
      <its-button
        type="button"
        variant="stroked"
        color="accent"
        (click)="onNoClick()"
      >
        {{ 'common.form.button.close' | translate }}
      </its-button>
    </div>
  </div>
</ng-container>
