import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ExperiencesEnum } from '@common/shared/enums';

export const EXPERIENCES = Object.freeze([
  { id: ExperiencesEnum.NoMather, value: _('experience.noMather') },
  { id: ExperiencesEnum.OneYear, value: _('experience.oneYear') },
  { id: ExperiencesEnum.TwoYears, value: _('experience.twoYears') },
  { id: ExperiencesEnum.ThreeYears, value: _('experience.threeYears') },
  { id: ExperiencesEnum.FiveYears, value: _('experience.fiveYears') },
  { id: ExperiencesEnum.SevenYears, value: _('experience.sevenYears') }
]);
