import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';

import { SOCIAL_ICONS } from '@client/shared/constants';

@Component({
  selector: 'its-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialLinksComponent implements OnInit {
  socialIcons = SOCIAL_ICONS;
  @Input() disabled = false;
  @Input() socialLinks: string[];
  @Input() size = 40;

  constructor() {}

  ngOnInit(): void {}

  getIcon(url: string) {
    // const icon = 's-unknown';
    const icon = '';
    try {
      const hostname = new URL(url).hostname;
      const key = Object.keys(this.socialIcons).find((i) =>
        hostname.includes(i)
      );
      if (key) {
        return this.socialIcons[key];
      }
    } catch (e) {}
    return icon;
  }

  openLink(ev) {
    if (this.disabled) {
      ev.preventDefault();
    }
  }
}
