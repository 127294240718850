export interface ITag {
  id: number;
  value: string;
  color: string;
  created?: Date;
  user?: {
    firstName: string;
    lastName: string;
  }
}
