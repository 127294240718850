import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { map } from 'rxjs/operators';

import { GlobalObjectService } from '@core/services/global-object.service';

import { COOKIE_CID_KEY } from '@common/shared/constants';
import { deleteCookie, getCookie, setCookie } from '@common/shared/helpers';
import { IQuery, ITracking } from '@common/shared/interfaces';

import { BaseHttpService } from '@client/shared/abstracts';
import {
  UTM_TRACK_COOKIE_KEY,
  UTM_TRACK_PARAM
} from '@client/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class TrackingService extends BaseHttpService {
  constructor(
    readonly globalObjectService: GlobalObjectService,
    readonly router: Router
  ) {
    super('tracking');
  }

  setupTracking() {
    const urlParams = new URLSearchParams(
      this.globalObjectService.getWindow().location.search
    );
    const utm = urlParams.get(UTM_TRACK_PARAM);

    if (utm) {
      // save UTM to cookies
      setCookie(UTM_TRACK_COOKIE_KEY, utm, 30);
      setTimeout(() => {
        this.router.navigate([], {
          queryParams: {
            [UTM_TRACK_PARAM]: null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });
      }, 0)

    }

    const utmCookie = this.getTrackCookies();
    if (utmCookie) {
      try {
        const utmConfig = JSON.parse(atob(utmCookie));
       /* {
          email: '',
          companyName: ''
        }*/
        const utmData = {
          cid: getCookie(COOKIE_CID_KEY),
          firstVisit: moment().toISOString(),
          utm: utmCookie,
          ...utmConfig,
          data: utmConfig
        };

        this.trackUser(utmData).subscribe();

        this.router.events.subscribe((event: any) => {
          if (event instanceof NavigationEnd) {
            const link = event?.url;
            if (link) {
              this.trackUser({
                cid: getCookie(COOKIE_CID_KEY),
                visitedPages: [event?.url]
              }).subscribe();
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  getTrackCookies() {
    return getCookie(UTM_TRACK_COOKIE_KEY);
  }

  getTrackingData(query: IQuery) {
    return this.get<ITracking[]>(query).pipe(map(({ content }) => content));
  }

  trackUser(tracking: ITracking) {
    return this.post<ITracking>(tracking).pipe(map(({ content }) => content));
  }

  checkAuthUsers() {
    if (this.getTrackCookies()) {
      deleteCookie(UTM_TRACK_COOKIE_KEY); // stop tracking
      this.trackUser({
        cid: getCookie(COOKIE_CID_KEY),
        accountCreated: moment().toISOString()
      }).subscribe();
    }
  }
}
