import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { SettingsService } from '@modules/settings/services';

import { ValuesService } from '@core/services';

import { FilterInformIntervalEnum } from '@common/shared/enums';

import { SearchTypeEnum } from '@client/shared/enums';
import { IFilterForm, IValues } from '@client/shared/interfaces';

@Component({
  selector: 'its-modal-save-filter',
  templateUrl: './modal-save-filter.component.html',
  styleUrls: ['./modal-save-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSaveFilterComponent implements OnInit {
  values$: Observable<IValues> = this.valuesService.getValues();
  form: FormGroup;
  SearchTypeEnum = SearchTypeEnum;
  constructor(
    readonly dialogRef: MatDialogRef<ModalSaveFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterForm,
    readonly dialog: MatDialog,
    readonly valuesService: ValuesService,
    readonly settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      filterName: new FormControl(
        {
          value: this.data?.filterName || this.data?.currentJob?.value || '',
          disabled: !!this.data?.job
        },
        Validators.compose([Validators.maxLength(70), Validators.required])
      ),
      interval: new FormControl(
        this.data?.interval || FilterInformIntervalEnum.MondayToFriday10AM,
        Validators.required
      )
    });
  }

  onNoClick(data = null): void {
    this.dialogRef.close(data);
  }

  saveFilter() {
    if (this.form.valid) {
      const value = this.form.getRawValue();
      const data = {
        ...value,
        ...this.data
      };

      switch (data.search) {
        case SearchTypeEnum.Jobs:
          (this.data.id
            ? this.settingsService.updateJobFilter(this.data.id, value)
            : this.settingsService.saveJobFilter(data)
          ).subscribe((res) => {
            this.onNoClick(res);
          });

          break;
        case SearchTypeEnum.Resume:
          (this.data.id
            ? this.settingsService.updateResumeFilter(this.data.id, value)
            : this.settingsService.saveResumeFilter(data)
          ).subscribe((res) => {
            this.onNoClick(res);
          });
          break;
      }
    }
  }
}
