import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { ShareModule } from 'ngx-sharebuttons';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';

import { environment } from '@env';

import { SHARED_COMPONENTS, MATERIAL_MODULES } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { PIPES } from './pipes';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ImageCropperModule,
  ScrollingModule,
  DragDropModule,
  PerfectScrollbarModule,
  TranslateModule,
  ShareModule,
  GoogleMapsModule,
  RecaptchaV3Module,
  NgChartsModule,
  NgxSliderModule,
  PickerModule,
  QuillModule
];

@NgModule({
  imports: [...MATERIAL_MODULES, ...MODULES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...PIPES],
  exports: [
    ...MODULES,
    ...SHARED_COMPONENTS,
    ...MATERIAL_MODULES,
    ...SHARED_DIRECTIVES,
    ...PIPES
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleRecaptchaKey
    }
  ]
})
export class SharedModule {}
