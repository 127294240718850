export enum UkrainianRegionsEnum {
  VinnytsiaOblast = 'UA-05',
  VolynOblast = 'UA-07',
  LuhanskOblast = 'UA-09',
  DniproOblast = 'UA-12',
  DonetskOblast = 'UA-14',
  ZhytomyrOblast = 'UA-18',
  ZakarpattiaOblast = 'UA-21',
  ZaporizhiaOblast = 'UA-23',
  IvanoFrankivskOblast = 'UA-26',
  KyivCity = 'UA-30',
  KyivOblast = 'UA-32',
  KropyvnytskyiOblast = 'UA-35',
  SevastopolCity = 'UA-40',
  Crimea = 'UA-43',
  LvivOblast = 'UA-46',
  MykolaivOblast = 'UA-48',
  OdessaOblast = 'UA-51',
  PoltavaOblast = 'UA-53',
  RivneOblast = 'UA-56',
  SumyOblast = 'UA-59',
  TernopilOblast = 'UA-61',
  KharkivOblast = 'UA-63',
  KhersonOblast = 'UA-65',
  KhmelnytskOblast = 'UA-68',
  CherkasyOblast = 'UA-71',
  ChernihivOblast = 'UA-74',
  ChernivtsiOblast = 'UA-77',
}
