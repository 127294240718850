<!--<mat-drawer-container
  [autosize]="autosize"
  [hasBackdrop]="hasBackdrop"
  (backdropClick)="onBackdropClick($event)"
>
  <mat-drawer-content>
    <ng-content select="[page-content]"></ng-content>
  </mat-drawer-content>

  <mat-drawer
    [opened]="opened"
    [mode]="mode"
    [disableClose]="disableClose"
    [autoFocus]="autoFocus"
    [position]="position"
    (closedStart)="onClosedStart($event)"
    (positionChanged)="onPositionChanged($event)"
    (openedChange)="onOpenedChange($event)"
    (openedStart)="onOpenedStart($event)"
  >
    <ng-content select="[drawer-content]"></ng-content>
  </mat-drawer>
</mat-drawer-container>-->

<!--<div class="drawer">
  <mat-sidenav-container>
    <mat-sidenav
      [mode]="opened ? 'over' : 'side'"
      [opened]="opened"
      [scrollTop]="0"
      [position]="position"
      [fixedInViewport]="fixedInViewport"
    >


      <ng-content select="[drawer-content]"></ng-content>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content select="[page-content]"></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>-->

<mat-sidenav-container
  [autosize]="autosize"
  [hasBackdrop]="hasBackdrop"
  (backdropClick)="onBackdropClick($event)"
>
  <div class="mat-drawer-backdrop"></div>
  <mat-sidenav
    [opened]="opened"
    [mode]="opened ? 'over' : 'side'"
    [position]="position"
    [fixedInViewport]="fixedInViewport"
    (closedStart)="onClosedStart($event)"
    (positionChanged)="onPositionChanged($event)"
    (openedChange)="onOpenedChange($event)"
    (openedStart)="onOpenedStart($event)"
  >
    <div #drawerContent class="drawer">
      <div class="drawer__close">
        <its-button color="primary" variant="icon" (click)="closeDrawer()">
          <its-icon size="18" svgIcon="clear"></its-icon>
        </its-button>
      </div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content select="[page-content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
