import { Injectable } from '@angular/core';

import { IJobApplyRequest, IJobResponse } from '@modules/jobs/interfaces';
import { JobsFactory } from '@modules/jobs/services/jobs.factory';

import { ANONYMOUS_VALUE } from '@common/shared/constants';

import { resumeFromResponse } from '@client/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class JobsRequestFactory {
  constructor(readonly jobFactory: JobsFactory) {}

  fromResponse(response: IJobApplyRequest): IJobApplyRequest {
    return response
      ? {
          ...response,
          job: this.jobFactory.fromResponse(response.job as IJobResponse),
          resume: resumeFromResponse(response.resume),
          user: response.user
            ? {
                ...response.user,
                fullName: response.user?.firstName
                  ? `${response.user?.firstName} ${response.user?.lastName}`
                  : ANONYMOUS_VALUE
              }
            : null
        }
      : null;
  }
}
