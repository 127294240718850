import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { CloseVacancyReasonEnum } from '@common/shared/enums';

import { ISelectOption } from '@client/shared/components/select';

export const CLOSE_VACANCY_REASONS: ISelectOption[] = [
  {
    id: CloseVacancyReasonEnum.WithKupno,
    value: _('closeVacancyReason.withKupno')
  },
  {
    id: CloseVacancyReasonEnum.WithOther,
    value: _('closeVacancyReason.withOther')
  },
  {
    id: CloseVacancyReasonEnum.VacancyCanceled,
    value: _('closeVacancyReason.vacancyCanceled')
  }
];
