<mat-icon
  [class.has-theme-color]="color"
  [color]="color"
  [svgIcon]="svgIcon"
  style="{{
    size
      ? 'width: ' +
        size +
        'px; height: ' +
        size +
        'px;' +
        'font-size: ' +
        size +
        'px'
      : ''
  }}"
  [class]="classes"
  ><ng-content></ng-content
></mat-icon>
