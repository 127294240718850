import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { ISelectOption } from '@client/shared/components/select';

export const HIRING_STEPS: ISelectOption[] = [
  {
    id: 'hrStep',
    value: _('hiringStep.hrStep')
  },
  {
    id: 'technicalStep',
    value: _('hiringStep.technicalStep')
  },
  {
    id: 'notTechnicalStep',
    value: _('hiringStep.pmStep')
  },
  {
    id: 'teamStep',
    value: _('hiringStep.teamStep')
  },
  {
    id: 'customerStep',
    value: _('hiringStep.customerStep')
  },
  {
    id: 'technicalCustomerStep',
    value: _('hiringStep.technicalCustomerStep')
  },
  {
    id: 'coddingStep',
    value: _('hiringStep.coddingStep')
  },
  {
    id: 'testTaskStep',
    value: _('hiringStep.testTaskStep')
  }
];
